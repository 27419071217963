import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { GlobalButton } from './UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { useNavigate } from 'react-router-dom';
import { PlanCycle } from '@/enums/billing';
import { useGlobalContext } from '../../../core/helpers';
import { Roles } from '@/enums/organisation';
import { InputToolTip } from './UI/InputToolTip';

export const SelectPlanModal = ({
  selectPlanModal,
  setSelectPlanModal,
  modalText,
}: {
  selectPlanModal: boolean;
  setSelectPlanModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalText: string;
}) => {
  const { formatMessage } = useIntl();
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [active, setActive] = useState<PlanCycle>(PlanCycle.YEARLY);
  const navigate = useNavigate();
  const { currentUser } = useGlobalContext();
  return (
    <>
      <div>
        <Modal
          show={selectPlanModal}
          onHide={() => setSelectPlanModal(!selectPlanModal)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <h2>{formatMessage({ id: 'Upgrade plan' })}</h2>
          </Modal.Header>
          <Modal.Body>
            <div>{modalText}</div>
          </Modal.Body>
          <Modal.Footer>
            {currentUser?.role !== Roles.OWNER &&
              <InputToolTip
                tooltipText={formatMessage({
                  id: 'GLOBAL.TOOLTIP.UPGRADE.PLAN',
                })}
              />
            }
            <GlobalButton
              buttonText={formatMessage({ id: 'Upgrade subscription' })}
              buttonType={ButtonTypes.PRIMARY}
              onButtonClick={() => {
                setSelectPlanModal(false);
                navigate('/settings');
              }}
              isDisable={currentUser?.role !== Roles.OWNER}
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
