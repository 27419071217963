import { Roles } from '@/enums/organisation';
import { Priority } from '@/enums/priority';
import { MenuRoutes, TaskStatus } from '@/enums/projects';

export const priorityOptions = [
  { value: Priority.MINOR, label: Priority.MINOR, color: 'var(--minor-priority-color)' },
  { value: Priority.NORMAL, label: Priority.NORMAL, color: 'var(--normal-priority-color)' },
  { value: Priority.MAJOR, label: Priority.MAJOR, color: 'var(--major-priority-color)' },
  { value: Priority.CRITICAL, label: Priority.CRITICAL, color: 'var(--critical-priority-color)' },
];

export const statusOptions = [
  { value: TaskStatus.BACKLOG, label: 'Backlog' },
  { value: TaskStatus.TODO, label: 'Todo' },
  { value: TaskStatus.INPROGRESS, label: 'In Progress' },
  { value: TaskStatus.REVIEW, label: 'Review' },
  { value: TaskStatus.COMPLETED, label: 'Completed' },
];

export const PER_PAGE_DATA = 50;

export const KebabMenu = [
  {
    id: 'edit',
    title: 'Edit',
    icon: '/media/icons/sidebar/edit.svg',
    role: [Roles.OWNER, Roles.ADMIN],
  },
  {
    id: MenuRoutes.TASK_BOARD,
    title: 'Task Board',
    icon: '/media/icons/sidebar/taskBlack.svg',
    role: [Roles.OWNER, Roles.ADMIN, Roles.MEMBER],
  },
  {
    id: MenuRoutes.INTEGRATION,
    title: 'Integration',
    icon: '/media/icons/sidebar/integrationBlack.svg',
    role: [Roles.OWNER, Roles.ADMIN],
  },
  {
    id: MenuRoutes.PROJECT_SETTINGS,
    title: 'Project settings',
    icon: '/media/icons/sidebar/projectsSettingBlack.svg',
    role: [Roles.OWNER, Roles.ADMIN],
  },
  {
    id: 'delete',
    title: 'Delete',
    icon: '/media/icons/sidebar/delete.svg',
    role: [Roles.OWNER, Roles.ADMIN],
  },
];

export const OAuthConfig = {
  GITHUB: {
    CLIENTID: process.env.REACT_APP_GITHUB_CLIENT_ID as string,
    REDIRECTURI: '/integration/oauth',
    SCOPE: 'repo write:org',
    GITHUB_OAUTH_BASE_URL: 'https://github.com/login/oauth/authorize?',
  },
  YOUTRACK: {},
  JIRA: {
    BASE_URL: 'https://api.atlassian.com/ex/jira/', // TODO: Setup for enterprise on-premises
    CLIENTID: process.env.REACT_APP_JIRA_CLIENT_ID as string,
    REDIRECTURI: '/integration/oauth',
    SCOPE: 'write:jira-work read:jira-work offline_access read:jira-user',
    JIRA_OAUTH_BASE_URL: 'https://auth.atlassian.com/authorize?audience=api.atlassian.com&', // TODO: Setup for enterprise on-premises
  },
  TRELLO: {
    BASE_URL: 'https://trello.com/1/authorize',
    EXPIRATION: 'never',
    SCOPE: 'read,write,account',
    RESPONSE_TYPE: 'token',
    KEY: process.env.REACT_APP_TRELLO_KEY as string,
    RETURNURL: '/integration/oauth',
  },
};
