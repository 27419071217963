import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import Select from 'react-select';
import { WebHookMetaData, IntegrationType } from '@/types/projects';
import { useYoutrackIntegrationRequests } from '@/requests/useYoutrackIntegrationRequests';
import { BWInput } from '../../../shared/components/Input/BWInput';
import { InputToolTip } from '../../../shared/components/UI/InputToolTip';

export const WebhookIntegrationModel = ({
  openWebhookIntegrationModal,
  setOpenWebhookIntegrationModal,
  handleIntegrateProject,
  data,
  loading,
}: {
  openWebhookIntegrationModal: boolean;
  setOpenWebhookIntegrationModal: Dispatch<SetStateAction<boolean>>;
  handleIntegrateProject: any;
  data: any;
  loading?: boolean;
}) => {
  const { getyouTrackProjects, getAllAligleBoard, getAllSprints } =
    useYoutrackIntegrationRequests();
  const { formatMessage } = useIntl();
  const [baseUrl, setBaseUrl] = useState<string>('');

  const [headerSelection, setHeaderOption] = useState('');

  const projectSchema = Yup.object().shape({
    baseUrl: Yup.string()
      .matches(
        /^((https?):\/\/)?(www\.)?([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}(:[0-9]+)?(\/.*)?$/i,
        'Enter correct url!'
      )
      .required(formatMessage({ id: 'Endpoint URL is required' })),
    headerKey: Yup.string().required(formatMessage({ id: 'Header key is required' })),
    headerValue: Yup.string().required(formatMessage({ id: 'Header value is required' })),
  });

  const initialValues = {
    baseUrl: data ? (JSON.parse(data?.meta_data) as WebHookMetaData).baseUrl : '',
    headerKey: data ? (JSON.parse(data?.meta_data) as WebHookMetaData).headerKey : '',
    headerValue: data ? (JSON.parse(data?.meta_data) as WebHookMetaData).headerValue : '',
  };

  const handleSelectChange = (event: any, setFieldValue: any, setFieldTouched: any) => {
    setHeaderOption(event.target.value);
    if (event.target.value !== 'other') {
      setFieldValue('headerKey', event.target.value);
    } else {
      setFieldTouched('headerKey', false, false);
      setFieldValue('headerKey', '');
    }
  };

  useEffect(() => {
    if (data) {
      const headerKey = (JSON.parse(data?.meta_data) as WebHookMetaData).headerKey;

      if (headerKey === 'x-api-key' || headerKey === 'Authorization') {
        setHeaderOption(headerKey);
      } else {
        setHeaderOption('other');
      }
    }
  }, [data]);
  useEffect(() => {
    if (!data) {
      setBaseUrl('');
      setHeaderOption('');
    }
  }, [data]);

  return (
    <>
      <Modal
        show={openWebhookIntegrationModal}
        onHide={() => {
          setOpenWebhookIntegrationModal(false);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className=''>
          <div className='font-size-16 fw-bold'>{formatMessage({ id: 'Setup webhook' })}</div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={projectSchema}
          onSubmit={async (values, formikHelpers) => {
            setBaseUrl(values.baseUrl);
            let meta_data = {
              baseUrl: values.baseUrl ? values.baseUrl : baseUrl,
              headerKey: headerSelection !== 'other' ? headerSelection : values.headerKey,
              headerValue: values.headerValue,
            };
            await handleIntegrateProject(meta_data);
          }}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <Modal.Body className='pt-2 pb-0'>
                <div className='mt-5'>
                  <div className='alert alert-info' role='alert'>
                    {formatMessage({ id: 'WEBHOOK_INFO_TEXT' })}
                  </div>
                  <BWInput
                    fieldType={'text'}
                    fieldName={'baseUrl'}
                    formik={formik}
                    placeholder={formatMessage({ id: 'Enter endpoint URL' })}
                    label={formatMessage({ id: 'Endpoint URL' })}
                    isRequired={true}
                    toolTipText={formatMessage({
                      id: 'GLOBAL.TOOLTIP.WEBHOOK.URL',
                    })}
                  />
                  <div>
                    <label
                      htmlFor='headerKey'
                      className='form-label font-size-14 text-dark text-capitalize mb-4'
                    >
                      {formatMessage({ id: 'Header key' })}
                      <span className='red-color'>*</span>
                      <InputToolTip
                        tooltipText={formatMessage({ id: 'GLOBAL.TOOLTIP.WEBHOOK.HEADER_KEY' })}
                      />
                    </label>
                    <select
                      className={`form-select form-select-solid form-select-lg ${
                        formik.touched.headerKey && formik.errors.headerKey
                          ? 'is-invalid mb-2'
                          : 'mb-5'
                      }`}
                      value={headerSelection}
                      onChange={(e) =>
                        handleSelectChange(e, formik.setFieldValue, formik.setFieldTouched)
                      }
                      onBlur={formik.handleBlur}
                      name='headerKey'
                    >
                      <option value=''>{formatMessage({ id: 'Select a header key' })}</option>
                      <option value='Authorization'>Authorization</option>
                      <option value='x-api-key'>x-api-key</option>
                      <option value='other'>{formatMessage({ id: 'Other' })}</option>
                    </select>
                    {headerSelection !== 'other' && (
                      <div className='text-danger mb-5'>
                        <ErrorMessage name={'headerKey'} />
                      </div>
                    )}
                    {headerSelection === 'other' && (
                      <div>
                        <BWInput
                          fieldType={'text'}
                          fieldName={'headerKey'}
                          formik={formik}
                          placeholder={formatMessage({
                            id: 'Enter custom header key',
                          })}
                          isRequired={false}
                        />
                      </div>
                    )}
                    <BWInput
                      fieldType={'text'}
                      fieldName={'headerValue'}
                      formik={formik}
                      placeholder={formatMessage({ id: 'Enter header value' })}
                      label={formatMessage({ id: 'Header value' })}
                      isRequired={true}
                      toolTipText={formatMessage({
                        id: 'GLOBAL.TOOLTIP.WEBHOOK.HEADER_VALUE',
                      })}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className='pt-0 border-top-0'>
                <GlobalButton
                  buttonText={formatMessage({ id: 'Cancel' })}
                  buttonType={ButtonTypes.SECONDARY}
                  onButtonClick={() => {
                    setOpenWebhookIntegrationModal(false);
                  }}
                />
                <GlobalButton
                  buttonText={formatMessage({ id: 'Save' })}
                  buttonType={ButtonTypes.PRIMARY}
                  // isDisable={formik.isSubmitting || !formik.isValid}
                  onButtonClick={formik.handleSubmit}
                  isLoading={loading}
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
