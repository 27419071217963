import clsx from 'clsx';
import { ToolbarType, useLayout } from '../../core';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { Billing } from '../../../../modules/settings/views/Billing';
import { Locale } from '../../../../modules/settings/views/Locale';
import { BillingStatus, localeInitialValues } from '@/constants/settings';
import { useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from '../../../helpers';
import useGetBillingData from '../../../../hooks/useGetBillingData';
import { Transaction } from '../../../../modules/settings/views/Transaction';
import { PageHeading } from '../../../../modules/shared/components/PageHeading';
import { Roles } from '@/enums/organisation';
import { useReferralRequests } from '@/requests/useReferralRequests';
import { useBillingRequests } from '@/requests/useBillingRequests';
import { CurrentPlan, SubscriptionDetails } from '@/types/billing';
import { MetaKeys } from '@/enums/user';
import { PlanType } from '@/enums/billing';
import { getAuth } from '../../../../modules/auth';
import { useAuthRequests } from '@/requests/useAuthRequests';

const ToolbarWrapper = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const { config, classes } = useLayout();
  const { refreshTokenAPI } = useAuthRequests();
  const [key, setKey] = useState(1);
  const { currentUser, setPlansCurrencyCode, plansCurrencyCode, getCurrentUser, setUserToken } =
    useGlobalContext();
  const { getTransactionData } = useBillingRequests();
  const { getReferralData, getTotalReferralEarning } = useReferralRequests();
  const { getBillingDetailsAPI, loading } = useGetBillingData();
  const [getLocaleApiLoading] = useState(false);
  const [referral, setReferral] = useState<string>();
  const [paypalId, setPaypalId] = useState<string>();
  const [totalEarning, setTotalEarning] = useState<string>();
  const [totalReferred, setTotalReferred] = useState<string>();
  const [totalPaidUsers, setTotalPaidUsers] = useState<string>();
  const [totalTrialUsers, setTotalTrialUsers] = useState<string>();
  const [transactionData, setTransactionData] = useState<any[] | string | undefined>([]);
  const [searchParams] = useSearchParams();
  const tabKey = useMemo(() => searchParams.get('tabKey'), []);
  const [runTimezoneApi, setRunTimezoneApi] = useState<boolean>(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails | undefined>();
  const [scheduleDowngradeData, setScheduleDowngradeData] = useState<
    Partial<SubscriptionDetails['schedule']> | undefined
  >();

  const [transactionDetails, setTransactionDetails] = useState<boolean>(false);
  const [referralTabData, setReferralTabData] = useState<boolean>(false);

  const [transactionLoading, setTransactionLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('Profile');
  const { userMeta } = useGlobalContext();
  const [currentPlan, setCurrentPlan] = useState<CurrentPlan | undefined>();
  const upgrade = searchParams.get('u');
  useEffect(() => {
    if (currentUser?.role === Roles.OWNER) {
      fetchSubscriptionDetails();
    } else {
      setRunTimezoneApi(true);
    }
  }, []);

  async function fetchSubscriptionDetails() {
    const result = await getBillingDetailsAPI();
    setSubscriptionDetails(result);
    setCurrentPlan(result?.billingDetails);
    setScheduleDowngradeData(result?.schedule);
    setPlansCurrencyCode({
      ...plansCurrencyCode,
      currencyCode: result?.billingDetails.currency.toUpperCase(),
    });
    if (
      result?.billingDetails.title.toLowerCase() !== PlanType.FREE &&
      result?.billingDetails.status === BillingStatus.ACTIVE
    ) {
    }
  }

  useEffect(() => {
    if (userMeta) {
      localeInitialValues[MetaKeys.LOCALE] = userMeta[MetaKeys.LOCALE];
      localeInitialValues[MetaKeys.TIMEZONE] = userMeta[MetaKeys.TIMEZONE];
      localeInitialValues[MetaKeys.DATEFORMAT] = userMeta[MetaKeys.DATEFORMAT];
      localeInitialValues[MetaKeys.TIMEFORMAT] = userMeta[MetaKeys.TIMEFORMAT];
    }
  }, [runTimezoneApi]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReferral = async () => {
    try {
      const {
        data: { success, data, errors },
      } = await getReferralData();
      if (success) {
        setReferral(data.referCode);
        setPaypalId(data.paypalEmail);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const totalReferralEarning = async () => {
    try {
      const {
        data: { success, data, errors },
      } = await getTotalReferralEarning();
      if (success) {
        setTotalEarning(data.totalEarnings);
        setTotalReferred(data.totalReferred);
        setTotalPaidUsers(data.user_subscribed_referred);
        setTotalTrialUsers(data.user_trail_referred);
        setReferralTabData(false);
      } else {
        setReferralTabData(false);
      }
    } catch (err) {
      console.log(err);
      setReferralTabData(false);
    }
  };
  useEffect(() => {
    if (referralTabData) {
      getReferral();
      totalReferralEarning();
    }
  }, [referralTabData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!config.app?.toolbar?.display) {
    return null;
  }

  const upgradePlan = async () => {
    try {
      const auth: any = getAuth();
      if (auth && auth?.refreshToken) {
        const {
          data: { data, success },
        } = await refreshTokenAPI(auth.refreshToken);
        if (success) {
          const tokenObj = {
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          };
          getCurrentUser(tokenObj);
          setUserToken(data.accessToken);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (upgrade) {
      upgradePlan();
    }
  }, [upgrade]);
  async function getTransactions() {
    try {
      setTransactionLoading(true);
      const {
        data: { data },
      } = await getTransactionData();
      {
        setTransactionData(data);
        setTransactionDetails(false);
      }
    } catch (error) {
      console.log(error);
      setTransactionDetails(false);
    } finally {
      setTransactionLoading(false);
    }
  }

  useEffect(() => {
    if (transactionDetails) {
      getTransactions();
    }
  }, [transactionDetails]);

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    const tabsContainer = document.getElementsByClassName('setting-tabs-list')[0];
    if (tabsContainer) {
      const tabElements = tabsContainer.getElementsByClassName('rb-tabs-item');
      const selectedTab = Array.from(tabElements).find(
        (element) => element.textContent === activeTab
      );
      if (selectedTab) {
        const rect = selectedTab.getBoundingClientRect();
        tabsContainer.scrollTo({
          left: rect.left - tabsContainer.getBoundingClientRect().left,
          behavior: 'smooth',
        });
      }
    }
  }, [activeTab]);

  return (
    <div id='kt_app_toolbar' className={clsx('app-toolbar')}>
      <div
        id='kt_app_toolbar_container'
        className={clsx(
          'app-container d-md-flex flex-md-column align-items-start flex-column overflow-hidden',
          classes.toolbarContainer.join(' '),
          config.app?.toolbar?.containerClass,
          config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
          {
            'container-fluid': config.app?.toolbar?.container === 'fluid',
            'container-xxl': config.app?.toolbar?.container === 'fixed',
          }
        )}
      >
        {location.pathname.includes('settings') && (
          <>
            <PageHeading
              title={formatMessage({ id: 'Settings' })}
              description={formatMessage({
                id: 'You can customize the billing, transaction, and many more options here.',
              })}
            />

            <Tabs
              activeTab={parseInt(`${tabKey ? tabKey : currentUser?.role === Roles.OWNER ? 1 : 3}`)}
              className='font-size-13'
              ulClassName='text-muted dark-border setting-tabs-list'
              activityClassName='active-tab'
              onClick={(event, tab) => {
                setKey(tab);
                if (tab === 1 && currentUser?.role !== Roles.MEMBER) {
                  handleTabClick('Billing');
                } else if (tab === 2) {
                  setTransactionDetails(true);
                  handleTabClick('Transactions');
                } else if (tab === 3) {
                  setRunTimezoneApi(true);
                  handleTabClick('Locale');
                } else if (tab === 4) {
                  setReferralTabData(true);
                  handleTabClick('Referral');
                }
              }}
            >
              <Tab
                title={formatMessage({ id: 'Billing' })}
                className={`mr-3 mt-2 fw-bolder text-black ${
                  currentUser?.role === Roles.OWNER ? 'd-block' : 'd-none'
                }`}
              >
                <Billing
                  subscriptionDetails={subscriptionDetails}
                  getBillingDetailsLoading={loading}
                  scheduleDowngradeData={scheduleDowngradeData}
                />
              </Tab>
              <Tab
                title={formatMessage({ id: 'Transactions' })}
                className={`mr-3 mt-2 fw-bolder text-black ${
                  currentUser?.role === Roles.OWNER ? 'd-block' : 'd-none'
                }`}
              >
                <Transaction
                  key={key}
                  transactionData={transactionData}
                  transactionLoading={transactionLoading}
                />
              </Tab>

              <Tab
                title={formatMessage({ id: 'Locale' })}
                className='mr-3 mt-2 fw-bolder text-black'
              >
                <Locale
                  key={key}
                  getLocaleApiLoading={getLocaleApiLoading}
                  setRunTimezoneApi={setRunTimezoneApi}
                  runTimezoneApi={runTimezoneApi}
                />
              </Tab>

              {/* <Tab
                title={formatMessage({ id: 'Referral' })}
                className='mr-3 mt-2 fw-bolder text-black'
              >
                <div className='mt-4'>
                  <Referral
                    key={key}
                    paypalId={paypalId}
                    referral={referral}
                    totalEarning={totalEarning}
                    totalReferred={totalReferred}
                    setPaypalId={setPaypalId}
                    totalPaidUsers={totalPaidUsers}
                    totalTrialUsers={totalTrialUsers}
                  />
                </div>
              </Tab> */}
            </Tabs>
          </>
        )}
      </div>
    </div>
  );
};

const showPageTitle = (appToolbarLayout?: ToolbarType, appPageTitleDisplay?: boolean): boolean => {
  const viewsWithPageTitles = ['classic', 'reports', 'saas'];
  if (!appToolbarLayout || !appPageTitleDisplay) {
    return false;
  }

  return appPageTitleDisplay && viewsWithPageTitles.some((t) => t === appToolbarLayout);
};

export { ToolbarWrapper };
