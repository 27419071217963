import axios from 'axios';
import { API_URL } from '.';
import { IntegrationPlatform } from '@/enums/projects';

const INTEGRATION_URL = `${API_URL}/integration`;
const OAUTH_VALIDATION = `${INTEGRATION_URL}/validation/oauth`;

export const useIntegrationRequests = () => {
  const getIntegrationList = (projectId: number | undefined) => {
    return axios.get(`${INTEGRATION_URL}?projectId=${projectId}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const createIntegrateProject = (
    meta_data: JSON,
    platformName: IntegrationPlatform,
    projectId: number,
    status: boolean
  ) => {
    const payload = {
      platformName: platformName,
      status: status,
      projectId: projectId,
      meta_data: meta_data,
    };
    return axios.post(INTEGRATION_URL, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const toggleChange = (values: any, projectId: number, status: boolean) => {
    const payload = {
      platformName: values.platformName,
      token: values?.token,
      status: status,
      projectId: projectId,
    };
    return axios.post(INTEGRATION_URL, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const oauthValidation = (
    code: string | null,
    projectId: number | undefined,
    platform: string | null
  ) => {
    const payload = {
      code: code,
      projectId: projectId,
      platform: platform,
    };
    return axios.post(OAUTH_VALIDATION, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const deleteIntegration = (integrationId: number | undefined, projectId: number | undefined) => {
    return axios.delete(`${INTEGRATION_URL}/${integrationId}`, {
      params: {
        projectId: projectId,
      },
      validateStatus(status) {
        return true;
      },
    });
  };

  return {
    toggleChange,
    createIntegrateProject,
    getIntegrationList,
    oauthValidation,
    deleteIntegration,
  };
};
