import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';

import { OAuthConfig } from '@/constants/projects';
import { useJiraIntegrationRequests } from '@/requests/useJiraIntegrationRequests';
import { useIntegrationRequests } from '@/requests/useIntegrationRequests';
import { IntegrationType, JiraMetaData } from '@/types/projects';
import { useGlobalContext } from '../../../../core/helpers';
import Skeleton from 'react-loading-skeleton';

export const JiraIntegrationModel = ({
  data,
  handleJiraToggle,
}: {
  data: any;
  handleJiraToggle: any;
}) => {
  const { formatMessage } = useIntl();
  const [headerSelection, setHeaderOption] = useState('');
  const [projectSelection, setProjectSelection] = useState('');
  const [projectData, setProjectData] = useState([]);
  const [accountNames, setAccountNames] = useState([]);
  const [projectNames, setProjectNames] = useState([]);
  const urlSchema = Yup.object().shape({
    url: Yup.string().required(formatMessage({ id: 'URL is required' })),
  });
  const { getJiraProjects } = useJiraIntegrationRequests();
  const { createIntegrateProject } = useIntegrationRequests();
  const { openOauthConfigModal, setOpenOauthConfigModal } = useGlobalContext();

  const initialValues = {
    headerSelection: data ? (JSON.parse(data?.meta_data) as JiraMetaData).accountName : '',
    projectSelection: data ? (JSON.parse(data?.meta_data) as JiraMetaData).projectName : '',
  };

  useEffect(() => {
    if (data && data.meta_data) {
      const meta_data_object = JSON.parse(data?.meta_data);
      const account_names = meta_data_object?.accessible_resources.map(
        (resource: any) => resource.name
      );
      setAccountNames(account_names);
      const project_names = meta_data_object?.projectNames;
      if (project_names) {
        setProjectNames(project_names);
      }

      if (meta_data_object?.accountName) {
        setHeaderOption(meta_data_object?.accountName);
      }
      if (meta_data_object?.projectName) {
        setProjectSelection(meta_data_object?.projectName);
      }
    }
  }, [data, openOauthConfigModal]);

  const handleSelectChange = async (event: any) => {
    setHeaderOption(event.target.value);
    const projectId = data?.projectId;
    const platformName = data?.platformName;
    let cloudId;
    const meta_data_object = JSON.parse(data?.meta_data);

    const accessible_resource_data = meta_data_object?.accessible_resources;

    accessible_resource_data?.map((item: any) => {
      if (item.name === event.target.value) {
        cloudId = item.id;
      }
    });
    if (cloudId) {
      try {
        const {
          data: { data, success, errors },
        } = await getJiraProjects(projectId, platformName, cloudId);
        if (success) {
          setProjectData(data);
          const project_names = data?.map((resource: any) => resource.name);

          setProjectNames(project_names);
        } else {
          setProjectData([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleProjectSelection = async (event: any) => {
    setProjectSelection(event.target.value);
  };
  const handleProceed = async () => {
    const projectId = data?.projectId;
    const platformName = data?.platformName;
    const status = data?.status;
    const meta_data = JSON.parse(data?.meta_data);
    projectData.map((data: any) => {
      if (data.name === projectSelection) {
        meta_data.platformProjectId = data.id;
        meta_data.projectName = data.name;
      }
    });
    const accessible_resource_data = meta_data?.accessible_resources;
    accessible_resource_data?.map((item: any) => {
      if (item.name === headerSelection) {
        meta_data.cloudId = item.id;
        meta_data.accountName = item.name;
        meta_data.projectNames = projectNames;
      }
    });
    const jiraConfig = OAuthConfig.JIRA;
    meta_data.baseUrl = jiraConfig.BASE_URL;

    try {
      const {
        data: { data: value, success, errors },
      } = await createIntegrateProject(meta_data, platformName, projectId, status);
      if (success) {
        setOpenOauthConfigModal(false);
        handleJiraToggle('modal');
        return value;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        show={openOauthConfigModal}
        onHide={() => {
          setOpenOauthConfigModal(false);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className=''>
          <div className='font-size-16 fw-bold title-color'>
            {formatMessage({ id: 'Select account and project' })}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='row p-3'>
            <label
              htmlFor='headerKey'
              className='form-label font-size-14 text-dark text-capitalize mb-4'
            ></label>
            <select
              className='form-select form-select-solid form-select-lg mb-5'
              value={headerSelection}
              onChange={handleSelectChange}
            >
              <option disabled value=''>
                Select an account
              </option>
              {accountNames.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          {headerSelection && projectNames.length == 0 ? (
            <div>
              <Skeleton height={45} />
            </div>
          ) : (
            <div className='row p-3'>
              <label
                htmlFor='headerKey'
                className='form-label font-size-14 text-dark text-capitalize mb-4'
              ></label>
              <select
                className='form-select form-select-solid form-select-lg mb-5'
                value={projectSelection}
                onChange={handleProjectSelection}
              >
                <option disabled value=''>
                  Select project
                </option>
                {projectNames.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          )}
        </Modal.Body>
        <Formik
          initialValues={{ headerSelection, projectSelection }}
          // validationSchema={urlSchema}
          onSubmit={(values, formikHelpers) => {
            // handleCreateLink(values.url);
          }}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <Modal.Footer className='pt-0 border-top-0'>
                <GlobalButton
                  buttonText={formatMessage({ id: 'Cancel' })}
                  buttonType={ButtonTypes.SECONDARY}
                  onButtonClick={() => {
                    setOpenOauthConfigModal(false);
                  }}
                />
                <GlobalButton
                  buttonText={formatMessage({ id: 'Proceed' })}
                  buttonType={ButtonTypes.PRIMARY}
                  isDisable={formik.isSubmitting || !formik.isValid}
                  onButtonClick={handleProceed}
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
