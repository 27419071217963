import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../../../core/helpers';
import { useIntl } from 'react-intl';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ButtonTypes, InputTypes } from '@/enums';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { BWInput } from '../../../shared/components/Input/BWInput';
import { ConfirmChangePasswordModal } from '../ConfirmChangePasswordModal';

const LoginCard = ({ invitedEmail }: { invitedEmail: string }) => {
  const { login } = useAuthRequests();
  const { formatMessage } = useIntl();
  const { getCurrentUser } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const { forgotPassword } = useAuthRequests();

  const initialValues = {
    email: invitedEmail,
    password: '',
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'Invalid email format' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Email is required' })),
    password: Yup.string()
      .min(6, formatMessage({ id: 'Minimum 6 characters' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Password is required' })),
  });

  const handleLogin = async (values: any) => {
    setLoading(true);
    try {
      const {
        data: { data, success },
      } = await login(values.email, values.password);
      if (success) {
        await getCurrentUser(data);
      } else {
        await getCurrentUser(undefined);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const sendPasswordResetEmail = async () => {
    if (invitedEmail) {
      try {
        setChangePasswordLoading(true);
        const {
          data: { success },
        } = await forgotPassword(invitedEmail);
        if (success) {
          setConfirmModalShow(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setChangePasswordLoading(false);
      }
    }
  };

  return (
    <>
      <div>
        <Formik
          validationSchema={loginSchema}
          initialValues={initialValues}
          validateOnMount
          onSubmit={handleLogin}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework px-3 pe-0 ps-0'>
                <BWInput
                  fieldType={'email'}
                  fieldName={'email'}
                  formik={formik}
                  placeholder={formatMessage({ id: 'Enter Email' })}
                  label={formatMessage({ id: 'Email' })}
                  toolTipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.LOGIN.EMAIL',
                  })}
                  isDisabled={true}
                  isRequired={true}
                />
                {/* end::Form group */}

                {/* begin::Form group */}
                <div
                  className='position-relative d-flex flex-row-reverse fw-bold'
                  style={{ top: '1.55rem' }}
                >
                  <Link
                    to='#'
                    onClick={() => setConfirmModalShow(true)}
                    className='font-size-14 link-primary'
                  >
                    {formatMessage({ id: 'Forgot Password' })}?
                  </Link>
                </div>
                <BWInput
                  fieldType={InputTypes.PASSWORD}
                  fieldName={'password'}
                  formik={formik}
                  placeholder={formatMessage({ id: 'Password' })}
                  label={formatMessage({ id: 'Password' })}
                  toolTipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.LOGIN.PASSWORD',
                  })}
                  isRequired={true}
                />

                <div className='d-flex align-items-center justify-content-end'>
                  <GlobalButton
                    buttonText={formatMessage({ id: 'Login' })}
                    buttonType={ButtonTypes.PRIMARY}
                    isLoading={loading}
                    isDisable={formik.isSubmitting || !formik.isValid}
                    onButtonClick={formik.handleSubmit}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <ConfirmChangePasswordModal
        confirmModalShow={confirmModalShow}
        setConfirmModalShow={setConfirmModalShow}
        getForgotedPassword={sendPasswordResetEmail}
        changePasswordLoading={changePasswordLoading}
      />
    </>
  );
};

export { LoginCard };
