import React, { Fragment, useEffect, useState } from 'react';
import '../styles/project.scss';
import { Dropdown } from 'react-bootstrap';
import { ConfirmationModal } from '../../shared/components/ConfirmationModal';
import { useIntl } from 'react-intl';
import { ProjectModal } from '../components/ProjectModal';
import { toast } from 'react-toastify';
import { Project } from '../../../types/projects';
import { useGlobalContext } from '../../../core/helpers';
import useAllProjectList from '../../../hooks/useAllProjectList';
import { PageHeading } from '../../shared/components/PageHeading';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { MenuRoutes } from '@/enums/projects';
import UserAvatar from '../../shared/components/UserAvatar';
import { KTSVG } from '../../../core/helpers';
import { KebabMenu } from '@/constants/projects';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserMetaRequests } from '@/requests/useUserMetaRequests';
import { useProjectRequests } from '@/requests/useProjectRequests';
import { SelectPlanModal } from '../../shared/components/SelectPlanModal';
import { NoDataComponent } from '../../shared/components/NoDataComponent';
import { UserAvatarDisplayName, UserAvatarSize } from '@/enums/user';
import { setCookie } from '../../../util/cookie';
import { useSetUserMeta } from '../../../hooks/useSetUserMeta';
import { MetaKeys } from '@/enums/user';
import { checkPlanAccess } from '../../../util/checkPlanAccess';
import { StripeMetaKeys } from '@/enums/stripeMetaKeys';
import '../styles/project-list.scss';
import Skeleton from 'react-loading-skeleton';
import { Roles } from '@/enums/organisation';

export const ProjectList = () => {
  const { createNewProject, editProject, deleteProject } = useProjectRequests();
  const { setUserMetas } = useUserMetaRequests();
  const { getAllProjectList, projectListLoading } = useAllProjectList();
  const [showDeleteModal, setDeleteShowModal] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [editId, setEditId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const [selectPlanModal, setSelectPlanModal] = useState(false);
  const [projectList, setProjectList] = useState<Project[] | []>([]);
  const [singleProjectData, setSingleProjectData] = useState<Project>();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const { setUserMetaData } = useSetUserMeta();
  const [searchParams] = useSearchParams();
  const isOpen = searchParams.get('open');
  const navigate = useNavigate();
  const { currentUser, openCreateProjectModal, setopenCreateProjectModal, userMeta } =
    useGlobalContext();
  const { setSingleUserMeta } = useUserMetaRequests();
  const filteredKebabMenu =
    currentUser?.role === Roles.MEMBER
      ? KebabMenu.filter((menu) => menu.role.includes(Roles.MEMBER))
      : KebabMenu;

  function handleOpenNewProjectModal() {
    setopenCreateProjectModal(true);
  }

  useEffect(() => {
    async function fetchProjectList() {
      setProjectList(await getAllProjectList());
      setIsComplete(true);
    }
    fetchProjectList();
  }, []);

  const handleDeleteProject = async (projectId: number) => {
    try {
      setDeleteLoading(true);
      const {
        data: { data, success, errors },
      } = await deleteProject(projectId);
      if (success) {
        setDeleteLoading(false);
        toast.success(formatMessage({ id: 'Project Deleted Successfully' }));
        setProjectList(await getAllProjectList());
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      setDeleteShowModal(false);
    }
  };

  const handleCreateNewProject = async (
    title: string,
    description: string | null,
    projectCode: string
  ) => {
    try {
      setLoading(true);
      const {
        data: { data, success, errors },
      } = await createNewProject(title, description, projectCode);
      if (success) {
        toast.success(formatMessage({ id: 'Project created successfully' }));
        setopenCreateProjectModal(false);
        setLoading(false);
        setProjectList(await getAllProjectList());
      }
    } catch (err) {
      console.log(err);
    } finally {
      setopenCreateProjectModal(false);
      setLoading(false);
    }
  };

  const handleEditProject = async (
    projectId: number | null,
    title: string,
    description: string | null
  ) => {
    try {
      setLoading(true);
      const {
        data: { data, success, errors },
      } = await editProject(projectId, title, description);
      if (success) {
        setopenCreateProjectModal(false);
        setLoading(false);
        toast.success(formatMessage({ id: 'Project Updated Successfully' }));
        setProjectList(await getAllProjectList());
      }
    } catch (err) {
      console.log(err);
    } finally {
      setopenCreateProjectModal(false);
      setLoading(false);
    }
  };

  const handleProjectMeta = async (projectId: number) => {
    if (
      !userMeta?.[MetaKeys.CURRENT_SELECTION] ||
      projectId != JSON.parse(userMeta?.[MetaKeys.CURRENT_SELECTION]?.toString() || '').projectId
    ) {
      try {
        const {
          data: { data, success, errors },
        } = await setSingleUserMeta({
          key: MetaKeys.CURRENT_SELECTION,
          value: JSON.stringify({ projectId: projectId.toString(), teamId: currentUser?.teamId }),
        });
        if (success) {
          setCookie(
            process.env.REACT_APP_PROJECT_COOKIE_KEY || MetaKeys.PROJECTID,
            projectId.toString()
          );
          setUserMetaData({ [MetaKeys.CURRENT_SELECTION]: data.value });
          navigate(`/project/${projectId}/${MenuRoutes.TASK_BOARD}`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (isOpen && isComplete) {
      if (checkPlanAccess(projectList, currentUser?.metadata?.[StripeMetaKeys.PROJECT]!)) {
        setopenCreateProjectModal(true);
      } else {
        setSelectPlanModal(true);
      }
      setEditId(null);
      setSingleProjectData(undefined);
      setIsComplete(false);
    }
  }, [projectList]);
  return (
    <>
      <div className='d-flex flex-md-row justify-content-md-between flex-column'>
        <PageHeading
          title={'Projects'}
          description={formatMessage({ id: 'Manage or create your bugs.work projects.' })}
        />
        <div className='mt-md-3 mb-5'>
          {currentUser?.role !== 'member' && (
            <GlobalButton
              buttonText={formatMessage({ id: 'Create new' })}
              buttonType={ButtonTypes.PRIMARY}
              onButtonClick={() => {
                if (
                  checkPlanAccess(projectList, currentUser?.metadata?.[StripeMetaKeys.PROJECT]!)
                ) {
                  setopenCreateProjectModal(true);
                } else {
                  setSelectPlanModal(true);
                }
                setEditId(null);
                setSingleProjectData(undefined);
              }}
            />
          )}
        </div>
      </div>

      <div className='d-flex flex-row flex-wrap gap-6'>
        {!projectListLoading && projectList?.length === 0 ? (
          <NoDataComponent
            title='No project found'
            subtitle='No data available at the moment.'
            buttonText={currentUser?.role !== 'member' ? 'Create new' : undefined}
            buttonClick={handleOpenNewProjectModal}
            image={false}
          />
        ) : (
          <>
            <div className='card row w-100 m-0'>
              <div className='card-body position-relative'>
                <div className='table-responsive'>
                  <table className='w-100 custom-table-project'>
                    <thead className='table-border'>
                      <tr className='custom-thead font-size-12 font-weight-400 col-12'>
                        <td className='pb-4 col-4'>{formatMessage({ id: 'Project title' })}</td>
                        <td className='pb-4 col-3'>{formatMessage({ id: 'Project code' })}</td>
                        <td className='pb-4 col-4'>{formatMessage({ id: 'Created By' })}</td>
                        <td className='pb-4 col-1'>{formatMessage({ id: 'Actions' })}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {projectListLoading ? (
                        [...Array(3)].map((_, index) => (
                          <tr key={index} className='table-border table-row box-shadow mt-3'>
                            <td className='py-1'>
                              <div className='d-flex align-items-center gap-3'>
                                <Skeleton circle={true} height={25} width={25} className='pe-2' />
                                <div className='d-flex flex-column'>
                                  <Skeleton height={20} width={50} />
                                  <Skeleton height={10} width={100} />
                                </div>
                              </div>
                            </td>
                            <td className='py-4'>
                              <Skeleton height={20} width={100} />
                            </td>
                            <td className='py-4'>
                              <div className='d-flex align-items-center gap-3'>
                                <Skeleton circle={true} height={25} width={25} />
                                <Skeleton height={20} width={100} />
                              </div>
                            </td>
                            <td
                              className='d-flex w-100 align-items-center'
                              style={{ height: '50px' }}
                            >
                              <Skeleton height={20} width={50} />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          {projectList?.map((project: Project, index: number) => (
                            <tr
                              key={project.projectId}
                              onMouseLeave={() => setShowKebabMenu(false)}
                              className='table-border table-row  box-shodow project-hover mt-3'
                            >
                              <td
                                className='py-1 cursor-pointer font-weight-600 font-14'
                                onClick={() => {
                                  handleProjectMeta(project.projectId);
                                  navigate(
                                    `/project/${project.projectId}/${MenuRoutes.TASK_BOARD}`
                                  );
                                }}
                                data-label={formatMessage({ id: 'Project title' })}
                              >
                                <div className='d-flex align-items-center ms-2'>
                                  <KTSVG path='/media/misc/cube.svg' className='pe-2' />
                                  <div className='d-flex flex-column'>
                                    <div>{project.title}</div>
                                    <div className='font-weight-400 font-10'>
                                      {project.description}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td
                                className='cursor-pointer py-4 font-weight-600 font-14 '
                                onClick={() => {
                                  handleProjectMeta(project.projectId);
                                  navigate(
                                    `/project/${project.projectId}/${MenuRoutes.TASK_BOARD}`
                                  );
                                }}
                                data-label={formatMessage({ id: 'Project code' })}
                              >
                                <div className='ms-3'>{project.projectCode}</div>
                              </td>
                              <td
                                className='cursor-pointer'
                                onClick={() => {
                                  handleProjectMeta(project.projectId);
                                  navigate(
                                    `/project/${project.projectId}/${MenuRoutes.TASK_BOARD}`
                                  );
                                }}
                                data-label={formatMessage({ id: 'Created By' })}
                              >
                                <div className='d-flex ms-3'>
                                  <div className='d-flex justify-content-center align-items-md-center'>
                                    <UserAvatar
                                      user={project.createdBy}
                                      size={UserAvatarSize.MEDIUM}
                                      displayName={UserAvatarDisplayName.SIDE}
                                    />
                                  </div>
                                </div>
                              </td>
                              <td
                                className='d-flex w-100 align-items-center cursor-pointer'
                                style={{ height: '50px' }}
                                data-label={formatMessage({ id: 'Actions' })}
                              >
                                <Dropdown
                                  className='bg-body cursor-pointer rounded-1'
                                  style={{
                                    position: 'absolute',
                                  }}
                                >
                                  <Dropdown.Toggle
                                    variant='bg-body'
                                    className='bi bi-three-dots-vertical p-0 fnt-16 ms-3'
                                  ></Dropdown.Toggle>
                                  <Dropdown.Menu
                                    className={`${
                                      currentUser?.role === Roles.MEMBER
                                        ? 'dropdown-menu-project1'
                                        : 'dropdown-menu-project'
                                    } dropdown-project`}
                                  >
                                    {filteredKebabMenu.map((menu) => (
                                      <Fragment key={menu.id}>
                                        <Dropdown.Item
                                          className='py-2'
                                          onClick={() => {
                                            if (menu.id === 'edit' || menu.id === 'delete') {
                                              if (menu.id === 'edit') {
                                                setEditId(project.projectId);
                                                setSingleProjectData(project);
                                                setopenCreateProjectModal(true);
                                              } else {
                                                setDeleteId(project.projectId);
                                                setDeleteShowModal(true);
                                              }
                                            } else {
                                              handleProjectMeta(project.projectId);
                                              navigate(`/project/${project.projectId}/${menu.id}`);
                                            }
                                          }}
                                        >
                                          <KTSVG path={menu.icon} />{' '}
                                          <span>
                                            {formatMessage({
                                              id: menu.title,
                                            })}
                                          </span>
                                        </Dropdown.Item>
                                        <div className='table-border' />
                                      </Fragment>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <ConfirmationModal
        showConfirmationModal={showDeleteModal}
        setShowConfirmationModal={setDeleteShowModal}
        headingMessage={formatMessage({ id: 'Delete project' })}
        description={formatMessage({
          id: 'Are you sure you want to delete this project permanently?',
        })}
        handleDelete={handleDeleteProject}
        deleteLoading={deleteLoading}
        id={deleteId}
      />

      <ProjectModal
        handleOnSubmit={singleProjectData ? handleEditProject : handleCreateNewProject}
        loading={loading}
        projectDetails={singleProjectData}
      />

      <SelectPlanModal
        selectPlanModal={selectPlanModal}
        setSelectPlanModal={setSelectPlanModal}
        modalText={formatMessage({
          id: `current_plan_project_message`,
        })
          .replace('{{planName}}', currentUser?.planName || '')
          .replace('{{project}}', currentUser?.metadata?.project || '')}
      />
    </>
  );
};
