import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { SelectInput } from '../../shared/components/Input/SelectInput';
import {
  dateFormatOptions,
  localeInitialValues,
  localeOptions,
  timeFormatOptions,
} from '@/constants/settings';
import { toast } from 'react-toastify';
import { selectedOptionType, TimeZoneType } from '../../../types';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { useUserMetaRequests } from '@/requests/useUserMetaRequests';
import { useTimeZoneRequests } from '@/requests/useTimeZoneRequests';
import { UserMeta } from '@/types/user';
import { MetaKeys } from '@/enums/user';
import Skeleton from 'react-loading-skeleton';
import { useSetUserMeta } from '../../../hooks/useSetUserMeta';

const localSchema = Yup.object().shape({
  locale: Yup.string(),
  timezone: Yup.string(),
  dateFormat: Yup.string(),
  timeFormat: Yup.string(),
  timestampFormat: Yup.string(),
});

export const Locale = ({
  getLocaleApiLoading,
  setRunTimezoneApi,
  runTimezoneApi,
}: {
  getLocaleApiLoading: boolean;
  setRunTimezoneApi: Dispatch<SetStateAction<boolean>>;
  runTimezoneApi: boolean;
}) => {
  const { getTimeZone } = useTimeZoneRequests();
  const { setUserMetas, getUserMetas } = useUserMetaRequests();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState<selectedOptionType[]>([]);
  const { setUserMetaData } = useSetUserMeta();

  const onSubmit = async (values: {
    locale: string;
    timezone: string;
    dateFormat: string;
    timeFormat: string;
    timestampFormat: string;
  }) => {
    try {
      setLoading(true);
      const {
        data: { data, success },
      } = await setUserMetas([
        {
          key: MetaKeys.LOCALE,
          value: values.locale,
        },
        {
          key: MetaKeys.TIMEZONE,
          value: values.timezone,
        },
        {
          key: MetaKeys.DATEFORMAT,
          value: values.dateFormat,
        },
        {
          key: MetaKeys.TIMEFORMAT,
          value: values.timeFormat,
        },
      ]);

      if (success) {
        UserMeta();
        toast.success('Locale is updated Successfully');
        data.map((e: UserMeta & { key: MetaKeys; value: string }) => {
          localeInitialValues[e.key] = e.value;
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleTimeZone = async () => {
    try {
      const {
        data: { data, success },
      } = await getTimeZone();
      if (success) {
        const timeZoneData = data.map((timeZone: TimeZoneType) => {
          return {
            id: timeZone.timezoneId,
            name: timeZone.title,
            value: timeZone.timezoneId,
          };
        });
        setTimeZoneOptions(timeZoneData);
        setRunTimezoneApi(false);
      }
    } catch (err) {
      console.log(err);
      setRunTimezoneApi(false);
    }
  };
  useEffect(() => {
    if (runTimezoneApi) {
      handleTimeZone();
    }
  }, [runTimezoneApi]);

  const UserMeta = async () => {
    try {
      const {
        data: { data, success },
      } = await getUserMetas();
      if (success) {
        setUserMetaData(data);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <>
      <div className='p-8 locale-container'>
        <div className='fs-6 fw-semibold pb-8'>{formatMessage({ id: 'Locale Settings' })}</div>
        <div className='w-100'>
          <Formik
            initialValues={localeInitialValues}
            validationSchema={localSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form>
                  {getLocaleApiLoading ? (
                    <div className='mb-12'>
                      <div className='row d-flex mb-8'>
                        <div className='col-md-6 col-12 ps1'>
                          <Skeleton height={40} />
                        </div>
                        <div className='col-md-6 col-12'>
                          <Skeleton height={40} />
                        </div>
                      </div>
                      <div className='row d-flex mb-8'>
                        <div className='col-md-6 col-12 ps1'>
                          <Skeleton height={40} />
                        </div>
                        <div className='col-md-6 col-12'>
                          <Skeleton height={40} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='d-md-flex col-md-12 flex-wrap w-100'>
                      <SelectInput
                        label={formatMessage({ id: 'Locale' })}
                        fieldName={'locale'}
                        placeholder={formatMessage({ id: 'Select locale' })}
                        formik={formik}
                        isStarRequired={true}
                        options={localeOptions}
                        toolTipText={formatMessage({
                          id: 'GLOBAL.TOOLTIP.LOCALE.LOCALE',
                        })}
                        margin='me-6'
                        width={5}
                      />
                      <SelectInput
                        label={formatMessage({ id: 'Time Zone' })}
                        fieldName={'timezone'}
                        placeholder={formatMessage({
                          id: 'Select timezone',
                        })}
                        formik={formik}
                        isStarRequired={true}
                        options={timeZoneOptions}
                        toolTipText={formatMessage({
                          id: 'GLOBAL.TOOLTIP.LOCALE.TIME_ZONE',
                        })}
                        margin='me-6'
                        width={5}
                      />
                      <SelectInput
                        label={formatMessage({ id: 'Date format' })}
                        fieldName={'dateFormat'}
                        placeholder={formatMessage({
                          id: 'Select date format',
                        })}
                        formik={formik}
                        isStarRequired={true}
                        options={dateFormatOptions}
                        toolTipText={formatMessage({
                          id: 'GLOBAL.TOOLTIP.LOCALE.DATE_FORMAT',
                        })}
                        margin='me-6'
                        width={5}
                      />
                      <SelectInput
                        label={formatMessage({ id: 'Time format' })}
                        fieldName={'timeFormat'}
                        placeholder={formatMessage({
                          id: 'Select time format',
                        })}
                        formik={formik}
                        isStarRequired={true}
                        toolTipText={formatMessage({
                          id: 'GLOBAL.TOOLTIP.LOCALE.TIME_FORMAT',
                        })}
                        options={timeFormatOptions}
                        margin='me-6'
                        width={5}
                      />
                    </div>
                  )}

                  <div className='d-flex justify-content-end button-margin'>
                    <GlobalButton
                      buttonText={formatMessage({ id: 'Save changes' })}
                      buttonType={ButtonTypes.PRIMARY}
                      isLoading={loading}
                      isDisable={formik.isSubmitting || !formik.isValid}
                      onButtonClick={formik.handleSubmit}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};
