import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../core/helpers';

export const InputToolTip = ({ tooltipText }: { tooltipText: string }) => {
  return (
    <>
      <OverlayTrigger
        placement='bottom'
        overlay={
          <Tooltip id='tooltip'>
            <p className='mb-0'> {tooltipText}</p>
          </Tooltip>
        }
      >
        <img
          src={toAbsoluteUrl('/media/icons/duotune/general/tooltip.svg')}
          alt='Tooltip'
          className='ms-1 mb-1'
          width='16px'
          height='16px'
        />
      </OverlayTrigger>
    </>
  );
};
