import axios from 'axios';
import { API_URL } from '.';

const UPLOAD_FILE = `${API_URL}/file`;

export const useFileRequests = () => {
  const uploadFile = (formData: any) => {
    return axios.post(UPLOAD_FILE, formData, {
      validateStatus() {
        return true;
      },
    });
  };

  return {
    uploadFile,
  };
};
