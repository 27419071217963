import axios from 'axios';
import { API_URL } from '.';
import { OAuthConfig } from '@/constants/projects';

export const GET_ALL_TRELLO_BOARDS = `https://api.trello.com/1/members/me/boards`;

export const GET_ALL_LIST_ID = `https://api.trello.com/1/boards/`;
const trelloConfig = OAuthConfig.TRELLO;
const key = trelloConfig.KEY;

export const useTrelloIntegrationrequest = () => {
  const getAllTrelloBoards = (token: string) => {
    const url = `${GET_ALL_TRELLO_BOARDS}?key=${key}&token=${token}`;
    return axios.get(url, {
      validateStatus(status) {
        return true;
      },
    });
  };
  const getAllListId = (token: string, boardId: string) => {
    const url = `https://api.trello.com/1/boards/${boardId}/lists?key=${key}&token=${token}`;
    return axios.get(url, {
      validateStatus(status) {
        return true;
      },
    });
  };
  return {
    getAllTrelloBoards,
    getAllListId,
  };
};
