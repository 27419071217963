import clsx from 'clsx';
import { KTSVG } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
import { useLayout } from '../../core';
import { useGlobalContext } from '../../../helpers';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '../../../../modules/shared/components/UserAvatar';
import { useTeamManagementRequests } from '@/requests/useTeamManagementRequests';
import { MetaKeys, UserAvatarSize } from '@/enums/user';
import { removeCookie, setCookie } from '../../../../util/cookie';
import { useUserMetaRequests } from '@/requests/useUserMetaRequests';
import { useSetUserMeta } from '../../../../hooks/useSetUserMeta';
import { useIntl } from 'react-intl';
import Select, { components } from 'react-select';
import { CreateTeamModal } from '../../../../modules/organization/components/CreateTeamModal';

const itemClass = 'ms-1';
const userAvatarClass = 'symbol-35px symbol-md-40px';
const btnIconClass = 'svg-icon-1';

const Navbar = () => {
  const { getAllTeamsForUser, getUserTokenFromTeam } = useTeamManagementRequests();
  const { getSingleUserMeta, setSingleUserMeta } = useUserMetaRequests();
  const { setUserMetaData } = useSetUserMeta();
  const { config } = useLayout();
  const { currentUser, getCurrentUser, setUserToken, userMeta } = useGlobalContext();
  const [teamOptions, setTeamOptions] = useState<any[]>([]);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { Menu } = components;
  const [showTeamModal, setShowTeamModal] = useState(false);

  const CustomMenu = (props: any) => {
    return (
      <Menu {...props}>
        {props.children}
        <div style={{ padding: '8px', borderTop: '1px solid #ccc' }}>
          <button className='btn btn-sm btn-primary w-100' onClick={() => setShowTeamModal(true)}>
            {formatMessage({ id: 'Create new team' })}
          </button>
        </div>
      </Menu>
    );
  };

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      width: '100%',
      backgroundColor: 'white',
    }),
    control: (provided: any) => ({
      ...provided,
      height: '40px',
      backgroundColor: '#f4f6f9',
    }),
  };

  async function getAllTeams() {
    try {
      const {
        data: { data },
      } = await getAllTeamsForUser();

      const options = data.map((team: any) => ({ label: team.teamName, value: team.teamId }));
      setTeamOptions(options);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getAllTeams();
  }, [currentUser?.teamId]);

  const handleTeam = async (value: any) => {
    try {
      const {
        data: { data },
      } = await getUserTokenFromTeam(value);
      if (data) {
        removeCookie(process.env.REACT_APP_PROJECT_COOKIE_KEY || MetaKeys.PROJECTID);
        getCurrentUser(data);
        setUserToken(data.accessToken);
        const {
          data: { success, data: userMetaData },
        } = await getSingleUserMeta(MetaKeys.CURRENT_SELECTION);

        if (
          success &&
          JSON.parse(userMetaData.value).teamId !== value &&
          JSON.parse(userMetaData.value).projectId
        ) {
          setUserMetaData({ [MetaKeys.CURRENT_SELECTION]: userMetaData.value });
          setCookie(
            process.env.REACT_APP_PROJECT_COOKIE_KEY || MetaKeys.PROJECTID,
            JSON.parse(userMetaData.value).projectId
          );
        } else {
          try {
            const {
              data: { data, success, errors },
            } = await setSingleUserMeta({
              key: MetaKeys.CURRENT_SELECTION,
              value: JSON.stringify({ teamId: value }),
            });
            if (success) {
              setUserMetaData({
                [MetaKeys.CURRENT_SELECTION]: JSON.stringify({ teamId: value }),
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
        navigate('/projects');
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className='app-navbar flex-shrink-0 pe-6'>
        {teamOptions.length > 0 && currentUser && (
          <Select
            className='border rounded-1 h-40px w-200px mt-3'
            classNamePrefix='react-select'
            options={teamOptions}
            defaultValue={teamOptions.find((option) => option.value === currentUser?.teamId)}
            onChange={(selectedOption) => handleTeam(selectedOption.value)}
            components={{ Menu: CustomMenu }}
            styles={customStyles}
            isSearchable={false}
          />
        )}

        <div className={clsx('app-navbar-item', itemClass)}>
          <p className='m-1 fw-bold d-none d-md-block'>
            <span className='font-size-14'>Hi, </span>
            <span className='font-size-14'>{currentUser?.firstName}</span>
          </p>
        </div>
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('navbar-image cursor-pointer', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className='mt-1'>
              <UserAvatar user={currentUser} size={UserAvatarSize.LARGE} />
            </div>
          </div>
          <HeaderUserMenu />
        </div>
        {config.app?.header?.default?.menu?.display && (
          <div className='app-navbar-item d-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-35px h-35px'
              id='kt_app_header_menu_toggle'
            >
              <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
            </div>
          </div>
        )}
      </div>
      <CreateTeamModal showTeamModal={showTeamModal} setShowTeamModal={setShowTeamModal} />
    </>
  );
};

export { Navbar };
