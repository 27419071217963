import { FC, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';
import '../../../styles/sidebar.scss';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

type Props = {
  to: string;
  title: string;
  icon?: string;
  activeIcon?: string;
  hoverIcon: string;
  fontIcon?: string;
  hasBullet?: boolean;
  isSidebarCollapsed: boolean;
  tabIsActive: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  activeIcon,
  hoverIcon,
  fontIcon,
  hasBullet = false,
  isSidebarCollapsed,
  tabIsActive,
}) => {
  const { config } = useLayout();
  const { app } = config;
  const [isHovered, setIsHovered] = useState(false);

  // Tooltip rendering function
  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props} placement='left'>
      {title}
    </Tooltip>
  );

  return (
    <div className='menu-item'>
      <OverlayTrigger
        placement='top-start'
        delay={{ show: 250, hide: 400 }}
        overlay={isSidebarCollapsed ? renderTooltip : <></>}
      >
        <Link
          className={clsx('menu-link without-sub', { active: tabIsActive, hover: isHovered })}
          to={to}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          <div className={`d-flex ${isSidebarCollapsed ? 'px-7' : 'px-10'}`}>
            {icon && activeIcon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                {' '}
                <>
                  {isHovered && !tabIsActive ? (
                    <KTSVG path={hoverIcon} className='svg-menu-icon' />
                  ) : tabIsActive ? (
                    <KTSVG path={activeIcon} className='svg-menu-icon' />
                  ) : (
                    <KTSVG path={icon} className='svg-menu-icon' />
                  )}
                </>
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            <span
              className={clsx(
                `menu-title font-14 ${
                  isHovered && !tabIsActive
                    ? 'light-green'
                    : tabIsActive
                    ? 'white-color'
                    : 'text-menu-light-gray'
                }`
              )}
            >
              {title}
            </span>
          </div>
        </Link>
      </OverlayTrigger>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
