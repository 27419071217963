import { getFileContentType } from './_file';

export const processUploadedFiles = (
  filesArray: any[],
  fileIds: string[],
  filesKey: { key: string }[]
) => {
  const newFiles = [];

  for (let i = 0; i < filesArray.length; i++) {
    const file = filesArray[i];
    const blob = new Blob([file], { type: file.type });
    const imageUrl = URL.createObjectURL(blob);
    const fileType = getFileContentType(file.name);

    newFiles.push({
      blob: blob,
      signedURL: imageUrl,
      contentType: fileType,
      fileId: fileIds[i],
      fileName: filesKey[i].key,
    });
  }

  return newFiles;
};
