import { useAuthRequests } from '@/requests/useAuthRequests';
import { AuthModel } from '@/types';
import { UserMeta } from '@/types/user';
import { Dispatch, FC, SetStateAction, createContext, useContext, useState } from 'react';
import { WithChildren } from '.';
import * as authHelper from '../../modules/auth/core/AuthHelpers';
import { User } from '../../types/user';
import { getCookie } from '../../util/cookie';
import { Plans } from '@/types/billing';
import { AuthCookie } from '@/constants/settings';
import { Currency } from '@/enums/billing';
import { CurrencySelection } from '@/types/billing';
import { IntegrationPlatform } from '@/enums/projects';

// TODO: context => currentUser, BillingInfo, token,
// TODO: projectUsers (store for all project object wise update on when admin add or remove users) => used for dropdown in all places, if user comes as hard refresh then api call will be there and populate on GlobalConetext, and use from there
// TODO: teamUsers [update when we go to org page] => same as projectUsers
// if reusble Modal then make to component wise. trigger and distroy there only
type GlobalContextProps = {
  auth: AuthModel | undefined;
  setAuth: Dispatch<SetStateAction<AuthModel | undefined>>;
  getCurrentUser: (auth: AuthModel | undefined) => Promise<void>;
  getUserToken: () => string;
  currentUser: User | undefined;
  setCurrentUser: Dispatch<SetStateAction<User | undefined>>;
  userToken: string | undefined;
  setUserToken: Dispatch<SetStateAction<string | undefined>>;
  plans: Plans[];
  setPlans: Dispatch<SetStateAction<Plans[]>>;
  plansCurrencyCode: CurrencySelection;
  setPlansCurrencyCode: Dispatch<SetStateAction<CurrencySelection>>;
  plansApiLoading: boolean;
  setPlansApiLoading: Dispatch<SetStateAction<boolean>>;

  userList: User[];
  setAllUserList: Dispatch<SetStateAction<User[]>>;
  openCreateProjectModal: boolean;
  setopenCreateProjectModal: Dispatch<SetStateAction<boolean>>;
  openOauthConfigModal: boolean;
  setOpenOauthConfigModal: Dispatch<SetStateAction<boolean>>;

  openGithubOauthConfigModal: boolean;
  setOpenGithubOauthConfigModal: Dispatch<SetStateAction<boolean>>;

  openTrelloOauthConfigModal: boolean;
  setOpenTrelloOauthConfigModal: Dispatch<SetStateAction<boolean>>;

  IntegrationType: IntegrationPlatform | undefined;
  setIntegrationType: Dispatch<SetStateAction<IntegrationPlatform | undefined>>;
  userMeta: UserMeta | undefined;
  setUserMeta: Dispatch<SetStateAction<UserMeta | undefined>>;
};

const initGlobalContextPropsState = {
  // TODO: discuss on it later
  auth: authHelper.getAuth(),
  setAuth: () => {},
  getCurrentUser: async () => {},
  getUserToken: () => '',

  currentUser: undefined,
  setCurrentUser: () => {},

  userToken: undefined,

  // whenver set user token is called update getCurrentuser
  setUserToken: () => {},

  plans: [],
  setPlans: () => {},
  plansCurrencyCode: { currencyCode: Currency.INR, isCurrencyChanged: false },
  setPlansCurrencyCode: () => {},
  plansApiLoading: false,
  setPlansApiLoading: () => {},

  openCreateProjectModal: false,
  setopenCreateProjectModal: () => {},
  openOauthConfigModal: false,
  setOpenOauthConfigModal: () => {},
  openGithubOauthConfigModal: false,
  setOpenGithubOauthConfigModal: () => {},
  openTrelloOauthConfigModal: false,
  setOpenTrelloOauthConfigModal: () => {},
  IntegrationType: undefined,
  setIntegrationType: () => {},
  userMeta: undefined,
  setUserMeta: () => {},

  userList: [],
  setAllUserList: () => {},
};

const GlobalContext = createContext<GlobalContextProps>(initGlobalContextPropsState);

const useGlobalContext = () => {
  return useContext(GlobalContext);
};

const GlobalContextProvider: FC<WithChildren> = ({ children }) => {
  const { getUserByToken } = useAuthRequests();
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [userToken, setUserToken] = useState<string | undefined>();

  const [plans, setPlans] = useState<Plans[]>([]);
  const [plansCurrencyCode, setPlansCurrencyCode] = useState<CurrencySelection>({
    currencyCode: Currency.INR,
    isCurrencyChanged: false,
  });
  const [plansApiLoading, setPlansApiLoading] = useState(false);

  const [userList, setAllUserList] = useState<User[]>([]);
  const [userMeta, setUserMeta] = useState<UserMeta>();
  const [openCreateProjectModal, setopenCreateProjectModal] = useState<boolean>(false);
  const [openOauthConfigModal, setOpenOauthConfigModal] = useState<boolean>(false);
  const [openGithubOauthConfigModal, setOpenGithubOauthConfigModal] = useState<boolean>(false);
  const [openTrelloOauthConfigModal, setOpenTrelloOauthConfigModal] = useState<boolean>(false);
  const [IntegrationType, setIntegrationType] = useState<IntegrationPlatform>();
  const getCurrentUser = async (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
      const { data } = await getUserByToken(auth.accessToken);
      if (data) {
        setCurrentUser(data.data);
      }
    } else {
      authHelper.removeAuth();
    }
  };

  const getUserToken = () => {
    if (getCookie(AuthCookie.TOKEN)) {
      const { accessToken } = getCookie(AuthCookie.TOKEN);
      setUserToken(accessToken);
      return accessToken;
    } else {
      return false;
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        auth,
        setAuth,
        getCurrentUser,
        getUserToken,
        currentUser,
        setCurrentUser,
        setUserToken,
        userToken,
        plans,
        setPlans,
        plansCurrencyCode,
        setPlansCurrencyCode,
        plansApiLoading,
        setPlansApiLoading,
        userList,
        setAllUserList,
        openCreateProjectModal,
        setopenCreateProjectModal,
        openOauthConfigModal,
        setOpenOauthConfigModal,
        openGithubOauthConfigModal,
        setOpenGithubOauthConfigModal,
        openTrelloOauthConfigModal,
        setOpenTrelloOauthConfigModal,
        IntegrationType,
        setIntegrationType,
        userMeta,
        setUserMeta,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContextProvider, useGlobalContext };
