import { FC, Suspense, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { I18nProvider } from './core/i18n/i18nProvider';
import { LayoutProvider } from './core/layout/core';
import { MasterInit } from './core/layout/MasterInit';
import { WithChildren, useGlobalContext } from './core/helpers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import useAxiosResponse from './modules/shared/components/General/useAxiosResponse';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getCookie } from './util/cookie';
import { AuthCookie } from '@/constants/settings';
import * as Sentry from '@sentry/react';
import { captureConsoleIntegration } from '@sentry/integrations';
import { Toaster } from './modules/shared/components/General/Toaster';
import { useAuthRequests } from './requests/useAuthRequests';
import { useSetUserMeta } from './hooks/useSetUserMeta';
import { useSplashScreen } from './hooks/useSplashScreen';
import { useLogout } from './hooks/useLogout';
import { useBillingRequests } from './requests/useBillingRequests';
import { useUserMetaRequests } from './requests/useUserMetaRequests';
import useAxiosRequest from './hooks/useAxiosRequest';
import useRefreshToken from './hooks/useRefreshToken';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './constants/auth';
const msalInstance = new PublicClientApplication(msalConfig);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV_KEY || 'dev',
  enabled: process.env.REACT_APP_ENV_KEY === 'prod',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    captureConsoleIntegration(),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

const AppInitializer: FC<WithChildren> = ({ children }) => {
  const { getUserByToken } = useAuthRequests();
  const {
    auth,
    setCurrentUser,
    setPlans,
    plans,
    userMeta,
    setPlansApiLoading,
    getUserToken,
    plansCurrencyCode,
  } = useGlobalContext();
  const { setUserMetaData } = useSetUserMeta();
  const { setSplashScreen } = useSplashScreen();
  const { logout } = useLogout();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { getPlans } = useBillingRequests();
  const { getUserMetas } = useUserMetaRequests();
  const { refreshToken } = useRefreshToken();

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(apiToken);
          if (data) {
            setCurrentUser(data.data);
          }
        }
      } catch (error: any) {
        if (!didRequest.current && error?.response?.status === 401) {
          const refreshResponse = await refreshToken(error.response.config);
          if (refreshResponse?.data?.success) {
            setCurrentUser(refreshResponse.data.data);
          }
        }
      }

      return () => (didRequest.current = true);
    };
    if (auth && auth.accessToken) {
      requestUser(auth.accessToken);
    } else {
      logout();
    }
    // eslint-disable-next-linex
  }, [auth]);

  async function getAllPlans() {
    setPlansApiLoading(true);
    try {
      let ip;
      const {
        data: { data, success },
      } = await getPlans();

      if (success) {
        setPlans(data);
      }
    } catch (err) {
      const {
        data: { data, success },
      } = await getPlans();
      if (success) {
        setPlans(data);
      }
    } finally {
      setPlansApiLoading(false);
    }
  }

  const UserMeta = async () => {
    try {
      const {
        data: { data, success },
      } = await getUserMetas();
      if (success) {
        setUserMetaData(data);
      }
    } catch (err: any) {
      if (err?.response?.status === 401) {
        const refreshResponse = await refreshToken(err.response.config);
        if (refreshResponse?.data?.success) {
          setUserMetaData(refreshResponse.data.data);
        }
      }
    }
  };

  useEffect(() => {
    if (getUserToken()) {
      getAllPlans();
    } else {
      setSplashScreen(false);
      setShowSplashScreen(false);
    }
  }, [auth, plansCurrencyCode.isCurrencyChanged, plansCurrencyCode.currencyCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (getUserToken()) {
      UserMeta();
    } else {
      setSplashScreen(false);
      setShowSplashScreen(false);
    }
  }, [auth]);

  useEffect(() => {
    if (userMeta && plans.length > 0) {
      setSplashScreen(false);
      setShowSplashScreen(false);
    }
  }, [!userMeta, plans.length === 0]); // eslint-disable-line react-hooks/exhaustive-deps

  return showSplashScreen ? <></> : <>{children}</>;
};

const App = () => {
  const { setUserToken } = useGlobalContext();

  // TODO: Repeated code we will revisit this later
  useAxiosRequest(axios);
  useAxiosResponse(axios);

  useEffect(() => {
    const updateToken = async () => {
      const token = getCookie(AuthCookie.TOKEN);
      if (token) setUserToken(token);
    };
    updateToken();
  }, []);

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Toaster />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
          <I18nProvider>
            <LayoutProvider>
              <AppInitializer>
                <Elements stripe={stripePromise}>
                  <Outlet />
                  <MasterInit />
                </Elements>
              </AppInitializer>
            </LayoutProvider>
          </I18nProvider>
        </GoogleOAuthProvider>
      </MsalProvider>
    </>
  );
};

export { App };
