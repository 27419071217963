import { useEffect, useState } from 'react';
import AddLinkUrl from '../components/project-settings/AddLinkUrl';
import { PageHeading } from '../../shared/components/PageHeading';
import ProjectUser from '../components/project-settings/ProjectUser';
import { ScriptCode } from '../components/project-settings/ScriptCode';
import { useParams } from 'react-router-dom';
import { useProjectRequests } from '@/requests/useProjectRequests';
import { BaseProject } from '@/types/projects';
import ProjectDetails from '../components/project-settings/ProjectDetails';
import { useIntl } from 'react-intl';

export const Settings = () => {
  const { singleProject } = useProjectRequests();
  const { projectId } = useParams();
  const { formatMessage } = useIntl();
  const [projectDetails, setProjectDetails] = useState<BaseProject | null>(null);
  const getProject = async () => {
    if (!projectId) {
      return;
    }
    try {
      const {
        data: { data, success, errors },
      } = await singleProject(parseInt(projectId));
      if (success) {
        setProjectDetails(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProject();
  }, [projectId]);
  return (
    <div id='project-setting'>
      <PageHeading
        title='Project settings'
        description={formatMessage({
          id: 'Configure your project details, domains, access management and integrate with your web application.',
        })}
      />
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <ProjectDetails projectDetails={projectDetails} getProject={getProject} />
        </div>
        <div className='col-12 col-lg-6'>
          {/* <AddLinkUrl /> */}
          <ScriptCode projectDetails={projectDetails} />
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-xl-6'>
          <ProjectUser />
        </div>
      </div>
    </div>
  );
};
