import axios from 'axios';
import { API_URL } from '.';

const TEAM_URL = `${API_URL}/team`;
const GET_ALL_TEAMS_FOR_USER = `${TEAM_URL}/user/teams`;
const TRANSFER_OWNERSHIP = `${TEAM_URL}/transfer-ownership/users`;
const TEAM_USERS = `${TEAM_URL}/users`;
const PENDING_USERS = `${TEAM_URL}/pending-invite`;

export const useTeamManagementRequests = () => {
  const getAllTeamsForUser = () => {
    return axios.get(GET_ALL_TEAMS_FOR_USER, {
      validateStatus() {
        return true;
      },
    });
  };

  const getUserTokenFromTeam = (teamId: string) => {
    return axios.get(`${GET_ALL_TEAMS_FOR_USER}/${teamId}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const getPendingUsers = () => {
    return axios.get(`${PENDING_USERS}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const deletePendingInvite = (inviteId: number) => {
    return axios.delete(`${PENDING_USERS}/delete/${inviteId}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const getUser = (userId: number) => {
    return axios.get(`${TEAM_USERS}/${userId}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const editUser = (userId: number | undefined, data: any) => {
    return axios.put(`${TEAM_USERS}/${userId}`, data, {
      validateStatus() {
        return true;
      },
    });
  };

  const updateTransferOwnership = (userId: number | undefined) => {
    return axios.post(`${TRANSFER_OWNERSHIP}/${userId}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const deleteUser = (userId: number) => {
    return axios.delete(`${TEAM_USERS}/${userId}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const getUserList = () => {
    return axios.get(`${TEAM_USERS}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const editTeamName = (teamName: string) => {
    return axios.put(
      `${TEAM_URL}`,
      { teamName },
      {
        validateStatus() {
          return true;
        },
      }
    );
  };
  const getAllTeamUsers = () => {
    return axios.get(`${TEAM_USERS}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const createTeam = (teamName: string) => {
    return axios.post(
      `${TEAM_URL}`,
      { teamName },
      {
        validateStatus() {
          return true;
        },
      }
    );
  };
  return {
    editTeamName,
    getUserList,
    deleteUser,
    deletePendingInvite,
    editUser,
    updateTransferOwnership,
    getUserTokenFromTeam,
    getUser,
    getPendingUsers,
    getAllTeamsForUser,
    getAllTeamUsers,
    createTeam,
  };
};
