import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormCheck from 'react-bootstrap/FormCheck';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { TextArea } from '../../../shared/components/Input/TextArea';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  YouTrackAgileSprintsData,
  Sprint,
  IntegrationType,
  YoutrackMetaData,
} from '../../../../types/projects';
import { useYoutrackIntegrationRequests } from '@/requests/useYoutrackIntegrationRequests';
import { IntegrationPlatform } from '@/enums/projects';
import { BWInput } from '../../../shared/components/Input/BWInput';
import Skeleton from 'react-loading-skeleton';

export const YoutrackIntegrationModel = ({
  openYoutrackIntegrationModal,
  setOpenYoutrackIntegrationModal,
  handleIntegrateProject,
  data,
  integrationLoading,
  isEdit,
}: {
  openYoutrackIntegrationModal: boolean;
  setOpenYoutrackIntegrationModal: Dispatch<SetStateAction<boolean>>;
  handleIntegrateProject: any;
  data: any;
  integrationLoading: boolean;
  isEdit: boolean;
}) => {
  const { getyouTrackProjects, getAllAligleBoard, getAllSprints } =
    useYoutrackIntegrationRequests();
  const { formatMessage } = useIntl();
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [projectToken, setProjectToken] = useState<string>('');
  const [youtrackProjectsLoading, setYoutrackProjectLoading] = useState<boolean>(false);
  const [youTrackProjectLists, setAllYouTrackProjectLists] = useState<any[]>([]);
  const [youtrackSelectedOption, setYoutrackSelectedOption] = useState<any>(null);
  const [boardSelected, setBoardSelected] = useState<boolean>(false);
  const [YouTrackAgileBoardLists, setAllYouTrackAgileBoardLists] = useState<any[]>([]);
  const [agileBoardSelectedOption, setAgileBoardSelectedOption] = useState<any>(null);
  const [sprintSelectedOption, setSprintSelectedOption] = useState<null | {
    value: any;
    label: any;
    id: any;
  }>(null);
  const [sprintSelected, setSprintSelected] = useState<boolean>(false);
  const [metaData, setMetaData] = useState<YoutrackMetaData | null>();

  const [YouTrackAgileSprintsLists, setAllYouTrackAgileSprintLists] =
    useState<YouTrackAgileSprintsData>({
      currentSprint: {
        name: '',
        id: '',
        $type: '',
      },
      sprints: [],
      name: '',
      id: '',
      $type: '',
    });

  const [currentSprint, setCurrentSprint] = useState<Sprint>({
    name: '',
    id: '',
    $type: '',
  });

  const projectSchema = Yup.object().shape({
    baseUrl: Yup.string()
      .matches(
        /((http|https):\/\/)(www\.)?[a-zA-Z0-9@:%._\+~#?&//=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%._\+~#?&//=]*)/,
        formatMessage({ id: 'Enter a valid base URL' })
      )
      .required(formatMessage({ id: 'Base URL is required' })),
    projectToken: Yup.string().required(formatMessage({ id: 'Permanent token is required' })),
  });

  const initialValues = {
    baseUrl: metaData ? (metaData as YoutrackMetaData).baseUrl : '',
    projectToken: metaData ? (metaData as YoutrackMetaData).token : '',
    platformProjectId: metaData ? (metaData as YoutrackMetaData).platformProjectId : '',
    addToBoard: metaData ? (metaData as YoutrackMetaData).addToBoard : false,
    agileId: metaData ? (metaData as YoutrackMetaData).agileId : '',
    sprintId: metaData ? (metaData as YoutrackMetaData).sprintId : '',
  };

  useEffect(() => {
    if (youtrackSelectedOption?.id) {
      handleGetAllBoards(baseUrl, projectToken, youtrackSelectedOption.id);
    }
  }, [youtrackSelectedOption]);

  const handleGetAllYouTrackProjectList = async (baseUrl: string, token: string) => {
    setYoutrackProjectLoading(true);
    try {
      const {
        data: { data, success, errors },
      } = await getyouTrackProjects(baseUrl, token);
      if (success) {
        setBaseUrl(baseUrl);
        setProjectToken(token);
        setAllYouTrackProjectLists(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setYoutrackProjectLoading(false);
    }
  };

  const handleGetAllBoards = async (baseUrl: string, token: string, platformProjectId: string) => {
    setYoutrackProjectLoading(true);
    try {
      const {
        data: { data, success, errors },
      } = await getAllAligleBoard(baseUrl, token, platformProjectId);
      if (success) {
        setAllYouTrackAgileBoardLists(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setYoutrackProjectLoading(false);
    }
  };

  const handleGellAllSprints = async (agileBoardId: string, token: any, baseUrl: any) => {
    setYoutrackProjectLoading(true);
    try {
      if (baseUrl && token) {
        const {
          data: { data, success, errors },
        } = await getAllSprints(agileBoardId, token, baseUrl);
        if (success) {
          setAllYouTrackAgileSprintLists(data);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setYoutrackProjectLoading(false);
    }
  };
  useEffect(() => {
    if (data && data?.meta_data) {
      setMetaData(JSON.parse(data?.meta_data));
    }
  }, [data]);

  useEffect(() => {
    if (metaData) {
      handleGetAllYouTrackProjectList(metaData.baseUrl, metaData.token);
    }
  }, [metaData]);

  useEffect(() => {
    if (youTrackProjectLists && youTrackProjectLists.length > 0 && data && metaData) {
      const matchingProject = youTrackProjectLists.find(
        (project) => project.id === (metaData as YoutrackMetaData)?.platformProjectId
      );

      if (matchingProject) {
        setYoutrackSelectedOption(matchingProject);
      }
    }
  }, [youTrackProjectLists, (data?.meta_data as YoutrackMetaData)?.platformProjectId, metaData]);

  useEffect(() => {
    if (metaData) {
      if (metaData?.addToBoard) {
        setBoardSelected(true);
      } else {
        setBoardSelected(false);
      }
    }
  }, [metaData]);

  useEffect(() => {
    if (
      boardSelected === true &&
      youtrackSelectedOption &&
      data?.platformName === IntegrationPlatform.YOUTRACK &&
      metaData
    ) {
      handleGetAllBoards(
        (metaData as YoutrackMetaData)?.baseUrl,
        (metaData as YoutrackMetaData)?.token,
        (metaData as YoutrackMetaData)?.platformProjectId
      );
    }
  }, [boardSelected]);

  useEffect(() => {
    if (
      data &&
      metaData &&
      (metaData as YoutrackMetaData)?.platformProjectId &&
      YouTrackAgileBoardLists.length > 0
    ) {
      const matchingProject = YouTrackAgileBoardLists.find(
        (project) =>
          project.projects.length > 0 &&
          project.projects[0].id === (metaData as YoutrackMetaData)?.platformProjectId
      );

      if (matchingProject) {
        const matchedProjectObj = {
          value: matchingProject.id,
          label: matchingProject.name,
          id: matchingProject.id,
        };
        setAgileBoardSelectedOption(matchedProjectObj);
      }
    }
  }, [
    (data?.meta_data as YoutrackMetaData)?.platformProjectId,
    YouTrackAgileBoardLists,
    boardSelected,
    metaData,
  ]);

  useEffect(() => {
    if (data && agileBoardSelectedOption && metaData) {
      handleGellAllSprints(
        agileBoardSelectedOption.id,
        (metaData as YoutrackMetaData)?.token,
        (metaData as YoutrackMetaData)?.baseUrl
      );
    }
  }, [agileBoardSelectedOption, projectToken, metaData]);

  useEffect(() => {
    if (!data) {
      setBaseUrl('');
      setProjectToken('');
      setAllYouTrackProjectLists([]);
      setYoutrackSelectedOption(null);
      setBoardSelected(false);
      setAllYouTrackAgileBoardLists([]);
      setAgileBoardSelectedOption(null);
      setSprintSelectedOption(null);
      setMetaData(null);
    }
  }, [data]);

  const handleYouTrackToggle = () => {
    setBoardSelected(!boardSelected);
  };

  useEffect(() => {
    if (boardSelected && !metaData) {
      handleGetAllBoards(baseUrl, projectToken, youtrackSelectedOption.id);
    }
  }, [boardSelected]);

  const handleYouTrackSprintToggle = () => {
    setSprintSelected(!sprintSelected);
  };

  useEffect(() => {
    if (agileBoardSelectedOption) {
      setSprintSelectedOption({
        value: YouTrackAgileSprintsLists.currentSprint?.id,
        label: YouTrackAgileSprintsLists.currentSprint?.name,
        id: 'current',
      });
      setSprintSelected(true);
    } else {
      setSprintSelectedOption(null);
    }
  }, [agileBoardSelectedOption, YouTrackAgileSprintsLists]);

  useEffect(() => {
    if (metaData && metaData.sprintId && metaData.sprintId !== 'current') {
      setSprintSelected(false);
    }
  }, [metaData, YouTrackAgileSprintsLists]);

  const handleHide = () => {
    if (!isEdit) {
      setAllYouTrackProjectLists([]);
      setYoutrackSelectedOption(null);
      setBoardSelected(false);
    }
    setOpenYoutrackIntegrationModal(false);
  };

  return (
    <>
      <Modal
        show={openYoutrackIntegrationModal}
        onHide={handleHide}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className=''>
          <div className='font-size-16 fw-bold title-color'>
            {formatMessage({ id: 'Setup Youtrack integration' })}
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={projectSchema}
          onSubmit={async (values, formikHelpers) => {
            setBaseUrl(values.baseUrl);
            setProjectToken(values.projectToken);
            let meta_data;
            if (youTrackProjectLists.length) {
              meta_data = {
                baseUrl: values.baseUrl,
                token: values.projectToken,
                platformProjectId: youtrackSelectedOption.id,
                addToBoard: boardSelected ? boardSelected : false,
                agileId:
                  boardSelected && agileBoardSelectedOption ? agileBoardSelectedOption.id : null,
                sprintId: boardSelected && sprintSelectedOption ? sprintSelectedOption.id : null,
              };

              setMetaData(meta_data);
              await handleIntegrateProject(meta_data);
            } else await handleGetAllYouTrackProjectList(values.baseUrl, values.projectToken);
          }}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <Modal.Body className='pt-2 pb-0'>
                <div className='mt-5'>
                  <div className='d-flex flex-column'>
                    <BWInput
                      fieldType={'text'}
                      fieldName={'baseUrl'}
                      formik={formik}
                      placeholder={formatMessage({ id: 'Enter base URL' })}
                      label={formatMessage({ id: 'Base URL' })}
                      isRequired={true}
                      toolTipText={formatMessage({
                        id: 'GLOBAL.TOOLTIP.YOUTRACK.BASEURL',
                      })}
                    />
                    <div>
                      <BWInput
                        fieldType={'text'}
                        fieldName={'projectToken'}
                        formik={formik}
                        placeholder={formatMessage({ id: 'Enter your Youtrack permanent token' })}
                        label={formatMessage({ id: 'Permanent token' })}
                        isRequired={true}
                        toolTipText={formatMessage({
                          id: 'GLOBAL.TOOLTIP.YOUTRACK.TOKEN',
                        })}
                      />
                    </div>
                  </div>
                </div>

                {youTrackProjectLists.length > 0 && (
                  <div className='w-100 d-flex flex-column'>
                    {' '}
                    <label className='form-label font-size-14 text-dark fw-bold mb-3 me-3'>
                      {formatMessage({
                        id: 'Select Youtrack project',
                      })}
                    </label>
                    <Select
                      name='platformProjectId'
                      options={youTrackProjectLists.map((project: any) => ({
                        value: project.id,
                        label: project.name,
                        id: project.id,
                      }))}
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          'platformProjectId',
                          selectedOption ? selectedOption.id : ''
                        );

                        setYoutrackSelectedOption(selectedOption);
                      }}
                      defaultValue={
                        data?.platformName === IntegrationPlatform.YOUTRACK
                          ? youTrackProjectLists.find(
                              (project: any) =>
                                project.id === (metaData as YoutrackMetaData).platformProjectId
                            )
                            ? {
                                value: youTrackProjectLists.find(
                                  (project: any) =>
                                    project.id === (metaData as YoutrackMetaData).platformProjectId
                                ).id,
                                label: youTrackProjectLists.find(
                                  (project: any) =>
                                    project.id === (metaData as YoutrackMetaData).platformProjectId
                                ).name,
                                id: youTrackProjectLists.find(
                                  (project: any) =>
                                    project.id === (metaData as YoutrackMetaData).platformProjectId
                                ).id,
                              }
                            : null
                          : null
                      }
                      placeholder={formatMessage({
                        id: 'Select project',
                      })}
                      className='custom-select pt-1'
                      classNamePrefix='team-dropdown'
                    />
                  </div>
                )}

                {youtrackSelectedOption && (
                  <div className='w-100 mt-6'>
                    <label className='form-label font-size-14 text-dark fw-bold mb-3 me-3'>
                      {formatMessage({
                        id: 'Add on agile board',
                      })}
                    </label>

                    <div>
                      <FormCheck
                        type='switch'
                        id='youTrackSwitch'
                        checked={boardSelected || (data && data.meta_data?.addToBoard)}
                        onChange={handleYouTrackToggle}
                      />
                    </div>
                  </div>
                )}

                {
                  <div>
                    {youtrackSelectedOption && boardSelected && (
                      <div className='w-100 mt-6 d-flex flex-column'>
                        <label className='form-label font-size-14 text-dark fw-bold mb-3 me-3'>
                          {' '}
                          {formatMessage({
                            id: 'Select agile board',
                          })}
                        </label>
                        <Select
                          name='agileBoardId'
                          options={YouTrackAgileBoardLists.map((board: any) => ({
                            value: board.id,
                            label: board.name,
                            id: board.id,
                          }))}
                          onChange={(selectedBoard) => {
                            if (selectedBoard) {
                              setAgileBoardSelectedOption(selectedBoard);
                              handleGellAllSprints(selectedBoard.id, projectToken, baseUrl);
                            }
                          }}
                          defaultValue={agileBoardSelectedOption}
                          placeholder={formatMessage({
                            id: 'Select agile board',
                          })}
                          className='custom-select pt-1'
                          classNamePrefix='team-dropdown'
                        />
                      </div>
                    )}
                  </div>
                }

                {agileBoardSelectedOption && boardSelected && (
                  <div className='w-100 mt-6'>
                    <div className='d-flex flex-column'>
                      <label className='form-label font-size-14 text-dark fw-bold mb-3 me-3'>
                        {formatMessage({
                          id: 'Always add to the current active sprint?',
                        })}
                      </label>

                      <div>
                        <FormCheck
                          type='switch'
                          id='youTrackSprintSwitch'
                          checked={sprintSelected}
                          onChange={handleYouTrackSprintToggle}
                        />
                      </div>
                    </div>
                    {!sprintSelected && (
                      <div className='d-flex flex-column mt-6'>
                        <label className='form-label font-size-14 text-dark fw-bold  mb-3 me-3'>
                          {formatMessage({
                            id: 'Select sprint',
                          })}
                        </label>
                        <Select
                          name='sprintId'
                          options={YouTrackAgileSprintsLists.sprints.map((board: any) => ({
                            value: board.id,
                            label:
                              board.id === YouTrackAgileSprintsLists.currentSprint.id ? (
                                <strong
                                  style={{
                                    color: 'black',
                                  }}
                                >
                                  {board.name} (Current sprint)
                                </strong>
                              ) : (
                                board.name
                              ),
                            id: board.id,
                          }))}
                          onChange={(selectedSprint) => {
                            setSprintSelectedOption(selectedSprint);
                          }}
                          placeholder={formatMessage({
                            id: 'Select sprint',
                          })}
                          defaultValue={(() => {
                            const sprintId = metaData
                              ? (metaData as YoutrackMetaData).sprintId
                              : '';

                            const findSprint = YouTrackAgileSprintsLists.sprints.find(
                              (board: any) => board.id === sprintId
                            );

                            return findSprint
                              ? {
                                  value: metaData ? (metaData as YoutrackMetaData).agileId : '',
                                  label: findSprint.name,
                                  id: sprintId,
                                }
                              : null;
                          })()}
                          className='custom-select pt-1'
                        />
                      </div>
                    )}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer
                className={`pt-0 border-top-0 ${youTrackProjectLists.length && 'mt-6'}`}
              >
                <GlobalButton
                  buttonText={formatMessage({ id: 'Cancel' })}
                  buttonType={ButtonTypes.SECONDARY}
                  onButtonClick={() => {
                    setOpenYoutrackIntegrationModal(false);
                  }}
                />
                {youTrackProjectLists.length ? (
                  <GlobalButton
                    buttonText={formatMessage({ id: 'Save integration' })}
                    buttonType={ButtonTypes.PRIMARY}
                    // isDisable={formik.isSubmitting || !formik.isValid}
                    isLoading={integrationLoading}
                    onButtonClick={formik.handleSubmit}
                  />
                ) : (
                  <GlobalButton
                    buttonText={formatMessage({ id: 'Continue' })}
                    buttonType={ButtonTypes.PRIMARY}
                    isDisable={formik.isSubmitting || !formik.isValid}
                    isLoading={youtrackProjectsLoading}
                    onButtonClick={formik.handleSubmit}
                  />
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
