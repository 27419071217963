import { FileType } from '@/enums/file';
import { getFileContentType, getFileExtension } from '../../../../util/_file';
import { FileObject, TemporaryFile } from '@/types/file';
import { toAbsoluteUrl } from '../../../../core/helpers';
import zoom from '../../../../core/assets/images/svg/bugs.work/screenshot-dimension.svg';
import FileDisplayModal from './FileDisplayModal';
import { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
const FileTypes = ({
  file,
  isFullScreen,
  isExpand,
}: {
  file: TemporaryFile | FileObject;
  isFullScreen: boolean;
  isExpand?: boolean;
}) => {
  const [isZoom, setIsZoom] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const fileName = file.fileName.substring(0, file.fileName.lastIndexOf('.'));
  const truncatedFileName = fileName.length > 14 ? fileName.substring(0, 15) + '....' : fileName;

  return (
    <>
      {(file.contentType === FileType.IMAGE || file.contentType.includes(FileType.IMAGE)) &&
        (!isFullScreen ? (
          <img
            src={file.signedURL}
            className='thumbnail-image cursor-pointer file-upload-box'
            alt={`Selected Image`}
            style={{ objectFit: 'contain' }}
          />
        ) : (
          <div className='d-flex justify-content-center align-items-center'>
            <img
              className='expanded-image w-100'
              src={file.signedURL}
              alt={`Clicked Image`}
              style={{ objectFit: 'contain' }}
            />
            {isExpand && (
              <div
                className='zoom-btn-container'
                onClick={() => {
                  setIsZoom(true);
                }}
              >
                <img src={zoom} alt='' height={24} />
              </div>
            )}

            {isZoom && <FileDisplayModal file={file} isClicked={true} setIsClicked={setIsZoom} />}
          </div>
        ))}
      {(file.contentType === FileType.VIDEO ||
        file.contentType.includes(FileType.VIDEO) ||
        getFileContentType(file.fileName) === FileType.VIDEO) &&
        (!isFullScreen ? (
          <div className='thumbnail-video '>
            <div className='extension-background extension-width'>
              <video className='thumbnail-video' src={file.signedURL} />
            </div>
          </div>
        ) : (
          <div
            className='d-flex justify-content-center align-items-center mx-12'
            style={{ height: '70vh' }}
          >
            <video width={'100%'} src={file.signedURL} autoPlay controls />
          </div>
        ))}
      {((getFileContentType(file.fileName) === FileType.DOCUMENT &&
        !file.contentType.includes(FileType.PDF)) ||
        file.contentType === FileType.DOCUMENT ||
        file.contentType === FileType.TEXT) &&
        (!isFullScreen ? (
          <div className='document-bg-color thumbnail-pdf'>
            <div className='badge badge-primary extension-width ps-2 mt-4 ms-4 mb-4'>
              {getFileExtension(file.fileName)}
            </div>
            <div className='p-3 text-wrap'>{truncatedFileName}</div>
          </div>
        ) : (
          <>
            <div className='image-overlay w-auto d-flex flex-column justify-content-center align-items-center'>
              <div className='document-bg-color thumbnail-pdf'>
                <div className='badge badge-primary extension-width ps-2 mt-4 ms-4'>
                  {getFileExtension(file.fileName)}
                </div>
                <div className='px-3 text-wrap'>{truncatedFileName}</div>
                <div className='d-flex justify-content-center'>
                  <a href={file.signedURL} download={file.fileName}>
                    <img
                      src={toAbsoluteUrl('/media/icons/duotune/general/download.svg')}
                      width={20}
                    />
                  </a>
                </div>
              </div>
            </div>
          </>
        ))}
      {getFileContentType(file.fileName) === FileType.DOCUMENT &&
        file.contentType.includes(FileType.PDF) &&
        (!isFullScreen ? (
          <div className='document-bg-color thumbnail-pdf'>
            <div className='badge badge-primary extension-width ps-2 mt-4 ms-4 mb-4'>
              {getFileExtension(file.fileName)}
            </div>
            <div className='p-3 text-wrap'>{truncatedFileName}</div>
          </div>
        ) : (
          <>
            <div className='image-overlay w-auto d-flex justify-content-center align-items-center'>
              <Worker workerUrl={process.env.REACT_APP_PDF_WORKER_URL as string}>
                <Viewer fileUrl={file?.signedURL} plugins={[defaultLayoutPluginInstance]} />
              </Worker>
            </div>
          </>
        ))}
    </>
  );
};

export default FileTypes;
