import { getAuth } from '../modules/auth';
import { isTokenExpired } from '../util/_jwt';
import useRefreshToken from './useRefreshToken';

function useAxiosRequest(axios: any) {
  const { refreshToken } = useRefreshToken();

  axios.defaults.headers.Accept = 'application/json';
  axios.interceptors.request.use(
    async (config: { headers: { Authorization: string }; url: string }) => {
      let auth = getAuth();

      if (auth?.accessToken && !config.url.includes('amazonaws.com')) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

export default useAxiosRequest;
