import { useLogout } from './useLogout';
import { getAuth, setAuth } from '../modules/auth';
import { useAuthRequests } from '@/requests/useAuthRequests';
import axios from 'axios';

const useRefreshToken = () => {
  const { refreshTokenAPI } = useAuthRequests();
  const { logout } = useLogout();
  const refreshToken = async (failedRequest: any) => {
    try {
      const auth: any = getAuth();
      if (auth && auth?.refreshToken) {
        const {
          data: { data, success },
        } = await refreshTokenAPI(auth.refreshToken);
        if (success) {
          setAuth(data);
          if (failedRequest.url.includes('/auth/verify-token')) {
            const parseData = JSON.parse(failedRequest.data);
            parseData.token = data.accessToken;
            failedRequest.data = JSON.stringify(parseData);
          }
          failedRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
          return axios(failedRequest);
        } else {
          logout();
          return Promise.reject('Token refresh failed');
        }
      } else {
        logout();
        return Promise.reject('Token refresh failed');
      }
    } catch (error) {
      logout();
      return Promise.reject(error);
    }
  };

  return { refreshToken };
};

export default useRefreshToken;
