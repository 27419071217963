import axios from 'axios';
import { API_URL } from '.';

const PROJECT = `${API_URL}/project`;

export const useProjectRequests = () => {
  const getAllProjects = () => {
    return axios.get(`${PROJECT}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const createNewProject = (title: string, description: string | null, projectCode: string) => {
    const payload = {
      title,
      description,
      projectCode,
    };

    return axios.post(PROJECT, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const editProject = (projectId: number | null, title: string, description: string | null) => {
    const payload = {
      title,
      description,
    };

    return axios.put(`${PROJECT}/${projectId}`, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const deleteProject = (projectId: number) => {
    return axios.delete(`${PROJECT}/${projectId}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const singleProject = (projectId: number) => {
    return axios.get(`${PROJECT}/${projectId}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const getProjectById = (projectId: number | null) => {
    return axios.get(`${PROJECT}/${projectId}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const getApiKey = (projectId: number) => {
    return axios.get(`${PROJECT}/fetch-api-key/${projectId}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  return {
    singleProject,
    deleteProject,
    editProject,
    createNewProject,
    getAllProjects,
    getProjectById,
    getApiKey,
  };
};
