import React, { useState } from 'react';
import '../styles/userAvatar.scss';
import unassigned from '../../../core/assets/images/svg/bugs.work/unassigned.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BaseUser } from '@/types/user';
import { UserAvatarDisplayName, UserAvatarSize } from '@/enums/user';
import { DEFAULT_USERAVATAR_COLOR } from '@/constants/profile';
import { maximumFullNameSize } from '../../../constants/profile';

const UserAvatar = ({
  user,
  size,
  displayName,
  maxAllowedTextLength,
}: {
  user?: BaseUser;
  size: UserAvatarSize;
  displayName?: UserAvatarDisplayName;
  maxAllowedTextLength?: number;
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleMouseEnter = () => {
    if (displayName === UserAvatarDisplayName.HOVER) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    if (displayName === UserAvatarDisplayName.HOVER) {
      setShowTooltip(false);
    }
  };

  const getSizeStyles = () => {
    let height;
    let width;
    let paddingTop;
    let fontSize;

    if (size === UserAvatarSize.LARGE) {
      height = '32px';
      width = '32px';
      paddingTop = '6px';
    } else if (size === UserAvatarSize.MEDIUM) {
      height = '26px';
      width = '26px';
      paddingTop = '3px';
    } else if (size === UserAvatarSize.SMALL) {
      height = '20px';
      width = '20px';
      paddingTop = '3px';
      fontSize = '10px';
    }
    return {
      height,
      width,
      paddingTop,
      fontSize,
    };
  };

  const circleBgStyle = {
    backgroundColor: user?.color || DEFAULT_USERAVATAR_COLOR,
    ...getSizeStyles(),
  };

  const renderTooltip = () => (
    <Tooltip>
      {user?.firstName} {user?.lastName}
    </Tooltip>
  );

  const fullName = `${user?.firstName} ${user?.lastName}`;
  return (
    <>
      {user?.firstName ? (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip()}
          show={showTooltip}
        >
          <div className='d-flex' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={`user-avatar-circle text-center`} style={circleBgStyle}>
              {user.firstName?.charAt(0)?.toUpperCase()}
              {user.lastName?.charAt(0)?.toUpperCase()}
            </div>
            {displayName === UserAvatarDisplayName.SIDE && (
              <div className='p-1 d-flex align-items-center' title={fullName}>
                {maxAllowedTextLength ? (
                  fullName.length > maxAllowedTextLength ? (
                    `${fullName.slice(0, maxAllowedTextLength)}...`
                  ) : (
                    <div className='name-container'>{fullName}</div>
                  )
                ) : fullName.length < maximumFullNameSize ? (
                  fullName
                ) : (
                  `${fullName.slice(0, maximumFullNameSize)}...`
                )}
              </div>
            )}
          </div>
        </OverlayTrigger>
      ) : (
        <img style={getSizeStyles()} src={unassigned} alt='Unassigned' />
      )}
    </>
  );
};

export default UserAvatar;
