/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useIntl } from 'react-intl';
import { useGlobalContext } from '../../../core/helpers';
import { BillingStatus } from '@/constants/settings';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { useBillingRequests } from '@/requests/useBillingRequests';
import { SubscriptionDetails, CurrentPlan } from '@/types/billing';
import '../styles/index.scss';
import { PlanName } from '@/enums/settings';
import Skeleton from 'react-loading-skeleton';
import { useGetLocale } from '../../../hooks/useGetLocale';
import { BillingModal } from '../../shared/components/BillingModal';
import { PlanCycle } from '@/enums/billing';

export function Billing({
  subscriptionDetails,
  getBillingDetailsLoading,
  scheduleDowngradeData,
}: {
  subscriptionDetails: SubscriptionDetails | undefined;
  getBillingDetailsLoading: boolean;
  scheduleDowngradeData?: Partial<SubscriptionDetails['schedule']>;
}) {
  const { ManageBiliing, createCheckoutSession } = useBillingRequests();
  const { plans } = useGlobalContext();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const { getLocaleDate } = useGetLocale();

  const [currentPlan, setCurrentPlan] = useState<CurrentPlan | undefined>();
  const [clickedPlan, setClickedPlan] = useState<CurrentPlan | undefined>();
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [activeFreq, setActiveFreq] = useState<PlanCycle>(PlanCycle.YEARLY);

  useEffect(() => {
    if (plans) {
      const defaultPlan = plans.find((plan) => plan.title == 'Free');
      setCurrentPlan(defaultPlan);
      setClickedPlan(defaultPlan);
    }
  }, [plans]);

  const update_Billing = async () => {
    try {
      setLoading(true);
      const {
        data: { data, success },
      } = await ManageBiliing();
      if (success && data) {
        setLoading(false);
        window.location.href = data;
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleSubscription = async (price_id: string) => {
    try {
      setLoading(true);

      const {
        data: { data, success },
      } = await createCheckoutSession(price_id, 'settings');
      if (success && data) {
        setLoading(false);
        window.location.href = data;
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  return (
    <>
      <>
        <div className='card card-custom rounded-1 mt-0 row'>
          <div className='card-body'>
            <span className='card-label fs-5 fw-bold '>{formatMessage({ id: 'Billing' })}</span>
            <div className='d-flex flex-column flex-md-row justify-content-between'>
              <div className='d-flex flex-column col-md-6 me-6 col-12'>
                <div className='d-flex flex-column col-md-6 w-100'>
                  <div className='row bg-gray-100 mx-0 gap-3 padding-16px mt-5 mb-5 mb-md-0'>
                    <div className='d-flex border-bottom border-secondary border-2 p-0 dark-border'>
                      <p className='col-6 mb-0 pb-3 text-gray-700 font-size-13'>
                        {formatMessage({ id: 'Current plan' })}
                      </p>
                      {getBillingDetailsLoading ? (
                        <Skeleton height={20} width={150} />
                      ) : (
                        <>
                          <div>
                            <div className='mb-0 font-size-13'>
                              {subscriptionDetails?.billingDetails.title}
                              <div className='mx-2 badge badge-pill badge-success'>
                                {subscriptionDetails?.billingDetails.status === 'trialing' &&
                                subscriptionDetails?.billingDetails.cancel_at_period_end
                                  ? 'Cancelled'
                                  : subscriptionDetails?.billingDetails.status === 'past_due'
                                  ? 'Payment Pending'
                                  : subscriptionDetails?.billingDetails.status === 'expired'
                                  ? 'Cancelled'
                                  : subscriptionDetails?.billingDetails.status === 'incomplete'
                                  ? 'Payment Pending'
                                  : subscriptionDetails?.billingDetails.status ===
                                    'incomplete_expired'
                                  ? 'Cancelled'
                                  : subscriptionDetails?.billingDetails.status === 'trialing'
                                  ? 'In Trial'
                                  : subscriptionDetails?.billingDetails.status === 'active' &&
                                    subscriptionDetails?.billingDetails.cancel_at_period_end
                                  ? 'Active'
                                  : subscriptionDetails?.billingDetails.status === 'active'
                                  ? 'Active'
                                  : subscriptionDetails?.billingDetails.status === 'canceled'
                                  ? 'Cancelled'
                                  : null}
                              </div>
                            </div>
                          </div>
                          {subscriptionDetails?.billingDetails.status === BillingStatus.TRIAL &&
                            !subscriptionDetails?.billingDetails.cancel_at_period_end && (
                              <div className='ms-1 fw-bold'>
                                {subscriptionDetails?.billingDetails.current_period_end
                                  ? Math.ceil(
                                      (((new Date(
                                        subscriptionDetails?.billingDetails.current_period_end *
                                          1000
                                      ) as any) - new Date().getTime()) as any) /
                                        (1000 * 60 * 60 * 24)
                                    )
                                  : 0}{' '}
                                Days Remaining
                              </div>
                            )}
                        </>
                      )}
                    </div>
                    <div className='d-flex border-bottom border-secondary border-2 p-0 dark-border'>
                      <p className='col-6 mb-0 pb-3 text-gray-700 font-size-13'>
                        {' '}
                        {formatMessage({ id: 'Next billing amount' })}
                      </p>
                      {getBillingDetailsLoading ? (
                        <Skeleton height={20} width={150} />
                      ) : (
                        <p className='col-6 mb-0 font-size-13'>
                          {'$'}
                          {(
                            (subscriptionDetails?.billingDetails.nextBillingAmount || 0) / 100
                          ).toFixed(2)}
                        </p>
                      )}
                    </div>
                    <div className='d-flex border-bottom border-secondary border-2 p-0 dark-border'>
                      <p className='col-6 mb-0 pb-3 text-gray-700 font-size-13'>
                        {' '}
                        {formatMessage({ id: 'Billing cycle' })}
                      </p>
                      {getBillingDetailsLoading ? (
                        <Skeleton height={20} width={150} />
                      ) : (
                        <p className='col-6 mb-0 font-size-13'>
                          {subscriptionDetails?.billingDetails.planCycle &&
                            subscriptionDetails.billingDetails.planCycle.charAt(0)?.toUpperCase() +
                              subscriptionDetails.billingDetails.planCycle.slice(1) +
                              'ly'}
                        </p>
                      )}
                    </div>
                    <div className='d-flex border-bottom border-secondary border-2 p-0 dark-border'>
                      <div className='col-6 mb-0 pb-3 text-gray-700 font-size-13'>
                        {subscriptionDetails?.billingDetails.status === BillingStatus.ACTIVE &&
                        subscriptionDetails?.billingDetails.cancel_at_period_end ? (
                          <div className='mx-2 badge badge-pill badge-warning'>
                            {formatMessage({ id: 'Cancellation date' })}
                          </div>
                        ) : (
                          formatMessage({ id: 'Next billing date' })
                        )}
                      </div>
                      {getBillingDetailsLoading ? (
                        <Skeleton height={20} width={150} />
                      ) : (
                        <p className='col-6 mb-0 font-size-13'>
                          {(subscriptionDetails?.billingDetails &&
                            isNaN(subscriptionDetails?.billingDetails?.current_period_start)) ||
                          subscriptionDetails?.billingDetails.status === BillingStatus.CANCELED ||
                          subscriptionDetails?.billingDetails.status ===
                            BillingStatus.INCOMPLETE_EXPIRED ||
                          subscriptionDetails?.billingDetails.status === BillingStatus.EXPIRED
                            ? '-'
                            : subscriptionDetails?.billingDetails &&
                              getLocaleDate(
                                subscriptionDetails?.billingDetails?.current_period_end
                              )}
                        </p>
                      )}
                    </div>
                  </div>
                  {scheduleDowngradeData && (
                    <div className='mt-3 bg-white'>
                      <div className='mt-3 bg-gray-100 p-3 '>{`You have opted to downgrade the plan from ${subscriptionDetails
                        ?.billingDetails.title} - ${
                        subscriptionDetails?.billingDetails.planCycle + 'ly'
                      } to ${scheduleDowngradeData?.planName} - ${
                        scheduleDowngradeData?.period_unit + 'ly'
                      } which will be done on ${new Date(
                        scheduleDowngradeData?.planStarts! * 1000
                      ).toDateString()}`}</div>
                    </div>
                  )}
                </div>
                <div className='d-flex flex-md-row flex-column mt-5 gap-3'>
                  {getBillingDetailsLoading ? (
                    <Skeleton height={40} width={200} />
                  ) : subscriptionDetails?.billingDetails.status !== 'canceled' ? (
                    <GlobalButton
                      buttonText={
                        subscriptionDetails?.billingDetails.title === PlanName.FREE
                          ? formatMessage({ id: 'Upgrade Subscription' })
                          : formatMessage({ id: 'Manage Subscription' })
                      }
                      buttonType={ButtonTypes.PRIMARY}
                      onButtonClick={() => {
                        update_Billing();
                      }}
                      isLoading={loading}
                    />
                  ) : (
                    <GlobalButton
                      buttonText={formatMessage({ id: 'Reactivate Subscription' })}
                      buttonType={ButtonTypes.PRIMARY}
                      onButtonClick={() => {
                        setShowBillingModal(true);
                      }}
                      isLoading={loading}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <BillingModal
        setShowBillingModal={setShowBillingModal}
        showBillingModal={showBillingModal}
        active={activeFreq}
        setActive={setActiveFreq}
        currentPlan={currentPlan as CurrentPlan}
        setCurrentPlan={setCurrentPlan as Dispatch<SetStateAction<CurrentPlan>>}
        clickedPlan={clickedPlan}
        setClickedPlan={setClickedPlan}
        onContinue={handleSubscription}
      />
    </>
  );
}
