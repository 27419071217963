export enum MenuRoutes {
  TASK_BOARD = 'task-board',
  INTEGRATION = 'integration',
  PROJECT_SETTINGS = 'project-settings',
  AUTOMATION = 'automation',
}

export enum IntegrationPlatform {
  JIRA = 'JIRA',
  YOUTRACK = 'YOUTRACK',
  WEBHOOK = 'WEBHOOK',
  GITHUB = 'GITHUB',
  TRELLO = 'TRELLO',
  ZAPIER = 'ZAPIER',
}

export enum TaskStatus {
  BACKLOG = 'Backlog',
  TODO = 'Todo',
  INPROGRESS = 'In Progress',
  REVIEW = 'Review',
  COMPLETED = 'Completed',
}

export enum CreateTaskPosition {
  START = 'START',
  END = 'END',
}

export enum LogLevel {
  ALL = 'all',
  VERBOSE = 'verbose',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warn',
  DEBUG = 'debug',
  LOG = 'log',
}
