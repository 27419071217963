import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFileContentType } from '../../../../util/_file';
import { useFileRequests } from '@/requests/useFileRequests';
import fileUploadIcon from '../../../../core/assets/images/svg/bugs.work/fileuploadicon.svg';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import axios from 'axios';
import { numbersOfFiles } from '@/constants/settings';

const FileUpload = ({
  projectId,
  onUploadSuccess,
  fullWidth,
  length,
}: {
  projectId: number;
  onUploadSuccess: (fileArray: any, fileIds: string[], filesKey: any[]) => void;
  fullWidth?: boolean;
  length: number;
}) => {
  const { formatMessage } = useIntl();
  const { uploadFile } = useFileRequests();
  const [loading, setLoading] = useState(false);
  const fileList = useRef<File[]>([]);
  const filesKey = useRef<{ key: string }[]>([]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileData = {};
    if (length >= numbersOfFiles) {
      return toast.error(formatMessage({ id: 'Maximum of 5 files is allowed' }));
    } else {
      if (event.target.files) {
        const files = Array.from(event.target.files);
        fileList.current = [...fileList.current, ...files];
        fileData = {
          fileName: event.target.files[0].name,
          contentType: getFileContentType(event.target.files[0].name),
          fileSizes: event.target.files[0].size,
        };
      }
      if (fileList.current && fileList.current.length > 0) {
        try {
          setLoading(true);
          const {
            data: { data, success, errors },
          } = await uploadFile(fileData);
          if (success) {
            const response = await axios.put(
              data.url,
              fileList.current[fileList.current.length - 1]
            );
            if (response.status === 200) {
              filesKey.current.push({ key: data.key });
              const fileIds = fileList.current?.map((file: any) => file.id);
              onUploadSuccess(fileList.current, fileIds, filesKey.current);
              toast.success(
                `${fileList.current[fileList.current.length - 1].name} uploaded successfully:`
              );

              setLoading(false);
            } else {
              toast.error(formatMessage({ id: 'Failed to upload file' }));
              setLoading(false);
            }
          }
        } catch (err) {
          setLoading(false);
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <div className='mb-3 mb-lg-0'>
      {loading ? (
        <div
          className={`cursor-pointer me-4 d-flex flex-center file-upload-border ${
            fullWidth ? ' p-3' : 'file-upload-box'
          }`}
        >
          <div
            className={`spinner-border d-center`}
            style={{ width: '2rem', height: '2rem' }}
          ></div>
        </div>
      ) : (
        <div
          className={`cursor-pointer  d-flex flex-row align-items-center justify-content-center file-upload-border  ${
            fullWidth ? 'p-2' : 'file-upload-box'
          }`}
          onClick={() => {
            const fileInput = document.getElementById('fileInput');
            if (fileInput) {
              fileInput.click();
            }
          }}
        >
          <input
            id='fileInput'
            type='file'
            onChange={(event) => handleFileChange(event)}
            style={{ display: 'none' }}
            accept='image/*,.pdf,video/*,.doc,.docx,.xml,.xls,.xlsx,.csv,.ppt,.pptx,audio/*,.mkv'
          />
          <div className={`d-flex ${fullWidth ? 'flex-row' : 'flex-column'} align-items-center`}>
            <img
              src={fileUploadIcon}
              style={{
                width: '40px',
                height: '40px',
              }}
              alt=''
              className='ms-5 ms-md-0'
            />
            <h6 className='font-size-12 font-weight-400 mt-2 grey-color text-center'>
              {formatMessage({
                id: 'Click to add or attach files',
              })}
            </h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
