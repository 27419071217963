import { Dispatch, SetStateAction, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toAbsoluteUrl } from '../../../../core/helpers';
import { TemporaryFile, FileObject } from '@/types/file';
import { url } from 'inspector';
import { getFileContentType } from '../../../../util/_file';
import FileTypes from './FileTypes';

const FileDisplayModal = ({
  file,
  isClicked,
  setIsClicked,
}: {
  file: TemporaryFile | FileObject;
  isClicked: boolean;
  setIsClicked: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Modal
        show={isClicked}
        size={'xl'}
        onHide={() => {
          setIsClicked(false);
        }}
      >
        <Modal.Body className='py-5'>
          {
            <div className='close-btn-container'>
              <img
                src={toAbsoluteUrl('/media/icons/duotune/general/close.svg')}
                alt=''
                onClick={() => {
                  setIsClicked(false);
                }}
                className={` cursor-pointer `}
              />
            </div>
          }
          <FileTypes file={file} isFullScreen={true} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FileDisplayModal;
