import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useGlobalContext } from '../../../core/helpers';
import { toast } from 'react-toastify';
import UserModal from '../components/UserModal';
import { DateTime } from 'luxon';
import { RolesName } from '@/constants/organisation';
import { Roles } from '@/enums/organisation';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { useTimeFormat } from '../../../hooks/useTimeFormat';
import { SelectPlanModal } from '../../shared/components/SelectPlanModal';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { Spinner } from '../../shared/components/General/Spinner';
import { PageHeading } from '../../shared/components/PageHeading';
import { BiSolidEdit } from 'react-icons/bi';
import { TeamModal } from '../components/TeamModal';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { useTeamManagementRequests } from '@/requests/useTeamManagementRequests';
import { checkPlanAccess } from '../../../util/checkPlanAccess';
import { StripeMetaKeys } from '@/enums/stripeMetaKeys';
import { User } from '@/types/user';
import Skeleton from 'react-loading-skeleton';
import { titlecase } from '../../../util/titlecase';
import { useSearchParams } from 'react-router-dom';

export const Team = () => {
  const {
    updateTransferOwnership,
    getUser,
    deleteUser,
    deletePendingInvite,
    getPendingUsers,
    getUserList,
  } = useTeamManagementRequests();
  const { resendInvite } = useAuthRequests();
  const { formatMessage } = useIntl();
  const [showKebabMenu, setShowKebabMenu] = useState(false);
  const [showPendingKebabMenu, setShowPendingKebabMenu] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [userId, setUserId] = useState<any>();
  const [editUserModal, setEditUserModal] = useState(false);
  const [showUserId, setShowUserId] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>();
  const [flag, setFlag] = useState(false);
  const [transferOwnershipRadio, setTransferOwnershipRadio] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletePendingLoading, setDeletePendingLoading] = useState(false);
  const [getUserApiSpinner, setUserApiSpinner] = useState(false);
  const [showDeletePendingInvites, setShowPendingInvites] = useState(false);
  const [selectedInviteId, setSelectedInviteId] = useState<any>();
  const { getDateValue } = useDateFormat();
  const { getTimeValue } = useTimeFormat();
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState<User[]>([]);
  const [pendingUsersList, setPendingUsersList] = useState<User[]>([]);
  const [selectPlanModal, setSelectPlanModal] = useState(false);
  const { setUserToken, getCurrentUser, currentUser } = useGlobalContext();
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [runGetUserList, setRunGetUserList] = useState(false);
  const [getUsersListloading, setGetUsersListLoading] = useState(false);
  const [pendingUsersListLoading, setPendingUsersListLoading] = useState(false);
  const [pendingUserEmail, setPendingUserEmail] = useState<any>();
  const [removeUserEmail, setRemoveUserEmail] = useState<string | undefined>();
  const [isUpdate, setIsUpdate] = useState(false);
  const [getUsersListFetching, setGetUsersListFetching] = useState(false);
  const [pendingUsersListFetching, setPendingUsersListFetching] = useState(false);
  const [searchParams] = useSearchParams();
  const isOpen = searchParams.get('open');
  const getUsersList = async () => {
    setGetUsersListLoading(true);
    try {
      const {
        data: { success, data, errors },
      } = await getUserList();
      if (success) {
        setUserList(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGetUsersListLoading(false);
      setGetUsersListFetching(true);
    }
  };
  const getPendingUserList = async () => {
    setPendingUsersListLoading(true);
    try {
      const {
        data: { success, data, errors },
      } = await getPendingUsers();
      if (success) {
        setPendingUsersList(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPendingUsersListLoading(false);
      setPendingUsersListFetching(true);
    }
  };
  useEffect(() => {
    if (location.pathname.includes('organisation') && currentUser?.role !== Roles.MEMBER) {
      getUsersList();
    }
  }, [runGetUserList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.pathname.includes('organisation') && currentUser?.role !== Roles.MEMBER) {
      getPendingUserList();
    }
  }, []);

  useEffect(() => {
    if (isUpdate) {
      getUsersList();
      setIsUpdate(false);
    }
  }, [isUpdate]);

  const UpdateTransferOwnership = async () => {
    try {
      const {
        data: { data, success, errors },
      } = await updateTransferOwnership(userId);
      if (success) {
        await getCurrentUser(data);
        setUserToken(data.accessToken);
        getUserList();
        toast.success(formatMessage({ id: 'Account ownership transferred successfully' }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      getUsersList();
    }
  };

  const DeleteUser = async () => {
    try {
      setDeleteLoading(true);
      const {
        data: { success, errors },
      } = await deleteUser(showUserId);
      if (success) {
        toast.success(formatMessage({ id: 'User removed from team' }));
        getUsersList();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      setDeleteShowModal(!showDeleteModal);
    }
  };

  const deleteInvite = async (inviteId: number) => {
    try {
      setDeletePendingLoading(true);
      const {
        data: { success, errors },
      } = await deletePendingInvite(inviteId);
      if (success) {
        toast.success(formatMessage({ id: 'Invitation revoked successfully' }));
        getPendingUserList();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeletePendingLoading(false);
      setShowPendingInvites(false);
    }
  };

  const getUserData = async () => {
    try {
      setUserApiSpinner(true);
      const {
        data: { success, data, errors },
      } = await getUser(showUserId);
      if (success) {
        setUserDetails(data);
        setUserApiSpinner(false);
      }
    } catch (err) {
      setUserApiSpinner(false);
      console.log(err);
    }
  };

  const handleResendInvite = async (values: any) => {
    setLoading(true);
    try {
      const {
        data: { data, success, errors },
      } = await resendInvite(values.email);
      if (success) {
        toast.success(formatMessage({ id: data }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && getUsersListFetching && pendingUsersListFetching) {
      if (
        checkPlanAccess(
          [...userList, ...pendingUsersList],
          currentUser?.metadata?.[StripeMetaKeys.USERS]!
        )
      ) {
        setAddModal(true);
      } else {
        if (currentUser?.role === Roles.ADMIN) {
          toast.error(
            formatMessage({
              id: 'You cannot invite more users. Please contact your owner.',
            })
          );
        } else {
          setSelectPlanModal(true);
        }
      }
      setGetUsersListFetching(false);
      setPendingUsersListFetching(false);
    }
  }, [isOpen, getUsersListFetching, pendingUsersListFetching]);

  const countNumberOfAdmin = useMemo(() => {
    return userList.filter((user: User) => user.teams[0].user_team_role.role === Roles.ADMIN)
      .length;
  }, [userList]);

  return (
    <>
      <div className='d-flex flex-row justify-content-between'>
        <PageHeading
          title={formatMessage({ id: 'Organisation' })}
          description={formatMessage({
            id: 'You can manage your team members who have access to your bugs.work account.',
          })}
        />
      </div>
      <div className='card row w-100 m-0'>
        <div className='card-body'>
          <div className='row d-flex'>
            <div className='col-md-6 col-12'>
              <div className='d-flex justify-content between align-items-center'>
                <h4 className='card-label fs-5 mb-0 me-3'>{userList[0]?.teams[0]?.teamName}</h4>
                <div>
                  <BiSolidEdit onClick={() => setShowTeamModal(true)} className='cursor-pointer' />
                </div>
              </div>
              <p className='pt-3'>{formatMessage({ id: 'Manage your team members' })}</p>
            </div>
            <div className='col-12 d-md-flex flex-md-row justify-content-end col-md-6'>
              <div className='row mt-3'>
                <div className='col-12'>
                  <button
                    className='btn btn_bg_color white-color font-size-13'
                    onClick={() => {
                      if (
                        checkPlanAccess(
                          [...userList, ...pendingUsersList],
                          currentUser?.metadata?.[StripeMetaKeys.USERS]!
                        )
                      ) {
                        setAddModal(true);
                      } else {
                        if (currentUser?.role === Roles.ADMIN) {
                          toast.error(
                            formatMessage({
                              id: 'You cannot invite more users. Please contact your owner.',
                            })
                          );
                        } else {
                          setSelectPlanModal(true);
                        }
                      }
                    }}
                  >
                    <div className='d-flex'>{formatMessage({ id: 'Invite new user' })}</div>
                  </button>
                  <UserModal
                    title={formatMessage({ id: 'Invite new user' })}
                    buttonText={formatMessage({ id: 'Send invite' })}
                    addModal={addModal}
                    setAddModal={setAddModal}
                    flag={flag}
                    getUserList={getUserList}
                    getPendingUserList={getPendingUserList}
                  />
                  <SelectPlanModal
                    selectPlanModal={selectPlanModal}
                    setSelectPlanModal={setSelectPlanModal}
                    modalText={formatMessage({
                      id: `current_plan_message`,
                    })
                      .replace('{{planName}}', currentUser?.planName || '')
                      .replace('{{users}}', currentUser?.metadata?.users || '')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='table table-responsive custom-table mt-4'>
                <table className='w-100'>
                  <thead>
                    <tr className='custom-thead font-size-12 font-weight-400'>
                      <td className='pb-4 min-w-175px'>{formatMessage({ id: 'Name' })}</td>
                      <td className='pb-4 min-w-175px'>{formatMessage({ id: 'Role' })}</td>
                      <td className='pb-4 min-w-250px'>{formatMessage({ id: 'Email address' })}</td>
                      <td className='pb-4 min-w-175px'>
                        <div className='d-flex gap-2'>
                          {formatMessage({
                            id: 'Last Modification Date',
                          })}
                        </div>
                      </td>
                      <td className='text-center pb-4 min-w-175px'>
                        {formatMessage({ id: 'Action' })}
                      </td>
                    </tr>
                  </thead>
                  <tbody className='font-size-13 font-weight-400'>
                    {getUsersListloading ? (
                      [...Array(2)].map((_, index) => (
                        <tr key={index}>
                          <td className='min-w-175px pt-6'>
                            <Skeleton height={20} width={150} />
                          </td>
                          <td className='min-w-175px pt-6'>
                            <Skeleton height={20} width={150} />
                          </td>
                          <td className='min-w-250px pt-6'>
                            <Skeleton height={20} width={250} />
                          </td>
                          <td className='min-w-175px pt-6'>
                            <Skeleton height={20} width={150} />
                          </td>
                          <td className='text-center min-w-175px pt-6'>
                            <Skeleton height={20} width={150} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        {userList &&
                          userList.map((user: User) =>
                            user.teams[0].user_team_role.role === Roles.OWNER ? (
                              <tr key={user.userId}>
                                <td className='min-w-175px pt-6'>
                                  {user.firstName} {user.lastName}
                                </td>
                                <td className='min-w-175px pt-6'>
                                  {RolesName[user.teams[0].user_team_role.role]}
                                </td>
                                <td className=' min-w-250px pt-6'>{user.email}</td>
                                <td className=' min-w-175px pt-6'>
                                  {(getDateValue(user.updatedAt?.toString())
                                    ? getDateValue(user.updatedAt?.toString())
                                    : DateTime.fromISO(user.updatedAt!).toFormat('dd MMM, yyyy')) +
                                    ' ' +
                                    (getTimeValue(user.updatedAt?.toString())
                                      ? getTimeValue(user.updatedAt?.toString())
                                      : DateTime.fromISO(user.updatedAt!).toFormat('H:m:s'))}
                                </td>
                                {userList.length !== 1 &&
                                  user.userId === currentUser?.userId &&
                                  countNumberOfAdmin > 0 && (
                                    <td
                                      className='text-center text-primary cursor-pointer  min-w-175px'
                                      onClick={() => setShowBillingModal(!showBillingModal)}
                                    >
                                      {formatMessage({
                                        id: 'Transfer ownership',
                                      })}
                                    </td>
                                  )}
                              </tr>
                            ) : (
                              ''
                            )
                          )}
                        {userList &&
                          userList.map((user: User) =>
                            user.teams[0].user_team_role.role === Roles.OWNER ? (
                              ''
                            ) : (
                              <tr key={user.userId} onMouseLeave={() => setShowKebabMenu(false)}>
                                <td className='min-w-175px pt-6'>
                                  {user.firstName} {user.lastName}
                                </td>
                                <td className='min-w-175px pt-6'>
                                  {user.teams[0].user_team_role.role
                                    ? RolesName[user.teams[0].user_team_role.role]
                                    : '-'}
                                </td>
                                <td className='min-w-250px pt-6'>{user.email}</td>
                                <td className='min-w-175px pt-6'>
                                  {(getDateValue(user.updatedAt?.toString())
                                    ? getDateValue(user.updatedAt?.toString())
                                    : DateTime.fromISO(user.updatedAt!).toFormat('dd MMM, yyyy')) +
                                    ' ' +
                                    (getTimeValue(user.updatedAt?.toString())
                                      ? getTimeValue(user.updatedAt?.toString())
                                      : DateTime.fromISO(user.updatedAt!).toFormat('H:m:s'))}
                                </td>
                                {user.userId !== currentUser?.userId && (
                                  <td className='text-center p-0 min-w-175px'>
                                    <div className='w-100 d-flex justify-content-center position-relative'>
                                      <div id='remove-investor'>
                                        <Dropdown.Toggle
                                          className='bi bi-three-dots-vertical  me-1 p-1 px-3 py-2 bg-light text-dark'
                                          onMouseEnter={() => {
                                            setRemoveUserEmail(user?.email);
                                            setShowKebabMenu(true);
                                            setShowUserId(user.userId);
                                          }}
                                        ></Dropdown.Toggle>
                                      </div>
                                      {showKebabMenu && user.userId === showUserId && (
                                        <div className='card w-120px h-120px shadow rounded kebab-menu-dropdown cursor-pointer ms-auto'>
                                          <div className='border-bottom border-gray-300 border-bottom-2 px-4 py-3 dropdown-items'>
                                            <div
                                              className='m-0'
                                              onClick={() => {
                                                setEditUserModal(!editUserModal);
                                                getUserData();
                                                setFlag(true);
                                              }}
                                            >
                                              {formatMessage({
                                                id: 'Change role',
                                              })}
                                            </div>
                                          </div>
                                          <div className='border-gray-300 border-bottom-2 px-4 py-3 dropdown-items'>
                                            <div
                                              className='m-0'
                                              onClick={() => {
                                                setShowKebabMenu(!showKebabMenu);
                                                setDeleteShowModal(!showDeleteModal);
                                              }}
                                            >
                                              {formatMessage({
                                                id: 'Remove',
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            )
                          )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <UserModal
          title={formatMessage({
            id: 'Edit User',
          })}
          buttonText={formatMessage({
            id: 'Save',
          })}
          addModal={editUserModal}
          setAddModal={setEditUserModal}
          flag={flag}
          userDetails={userDetails}
          getUserList={getUserList}
          getUserApiSpinner={getUserApiSpinner}
          setIsUpdate={setIsUpdate}
        />
        {pendingUsersList && pendingUsersList.length > 0 && (
          <div className='card-body'>
            <div className='row d-flex'>
              <div className='col-md-6 col-12'>
                <h4 className='card-label fs-5'>{formatMessage({ id: 'Pending Invites' })}</h4>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='table table-responsive custom-table mt-4'>
                  <table className='w-100'>
                    <thead>
                      <tr className='custom-thead font-size-12 font-weight-400'>
                        <td className='pb-4'>{formatMessage({ id: 'Email address' })}</td>
                        <td className='pb-4 text-center text-md-start'>
                          {formatMessage({ id: 'Role' })}
                        </td>

                        <td className='pb-4'>
                          <div className='d-flex gap-2'>
                            <p>
                              {formatMessage({
                                id: 'Invited At',
                              })}
                            </p>
                          </div>
                        </td>
                        <td className='text-center pb-4'>{formatMessage({ id: 'Action' })}</td>
                      </tr>
                    </thead>
                    <tbody className='font-size-13 font-weight-400'>
                      {pendingUsersListLoading
                        ? [...Array(2)].map((_, index) => (
                            <tr key={index}>
                              <td className='pt-6'>
                                <Skeleton width={150} height={20} />
                              </td>
                              <td className='min-w-175px text-center text-md-start pt-6'>
                                <Skeleton width={150} height={20} />
                              </td>
                              <td className='pt-6'>
                                <Skeleton width={150} height={20} />
                              </td>
                              <td className='text-center p-0 min-w-175px'>
                                <Skeleton width={50} height={20} />
                              </td>
                            </tr>
                          ))
                        : pendingUsersList.map((user: User, index: number) => (
                            <tr
                              key={user.inviteId}
                              onMouseLeave={() => {
                                setShowPendingKebabMenu(false);
                              }}
                            >
                              <td className='pt-6'>
                                {user.email} {user.userId}
                              </td>
                              <td className=' min-w-175px text-center text-md-start pt-6'>
                                {titlecase(user.role)}
                              </td>

                              <td className='pt-6'>
                                {(getDateValue(user.updatedAt?.toString())
                                  ? getDateValue(user.updatedAt?.toString())
                                  : DateTime.fromISO(user.updatedAt!).toFormat('dd MMM, yyyy')) +
                                  ' ' +
                                  (getTimeValue(user.updatedAt?.toString())
                                    ? getTimeValue(user.updatedAt?.toString())
                                    : DateTime.fromISO(user.updatedAt!).toFormat('H:m:s'))}
                              </td>
                              <td className='text-center p-0 min-w-175px'>
                                <div className='w-100 d-flex justify-content-center position-relative'>
                                  <div id='remove-investor'>
                                    <div
                                      className={`${
                                        showPendingKebabMenu && 'btn btn-primary'
                                      } bi bi-three-dots-vertical  me-1 p-1 px-3 py-2 bg-light text-dark `}
                                      onMouseEnter={() => {
                                        setShowUserId(user.inviteId);
                                        setShowPendingKebabMenu(true);
                                      }}
                                    ></div>
                                  </div>
                                  {showPendingKebabMenu && user.inviteId === showUserId && (
                                    <div
                                      className={`card h-120px shadow rounded ${
                                        index === 0 ? 'team-kebab-menu' : 'kebab-menu-dropdown'
                                      } cursor-pointer`}
                                    >
                                      <div
                                        className='border-bottom border-gray-300 border-bottom-2 px-4 py-3 dropdown-items'
                                        onClick={() => {
                                          if (!loading)
                                            handleResendInvite({
                                              email: user.email,
                                            });
                                        }}
                                      >
                                        <div className={`m-0 ${loading && `disabled-team`}`}>
                                          {loading && <Spinner inline />}{' '}
                                          {formatMessage({
                                            id: 'Resend invitation',
                                          })}
                                          {showPendingKebabMenu} {user.userId}
                                        </div>
                                      </div>
                                      <div
                                        className='border-bottom border-gray-300 border-bottom-2 px-4 py-3 dropdown-items'
                                        onClick={() => {
                                          if (!deletePendingLoading) {
                                            setShowPendingInvites(true);
                                            setSelectedInviteId(user.inviteId);
                                            setPendingUserEmail(user.email);
                                          }
                                        }}
                                      >
                                        <div
                                          className={`m-0 ${
                                            deletePendingLoading && `disabled-team`
                                          }`}
                                          role='button'
                                        >
                                          {deletePendingLoading && <Spinner inline />}{' '}
                                          {formatMessage({
                                            id: 'Revoke invitation',
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          <Modal
            show={showBillingModal}
            onHide={() => setShowBillingModal(false)}
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header closeButton onClick={() => setTransferOwnershipRadio(false)}>
              <h2>{formatMessage({ id: 'Transfer ownership' })}</h2>
            </Modal.Header>
            <Modal.Body>
              <h6 className='mb-4 pb-2'>
                {formatMessage({
                  id: 'Select the user whom you want transfer ownership to',
                })}
              </h6>
              <table className='table transfer-ownership-table mb-0'>
                <thead>
                  <tr className='border border-top-0 border-right-0 border-left-0 border-2'>
                    <td className='p-0 m-0 pt-4'>
                      <p className='ps-15 transfer-ownership-td'>{formatMessage({ id: 'Name' })}</p>
                    </td>
                    <td className='p-0 m-0 pt-4'>
                      <p className='transfer-ownership-td'>
                        {formatMessage({ id: 'Email address' })}
                      </p>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((user: any) =>
                    user.teams[0].user_team_role.role === Roles.OWNER ? (
                      ''
                    ) : (
                      <tr
                        key={user.userId}
                        className='border border-top-0 border-right-0 border-left-0 border-2'
                      >
                        <td className='p-0 m-0'>
                          <div className='form-check form-check-inline pt-4 m-0'>
                            <input
                              className='form-check-input radio-button'
                              type='radio'
                              name='inlineRadioOptions'
                              id='inlineRadio1'
                              value='option1'
                              onClick={() => {
                                setUserId(user.userId);
                                setTransferOwnershipRadio(true);
                              }}
                            />
                            <p className='ms-2'>
                              {user.firstName} {user.lastName}
                            </p>
                          </div>
                        </td>
                        <td className='p-0 m-0'>
                          <div className='pt-4 m-0'>{user.email}</div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <button
                type='button'
                className='btn btn-light'
                onClick={() => {
                  setShowBillingModal(!showBillingModal);
                  setTransferOwnershipRadio(false);
                }}
              >
                {formatMessage({ id: 'Cancel' })}
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={!transferOwnershipRadio}
                onClick={() => {
                  setShowModal(!showModal);
                  setShowBillingModal(!showBillingModal);
                }}
              >
                {formatMessage({ id: 'Transfer ownership' })}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header closeButton>
              <h2>{formatMessage({ id: 'Transfer ownership' })}</h2>
            </Modal.Header>
            <Modal.Body>
              <p>
                {formatMessage({
                  id: 'Please note that transferring the ownership of your account is a permanent action and you will lose access to billing-related actions. Are you sure you wish to proceed?',
                })}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                type='button'
                className='btn btn-light'
                onClick={() => {
                  setShowModal(!showModal);
                  setTransferOwnershipRadio(false);
                }}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  UpdateTransferOwnership();
                  setShowModal(!showModal);
                }}
              >
                {formatMessage({ id: 'Confirm & transfer' })}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            show={showDeleteModal}
            onHide={() => setDeleteShowModal(false)}
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header closeButton>
              <h2>{formatMessage({ id: 'Remove user from team?' })}</h2>
            </Modal.Header>
            <Modal.Body>
              <p>
                {formatMessage({ id: 'Remove_user_statement' }).replace(
                  '{{email}}',
                  removeUserEmail ?? ''
                )}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <GlobalButton
                buttonText={formatMessage({ id: 'Cancel' })}
                buttonType={ButtonTypes.SECONDARY}
                onButtonClick={() => {
                  setDeleteShowModal(!showDeleteModal);
                }}
              />
              <GlobalButton
                buttonText={formatMessage({ id: 'Remove' })}
                buttonType={ButtonTypes.PRIMARY}
                onButtonClick={() => {
                  DeleteUser();
                }}
                isLoading={deleteLoading}
                isDisable={deleteLoading}
              />
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            show={showDeletePendingInvites}
            onHide={() => setShowPendingInvites(false)}
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header closeButton>
              <h2>
                {formatMessage({ id: `Revoke_Invite` }).replace('{{email}}', pendingUserEmail)}
              </h2>
            </Modal.Header>
            <Modal.Body>
              <p>
                {formatMessage({
                  id: 'Please note that the user will no longer be able to accept the invite and you will have to send a fresh invite if needed. Are you certain you wish to proceed with this?',
                })}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <GlobalButton
                buttonText={formatMessage({ id: 'Cancel' })}
                buttonType={ButtonTypes.SECONDARY}
                onButtonClick={() => {
                  setShowPendingInvites(false);
                }}
              />
              <GlobalButton
                buttonText={formatMessage({ id: 'Revoke invite' })}
                buttonType={ButtonTypes.PRIMARY}
                onButtonClick={() => deleteInvite(selectedInviteId)}
                isLoading={deletePendingLoading}
                isDisable={deletePendingLoading}
              />
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <TeamModal
        showTeamModal={showTeamModal}
        setShowTeamModal={setShowTeamModal}
        teamName={userList[0]?.teams[0]?.teamName}
        setRunGetUserList={setRunGetUserList}
      />
    </>
  );
};
