import React from 'react';
import { toAbsoluteUrl } from '../../core/helpers';
import { useIntl } from 'react-intl';
import { useThemeMode } from '../../core/partials';
import { useNavigate } from 'react-router-dom';
import { GlobalButton } from '../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';

interface UnauthorizedPageProps {
  customMessage?: string;
}

export const UnauthorizedPage: React.FC<UnauthorizedPageProps> = ({ customMessage }) => {
  const { mode } = useThemeMode();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const defaultMessage = formatMessage({
    id: "You don't have permission to access this page. Please contact admin for access this page.",
  });

  return (
    <>
      <div className='d-flex flex-column justify-content-center align-items-center my-20'>
        {mode === 'dark' ? (
          <img src={toAbsoluteUrl('/media/illustrations/error/illustration404Dark.svg')} alt='' />
        ) : (
          <img src={toAbsoluteUrl('/media/illustrations/error/illustration404.svg')} alt='' />
        )}
        <div className={`my-5 ${mode === 'dark' ? 'errorHeadingDark' : 'errorHeading'}`}>
          {formatMessage({ id: '404 Error - Page Not Found' })}
        </div>
        <div className={`my-5   ${mode === 'dark' ? 'errorDetailsDark' : 'errorDetails'}`}>
          {customMessage || defaultMessage}
        </div>
        <div className='mb-4 d-flex justify-content-center'>
          <GlobalButton
            buttonText={formatMessage({ id: 'Go to Dashboard' })}
            buttonType={ButtonTypes.PRIMARY}
            onButtonClick={() => {
              navigate('/');
            }}
          />
        </div>
      </div>
    </>
  );
};
