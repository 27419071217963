import { Routes, Route } from 'react-router-dom';
import { TasksBoard } from './views/TasksBoard';
import { Integrations } from './views/Integrations';
import { Settings } from './views/ProjectSettings';

const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path=':projectId'>
        <Route path='task-board/' element={<TasksBoard />} />
        <Route path='integration/' element={<Integrations />} />
        <Route path='project-settings/' element={<Settings />} />
      </Route>
    </Routes>
  );
};

export default ProjectRoutes;
