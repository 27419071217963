import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { BaseProject } from '../../../../types/projects';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { ProjectModal } from '../ProjectModal';
import { useProjectRequests } from '@/requests/useProjectRequests';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../../../core/helpers';
import { toAbsoluteUrl } from '../../../../core/helpers';

const ProjectDetails = ({
  projectDetails,
  getProject,
}: {
  projectDetails: BaseProject | null;
  getProject: () => Promise<void>;
}) => {
  const { editProject, getApiKey } = useProjectRequests();
  const { projectId } = useParams();
  const { formatMessage } = useIntl();
  const { openCreateProjectModal, setopenCreateProjectModal } = useGlobalContext();
  const [projectLoading, setProjectLoading] = useState(false);
  const [showApiKey, setShowApiKey] = useState<boolean>(false);
  const [projectApiKey, setProjectApiKey] = useState<string>('');
  const handleEditProject = async (
    projectId: number | null,
    title: string,
    description: string | null
  ) => {
    try {
      setProjectLoading(true);
      const {
        data: { success, errors },
      } = await editProject(projectId, title, description);
      if (success) {
        getProject();
        setopenCreateProjectModal(false);
        toast.success(formatMessage({ id: 'Project updated successfully' }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProjectLoading(false);
    }
  };
  const handelFetchApi = async (projectId: string | undefined) => {
    if (!projectId) return;
    try {
      const {
        data: { data, success, errors },
      } = await getApiKey(parseInt(projectId));
      if (success) {
        setShowApiKey(true);
        setProjectApiKey(data.apiKey);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className='project-details-card card p-5'>
        <div className='d-flex flex-row justify-content-between'>
          <div>
            <label className='title-color font-weight-500  font-size-16'>Basic details</label>
          </div>
          <div>
            <GlobalButton
              buttonText={formatMessage({ id: 'Edit' })}
              buttonType={ButtonTypes.SECONDARY}
              onButtonClick={() => {
                setopenCreateProjectModal(true);
              }}
            />
          </div>
        </div>
        <div className='row my-2'>
          <label className='title-color font-weight-500 font-size-14'>Name</label>
          <div className='gray-color font-size-14 font-weight-400 mt-1'>
            {projectDetails?.title || <Skeleton height={20} />}
          </div>
        </div>
        <div className='row my-2'>
          <label className='title-color font-weight-500 font-size-14'>Project code</label>
          <div className='gray-color font-size-14 font-weight-400 mt-1'>
            {projectDetails?.projectCode || <Skeleton height={20} />}
          </div>
        </div>
        <div className='row my-2'>
          <label className='title-color font-weight-500  font-size-14'>Description</label>
          <div className='gray-color font-size-14 font-weight-400 mt-1'>
            {!projectDetails?.title ? (
              <Skeleton height={20} />
            ) : projectDetails?.description ? (
              projectDetails?.description
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className='row my-2'>
          <label className='title-color font-weight-500  font-size-14'>
            {formatMessage({ id: 'Project API key' })}
            <span
              className='ms-2'
              role='button'
              onClick={() => {
                if (showApiKey) setShowApiKey(false);
                else handelFetchApi(projectId);
              }}
            >
              <img
                src={toAbsoluteUrl(
                  showApiKey
                    ? '/media/icons/duotune/general/openEye.svg'
                    : '/media/icons/duotune/general/eyeHide.svg'
                )}
                width='24px'
                height='24px'
                alt='eye'
                className='cursor-pointer'
              />
            </span>
          </label>
          <div className='gray-color font-size-14 font-weight-400 mt-1'>
            {!projectDetails?.title ? (
              <Skeleton height={20} />
            ) : showApiKey ? (
              projectApiKey
            ) : (
              '********************'
            )}
          </div>
        </div>
      </div>

      {openCreateProjectModal && (
        <ProjectModal
          handleOnSubmit={handleEditProject}
          loading={projectLoading}
          projectDetails={projectDetails}
        />
      )}
    </>
  );
};

export default ProjectDetails;
