import { useIntegrationRequests } from '@/requests/useIntegrationRequests';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IntegrationPlatform } from '@/enums/projects';
import { useGlobalContext } from '../../../../core/helpers';
import { MetaKeys } from '@/enums/user';
import { getCookie } from '../../../../util/cookie';
export const IntegrationPage = () => {
  const { oauthValidation } = useIntegrationRequests();
  const navigate = useNavigate();
  const [code, setCode] = useState<string | null>('');
  const [projectId, setProjectId] = useState<number | undefined>();
  const [platform, setPlatform] = useState<string | null>('');
  const {
    setOpenOauthConfigModal,
    setIntegrationType,
    setOpenGithubOauthConfigModal,
    setOpenTrelloOauthConfigModal,
  } = useGlobalContext();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const token = hashParams.get('token');
    if (token) {
      //token in case of TRELLO
      setCode(token);
      setProjectId(getCookie(process.env.REACT_APP_PROJECT_COOKIE_KEY || MetaKeys.PROJECTID));
      setPlatform(IntegrationPlatform.TRELLO);
    } else {
      setCode(params.get('code')); //code in case of JIRA and GITHUB
      const state = params.get('state');
      const colonIndex = state?.indexOf(':');
      let projectId;
      let platform;
      if (colonIndex) {
        projectId = state?.substring(colonIndex + 1);
        setProjectId(parseInt(projectId ? projectId : ''));
        platform = state?.substring(0, colonIndex);
        setPlatform(platform ? platform : '');
      }
    }
  }, []);

  useEffect(() => {
    if (code && projectId && platform) {
      oauthValidationFunc();
    }
  }, [code, projectId]);

  const oauthValidationFunc = async () => {
    try {
      const {
        data: { data, success, errors },
      } = await oauthValidation(code, projectId, platform);
      if (success) {
        navigate(`/project/${projectId}/integration/`);
        if (platform === IntegrationPlatform.JIRA) {
          setOpenOauthConfigModal(true);
          setIntegrationType(IntegrationPlatform.JIRA);
        } else if (platform === IntegrationPlatform.GITHUB) {
          setOpenGithubOauthConfigModal(true);
          setIntegrationType(IntegrationPlatform.GITHUB);
        } else if (IntegrationPlatform.TRELLO) {
          setOpenTrelloOauthConfigModal(true);
          setIntegrationType(IntegrationPlatform.TRELLO);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  return <h1>Integration</h1>;
};
