import React, { Dispatch, SetStateAction } from 'react';
import taskSymbol from '../../../../core/assets/images/svg/bugs.work/task-symbol.svg';
import copylink from '../../../../core/assets/images/svg/bugs.work/copy.svg';
import { Link } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { handleCopyLink } from '../../../../util/_copyLink';
import '../../styles/project.scss';
import { useIntl } from 'react-intl';

// TODO: Review this component with developer
export const TaskHeader = ({
  taskCode,
  setDeleteShowModal,
  isTaskDetailPage,
  publicUuid,
  isPublic,
}: {
  taskCode: string;
  setDeleteShowModal: Dispatch<SetStateAction<boolean>>;
  isTaskDetailPage: boolean;
  publicUuid: string;
  isPublic?: boolean;
}) => {
  const task_detail_page_url = `${process.env.REACT_APP_FRONTEND_BASE_URL}/task/${taskCode}`;
  const public_task_page_url = `${process.env.REACT_APP_FRONTEND_BASE_URL}/task/public/${publicUuid}`;
  const { formatMessage } = useIntl();

  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      {formatMessage({
        id: 'Copy link to task',
      })}
    </Tooltip>
  );

  return (
    <>
      <div className='d-flex flex-row justify-content-between'>
        <div className='d-flex flex-row mt-2'>
          <div className='me-2'>
            <img src={taskSymbol} alt='task-symbol' />
          </div>

          {!isTaskDetailPage && !isPublic && (
            <Link
              className='cursor-pointer font-weight-600 font-size-16 title-color mx-2 text-underline'
              target='_blank'
              to={`/task/${taskCode}`}
            >
              {taskCode}
            </Link>
          )}

          {isTaskDetailPage && (
            <div className=' font-weight-600 font-size-16 title-color mx-2'>{taskCode}</div>
          )}
          <OverlayTrigger
            placement='bottom'
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <div>
              <img
                src={copylink}
                className='cursor-pointer'
                alt='copy-link'
                onClick={() =>
                  isTaskDetailPage
                    ? handleCopyLink(public_task_page_url)
                    : handleCopyLink(task_detail_page_url)
                }
              />
            </div>
          </OverlayTrigger>
        </div>

        <div className='d-flex'>
          <Dropdown className='bg-body cursor-pointer rounded-1'>
            <Dropdown.Toggle
              variant='bg-body'
              className=' bi bi-three-dots-vertical  p-3'
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              {!isTaskDetailPage && !isPublic && (
                <Dropdown.Item
                  as='a'
                  href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/task/${taskCode}`}
                  target='_blank'
                  className='cursor-pointer anchor-color'
                >
                  View task in full screen
                </Dropdown.Item>
              )}

              <Dropdown.Item key={3} onClick={() => handleCopyLink(public_task_page_url)}>
                Copy public link
              </Dropdown.Item>

              {!isPublic && (
                <Dropdown.Item
                  as='a'
                  href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/task/public/${publicUuid}`}
                  target='_blank'
                  className='cursor-pointer anchor-color'
                >
                  Go to public link
                </Dropdown.Item>
              )}
              {isTaskDetailPage && (
                <Dropdown.Item onClick={() => handleCopyLink(task_detail_page_url)}>
                  Copy private link
                </Dropdown.Item>
              )}
              {isTaskDetailPage && (
                <Dropdown.Item
                  as='a'
                  href={task_detail_page_url}
                  target='_blank'
                  className='cursor-pointer anchor-color'
                >
                  Go to private link
                </Dropdown.Item>
              )}

              {!isPublic && (
                <Dropdown.Item key={1} onClick={() => setDeleteShowModal(true)}>
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};
