import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { TaskModal } from '../components/tasks-board/TaskModal';
import Select, { MultiValue, StylesConfig, components } from 'react-select';
import '../styles/project.scss';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmationModal } from '../../shared/components/ConfirmationModal';
import { BaseUser, User } from '../../../types/user';
import { DetailedTask, BaseProject, Task } from '../../../types/projects';
import { useGlobalContext } from '../../../core/helpers';
import { PageHeading } from '../../shared/components/PageHeading';
import lens from '../../../core/assets/images/svg/bugs.work/lens.svg';
import dashedRed from '../../../core/assets/images/svg/bugs.work/dashed-red.svg';
import UserAvatar from '../../shared/components/UserAvatar';
import useUserList from '../../../hooks/useUserList';
import useGetProjectById from '../../../hooks/useGetProjectById';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTaskRequests } from '@/requests/useTaskRequests';
import { BoardColumn } from '../components/tasks-board/BoardColumn';
import { CreateTaskPosition, TaskStatus } from '@/enums/projects';
import { PER_PAGE_DATA, priorityOptions } from '@/constants/projects';
import { UserAvatarDisplayName, UserAvatarSize } from '@/enums/user';
import { AddUserModal } from '../components/project-settings/AddUserModal';
import { useAllTeamUserList } from '../../../hooks/useAllTeamUserList';
import { selectedOptionType, Option } from '@/types';
import { useAddUser } from '../../../hooks/useAddUser';
import { Priority } from '@/enums/priority';
import { Roles } from '@/enums/organisation';
import { FileObject, TemporaryFile } from '@/types/file';
import Skeleton from 'react-loading-skeleton';
import { priorityColors } from '@/constants/colors';

export const TasksBoard: React.FC = () => {
  const { updateTask, deleteTask, getAllTaskLists } = useTaskRequests();
  const navigate = useNavigate();
  const { fetchUserList } = useUserList();
  const { projectId } = useParams();
  const [createTaskModal, setCreateTaskModal] = useState<boolean>(false);
  const [activeColumn, setActiveColumn] = useState<TaskStatus>(TaskStatus.BACKLOG);
  const [createPosition, setCreatePosition] = useState<CreateTaskPosition>(
    CreateTaskPosition.START
  );
  const { formatMessage } = useIntl();
  const columns = useRef<TaskStatus[]>([
    TaskStatus.BACKLOG,
    TaskStatus.TODO,
    TaskStatus.INPROGRESS,
    TaskStatus.REVIEW,
    TaskStatus.COMPLETED,
  ]);
  const [tasks, setTasks] = useState<Record<TaskStatus, Task[]>>({
    [TaskStatus.BACKLOG]: [],
    [TaskStatus.TODO]: [],
    [TaskStatus.INPROGRESS]: [],
    [TaskStatus.REVIEW]: [],
    [TaskStatus.COMPLETED]: [],
  });
  const [loading, setLoading] = useState<Record<TaskStatus, boolean>>({
    [TaskStatus.BACKLOG]: true,
    [TaskStatus.TODO]: true,
    [TaskStatus.INPROGRESS]: true,
    [TaskStatus.REVIEW]: true,
    [TaskStatus.COMPLETED]: true,
  });
  const [totalCount, setTotalCount] = useState<Record<TaskStatus, number>>({
    [TaskStatus.BACKLOG]: 0,
    [TaskStatus.TODO]: 0,
    [TaskStatus.INPROGRESS]: 0,
    [TaskStatus.REVIEW]: 0,
    [TaskStatus.COMPLETED]: 0,
  });
  const [taskCode, setTaskCode] = useState<string | undefined>('');
  const [showDeleteModal, setDeleteShowModal] = useState<boolean>(false);
  const [deleteTaskId, setDeleteTaskId] = useState<number | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<DetailedTask | null>(null);
  const [isTaskEdit, setIsTaskEdit] = useState(false);
  const [assigneeUser, setAssigneeUser] = useState<BaseUser | undefined>();
  const { userList, currentUser } = useGlobalContext();
  const { handleAddUser } = useAddUser();
  const { getProject, projectLoading } = useGetProjectById();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [showDropdownUserList, setShowDropdownUserList] = useState<boolean>(false);
  const [projectDetails, setProjectDetails] = useState<BaseProject>();
  const [openAddUsersModel, setAddUsersModal] = useState<boolean>(false);
  const { handleGetAllTeamUsers, allTeamUsers } = useAllTeamUserList();
  const [filterSelection, setFilterSelection] = useState<MultiValue<Option>>([]);
  const [file, setFile] = useState<TemporaryFile[] | FileObject[]>([]);
  const options: Option[] = Object.values(Priority).map((priority) => ({
    value: priority,
    label: priority,
  }));
  const reversedPriorityOptions = [...priorityOptions].reverse();
  const handleDropdownClick = () => {
    setShowDropdownUserList(!showDropdownUserList);
  };

  const isAnyDropdownUserSelected = userList
    ?.slice(3)
    .some((user) => selectedUserIds.includes(user.userId));

  const handleUserClick = (userId: number) => {
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
    } else {
      setSelectedUserIds([...selectedUserIds, userId]);
    }
  };

  const handleFilterSelection = (selectedFilterList: MultiValue<Option>) => {
    setFilterSelection(selectedFilterList);
  };

  useEffect(() => {
    if (filterSelection) {
      columns.current.map((column) => {
        getTasksByColumn(column, 1);
      });
    }
  }, [filterSelection]);

  const getTasksByColumn = async (columnName: TaskStatus, pageNumber = 1) => {
    try {
      const filterByPriority =
        filterSelection.length > 0
          ? filterSelection.map((priority) => priority.value).join(',')
          : undefined;

      const {
        data: { data, success },
      } = await getAllTaskLists(
        parseInt(projectId!),
        columnName,
        PER_PAGE_DATA,
        pageNumber,
        searchValue,
        selectedUserIds,
        filterByPriority
      );

      if (success && data) {
        if (pageNumber !== 1) {
          setTasks((prevTasksData) => ({
            ...prevTasksData,
            [columnName]: [...prevTasksData[columnName], ...data.tasks],
          }));
        } else {
          setTasks((prevTasksData) => ({
            ...prevTasksData,
            [columnName]: data.tasks,
          }));
        }
        setTotalCount((prevCount) => ({
          ...prevCount,
          [columnName]: data.totalTasks,
        }));
      } else {
        navigate('/projects');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading((prevLoadingData) => ({
        ...prevLoadingData,
        [columnName]: false,
      }));
    }
  };

  const pushTask = (task: Task, createPosition: CreateTaskPosition) => {
    setTasks((prevTasksData) => ({
      ...prevTasksData,
      [task.status]:
        createPosition === CreateTaskPosition.END
          ? [...prevTasksData[task.status], task]
          : [task, ...prevTasksData[task.status]],
    }));
    setTotalCount((prevCount) => ({
      ...prevCount,
      [task.status]: prevCount[task.status] + 1,
    }));
  };

  const replaceTask = (updatedTask: Task, options: { sourceColumn: TaskStatus }) => {
    let existingColumnTasks = tasks[options.sourceColumn];
    if (updatedTask.status === options.sourceColumn) {
      // Same column
      existingColumnTasks = existingColumnTasks.map((task) => {
        if (task.taskCode === taskCode) {
          return updatedTask;
        }
        return task;
      });

      setTasks((prevTasksData) => ({
        ...prevTasksData,
        [options.sourceColumn]: existingColumnTasks,
      }));
    } else {
      existingColumnTasks.splice(
        existingColumnTasks.findIndex((t) => t.taskCode === updatedTask.taskCode),
        1
      );
      // Different column
      setTasks((prevTasksData) => ({
        ...prevTasksData,
        [options.sourceColumn]: existingColumnTasks,
        [updatedTask.status]: [updatedTask, ...prevTasksData[updatedTask.status]],
      }));

      setTotalCount((prevCount) => ({
        ...prevCount,
        [options.sourceColumn]: prevCount[options.sourceColumn] - 1,
        [updatedTask.status]: prevCount[updatedTask.status] + 1,
      }));
    }
  };
  const getProjectDetail = async (id: number) => {
    setProjectDetails(await getProject(id));
  };

  useEffect(() => {
    if (projectId) {
      fetchUserList(parseInt(projectId));
      getProjectDetail(parseInt(projectId));
    }
  }, [projectId]);

  const onDragEnd = async (result: DropResult): Promise<void> => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    const sourceColumn = source.droppableId as TaskStatus;
    const destinationColumn = destination.droppableId as TaskStatus;

    const movedTaskIndex = source.index;

    // Store the original state of tasks
    const originalTasks = { ...tasks };
    const originalCount = { ...totalCount };

    const updatedTasks = { ...tasks };

    const [movedTask] = updatedTasks[sourceColumn].splice(movedTaskIndex, 1);
    updatedTasks[destinationColumn].splice(destination.index, 0, movedTask);

    const updatedCount = { ...totalCount };
    updatedCount[sourceColumn] -= 1;
    updatedCount[destinationColumn] += 1;

    setTasks(updatedTasks);
    setTotalCount(updatedCount);

    const destinationPosition =
      updatedTasks[destinationColumn][destination.index - 1]?.position + 1 || 0;

    setTasks((prevData) => {
      prevData[destinationColumn][destination.index].position = destinationPosition;
      return prevData;
    });

    try {
      const payload = {
        status: destinationColumn,
        position: destinationPosition,
      };

      await updateTask(payload, movedTask.taskId);
    } catch (err) {
      setTasks(originalTasks);
      setTotalCount(originalCount);
      console.log(err);
    }
  };

  const handleDeleteTask = async (task: Task | null) => {
    if (!task) return;

    try {
      const { taskId, status }: { taskId: number; status: TaskStatus } = task;
      setDeleteLoading(true);
      const {
        data: { success },
      } = await deleteTask(taskId);
      if (success) {
        setTasks((allTasksData) => ({
          ...allTasksData,
          [status]: allTasksData[status].filter((task) => task.taskId !== taskId),
        }));

        setTotalCount((prevCount) => ({
          ...prevCount,
          [status]: prevCount[status] - 1,
        }));
        toast.success('Task deleted successfully');
        setTaskData(null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      setCreateTaskModal(false);
      setDeleteShowModal(false);
      setIsTaskEdit(false);
      setTaskData(null);
      setFile([]);
      setAssigneeUser(undefined);
    }
  };

  const handleInputChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleClickOutside = () => {
    setShowDropdownUserList(false);
  };

  useEffect(() => {
    if (currentUser?.role !== Roles.MEMBER) {
      handleGetAllTeamUsers();
    }
  }, []);

  const handleAddUserClick = async (selectedOption: selectedOptionType) => {
    try {
      const success = await handleAddUser(selectedOption, projectId);
      if (success) {
        setAddUsersModal(false);
        fetchUserList(parseInt(projectId!));
      }
    } catch (err) {
      console.error('Error deleting user:', err);
    } finally {
      setAddUsersModal(false);
    }
  };

  const colourStyles: StylesConfig<any, true> = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: priorityColors[state.data.label],
      color: 'black',
    }),
  };

  const CustomOption = (props: any) => (
    <components.Option {...props}>
      <div className='priority-dropdown__option'>
        <span
          className='priority-badge px-2 py-1'
          style={{
            backgroundColor: props.data.color,
          }}
        >
          {props.data.label}
        </span>
      </div>
    </components.Option>
  );

  return (
    <>
      <div className='d-flex flex-column'>
        {projectLoading ? (
          <div className='w-100'>
            <div className='mb-3'>
              <div className='mb-1 w-25'>
                <Skeleton height={20} />
              </div>
              <div className='w-50'>
                <Skeleton height={10} />
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-row justify-content-between'>
            <PageHeading
              title={projectDetails?.title}
              description={formatMessage({
                id: 'Organise, prioritise, and track your tasks visually with our intuitive task board.',
              })}
            />
          </div>
        )}

        <div className='pb-5'>
          {/* TODO: Search Component & Filter component */}
          {projectLoading ? (
            <div className='mb-3 w-75'>
              <Skeleton height={40} />
            </div>
          ) : (
            <div className='task-list-top-section d-flex flex-column flex-md-row '>
              <div className='search-task me-3'>
                <div className='search-container'>
                  <img src={lens} alt='lens' className='search-icon me-5' />
                  <input
                    value={searchValue}
                    onChange={(e) => handleInputChange(e)}
                    className='search py-5'
                    placeholder='Search by task ID, description'
                  />
                </div>
              </div>
              <div className='d-flex flex-row my-4 my-md-0'>
                {currentUser?.role !== Roles.MEMBER && (
                  <img
                    onClick={() => setAddUsersModal(true)}
                    className='ms-md-6 cursor-pointer dashed-red mt-2'
                    src={dashedRed}
                  />
                )}
                {userList &&
                  userList.slice(0, 3).map((user: User) => (
                    <div
                      className={`text-center -mr-6px cursor-pointer mt-2 ${
                        selectedUserIds.includes(user.userId) ? 'selected' : ''
                      }`}
                      onClick={() => {
                        handleUserClick(user.userId);
                      }}
                      key={user.userId}
                    >
                      <UserAvatar
                        user={user}
                        size={UserAvatarSize.LARGE}
                        displayName={UserAvatarDisplayName.HOVER}
                      />
                    </div>
                  ))}

                <div className='position-relative'>
                  <OutsideClickHandler onOutsideClick={handleClickOutside}>
                    {userList.length - 3 > 0 && (
                      <div
                        className={`text-center mt-2 pt-2 cursor-pointer frequency-user-list-circle ${
                          isAnyDropdownUserSelected ? 'selected' : ''
                        }`}
                        onClick={handleDropdownClick}
                      >
                        <span className='grey-color'> +{userList.length - 3}</span>
                      </div>
                    )}
                    {showDropdownUserList && (
                      <div className='dropdown userList-dropdown position-absolute'>
                        {userList?.slice(3)?.map((user: User) => (
                          <div key={user.userId} className='single-user p-2 pt-4'>
                            <input
                              className={'me-3 ms-2 grey-color'}
                              type='checkbox'
                              id={`user-${user.userId}`}
                              value={user.userId}
                              checked={selectedUserIds.includes(user.userId)}
                              onChange={() => {
                                handleUserClick(user.userId);
                              }}
                            />
                            <div className='user-circle me-2 pt-1 creator-list-circle font-size-8px'>
                              <UserAvatar user={user} size={UserAvatarSize.MEDIUM} />
                            </div>
                            <label
                              htmlFor={`user-${user.userId}`}
                              className='title-color font-size-12 font-size-400'
                            >
                              {user.firstName} {user.lastName}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </OutsideClickHandler>
                </div>
              </div>
              <div className='filter-container ms-md-5'>
                <div className='mb-10'>
                  <Select
                    isMulti
                    name='priority'
                    classNamePrefix='priority-dropdown'
                    options={reversedPriorityOptions}
                    placeholder='Filter by Priority'
                    value={filterSelection}
                    onChange={handleFilterSelection}
                    styles={colourStyles}
                    components={{ Option: CustomOption }}
                  />
                </div>
              </div>
            </div>
          )}

          <div className='w-100 scroll-y'>
            <div className='task-board-width d-flex flex-row justify-content-between'>
              <DragDropContext onDragEnd={onDragEnd}>
                {columns.current.map((column) => {
                  return (
                    <BoardColumn
                      status={column}
                      projectId={parseInt(projectId!)}
                      searchValue={searchValue}
                      key={column}
                      selectedUserIds={selectedUserIds}
                      setActiveColumn={setActiveColumn}
                      setCreateTaskModal={setCreateTaskModal}
                      setIsTaskEdit={setIsTaskEdit}
                      setCreatePosition={setCreatePosition}
                      setTaskCode={setTaskCode}
                      tasks={tasks[column]}
                      totalCount={totalCount[column]}
                      getTasks={(pageNumber: number) => getTasksByColumn(column, pageNumber)}
                      loading={loading[column]}
                    />
                  );
                })}
              </DragDropContext>

              <TaskModal
                openTaskModal={createTaskModal}
                setOpenTaskModal={setCreateTaskModal}
                isTaskEdit={isTaskEdit}
                setIsTaskEdit={setIsTaskEdit}
                userList={userList}
                activeColumn={activeColumn}
                setActiveColumn={setActiveColumn}
                setDeleteShowModal={setDeleteShowModal}
                taskData={taskData as DetailedTask}
                setTaskData={setTaskData}
                taskCode={taskCode}
                assigneeUser={assigneeUser}
                setAssigneeUser={setAssigneeUser}
                createPosition={createPosition}
                pushTask={pushTask}
                replaceTask={replaceTask}
                file={file}
                setFile={setFile}
              />

              <ConfirmationModal
                showConfirmationModal={showDeleteModal}
                setShowConfirmationModal={setDeleteShowModal}
                headingMessage={formatMessage({ id: 'Delete Task' })}
                description={formatMessage({
                  id: 'Are you sure you want to delete this task permanently?',
                })}
                handleDelete={() => handleDeleteTask(taskData)}
                deleteLoading={deleteLoading}
                id={deleteTaskId}
              />

              {openAddUsersModel && (
                <AddUserModal
                  openAddUsersModal={openAddUsersModel}
                  setAddUsersModal={setAddUsersModal}
                  projectUserList={userList}
                  allTeamUsers={allTeamUsers}
                  onUserSelected={(selectedUser) => {
                    if (selectedUser !== null) {
                      handleAddUserClick(selectedUser);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
