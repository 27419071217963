import axios from 'axios';
import { API_URL } from '.';
import { User } from '@/types/user';

const USER_URL = `${API_URL}/user`;
const DELETE_USER_PERMANENTLY = `${USER_URL}/delete`;
const URER_INVITE = `${USER_URL}/invite`;

export const useUserRequests = () => {
  const profileData = () => {
    return axios.get(USER_URL, {
      validateStatus() {
        return true;
      },
    });
  };

  const updateProfileInfo = (user: User) => {
    return axios.put(USER_URL, user, {
      validateStatus() {
        return true;
      },
    });
  };

  // delete user permanently
  const deleteUserPermanently = () => {
    return axios.delete(DELETE_USER_PERMANENTLY, {
      validateStatus() {
        return true;
      },
    });
  };

  const addUser = (data: any) => {
    return axios.post(URER_INVITE, data, {
      validateStatus() {
        return true;
      },
    });
  };

  return {
    addUser,
    deleteUserPermanently,
    updateProfileInfo,
    profileData,
  };
};
