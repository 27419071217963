import { Field, Formik, ErrorMessage } from 'formik';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { InputToolTip } from '../../shared/components/UI/InputToolTip';
import { Roles } from '@/enums/organisation';
import { toast } from 'react-toastify';
import { ButtonTypes, InputTypes } from '@/enums';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { useUserRequests } from '@/requests/useUserRequests';
import { useTeamManagementRequests } from '@/requests/useTeamManagementRequests';
import { BWInput } from '../../shared/components/Input/BWInput';
import Skeleton from 'react-loading-skeleton';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  roles: '',
};

const UserModal = ({
  title,
  buttonText,
  addModal,
  setAddModal,
  flag,
  userDetails,
  getUserList,
  getPendingUserList,
  getUserApiSpinner,
  setIsUpdate,
}: {
  title: string;
  buttonText: string;
  addModal: boolean;
  setAddModal: Dispatch<SetStateAction<boolean>>;
  flag: boolean;
  userDetails?: any;
  getUserList: any;
  getPendingUserList?: any;
  getUserApiSpinner?: boolean;
  setIsUpdate?: any;
}) => {
  const { editUser } = useTeamManagementRequests();
  const { addUser } = useUserRequests();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const userSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'Invalid email format' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Email is required' })),
    roles: Yup.string()
      .required(formatMessage({ id: 'Role is required' }))
      .nullable(),
  });

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      if (!flag) {
        const payload = {
          email: values.email,
          role: values.roles,
        };
        const {
          data: { success, errors },
        } = await addUser(payload);
        if (success) {
          setLoading(false);
          setAddModal(!addModal);
          getUserList();
          getPendingUserList();
          toast.success(
            formatMessage({ id: `Invitation_Sent` }).replace('{{email}}', values.email)
          );
        }
      } else {
        const payload = {
          firstName: values.firstname,
          lastName: values.lastname,
          email: values.email,
          role: values.roles,
        };

        const {
          data: { success, errors },
        } = await editUser(userDetails?.userId, payload);
        if (success) {
          setLoading(false);
          setAddModal(!addModal);
          toast.success(formatMessage({ id: 'Role changed successfully' }));
          getUserList();
          setIsUpdate(true);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (flag) {
    initialValues.email = userDetails?.email;
    initialValues.roles = userDetails?.teams[0].user_team_role.role;
  } else {
    initialValues.email = '';
    initialValues.roles = '';
  }
  return (
    <>
      <div>
        <Modal
          show={addModal}
          onHide={() => setAddModal(!addModal)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <h2>{title}</h2>
          </Modal.Header>
          <Formik initialValues={initialValues} validationSchema={userSchema} onSubmit={onSubmit}>
            {(formik) => {
              const isMemberRole = formik.values.roles === Roles.MEMBER;
              return (
                <Form>
                  <Modal.Body>
                    {getUserApiSpinner ? (
                      <div className='mb-5'>
                        <Skeleton height={50} />
                      </div>
                    ) : (
                      <BWInput
                        fieldType={InputTypes.EMAIL}
                        fieldName={'email'}
                        formik={formik}
                        placeholder={formatMessage({ id: 'Enter email' })}
                        label={formatMessage({ id: 'Email address' })}
                        isDisabled={flag}
                      />
                    )}

                    <div className='col-12 mb-4'>
                      <label className='ps-0 font-size-13'>
                        {formatMessage({ id: 'Assign Role' })}
                        <InputToolTip
                          tooltipText={formatMessage({
                            id: 'GLOBAL.TOOLTIP.USER.ASSIGN_ROLE',
                          })}
                        />
                      </label>
                    </div>
                    {getUserApiSpinner ? (
                      <div className='row'>
                        <div className='col-6'>
                          <div className='col-5'>
                            <Skeleton height={25} />
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='col-5'>
                            <Skeleton height={25} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='row'>
                        <div className='col-6 d-flex'>
                          <div className='col-3'>
                            <Field
                              className={`${'form-check-input'}`}
                              type={'radio'}
                              name={'roles'}
                              value={Roles.ADMIN}
                            />
                            <span className='ms-3 font-size-14 text-black'>
                              {formatMessage({ id: 'Admin' })}
                            </span>
                          </div>
                          <div className='col-1'>
                            <InputToolTip
                              tooltipText={formatMessage({
                                id: 'GLOBAL.TOOLTIP.USER.ADMIN',
                              })}
                            />
                          </div>
                        </div>
                        <div className='col-6 d-flex'>
                          <div>
                            <Field
                              className={`${'form-check-input'}`}
                              type={'radio'}
                              name={'roles'}
                              value={Roles.MEMBER}
                            />
                            <span className='ms-3 font-size-14 text-black'>
                              {formatMessage({ id: 'Member' })}
                            </span>
                          </div>
                          <div className='col-1 ms-2'>
                            <InputToolTip
                              tooltipText={formatMessage({
                                id: 'GLOBAL.TOOLTIP.USER.MEMBER',
                              })}
                            />
                          </div>
                        </div>
                        <div className='text-danger mt-1'>
                          <ErrorMessage name='roles' />
                        </div>
                      </div>
                    )}
                    {isMemberRole && (
                      <div className='alert alert-info mt-4' role='alert'>
                        {formatMessage({
                          id: 'Note: By default, Members will not have access to any project within your organisation. You can go to your Project Settings and add them to your projects.',
                        })}
                      </div>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <div className='d-flex flex-wrap gap-3'>
                      <GlobalButton
                        buttonText={formatMessage({ id: 'Discard' })}
                        buttonType={ButtonTypes.SECONDARY}
                        onButtonClick={() => setAddModal(false)}
                      />

                      <GlobalButton
                        buttonText={buttonText}
                        buttonType={ButtonTypes.PRIMARY}
                        isLoading={loading}
                        isDisable={formik.isSubmitting || !formik.isValid}
                        onButtonClick={formik.handleSubmit}
                      />
                    </div>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
    </>
  );
};

export default UserModal;
