import { Draggable } from 'react-beautiful-dnd';
import { Priority } from '@/enums/priority';
import UserAvatar from '../../../shared/components/UserAvatar';
import taskSymbol from '../../../../core/assets/images/svg/bugs.work/task-symbol.svg';
import { Task } from '@/types/projects';
import { UserAvatarDisplayName, UserAvatarSize } from '@/enums/user';

export const TaskCard = ({
  task,
  index,
  handleOpenTask,
}: {
  task: Task;
  index: number;
  handleOpenTask: (task: Task) => void;
}) => {
  return (
    <Draggable
      key={task.taskId.toString() || ''}
      draggableId={task.taskId.toString() || ''}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className='d-flex flex-column justify-content-between task-card mx-5 mb-3 p-3'
          onClick={() => handleOpenTask(task)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            border: '1px solid lightgrey',
            borderRadius: '4px',
            background: snapshot.isDragging ? 'lightgreen' : 'white',
          }}
        >
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row mb-3'>
              <img className='me-2' src={taskSymbol} alt='task-symbol' />
              <div className='title-color font-weight-600 font-size-12 cursor-pointer'>
                {task.taskCode}
              </div>
            </div>
            <div className='task-description'>{task.description}</div>
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row justify-content-between'>
              <div className='text-center'>
                <UserAvatar
                  user={task.assignee}
                  size={UserAvatarSize.MEDIUM}
                  displayName={UserAvatarDisplayName.HOVER}
                />
              </div>

              <div
                className={`badge font-weight-400 font-size-10 mt-1 d-flex justify-content-center align-items-center text-black ${
                  task.priority === Priority.NORMAL
                    ? 'bg-card-normal'
                    : task.priority === Priority.MAJOR
                    ? 'bg-card-major'
                    : task.priority === Priority.MINOR
                    ? 'bg-card-minor'
                    : task.priority === Priority.CRITICAL
                    ? 'bg-card-critical'
                    : ''
                }`}
              >
                {task.priority}
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
