import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../core/helpers';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGlobalContext } from '../../../core/helpers';
import { useIntl } from 'react-intl';
import { useUtmValidator } from '../../../hooks/useUtmValidator';
import { useFetchCountryAndTimeZone } from '../../../hooks/useFetchCountryAndTimeZone';

const SocialLoginButtons = ({ localStorageReferral }: { localStorageReferral?: string }) => {
  const [searchParams] = useSearchParams();
  const path = searchParams.get('path');
  const { handleUtmEvents, utmValues } = useUtmValidator();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { getCurrentUser, setUserToken } = useGlobalContext();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [, setErrorsArray] = useState<string[]>();
  const { fetchCountryAndTimeZone } = useFetchCountryAndTimeZone();

  useEffect(() => {
    handleUtmEvents();
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true);
      const locationData = await fetchCountryAndTimeZone();

      const {
        data: { data: googleData, errors, success },
      } = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/auth/google-signin`, {
        type: 'GOOGLE',
        token: response.access_token,
        referral: localStorageReferral,
        inviteToken: state?.inviteToken || undefined,
        ...utmValues,
        locale: 'en_US',
        dateFormat: 'MMM DD, YYYY',
        timeFormat: 'hh:mm:ss a',
        timeZone: locationData?.timeZone || 5.5,
        countryId: locationData?.countryId || 103,
      });

      if (success) {
        setLoading(false);
        await getCurrentUser(googleData);
        setUserToken(googleData.accessToken);
        if (path) {
          navigate(path);
        } else {
          navigate('/');
        }
      } else {
        setLoading(false);
        setErrorsArray(errors);
        await getCurrentUser(undefined);
      }
    },
  });

  return (
    <>
      {/* begin::Col */}
      <div className='col-12'>
        <button
          type='button'
          onClick={() => googleLogin()}
          disabled={loading}
          className='btn btn-flex btn-light-primary flex-center text-nowrap py-3 px-5 w-100 h-48px border border-primary'
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-24px google-signin-button me-4'
          />
          {!loading && (
            <span className='indicator-label font-size-14'>
              {formatMessage({ id: 'Continue with Google' })}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {formatMessage({ id: 'Please wait...' })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Col */}
    </>
  );
};

export default SocialLoginButtons;
