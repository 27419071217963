import React, { useEffect, useState } from 'react';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectedOptionType } from '../../../../types';
import { User } from '../../../../types/user';
import { useIntl } from 'react-intl';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { AddUserModal } from './AddUserModal';
import UserAvatar from '../../../shared/components/UserAvatar';
import cross from '../../../../core/assets/images/svg/bugs.work/circlecross.svg';
import { useUserProjectRequests } from '@/requests/useUserProjectRequests';
import { UserAvatarDisplayName, UserAvatarSize } from '@/enums/user';
import { useAllTeamUserList } from '../../../../hooks/useAllTeamUserList';
import { useAddUser } from '../../../../hooks/useAddUser';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

const AddUser = () => {
  const { projectId } = useParams();
  const [projectUserList, setAllProjectUserList] = useState<User[]>([]);
  const [admins, setAdmins] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [openAddUsersModel, setAddUsersModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);
  const { formatMessage } = useIntl();
  const { handleGetAllTeamUsers, allTeamUsers } = useAllTeamUserList();
  const { deleteUser } = useUserProjectRequests();
  const { handleAddUser } = useAddUser();
  const { getAllUsers } = useUserProjectRequests();
  const handleDeleteUser = async (userId: number) => {
    if (!projectId) return;
    try {
      const {
        data: { data, success, errors },
      } = await deleteUser(parseInt(projectId), userId);
      if (success) {
        setDeleteShowModal(false);
        toast.success('User deleted successfully');
        handleGetProjectUsers();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetProjectUsers = async () => {
    if (!projectId) return;
    try {
      const {
        data: { data, success, errors },
      } = await getAllUsers(parseInt(projectId));
      if (success) {
        setAllProjectUserList(data);
        handleGetAllTeamUsers();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddUserClick = async (selectedOption: selectedOptionType) => {
    try {
      const success = await handleAddUser(selectedOption, projectId);
      if (success) {
        setAddUsersModal(false);
        handleGetProjectUsers();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddUsersModal(false);
    }
  };

  useEffect(() => {
    handleGetProjectUsers();
  }, []);

  useEffect(() => {
    handleGetAllTeamUsers();
  }, [projectUserList]);

  useEffect(() => {
    if (projectUserList.length > 0) {
      const adminUsers = projectUserList.filter(
        (user) => user.role === 'owner' || user.role === 'admin'
      );
      const memberUsers = projectUserList.filter((user) => user.role === 'member');
      setAdmins(adminUsers);
      setMembers(memberUsers);
    }
  }, [projectUserList]);

  return (
    <>
      <div className='card p-4 mt-4'>
        <div className='d-flex flex-row mx-2 align-items-center'>
          <div>
            <label className='title-color font-weight-500 font-size-16 py-4'>Manage members</label>
          </div>
          <div className=' ms-auto'>
            <GlobalButton
              buttonText={formatMessage({ id: 'Add project member' })}
              buttonType={ButtonTypes.PRIMARY}
              onButtonClick={() => {
                setAddUsersModal(true);
              }}
            />
          </div>
        </div>
        <div className=' mx-2'>
          <div>
            <label className='title-color font-weight-400 font-size-14'>
              {formatMessage({
                id: 'Allow members from your organisation to access this project. If a user is not added in your organisation',
              })}
              ,{' '}
              <Link to='/organisation?open=true' className='link-primary'>
                {formatMessage({ id: 'you can invite them' })}
              </Link>
              .
            </label>
          </div>
        </div>
        {projectUserList.length === 0 ? (
          <div
            className='d-flex flex-row'
            style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            <Skeleton
              circle={true}
              height={35}
              width={35}
              count={4}
              style={{ marginTop: '10px' }}
            />
            <Skeleton height={35} width={200} count={4} style={{ marginTop: '10px' }} />
          </div>
        ) : (
          <div>
            <div className=' mx-2 align-items-center'>
              <div className='col'>
                <label className='title-color font-weight-500 font-size-16 py-4'>Admins</label>
                {admins.map((user) => (
                  <div key={user.userId} className='d-flex align-items-center mt-1 pb-2'>
                    <UserAvatar
                      user={user}
                      size={UserAvatarSize.LARGE}
                      displayName={UserAvatarDisplayName.SIDE}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='mx-2 align-items-center'>
              <div className=''>
                {members?.length > 0 && (
                  <label className='title-color font-weight-500 font-size-16 py-4'>
                    {formatMessage({ id: 'Project members' })}
                  </label>
                )}

                {members.map((user) => (
                  <div key={user.userId} className='d-flex align-items-center pb-2'>
                    <UserAvatar
                      user={user}
                      size={UserAvatarSize.LARGE}
                      displayName={UserAvatarDisplayName.SIDE}
                    />
                    <img
                      src={cross}
                      alt='cross'
                      className='icon ms-3 cursor-pointer'
                      style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                      onClick={() => {
                        setDeleteUserId(user.userId);
                        setDeleteShowModal(true);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {openAddUsersModel && (
        <AddUserModal
          openAddUsersModal={openAddUsersModel}
          setAddUsersModal={setAddUsersModal}
          projectUserList={projectUserList}
          allTeamUsers={allTeamUsers}
          onUserSelected={(selectedUser) => {
            if (selectedUser !== null) {
              handleAddUserClick(selectedUser);
            }
          }}
        />
      )}

      <ConfirmationModal
        showConfirmationModal={showDeleteModal}
        setShowConfirmationModal={setDeleteShowModal}
        headingMessage={formatMessage({ id: 'Remove user' })}
        description={formatMessage({
          id: 'Are you sure you want to remove this user from the project?',
        })}
        handleDelete={() => handleDeleteUser(deleteUserId ?? 0)}
        deleteLoading={false}
        id={deleteUserId ?? 0}
      />
    </>
  );
};

export default AddUser;
