import axios from 'axios';
import { API_URL } from '.';

const QUICK_START_URL = `${API_URL}/quick-start`;

export const useQuickStartRequests = () => {
  const getAllQuickStart = (teamId: number | undefined) => {
    return axios.get(`${QUICK_START_URL}/${teamId}`, {
      validateStatus(status) {
        return true;
      },
    });
  };
  const createQuickStartTeam = (quickStartId: number) => {
    const payload = {
      quickStartId: quickStartId,
    };
    return axios.post(`${QUICK_START_URL}`, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  return {
    getAllQuickStart,
    createQuickStartTeam,
  };
};
