import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../core/helpers';
import { GlobalButton } from './UI/GlobalButton';
import { ButtonTypes } from '@/enums';

export const NoDataComponent = ({
  title,
  subtitle,
  buttonText,
  buttonClick,
  image = true,
}: {
  title: string;
  subtitle?: string;
  buttonText?: string;
  buttonClick?: () => void;
  image?: boolean;
}) => {
  const { formatMessage } = useIntl();

  return (
    <div
      className='w-100 custom-height d-flex flex-column justify-content-center align-items-center bg-white'
      style={{ '--variable-height': '410px' } as React.CSSProperties}
    >
      <img src={toAbsoluteUrl('/media/icons/no-data.svg')} alt='No data' />
      <div className='font-24 font-weight-600 mt-4'>{formatMessage({ id: title })}</div>
      {subtitle && <div className='mb-4'>{formatMessage({ id: subtitle })}</div>}
      {buttonText && (
        <GlobalButton
          buttonText={formatMessage({ id: buttonText })}
          buttonType={ButtonTypes.PRIMARY}
          onButtonClick={buttonClick ? buttonClick : () => {}}
          isImage={image}
          src={toAbsoluteUrl('/media/projects/plus.svg')}
          alt='plus icon'
        />
      )}
    </div>
  );
};
