import { OAuthConfig } from '@/constants/projects';
import { ButtonTypes } from '@/enums';
import { IntegrationPlatform } from '@/enums/projects';
import { useIntegrationRequests } from '@/requests/useIntegrationRequests';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import gearIcon from '../../../core/assets/images/svg/bugs.work/gear.png';
import jira from '../../../core/assets/images/svg/integration/Jira.svg';
import viewConfig from '../../../core/assets/images/svg/integration/ViewConfig.svg';
import youTrack from '../../../core/assets/images/svg/integration/Youtrack.svg';
import github from '../../../core/assets/images/svg/integration/Github.svg';
import webhook from '../../../core/assets/images/svg/integration/Webhook.svg';
import zapier from '../../../core/assets/images/svg/integration/Zapier.png';
import trello from '../../../core/assets/images/svg/integration/trello.png';
import { useGlobalContext } from '../../../core/helpers';
import useGetProjectById from '../../../hooks/useGetProjectById';
import { IntegrationType } from '../../../types/projects';
import { generateOAuthUrl, generateTrelloOAuthURL } from '../../../util/OAuth';
import { PageHeading } from '../../shared/components/PageHeading';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { WebhookIntegrationModel } from '../components/integrations/WebHookIntegrationModel';
import { YoutrackIntegrationModel } from '../components/integrations/YoutrackIntegrationModel';
import { JiraIntegrationModel } from '../components/integrations/JiraIntegrationModel';
import { ConfirmationModal } from '../../shared/components/ConfirmationModal';
import { Spinner } from '../../shared/components/General/Spinner';
import { GithubIntegrationModel } from '../components/integrations/GithubIntegrationModel';
import Skeleton from 'react-loading-skeleton';
import { TrelloIntegrationModel } from '../components/integrations/TrelloIntegrationModel';
import { zapierConnect } from '../../../core/layout/core/_constants';

export const Integrations = () => {
  const { createIntegrateProject, getIntegrationList, toggleChange, deleteIntegration } =
    useIntegrationRequests();
  const { projectId } = useParams();
  const [youTrackEnabled, setYouTrackEnabled] = useState<boolean>(false);
  const [jiraEnabled, setJiraEnabled] = useState<boolean>(false);
  const [webhooksEnabled, setWebhooksEnabled] = useState<boolean>(false);
  const [githubEnabled, setGithubEnabled] = useState<boolean>(false);
  const [trelloEnabled, setTrelloEnabled] = useState<boolean>(false);
  const [zapierEnabled, setZapierEnabled] = useState<boolean>(false);
  const [openIntegrationModal, setOpenIntegrationModal] = useState<boolean>(false);

  const [openYoutrackIntegrationModal, setOpenYoutrackIntegrationModal] = useState<boolean>(false);
  const [openWebhookIntegrationModal, setOpenWebhookIntegrationModal] = useState<boolean>(false);

  const [integrationListLoading, setIntegrationListLoading] = useState<boolean>(true);
  const [, setIntegrationLists] = useState<IntegrationType[]>([]);
  const [jiraIntegrationData, setJiraIntegrationData] = useState<IntegrationType>();
  const [youTrackIntegrationData, setYouTrackIntegrationData] = useState<IntegrationType>();
  const [webHooksIntegrationData, setwebHooksIntegrationData] = useState<IntegrationType>();
  const [githubIntegrationData, setGithubIntegrationData] = useState<IntegrationType>();
  const [trelloIntegrationData, setTrelloIntegrationData] = useState<IntegrationType>();
  const [zapierIntegrationData, setZapierIntegrationData] = useState<IntegrationType>();
  const [integrationLoading, setIntegrationLoading] = useState<boolean>(false);
  const { getProject } = useGetProjectById();
  const [platformData, setPlatformData] = useState<IntegrationType>();
  const [showDeleteModal, setDeleteShowModal] = useState<boolean>(false);
  const [deleteIntegrationData, setDeleteIntegrationData] = useState<IntegrationType>();
  const [reconnectJira, setReconnectJira] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const {
    setOpenOauthConfigModal,
    IntegrationType,
    setIntegrationType,
    setOpenGithubOauthConfigModal,
    setOpenTrelloOauthConfigModal,
  } = useGlobalContext();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const handleYouTrackToggle = () => {
    setYouTrackEnabled(!youTrackEnabled);
    if (youTrackIntegrationData) {
      handleToggleChange(youTrackIntegrationData, !youTrackEnabled);
    }
  };

  const handleJiraToggle = (val: any) => {
    if (val === 'modal') {
      setJiraEnabled(true);
      if (jiraIntegrationData) {
        handleToggleChange(jiraIntegrationData, true);
      }
    } else {
      setJiraEnabled(!jiraEnabled);
      if (jiraIntegrationData) {
        handleToggleChange(jiraIntegrationData, !jiraEnabled);
      }
    }
  };

  const handleWebhooksToggle = () => {
    setWebhooksEnabled(!webhooksEnabled);
    if (webHooksIntegrationData) {
      handleToggleChange(webHooksIntegrationData, !webhooksEnabled);
    }
  };

  const handleGithubToggle = (val: any) => {
    if (val === 'modal') {
      setGithubEnabled(true);
      if (githubIntegrationData) {
        handleToggleChange(githubIntegrationData, true);
      }
    } else {
      setGithubEnabled(!githubEnabled);
      if (githubIntegrationData) {
        handleToggleChange(githubIntegrationData, !githubEnabled);
      }
    }
  };
  const handleTrelloToggle = (val: any) => {
    if (val === 'modal') {
      setTrelloEnabled(true);
      if (trelloIntegrationData) {
        handleToggleChange(trelloIntegrationData, true);
      }
    } else {
      setTrelloEnabled(!trelloEnabled);
      if (trelloIntegrationData) {
        handleToggleChange(trelloIntegrationData, !trelloEnabled);
      }
    }
  };

  const getAllIntegrationList = async () => {
    if (!projectId) return;
    try {
      setIntegrationListLoading(true);
      const projectIdNumber = parseInt(projectId);
      const {
        data: { data, success, errors },
      } = await getIntegrationList(projectIdNumber);
      if (success) {
        setIntegrationLists(data);
        const youTrackIntegrationData = data.find(
          (integration: IntegrationType) =>
            integration.platformName === IntegrationPlatform.YOUTRACK
        );
        const jiraIntegrationData = data.find(
          (integration: IntegrationType) => integration.platformName === IntegrationPlatform.JIRA
        );
        const webhooksIntegrationData = data.find(
          (integration: IntegrationType) => integration.platformName === IntegrationPlatform.WEBHOOK
        );
        const githubIntegrationData = data.find(
          (integration: IntegrationType) => integration.platformName === IntegrationPlatform.GITHUB
        );
        const trelloIntegrationData = data.find(
          (integration: IntegrationType) => integration.platformName === IntegrationPlatform.TRELLO
        );
        const zapierIntegrationData = data.find(
          (integration: IntegrationType) => integration.platformName === IntegrationPlatform.ZAPIER
        );
        setJiraIntegrationData(jiraIntegrationData);
        setYouTrackIntegrationData(youTrackIntegrationData);
        setwebHooksIntegrationData(webhooksIntegrationData);
        setGithubIntegrationData(githubIntegrationData);
        setTrelloIntegrationData(trelloIntegrationData);
        setZapierIntegrationData(zapierIntegrationData);
        setYouTrackEnabled(youTrackIntegrationData ? youTrackIntegrationData?.status : false);
        setJiraEnabled(jiraIntegrationData ? jiraIntegrationData?.status : false);
        setWebhooksEnabled(webhooksIntegrationData ? webhooksIntegrationData?.status : false);
        setGithubEnabled(githubIntegrationData ? githubIntegrationData?.status : false);
        setTrelloEnabled(trelloIntegrationData ? trelloIntegrationData.status : false);
        setReconnectJira(jiraIntegrationData ? jiraIntegrationData.requiresReconnect : false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIntegrationListLoading(false);
    }
  };

  useEffect(() => {
    getAllIntegrationList();
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      getProject(parseInt(projectId));
      getAllIntegrationList();
    }
  }, [projectId]);

  const handleIntegrateProject = async (meta_data?: any) => {
    if (!projectId) {
      return;
    }
    const projectIdNumber = parseInt(projectId);
    const status =
      IntegrationType === IntegrationPlatform.YOUTRACK
        ? youTrackEnabled
        : IntegrationType === IntegrationPlatform.JIRA
        ? jiraEnabled
        : webhooksEnabled;

    try {
      setIntegrationLoading(true);
      const {
        data: { data, success, errors },
      } = await createIntegrateProject(meta_data, IntegrationType!, projectIdNumber, status);
      if (success) {
        if (IntegrationType === IntegrationPlatform.YOUTRACK) {
          setOpenYoutrackIntegrationModal(false);
        } else if (IntegrationType === IntegrationPlatform.WEBHOOK) {
          setOpenWebhookIntegrationModal(false);
        } else setOpenIntegrationModal(false);
        getAllIntegrationList();
        toast.success(formatMessage({ id: 'Integration configured successfully' }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOpenIntegrationModal(false);
      setIntegrationLoading(false);
    }
  };

  const handleToggleChange = async (values: any, status: boolean) => {
    if (!projectId) return;
    try {
      const projectIdNumber = parseInt(projectId);
      const {
        data: { data, success, errors },
      } = await toggleChange(values, projectIdNumber, status);
      if (success) {
        if (status) {
          toast.success(formatMessage({ id: 'Integration enabled successfully' }));
        } else {
          toast.success(formatMessage({ id: 'Integration disabled successfully' }));
        }
      } else {
        setYouTrackEnabled(!status);
        setJiraEnabled(false);
      }
    } catch (err) {
      setYouTrackEnabled(!status);
      console.log(err);
    } finally {
      getAllIntegrationList();
    }
  };
  const handleDisconnectIntegration = async (integrationData: any) => {
    try {
      setIntegrationLoading(true);
      const {
        data: { data, success, errors },
      } = await deleteIntegration(integrationData.integrationId, integrationData.projectId);
      if (success) {
        setDeleteShowModal(false);
        toast.success(formatMessage({ id: 'Disconnected successfully' }));
        getAllIntegrationList();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIntegrationLoading(false);
    }
  };
  const onJiraClick = async () => {
    const jiraConfig = OAuthConfig.JIRA;

    const { CLIENTID, REDIRECTURI, SCOPE, JIRA_OAUTH_BASE_URL } = jiraConfig;
    const state = `${IntegrationPlatform.JIRA}:${projectId}`;
    const url = generateOAuthUrl(
      JIRA_OAUTH_BASE_URL,
      CLIENTID,
      process.env.REACT_APP_FRONTEND_BASE_URL + REDIRECTURI,
      SCOPE,
      state
    );

    window.location.href = url;
  };
  return (
    <>
      <div>
        <PageHeading title='Project Integration' description='Manage your project integrations' />
        <div>
          <div className='integration-card col-12 col-md-8 col-xxl-6 h-100 parent-container'>
            <h4>{formatMessage({ id: 'Task integration' })}</h4>
            <p>
              {formatMessage({
                id: 'Connect your 3rd-party tools for seamless integration with bugs.work. All tasks created on bugs.work will trigger an event to your enabled integrations.',
              })}
            </p>
            {integrationListLoading ? (
              <Skeleton count={4} height={40} style={{ marginBottom: '10px' }} />
            ) : (
              <div>
                <div className='d-flex align-items-md-center mt-6 justify-content-between'>
                  <div className='col-md-3 col mt-2 mt-md-0'>
                    <img src={jira} alt='jira' />
                    &nbsp;<span className=''>Jira</span>
                  </div>
                  <div className='col-md-9  ps-0 d-flex align-items-center'>
                    {!jiraIntegrationData ? (
                      <div className='col-9 ps-0'>
                        <GlobalButton
                          buttonText={formatMessage({
                            id: 'Connect',
                          })}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={onJiraClick}
                        />
                      </div>
                    ) : (
                      <>
                        <div className='mx-2 badge badge-pill badge-integration d-none d-lg-block d-md-block'>
                          {formatMessage({
                            id: 'Connected',
                          })}
                        </div>
                        <div className='ms-6'></div>
                        <div
                          className='cursor-pointer'
                          onClick={() => {
                            setIntegrationType(IntegrationPlatform.JIRA);
                            setOpenOauthConfigModal(true);
                          }}
                        >
                          <img src={viewConfig} alt='view config' />
                          &nbsp;<span className=''>View configs</span>
                        </div>
                        <div className='ms-6'></div>
                        <div className='d-flex d-none d-lg-block d-md-block'>
                          <Form.Check
                            type='switch'
                            id='jiraSwitch'
                            label=''
                            checked={jiraEnabled}
                            onChange={handleJiraToggle}
                          />
                        </div>
                        <div className='ms-6'></div>
                        {reconnectJira ? (
                          <Button
                            variant='warning'
                            className='btn-orange py-3'
                            onClick={onJiraClick}
                          >
                            {formatMessage({ id: 'Reconnect' })}
                          </Button>
                        ) : (
                          <Button
                            variant='warning'
                            className='btn-orange py-3'
                            onClick={() => {
                              setDeleteIntegrationData(jiraIntegrationData);
                              setDeleteShowModal(true);
                            }}
                          >
                            {formatMessage({ id: 'Disconnect' })}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className='d-flex align-items-md-center mt-6  justify-content-between'>
                  <div className='col-md-3 col mt-2 mt-md-0'>
                    <img src={trello} alt='webhook' height={26} />
                    &nbsp;<span className=''>Trello</span>
                  </div>
                  <div className='col-md-9  ps-0 d-flex align-items-center'>
                    {!trelloIntegrationData ? (
                      <div className='col-9 ps-0'>
                        <GlobalButton
                          buttonText={formatMessage({
                            id: 'Connect',
                          })}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={() => {
                            const trelloConfig = OAuthConfig.TRELLO;

                            const { BASE_URL, EXPIRATION, SCOPE, RESPONSE_TYPE, KEY, RETURNURL } =
                              trelloConfig;
                            const state = `${IntegrationPlatform.TRELLO}:${projectId}`;
                            const url = generateTrelloOAuthURL(
                              BASE_URL,
                              EXPIRATION,
                              SCOPE,
                              RESPONSE_TYPE,
                              KEY,
                              state,
                              process.env.REACT_APP_FRONTEND_BASE_URL + RETURNURL
                            );
                            window.location.href = url;
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <div className='mx-2 badge badge-pill badge-integration d-none d-lg-block d-md-block'>
                          {formatMessage({
                            id: 'Connected',
                          })}
                        </div>
                        <div className='ms-6 d-none d-lg-block d-md-block'></div>
                        <div
                          className='cursor-pointer ms-2 ms-md-0'
                          onClick={() => {
                            setIntegrationType(IntegrationPlatform.TRELLO);
                            setOpenTrelloOauthConfigModal(true);
                          }}
                        >
                          <img src={viewConfig} alt='view config' />
                          &nbsp;<span className=''>View configs</span>
                        </div>
                        <div className='ms-6'></div>
                        <div className='d-flex d-none d-lg-block d-md-block'>
                          <Form.Check
                            type='switch'
                            id='trelloSwitch'
                            label=''
                            checked={trelloEnabled}
                            onChange={handleTrelloToggle}
                          />
                        </div>
                        <div className='ms-6 d-none d-lg-block d-md-block'></div>
                        <Button
                          variant='warning'
                          className='btn-orange py-3'
                          onClick={() => {
                            setDeleteIntegrationData(trelloIntegrationData);
                            setDeleteShowModal(true);
                          }}
                        >
                          {formatMessage({ id: 'Disconnect' })}
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                <div className='d-flex align-items-md-center mt-6 justify-content-between'>
                  <div className='col-md-3 col mt-2 mt-md-0'>
                    <img src={zapier} alt='zapier' height={26} />
                    &nbsp;<span className=''>{formatMessage({ id: 'Zapier' })}</span>
                  </div>
                  <div className='col-md-9  ps-0 d-flex align-items-center'>
                    {!zapierIntegrationData ? (
                      <div className='col-9 ps-0'>
                        <GlobalButton
                          buttonText={formatMessage({
                            id: 'Connect',
                          })}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={() => {
                            window.open(zapierConnect, '_blank');
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <div className='mx-2 badge badge-pill badge-integration d-none d-lg-block d-md-block'>
                          {formatMessage({
                            id: 'Connected',
                          })}
                        </div>
                        <div className='ms-6'></div>
                        <Button
                          variant='warning'
                          className='btn-orange py-3'
                          onClick={() => {
                            setDeleteIntegrationData(zapierIntegrationData);
                            setDeleteShowModal(true);
                          }}
                        >
                          {formatMessage({ id: 'Disconnect' })}
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                <div className='d-flex align-items-md-center mt-6 justify-content-between'>
                  <div className='mt-2 mt-md-0 col-md-3'>
                    <img src={youTrack} alt='youtrack' />
                    &nbsp;<span className=''>Youtrack Server (Build 2022)</span>
                  </div>
                  <div className='d-flex align-items-center col-md-9'>
                    {!youTrackIntegrationData ? (
                      <div className='col-9 ps-0'>
                        <GlobalButton
                          buttonText={formatMessage({
                            id: 'Connect',
                          })}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={() => {
                            setIntegrationType(IntegrationPlatform.YOUTRACK);
                            setOpenYoutrackIntegrationModal(true);
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <div className='mx-2 badge badge-pill badge-integration d-none d-lg-block d-md-block'>
                          {formatMessage({
                            id: 'Connected',
                          })}
                        </div>
                        <div className='ms-6 d-none d-lg-block d-md-block'></div>
                        <div
                          className='cursor-pointer ms-2 ms-md-0'
                          onClick={() => {
                            setIntegrationType(IntegrationPlatform.YOUTRACK);
                            setOpenYoutrackIntegrationModal(true);
                            setIsEdit(true);
                          }}
                        >
                          <img src={viewConfig} alt='view config' />
                          &nbsp;<span className=''>View configs</span>
                        </div>
                        <div className='ms-6 d-none d-lg-block d-md-block'></div>
                        <div className='d-flex d-none d-lg-block d-md-block'>
                          <Form.Check
                            type='switch'
                            id='youTrackSwitch'
                            label=''
                            checked={youTrackEnabled}
                            onChange={handleYouTrackToggle}
                          />
                        </div>
                        <div className='ms-6'></div>
                        <Button
                          variant='warning'
                          className='btn-orange py-3'
                          onClick={() => {
                            setDeleteIntegrationData(youTrackIntegrationData);
                            setDeleteShowModal(true);
                          }}
                        >
                          {formatMessage({ id: 'Disconnect' })}
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                <div className='d-flex align-items-md-center mt-6 justify-content-between'>
                  <div className='col-md-3 col mt-2 mt-md-0'>
                    <img src={github} alt='github' />
                    &nbsp;<span className=''>Github</span>
                  </div>
                  <div className='col-md-9  ps-0 d-flex align-items-center'>
                    {!githubIntegrationData ? (
                      <div className='col-9 ps-0'>
                        <GlobalButton
                          buttonText={formatMessage({
                            id: 'Connect',
                          })}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={() => {
                            const githubConfig = OAuthConfig.GITHUB;

                            const { CLIENTID, REDIRECTURI, SCOPE, GITHUB_OAUTH_BASE_URL } =
                              githubConfig;
                            const state = `${IntegrationPlatform.GITHUB}:${projectId}`;
                            const url = generateOAuthUrl(
                              GITHUB_OAUTH_BASE_URL,
                              CLIENTID,
                              process.env.REACT_APP_FRONTEND_BASE_URL + REDIRECTURI,
                              SCOPE,
                              state
                            );

                            window.location.href = url;
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <div className='mx-2 badge badge-pill badge-integration d-none d-lg-block d-md-block'>
                          {formatMessage({
                            id: 'Connected',
                          })}
                        </div>
                        <div className='ms-6'></div>
                        <div
                          className=''
                          onClick={() => {
                            setIntegrationType(IntegrationPlatform.GITHUB);
                            setOpenGithubOauthConfigModal(true);
                          }}
                        >
                          <img src={viewConfig} alt='view config' />
                          &nbsp;<span className=''>View configs</span>
                        </div>
                        <div className='ms-6'></div>
                        <div className='d-flex d-none d-lg-block d-md-block'>
                          <Form.Check
                            type='switch'
                            id='githubSwitch'
                            label=''
                            checked={githubEnabled}
                            onChange={handleGithubToggle}
                          />
                        </div>
                        <div className='ms-6'></div>
                        <Button
                          variant='warning'
                          className='btn-orange py-3'
                          onClick={() => {
                            setDeleteIntegrationData(githubIntegrationData);
                            setDeleteShowModal(true);
                          }}
                        >
                          {formatMessage({ id: 'Disconnect' })}
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                <div className='d-flex align-items-md-center mt-6  justify-content-between'>
                  <div className='col-md-3 col mt-2 mt-md-0'>
                    <img src={webhook} alt='webhook' />
                    &nbsp;<span className=''>Webhook</span>
                  </div>
                  <div className='col-md-9  ps-0 d-flex align-items-center'>
                    {!webHooksIntegrationData ? (
                      <div className='col-9 ps-0'>
                        <GlobalButton
                          buttonText={formatMessage({
                            id: 'Connect',
                          })}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={() => {
                            setIntegrationType(IntegrationPlatform.WEBHOOK);
                            setOpenWebhookIntegrationModal(true);
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <div className='mx-2 badge badge-pill badge-integration d-none d-lg-block d-md-block'>
                          {formatMessage({
                            id: 'Connected',
                          })}
                        </div>
                        <div className='ms-6 d-none d-lg-block d-md-block'></div>
                        <div
                          className='cursor-pointer ms-2 ms-md-0'
                          onClick={() => {
                            setIntegrationType(IntegrationPlatform.WEBHOOK);
                            setOpenWebhookIntegrationModal(true);
                          }}
                        >
                          <img src={viewConfig} alt='view config' />
                          &nbsp;<span className=''>View configs</span>
                        </div>
                        <div className='ms-6'></div>
                        <div className='d-flex d-none d-lg-block d-md-block'>
                          <Form.Check
                            type='switch'
                            id='webhookSwitch'
                            label=''
                            checked={webhooksEnabled}
                            onChange={handleWebhooksToggle}
                          />
                        </div>
                        <div className='ms-6 d-none d-lg-block d-md-block'></div>
                        <Button
                          variant='warning'
                          className='btn-orange py-3'
                          onClick={() => {
                            setDeleteIntegrationData(webHooksIntegrationData);
                            setDeleteShowModal(true);
                          }}
                        >
                          {formatMessage({ id: 'Disconnect' })}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className='integration-card w-50'>
          <h4>Automation</h4>
          <p>
            Your central hub for efficient project management, streamlining your workflow and
            keeping you organized effortlessly.
          </p>
          <div>
            <div className='d-flex align-items-center row'>
              <div className='col-2'>
                <span className=''>Github</span>
              </div>
              <div className='col-9 ps-0'>
                {' '}
                <GlobalButton
                  buttonText={formatMessage({
                    id: 'Connect',
                  })}
                  buttonType={ButtonTypes.SECONDARY}
                  onButtonClick={() => { }}
                // isLoading={loading}
                />
              </div>
            </div>
            <div className='d-flex align-items-center mt-6 justify-content-between row'>
              <div className='col-4'>
                <span className=''>Bitbucket</span>
                <div className='mx-2 badge badge-pill badge-integration'>Connected</div>
              </div>
              <div className='col-8 d-flex align-items-center justify-content-between'>
                <div className=''>
                  <img src={viewConfig} alt='view config' />
                  &nbsp;<span className=''>View configs</span>
                </div>
                <div className='d-flex'>
                  <span>Disable</span> &nbsp;
                  <Form.Check
                    type='switch'
                    id='jiraSwitch'
                    label=''
                    checked={jiraEnabled}
                    onChange={handleJiraToggle}
                  />
                </div>
                <Button variant='warning' className='btn-orange py-3'>
                  Disconnect
                </Button>
              </div>
            </div>
            <div className='d-flex align-items-center mt-6 justify-content-between row'>
              <div className='col-4'>
                <span className=''>AutoDeploy</span>
                <div className='mx-2 badge badge-pill badge-integration'>Connected</div>
              </div>
              <div className='col-8 d-flex align-items-center justify-content-between'>
                <div className=''>
                  <img src={viewConfig} alt='view config' />
                  &nbsp;<span className=''>View configs</span>
                </div>
                <div className='d-flex'>
                  <span>Disable</span> &nbsp;
                  <Form.Check
                    type='switch'
                    id='jiraSwitch'
                    label=''
                    checked={jiraEnabled}
                    onChange={handleJiraToggle}
                  />
                </div>
                <Button variant='warning' className='btn-orange py-3'>
                  Disconnect
                </Button>
              </div>
            </div>

            <div className='d-flex align-items-center mt-6 justify-content-between row'>
              <div className='col-4'>
                <span className=''>Gitlab</span>
                <div className='mx-2 badge badge-pill badge-integration'>Connected</div>
              </div>
              <div className='col-8 d-flex align-items-center justify-content-between'>
                <div className=''>
                  <img src={viewConfig} alt='view config' />
                  &nbsp;<span className=''>View configs</span>
                </div>
                <div className='d-flex'>
                  <span>Disable</span> &nbsp;
                  <Form.Check
                    type='switch'
                    id='jiraSwitch'
                    label=''
                    checked={jiraEnabled}
                    onChange={handleJiraToggle}
                  />
                </div>
                <Button variant='warning' className='btn-orange py-3'>
                  Disconnect
                </Button>
              </div>
            </div>
            <div className='d-flex align-items-center mt-6 justify-content-between row'>
              <div className='col-4'>
                <span className=''>Jenkins</span>
                <div className='mx-2 badge badge-pill badge-integration'>Connected</div>
              </div>
              <div className='col-8 d-flex align-items-center justify-content-between'>
                <div className=''>
                  <img src={viewConfig} alt='view config' />
                  &nbsp;<span className=''>View configs</span>
                </div>
                <div className='d-flex'>
                  <span>Disable</span> &nbsp;
                  <Form.Check
                    type='switch'
                    id='jiraSwitch'
                    label=''
                    checked={jiraEnabled}
                    onChange={handleJiraToggle}
                  />
                </div>
                <Button variant='warning' className='btn-orange py-3'>
                  Disconnect
                </Button>
              </div>
            </div>
          </div>
        </div> */}

        <YoutrackIntegrationModel
          openYoutrackIntegrationModal={openYoutrackIntegrationModal}
          setOpenYoutrackIntegrationModal={setOpenYoutrackIntegrationModal}
          handleIntegrateProject={handleIntegrateProject}
          data={youTrackIntegrationData}
          integrationLoading={integrationLoading}
          isEdit={isEdit}
        />

        <WebhookIntegrationModel
          openWebhookIntegrationModal={openWebhookIntegrationModal}
          setOpenWebhookIntegrationModal={setOpenWebhookIntegrationModal}
          handleIntegrateProject={handleIntegrateProject}
          data={webHooksIntegrationData}
          loading={integrationLoading}
        />

        <JiraIntegrationModel data={jiraIntegrationData} handleJiraToggle={handleJiraToggle} />
        <GithubIntegrationModel
          data={githubIntegrationData}
          handleGithubToggle={handleGithubToggle}
        />

        <TrelloIntegrationModel
          data={trelloIntegrationData}
          handleTrelloToggle={handleTrelloToggle}
        />

        <ConfirmationModal
          showConfirmationModal={showDeleteModal}
          setShowConfirmationModal={setDeleteShowModal}
          headingMessage={formatMessage({ id: 'Delete Integration' })}
          description={formatMessage({
            id: 'Are you sure you want to delete this integration permanently?',
          })}
          handleDelete={() => handleDisconnectIntegration(deleteIntegrationData)}
          deleteLoading={integrationLoading}
        />
      </div>
    </>
  );
};
