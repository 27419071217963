import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../core/helpers';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { useLogout } from '../../../hooks/useLogout';

export const VerifyRegistration = () => {
  const { verifyRegister } = useAuthRequests();
  const { registerToken } = useParams();
  const { getCurrentUser, setUserToken, auth } = useGlobalContext();
  const { logout } = useLogout();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const verifyRegistration = async (registerToken: any) => {
      try {
        const {
          data: { data: token, success, data, errors },
        } = await verifyRegister(registerToken);
        if (success) {
          await getCurrentUser(token);
          setUserToken(data.accessToken);
          navigate('/onboarding/');
        } else {
          await getCurrentUser(undefined);
          navigate('/');
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (registerToken) {
      if (auth?.token) {
        logout();
        document.location.reload();
      } else {
        verifyRegistration(registerToken);
      }
    } else {
      console.log('token is not rendering');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
