import { useDateFormat } from '../hooks/useDateFormat';
import { useTimeFormat } from '../hooks/useTimeFormat';
import { DateTime } from 'luxon';
export function formatDate(isoDateString: any) {
  if (!isoDateString) return;
  const { getDateValue } = useDateFormat();
  const { getTimeValue } = useTimeFormat();
  const formattedDate =
    (getDateValue(isoDateString.toString())
      ? getDateValue(isoDateString.toString())
      : DateTime.fromISO(isoDateString!).toFormat('dd MMM, yyyy')) +
    ' ' +
    (getTimeValue(isoDateString.toString())
      ? getTimeValue(isoDateString.toString())
      : DateTime.fromISO(isoDateString!).toFormat('H:m:s'));
  return formattedDate;
}
