import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { ButtonTypes } from '@/enums';
import { GlobalButton } from './UI/GlobalButton';

export const ConfirmationModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  headingMessage,
  description,
  handleDelete,
  deleteLoading,
  id,
  formik,
}: {
  showConfirmationModal: boolean;
  setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
  headingMessage: string;
  description: string;
  handleDelete?: any;
  deleteLoading?: boolean;
  id?: number | null;
  formik?: any;
  companyFolderContentId?: any;
  activeContentId?: any;
}) => {
  const { formatMessage } = useIntl();
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={ref} className='delete-modal-z-index'>
        <Modal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          container={ref.current}
        >
          <Modal.Header closeButton className='border-bottom-0 pb-3'>
            <h2 className='text-black'>{formatMessage({ id: headingMessage })}</h2>
          </Modal.Header>
          <Modal.Body className='py-1 font-size-14 text-black'>
            <p>
              {formatMessage({
                id: description,
              })}
            </p>
          </Modal.Body>
          <Modal.Footer className='pt-0 border-top-0'>
            <div className='w-100 d-flex justify-content-end gap-3'>
              <GlobalButton
                buttonText={formatMessage({ id: 'Cancel' })}
                buttonType={ButtonTypes.SECONDARY}
                onButtonClick={() => setShowConfirmationModal(false)}
              />
              <GlobalButton
                buttonText={formatMessage({ id: 'Delete' })}
                buttonType={ButtonTypes.PRIMARY}
                onButtonClick={() => handleDelete(id)}
                isLoading={deleteLoading}
                isDisable={deleteLoading}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
