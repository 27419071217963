export enum CardUpdateForm {
  CARD = 'card',
  ADDRESS = 'address',
}

export enum Currency {
  INR = 'inr',
  USD = 'usd',
}

export enum PlanType {
  FREE = 'free',
  PRO = 'pro',
  BUSINESS = 'business',
  ENTERPRISE = 'enterprise',
  STARTUP = 'startup',
}

export enum PlanCycle {
  MONTHLY = 'month',
  YEARLY = 'year',
}
