import { ButtonTypes } from '@/enums';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useGlobalContext } from '../../../core/helpers';
import '../../onboarding/styles/onboarding.scss';
import { GlobalButton } from './UI/GlobalButton';
import { InputToolTip } from './UI/InputToolTip';
import { CurrentPlan } from '@/types/billing';
import { Currency, PlanCycle, PlanType } from '@/enums/billing';

export const BillingModal = ({
  showBillingModal,
  setShowBillingModal,
  active,
  setActive,
  currentPlan,
  setCurrentPlan,
  setPriceId,
  clickedPlan,
  setClickedPlan,
  onContinue,
}: {
  showBillingModal: boolean;
  setShowBillingModal: Dispatch<SetStateAction<boolean>>;
  active: PlanCycle;
  setActive: Dispatch<SetStateAction<PlanCycle>>;
  currentPlan: CurrentPlan;
  setCurrentPlan?: Dispatch<SetStateAction<CurrentPlan>>;
  setPriceId?: Dispatch<SetStateAction<string | undefined>>;
  clickedPlan?: CurrentPlan;
  setClickedPlan: Dispatch<SetStateAction<CurrentPlan | undefined>>;
  onContinue?: (priceId: string) => void;
}) => {
  const [featureList, setFeatureList] = useState<any>([]);
  const [isContinueClicked, setIsContinueClicked] = useState<boolean>(false);
  const [clicked, setClickedId] = useState<string | null>(null);
  const desiredOrder = ['Free', 'Pro', 'Business'];
  const { plans, setPlansCurrencyCode } = useGlobalContext();

  useEffect(() => {
    if (plans) {
      setPlansCurrencyCode({
        currencyCode: plans[0]?.currency_code as Currency,
        isCurrencyChanged: true,
      });
    }
  }, [showBillingModal, plans]);

  useEffect(() => {
    if (showBillingModal && currentPlan) {
      setIsContinueClicked(false);
      setActive(currentPlan?.period_unit as PlanCycle);
    }
  }, [showBillingModal]);

  const previousSelectedRef = useRef(currentPlan?.title);
  useEffect(() => {
    previousSelectedRef.current = currentPlan?.title;
  }, [currentPlan?.title]);

  const previousactiveRef = useRef<any>(active);
  useEffect(() => {
    previousactiveRef.current = active;
  }, [active]);

  const { formatMessage } = useIntl();
  const location = useLocation();
  useEffect(() => {
    if (plans) {
      if (active === PlanCycle.YEARLY) {
        const selectedFeaturelist = plans.filter(
          (item: any) => item.period_unit === PlanCycle.YEARLY
        );
        selectedFeaturelist.sort((a: any, b: any) => {
          return desiredOrder.indexOf(a.title) - desiredOrder.indexOf(b.title);
        });
        setFeatureList(selectedFeaturelist);
        handlePlanChange(selectedFeaturelist);
      }
      if (active === PlanCycle.MONTHLY) {
        const selectedFeaturelist = plans.filter(
          (item: any) => item.period_unit === PlanCycle.MONTHLY
        );
        selectedFeaturelist.sort((a: any, b: any) => {
          return desiredOrder.indexOf(a.title) - desiredOrder.indexOf(b.title);
        });
        setFeatureList(selectedFeaturelist);
        handlePlanChange(selectedFeaturelist);
      }
    }
  }, [active, plans]);

  const setPropeties = () => {
    const selectedBenfits = featureList?.find(
      (features: any) => features?.price_id === clickedPlan?.price_id
    );
    if (setCurrentPlan) {
      setCurrentPlan(selectedBenfits);
    }

    if (onContinue && clickedPlan) {
      onContinue(clickedPlan.price_id);
    }
  };

  function handlePlanChange(selectedFeaturelist: any) {
    const selectedCard1 = selectedFeaturelist?.filter(
      (features: any) => features?.title === currentPlan?.title
    );
    if (selectedCard1) {
      setClickedId(selectedCard1[0]?.price_id);
      if (setPriceId) {
        setPriceId(selectedCard1[0]?.price_id);
      }
    }
  }

  const handlePlanCycle = (planCycle: PlanCycle) => {
    setActive(planCycle);
    let plan;

    if (clickedPlan) {
      plan = plans.find((item) => item.id === clickedPlan?.id && item.period_unit === planCycle);
    } else {
      plan = plans.find((item) => item.period_unit === planCycle && currentPlan?.id === item.id);
    }
    if (setPriceId) {
      setPriceId(plan?.price_id);
    }
    setClickedPlan(plan);
  };

  return (
    <>
      <Modal
        size='xl'
        show={showBillingModal}
        onHide={() => {
          setShowBillingModal(false);
          if (!isContinueClicked && location.pathname.includes('/onboarding')) {
            setActive(currentPlan?.period_unit as PlanCycle);
            if (setCurrentPlan) {
              setCurrentPlan(currentPlan as CurrentPlan);
            }
          }
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header className='border-bottom-0 px-8 py-4'>
          <div className='fw-bold d-flex flex-column flex-lg-row justify-content-between align-items-center w-100'>
            <div className='d-flex flex-row'>
              <h2 className='font-size-16 text-black mb-3 mb-lg-0'>
                {formatMessage({ id: 'Upgrade your plan' })}
              </h2>{' '}
              <InputToolTip
                tooltipText={formatMessage({
                  id: 'Upgrade your plan to unlock premium features and elevate your experience with enhanced benefits.',
                })}
              />
            </div>
            <div className='d-flex flex-column flex-md-row mx-auto align-items-center col-lg-7 col-xl-5'>
              <div className='d-flex main-block'>
                <div
                  className={`${
                    active === PlanCycle.MONTHLY ? 'active-button' : 'inactive-button'
                  } d-flex justify-content-center time-period`}
                  onClick={() => {
                    handlePlanCycle(PlanCycle.MONTHLY);
                  }}
                >
                  Monthly
                </div>
                <div
                  className={`${
                    active === PlanCycle.YEARLY ? 'active-button' : 'inactive-button'
                  } d-flex justify-content-center time-period`}
                  onClick={() => {
                    handlePlanCycle(PlanCycle.YEARLY);
                  }}
                >
                  <div className='d-flex flex-column'>
                    Yearly
                    <small className='font-size-12 fw-normal'>Save up to 30%</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='py-2'>
          <div className='pb-4'>
            <div className='row' id='onboarding'>
              {featureList?.length > 0 &&
                featureList.map((featureItem: any, index: number) => {
                  if (featureItem.title.toLowerCase() === PlanType.FREE) {
                    return (
                      <div key={index} className={`col-12 col-lg-4 col-xl-4 mb-3 my-lg-4 `}>
                        <div
                          className={`card price-card-height ${
                            featureItem?.title === clickedPlan?.title &&
                            clickedPlan?.period_unit === featureItem.period_unit
                              ? 'selected-card'
                              : 'border border-2'
                          }`}
                        >
                          <div
                            className={`card-body card-on-zoom d-flex flex-column justify-content-between`}
                          >
                            <div>
                              <div className='card-title'>
                                <div className='d-flex flex-row justify-content-between'>
                                  <div
                                    className={`price-header ${
                                      featureItem?.title === clickedPlan?.title &&
                                      clickedPlan?.period_unit === featureItem.period_unit &&
                                      'text-white'
                                    }`}
                                  >
                                    {featureItem?.title}
                                    {featureItem.mostPopular && (
                                      <div
                                        className={`ms-2 most-popular fw-normal p-2 px-2 mt-2 badge rounded-pill text-bg-success margin-top20 bg-pill text-white`}
                                      >
                                        Most Popular
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='price mt-1'>
                                  <span
                                    className={`align-top price-text ${
                                      featureItem?.title === clickedPlan?.title &&
                                      clickedPlan?.period_unit === featureItem.period_unit &&
                                      'text-white'
                                    }`}
                                  >
                                    $
                                  </span>
                                  <span className='price-text'>
                                    <span
                                      className={`price-text ${
                                        featureItem?.title === clickedPlan?.title &&
                                        clickedPlan?.period_unit === featureItem.period_unit &&
                                        'text-white'
                                      }`}
                                    >
                                      {featureItem.period_unit === PlanCycle.YEARLY ? (
                                        <span className='price-text'>
                                          {Math.ceil(featureItem.price / 100 / 12)}
                                        </span>
                                      ) : (
                                        <span className='price-text'>
                                          {Math.ceil(featureItem.price / 100)}
                                        </span>
                                      )}
                                    </span>
                                  </span>
                                  <span
                                    className={`after-text font-size-13 font-weight-500 ${
                                      featureItem?.title === clickedPlan?.title &&
                                      clickedPlan?.period_unit === featureItem.period_unit &&
                                      'text-white'
                                    }`}
                                  >
                                    {' '}
                                    {formatMessage({ id: '/month' })}
                                  </span>
                                  <span
                                    className={`ms-1 font-size-13 font-weight-500 ${
                                      featureItem?.title === clickedPlan?.title &&
                                      clickedPlan?.period_unit === featureItem.period_unit &&
                                      'text-white'
                                    }`}
                                  >
                                    {featureItem.period_unit === PlanCycle.YEARLY
                                      ? formatMessage({
                                          id: '(billed yearly)',
                                        })
                                      : null}
                                  </span>
                                </div>
                              </div>
                              <div className='mb-7'>
                                <ul className='feature mt-1'>
                                  {featureItem?.features?.map(
                                    (feature: any, featureIndex: number) => (
                                      <li
                                        key={featureIndex}
                                        className={`my-2 ${
                                          featureItem?.title === clickedPlan?.title &&
                                          clickedPlan?.period_unit === featureItem.period_unit &&
                                          'text-white'
                                        }`}
                                      >
                                        {feature?.title}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                setClickedId(featureItem?.price_id);
                                setClickedPlan(featureItem);
                                if (setPriceId) {
                                  setPriceId(featureItem?.price_id);
                                }
                              }}
                              className={`col-12 btn trial-btn text-center`}
                            >
                              {featureItem?.title === clickedPlan?.title &&
                              featureItem.period_unit === clickedPlan?.period_unit ? (
                                <span className='mt-3'>
                                  {formatMessage({
                                    id: 'Selected plan',
                                  })}
                                </span>
                              ) : (
                                <span>
                                  {formatMessage({
                                    id: 'Select plan',
                                  })}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (featureItem.title.toLowerCase() !== PlanType.FREE) {
                    return (
                      <div key={index} className={`col-12 col-lg-4 col-xl-4 mb-3 my-lg-4 `}>
                        <div
                          className={`card price-card-height ${
                            featureItem?.title === clickedPlan?.title &&
                            clickedPlan?.period_unit === featureItem.period_unit
                              ? 'selected-card'
                              : 'border border-2'
                          }`}
                        >
                          <div
                            className={`card-body card-on-zoom d-flex flex-column justify-content-between`}
                          >
                            <div>
                              <div className='card-title'>
                                <div className='d-flex flex-row justify-content-between'>
                                  <div
                                    className={`price-header ${
                                      featureItem?.title === clickedPlan?.title &&
                                      clickedPlan?.period_unit === featureItem.period_unit &&
                                      'text-white'
                                    }`}
                                  >
                                    {featureItem?.title}
                                    {featureItem.mostPopular && (
                                      <div
                                        className={`ms-2 most-popular fw-normal p-2 px-2 mt-2 badge rounded-pill text-bg-success margin-top20 bg-pill text-white`}
                                      >
                                        Most Popular
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='price mt-1'>
                                  <span
                                    className={`align-top price-text ${
                                      featureItem?.title === clickedPlan?.title &&
                                      clickedPlan?.period_unit === featureItem.period_unit &&
                                      'text-white'
                                    }`}
                                  >
                                    $
                                  </span>
                                  <span className='price-text'>
                                    <span
                                      className={`price-text ${
                                        featureItem?.title === clickedPlan?.title &&
                                        clickedPlan?.period_unit === featureItem.period_unit &&
                                        'text-white'
                                      }`}
                                    >
                                      {featureItem.period_unit === PlanCycle.YEARLY ? (
                                        <span className='price-text'>
                                          {Math.ceil(featureItem.price / 100 / 12)}
                                        </span>
                                      ) : (
                                        <span className='price-text'>
                                          {Math.ceil(featureItem.price / 100)}
                                        </span>
                                      )}
                                    </span>
                                  </span>
                                  <span
                                    className={`after-text font-size-13 font-weight-500 ${
                                      featureItem?.title === clickedPlan?.title &&
                                      clickedPlan?.period_unit === featureItem.period_unit &&
                                      'text-white'
                                    }`}
                                  >
                                    {' '}
                                    {formatMessage({ id: '/month' })}
                                  </span>
                                  <span
                                    className={`ms-1 font-size-13 font-weight-500 ${
                                      featureItem?.title === clickedPlan?.title &&
                                      clickedPlan?.period_unit === featureItem.period_unit &&
                                      'text-white'
                                    }`}
                                  >
                                    {featureItem.period_unit === PlanCycle.YEARLY
                                      ? formatMessage({
                                          id: '(billed yearly)',
                                        })
                                      : null}
                                  </span>
                                </div>
                              </div>
                              <div className='mb-7'>
                                <ul className='feature mt-1'>
                                  {featureItem?.features?.map(
                                    (feature: any, featureIndex: number) => (
                                      <li
                                        key={featureIndex}
                                        className={`my-2 ${
                                          featureItem?.title === clickedPlan?.title &&
                                          clickedPlan?.period_unit === featureItem.period_unit &&
                                          'text-white'
                                        }`}
                                      >
                                        {feature?.title}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                setClickedId(featureItem?.price_id);
                                setClickedPlan(featureItem);
                                if (setPriceId) {
                                  setPriceId(featureItem?.price_id);
                                }
                              }}
                              className={`col-12 btn trial-btn text-center`}
                            >
                              {featureItem?.title === clickedPlan?.title &&
                              featureItem.period_unit === clickedPlan?.period_unit ? (
                                <span className='mt-3'>
                                  {formatMessage({
                                    id: 'Selected plan',
                                  })}
                                </span>
                              ) : (
                                <span>
                                  {formatMessage({
                                    id: 'Select plan',
                                  })}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100 d-flex justify-content-end gap-6'>
            <GlobalButton
              buttonText={formatMessage({ id: 'Cancel' })}
              buttonType={ButtonTypes.SECONDARY}
              onButtonClick={() => {
                setShowBillingModal(false);
                setClickedPlan(currentPlan);

                if (setCurrentPlan) {
                  setCurrentPlan(currentPlan);
                }
                if (!isContinueClicked) {
                  setActive(currentPlan?.period_unit as PlanCycle);
                }
              }}
            />

            <GlobalButton
              buttonText={formatMessage({ id: 'Continue' })}
              buttonType={ButtonTypes.PRIMARY}
              onButtonClick={() => {
                setPropeties();
                setShowBillingModal(false);
                setIsContinueClicked(true);
              }}
              isDisable={clicked === null}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
