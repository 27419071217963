import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Task } from '@/types/projects';
import { Droppable } from 'react-beautiful-dnd';
import plusIcon from '../../../../core/assets/images/svg/bugs.work/plus-icon.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CreateTaskPosition, TaskStatus } from '@/enums/projects';
import { v4 as uuid } from 'uuid';
import { TaskCard } from './TaskCard';
import Skeleton from 'react-loading-skeleton';

export const BoardColumn = ({
  status,
  searchValue,
  selectedUserIds,
  setCreatePosition,
  setCreateTaskModal,
  setActiveColumn,
  setIsTaskEdit,
  setTaskCode,
  tasks,
  totalCount,
  getTasks,
  loading,
}: {
  status: TaskStatus;
  projectId: number;
  searchValue: string;
  selectedUserIds: number[];
  setCreatePosition: Dispatch<SetStateAction<CreateTaskPosition>>;
  setCreateTaskModal: Dispatch<SetStateAction<boolean>>;
  setIsTaskEdit: Dispatch<SetStateAction<boolean>>;
  setActiveColumn: Dispatch<SetStateAction<TaskStatus>>;
  setTaskCode: Dispatch<SetStateAction<string | undefined>>;
  tasks: Task[];
  totalCount: number;
  getTasks: (pageNumber: number) => void;
  loading: boolean;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const scrollableId = useRef<string>(uuid());
  const didMount = useRef<boolean>(false);

  const handleAddTask = (options: { position: CreateTaskPosition }) => {
    setActiveColumn(status);
    setCreateTaskModal(true);
    setCreatePosition(options.position);
  };

  const handleOpenTask = (task: Task) => {
    setIsTaskEdit(true);
    setCreateTaskModal(true);
    setActiveColumn(status);
    setTaskCode(task?.taskCode);
  };

  const loadFunc = () => {
    if (tasks.length < totalCount) {
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    getTasks(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (didMount.current) {
      setPageNumber((prevPageNumber: number) => {
        if (prevPageNumber === 1) getTasks(prevPageNumber);
        return 1;
      });
    }
    didMount.current = true;
  }, [searchValue, selectedUserIds]);

  return (
    <>
      <Droppable droppableId={status} key={status} direction='vertical'>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            key={status}
            id={scrollableId.current}
            className='column-height'
          >
            <div className='column-width font-size-14 font-weight-500 px-5 pt-4 white-color'>
              <div className='d-flex flex-row justify-content-between'>
                {status} ({totalCount})
                <img
                  onClick={() => handleAddTask({ position: CreateTaskPosition.START })}
                  src={plusIcon}
                  alt='add-task'
                  className='cursor-pointer'
                />
              </div>
            </div>
            <div className='column-width pt-2 pb-5'>
              {loading ? (
                <div className='mx-5 mb-1'>
                  <Skeleton height={120} count={3} style={{ marginBottom: '6px' }} />
                </div>
              ) : (
                <InfiniteScroll
                  style={{ overflow: 'unset' }}
                  dataLength={tasks.length}
                  next={loadFunc}
                  hasMore={tasks.length < totalCount}
                  loader={<div className='' key={0}></div>}
                  scrollableTarget={scrollableId.current}
                >
                  {tasks.map((task: Task, index: number) => (
                    <TaskCard
                      key={index}
                      task={task}
                      index={index}
                      handleOpenTask={handleOpenTask}
                    />
                  ))}
                </InfiniteScroll>
              )}

              {tasks.length < totalCount && (
                <p className='text-underline ps-5 text-center cursor-pointer' onClick={loadFunc}>
                  Load more
                </p>
              )}
              <div
                className='add-task-button ms-5 font-weight-500 cursor-pointer white-color'
                onClick={() => {
                  handleAddTask({
                    position: CreateTaskPosition.END,
                  });
                }}
              >
                <img src={plusIcon} alt='plus-icon' /> New task...
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};
