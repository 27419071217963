import React from 'react';
import { Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../core/helpers';

export const Spinner = ({
  inline = false,
  length = 1,
  pageSpinner = false,
}: {
  inline?: boolean;
  length?: number;
  pageSpinner?: boolean;
}) => {
  const { pathname } = useLocation();

  return (
    <>
      {inline ? (
        <div
          className={`spinner-border d-center`}
          style={{ width: `${length}rem`, height: `${length}rem` }}
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      ) : (
        <div className={`spinner-wrapper ${pageSpinner && 'page-spinner'}`}>
          <div
            className={`spinner-border d-center`}
            style={{ width: '4rem', height: '4rem' }}
            role='status'
          >
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};
