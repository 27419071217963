import { useState } from 'react';
import { useIntl } from 'react-intl';
import lens from '../../../../core/assets/images/svg/bugs.work/lens.svg';
import ellipse from '../../../../core/assets/images/svg/bugs.work/ellipse.svg';
import Vectorleft from '../../../../core/assets/images/svg/bugs.work/Vectorleft.svg';
import Vectorright from '../../../../core/assets/images/svg/bugs.work/Vectorright.svg';
import type { ConsoleInfo as ConsoleInfoType } from '@/types/projects';
import { NoDataComponent } from '../../../shared/components/NoDataComponent';
import { LogLevel } from '@/enums/projects';

const logLevelsArray: LogLevel[] = [
  LogLevel.ALL,
  LogLevel.VERBOSE,
  LogLevel.ERROR,
  LogLevel.INFO,
  LogLevel.WARNING,
  LogLevel.DEBUG,
  LogLevel.LOG,
];

const logLevelColors: Record<LogLevel, string> = {
  [LogLevel.VERBOSE]: 'gray',
  [LogLevel.ERROR]: 'red',
  [LogLevel.INFO]: 'blue',
  [LogLevel.WARNING]: 'yellow',
  [LogLevel.DEBUG]: 'green',
  [LogLevel.LOG]: 'black',
  [LogLevel.ALL]: 'black',
};

const ConsoleInfo = ({ consoleInfo }: { consoleInfo: string | null }) => {
  let consoleData: ConsoleInfoType[] = [];
  if (consoleInfo) {
    consoleData = JSON.parse(consoleInfo);
  }

  const { formatMessage } = useIntl();
  const [selectedLogLevel, setSelectedLogLevel] = useState<string>(LogLevel.ALL);
  const [filterText, setFilterText] = useState('');

  const handleLogLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLogLevel(e.target.value);
  };

  const filterConsoleData = (item: ConsoleInfoType) => {
    const levelFilter = selectedLogLevel === LogLevel.ALL || item.type === selectedLogLevel;
    const textFilter = item.data.toLowerCase().includes(filterText.toLowerCase());

    return levelFilter && textFilter;
  };

  const filteredConsoleData = consoleData.filter(filterConsoleData);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };

  const logLevelsWithData = new Set(filteredConsoleData.map((item) => item.type));
  const numLogLevelsWithData = logLevelsWithData.size;

  return (
    <div className='m-4'>
      {consoleData.length > 0  && (
        <div className='row'>
          <div className='col-12 col-md-6 mb-3 mb-md-0'>
            <div className='search-container'>
              <img src={lens} alt='lens' className='search-icon me-5 ' />
              <input
                value={filterText}
                onChange={handleFilterChange}
                className='public-search-rensponsive py-5 w-100'
                placeholder='Seach Console'
              />
            </div>
          </div>
          <div className='col-md-6 col-lg-6'>
            <div className='text-end'>
              <select
                value={selectedLogLevel}
                onChange={handleLogLevelChange}
                className='form-select form-select-solid form-select-lg'
              >
                {logLevelsArray.map((level) => (
                  <option key={level} value={level}>
                    {level === LogLevel.ALL
                      ? formatMessage({ id: 'All Levels' })
                      : formatMessage({
                          id: `logLevels.${level}`,
                          defaultMessage: level.charAt(0).toUpperCase() + level.slice(1),
                        })}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {filteredConsoleData.length > 0 ? (
        filteredConsoleData.map((item, index) => (
          <div className='col-md-8 col-lg-8 col' key={index}>
            <div className='d-flex text-start '>
              <div className=' font-weight-500 font-size-12 me-2 text-dark'>
                {(item.timestamp / 1000).toFixed(2)}
              </div>
              <div className=''>
                <span
                  className='mx-5 mb-1 w-5px h-5px d-inline-block rounded-circle'
                  style={{
                    backgroundColor: logLevelColors[item.type as LogLevel] || 'black',
                  }}
                />
              </div>
              <div>
                <div className=' font-weight-500 font-size-12 me-2 ms-4 mb-4 text-dark'>
                  {item.data}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className='font-weight-500 font-size-14 text-center mb-2'>
            {formatMessage({
              id: 'No Console Data.',
            })}
          </div>
          <div className='font-weight-500 font-size-14 text-center'>
            {formatMessage({
              id: 'Console info is only captured for current tab recording via bugs.work widget.',
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ConsoleInfo;
