import { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { useGlobalContext } from '../../../core/helpers';
import SocialLoginButtons from '../components/SocialLoginButtons';
import { useIntl } from 'react-intl';
import { CustomToast } from '../../shared/components/UI/CustomToast';
import { useNavigate } from 'react-router-dom';
import { useUtmValidator } from '../../../hooks/useUtmValidator';
import { useFetchCountryAndTimeZone } from '../../../hooks/useFetchCountryAndTimeZone';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes, InputTypes } from '@/enums';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { BWInput } from '../../shared/components/Input/BWInput';
import MicrosoftLoginButton from '../components/MicrosoftLoginButton';

export function Registration() {
  const { acceptInvite, register } = useAuthRequests();
  const { utmValues, handleUtmEvents } = useUtmValidator();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const { getCurrentUser, setUserToken } = useGlobalContext();
  const [errorsArray, setErrorsArray] = useState<string[]>();
  const [isInvited, setIsInvited] = useState<boolean>(false);
  const { fetchCountryAndTimeZone } = useFetchCountryAndTimeZone();
  const navigate = useNavigate();

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    acceptTerms: false,
  };

  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, formatMessage({ id: 'Minimum 3 characters' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'First name is required' })),
    email: Yup.string()
      .email(formatMessage({ id: 'Invalid email format' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Email is required' })),
    lastname: Yup.string()
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Last name is required' })),
    password: Yup.string()
      .min(6, formatMessage({ id: 'Minimum 6 characters' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .matches(/\d/, formatMessage({ id: 'Password must contain at least one digit' }))
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        formatMessage({ id: 'Password must contain at least one special character' })
      )
      .matches(
        /[a-zA-Z]/,
        formatMessage({ id: 'Password must contain at least one alphabetic character' })
      )
      .required(formatMessage({ id: 'Password is required' })),
    acceptTerms: Yup.bool(),
  });

  const referral = new URL(window.location.href).searchParams.get('r') || null;

  const lsReferral = localStorage.getItem('referral');

  if (!lsReferral?.length) {
    localStorage.setItem('referral', referral || '');
  }

  const localStorageReferral = localStorage.getItem('referral') || '';

  useEffect(() => {
    handleUtmEvents();
  }, []);

  const onSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: any
  ) => {
    setLoading(true);
    try {
      const locationData = await fetchCountryAndTimeZone();
      const {
        data: { data, success, errors },
      } = await register(
        values.email,
        values.firstname,
        values.lastname,
        values.password,
        utmValues,
        localStorageReferral,
        'en_US',
        'MMM DD, YYYY',
        'hh:mm:ss a',
        locationData?.timeZone || 5.5,
        locationData?.countryId! || 103
      );

      if (success) {
        setSubmitting(false);
        setLoading(false);
        resetForm();
        navigate('/email-verification', { state: { email: values.email } });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const { state } = useLocation() as any;

  const handleAcceptInvite = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setLoading(true);

    try {
      const locationData = await fetchCountryAndTimeZone();
      const {
        data: { data: token, success, errors, data },
      } = await acceptInvite(
        values.firstname,
        values.lastname,
        state.inviteToken,
        values.password,
        'en_US',
        'MMM DD, YYYY',
        'hh:mm:ss a',
        locationData?.timeZone || 5.5,
        locationData?.countryId! || 103
      );
      if (success) {
        await getCurrentUser(token);
        setUserToken(data.accessToken);
        navigate('/', { state: { email: values.email } });
      } else {
        setErrorsArray(errors);
        await getCurrentUser(undefined);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registrationSchema}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        onSubmit(values, setSubmitting, resetForm);
      }}
      validateOnMount
    >
      {(formik) => {
        if (state) {
          initialValues.firstname = state.firstName;
          initialValues.lastname = state.lastName;
          initialValues.email = state.email;
        }
        return (
          <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework px-3 pe-0 ps-0'>
            <div className='text-center mb-6'>
              <h1 className='text-black fw-bolder mb-9 font-size-24'>
                {formatMessage({ id: 'Sign Up to bugs.work' })}
              </h1>
              <SocialLoginButtons localStorageReferral={localStorageReferral} />
            </div>
            <div className='mb-10'>
              <MicrosoftLoginButton />
            </div>
            <div className='text-center mb-24px fw-bold font-size-14 text-black'>
              {formatMessage({ id: 'OR' })}
            </div>

            {errorsArray?.map((error: string) => <CustomToast status={error} />)}

            <div className='row d-flex justify-content-between'>
              <div className='col-6 ps1'>
                <BWInput
                  fieldType={InputTypes.TEXT}
                  label={formatMessage({ id: 'First Name' })}
                  fieldName={'firstname'}
                  formik={formik}
                  placeholder={formatMessage({ id: 'Enter first name' })}
                  toolTipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.REGISTRATION.FIRST_NAME',
                  })}
                  isRequired={true}
                />
              </div>
              <div className='col-6'>
                <BWInput
                  fieldType={InputTypes.TEXT}
                  label={formatMessage({ id: 'Last Name' })}
                  fieldName={'lastname'}
                  formik={formik}
                  placeholder={formatMessage({ id: 'Enter last name' })}
                  toolTipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.REGISTRATION.LAST_NAME',
                  })}
                  isRequired={true}
                />
              </div>
            </div>

            {/* begin::Form group */}
            <BWInput
              fieldType={InputTypes.EMAIL}
              fieldName={'email'}
              formik={formik}
              placeholder={formatMessage({ id: 'Enter email' })}
              label={formatMessage({ id: 'Email' })}
              toolTipText={formatMessage({
                id: 'GLOBAL.TOOLTIP.REGISTRATION.EMAIL',
              })}
              isRequired={true}
            />
            {/* end::Form group */}

            {/* begin::Form group */}
            <BWInput
              fieldType={InputTypes.PASSWORD}
              fieldName={'password'}
              formik={formik}
              placeholder={formatMessage({ id: 'Password' })}
              label={formatMessage({ id: 'Password' })}
              toolTipText={formatMessage({
                id: 'GLOBAL.TOOLTIP.REGISTRATION.PASSWORD',
              })}
              isRequired={true}
            />
            {/* end::Form group */}
            <div className='text-center text-md-start'>
              <Field
                className='form-check-input terms mt-0 cursor-pointer'
                aria-describedby='tandc'
                type={InputTypes.CHECKBOX}
                name={'acceptTerms'}
                id={'tandc'}
                autoComplete='off'
                onChange={(e: any) => formik.handleChange(e)}
              />
              <label htmlFor={'tandc'} className='ms-3 font-size-14 text-black'>
                {formatMessage({ id: 'I agree to the' })}
                <>
                  <a
                    href={`https://bugs.work/terms-of-use/`}
                    target='_blank'
                    className='ms-1 fw-bold link-primary'
                  >
                    {formatMessage({ id: 'Terms of use' })}
                  </a>{' '}
                  &{' '}
                  <a
                    href={`https://bugs.work/privacy-policy/`}
                    target='_blank'
                    className='fw-bold link-primary'
                  >
                    {formatMessage({ id: 'Privacy policy' })}
                  </a>
                  .
                </>
              </label>
            </div>

            {/* begin::Form group */}
            {/* begin::Action */}
            <div className='d-flex align-items-center justify-content-between flex-column flex-md-row'>
              <div className='text-center fw-bold font-size-14 text-black'>
                {formatMessage({ id: 'Already have an account?' })}{' '}
                <Link to='/login/' className='link-primary'>
                  {formatMessage({ id: 'Sign In' })}
                </Link>
              </div>
              <div className='mt-3 mt-md-0'>
                <GlobalButton
                  buttonText={formatMessage({ id: 'Sign Up' })}
                  buttonType={ButtonTypes.PRIMARY}
                  isLoading={loading}
                  isDisable={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                  onButtonClick={formik.handleSubmit}
                />
              </div>
            </div>
            {/* end::Action */}
            {/* end::Form group */}
          </Form>
        );
      }}
    </Formik>
  );
}
