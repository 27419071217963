import { format } from 'date-fns';
import { useGlobalContext } from '../core/helpers';

export const useGetLocale = () => {
  const { userMeta } = useGlobalContext();
  function getLocaleDate(date?: number) {
    if (!userMeta?.dateFormat) {
      return new Date().toDateString();
    }
    return date
      ? format(
          new Date(date * 1000),
          (userMeta?.dateFormat as string).replace('DD', 'dd').replace('YYYY', 'yyyy')
        )
      : format(
          new Date(),
          (userMeta?.dateFormat as string).replace('DD', 'dd').replace('YYYY', 'yyyy')
        );
  }

  return { getLocaleDate };
};
