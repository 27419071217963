import axios from 'axios';
import { API_URL } from '.';
import { CardUpdateForm } from '@/enums/billing';

const BILLING_URL = `${API_URL}/billing`;
const GET_PLAN_URL = `${BILLING_URL}/plans`;
const PROCEED_TO_PAYMENT = `${BILLING_URL}/payment`;
const GET_CARD_DETAILS = `${BILLING_URL}/card`;
const PRO_RATED_PRICE = `${BILLING_URL}/proration`;
const UPDATE_CARD_DETAILS = `${BILLING_URL}/update-card`;
const COUPON_DISCOUNT = `${BILLING_URL}/coupon`;
const GET_IP_ADDRESS = `https://api.ipify.org/`;
const TRANSACTION_URL = `${BILLING_URL}/transactions`;
const CANCEL_SUBSCRIPTION = `${BILLING_URL}/subscription/cancel`;
const VALIDATE_CHECKOUT = `${BILLING_URL}/checkout-session/validate`;
const MANAGE_BILLING = `${BILLING_URL}/manage-billing`;
const CHECKOUT_SESSION = `${BILLING_URL}/checkout-session`;
const controller = new AbortController();

export const useBillingRequests = () => {
  const getPlans = () => {
    return axios.get(`${GET_PLAN_URL}`, {
      signal: controller.signal,
      validateStatus() {
        return true;
      },
    });
  };

  const getIpAddress = () => {
    return axios.get(`${GET_IP_ADDRESS}`, {
      headers: {
        Authorization: '',
      },
      validateStatus() {
        return true;
      },
    });
  };

  //Proceed to payment
  const postProceedToPayment = (priceId: string, currencyId: string, promoCode?: string) => {
    const payload = {
      priceId,
      currencyId,
      promoCode,
    };
    return axios.post(PROCEED_TO_PAYMENT, payload, {
      validateStatus() {
        return true;
      },
    });
  };

  const changeCard = (paymentToken: string) => {
    const payload = {
      paymentToken,
    };

    return axios.post(`${API_URL}/billing/create-setup-intent`, payload, {
      validateStatus() {
        return true;
      },
    });
  };

  const confirmCardChange = (setupIntentId: string) => {
    return axios.get(`${API_URL}/billing/confirm-card-change/${setupIntentId}`, {
      validateStatus() {
        return true;
      },
    });
  };

  //Proceed to payment if card is already there
  const postProceedToPaymentCard = (
    planName: string,
    priceId: string,
    currencyId: string,
    promoCode?: string
  ) => {
    const payload = {
      planName,
      priceId,
      currencyId,
      promoCode,
    };
    return axios.put(BILLING_URL, payload, {
      validateStatus() {
        return true;
      },
    });
  };

  //card details
  const getCardDetailsApi = () => {
    return axios.get(`${GET_CARD_DETAILS}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const proceedToProRated = (priceId: string, currencyId: string, couponCode?: string) => {
    return axios.get(
      `${PRO_RATED_PRICE}?priceId=${priceId}&currencyId=${currencyId}${
        couponCode ? `&promoCode=${couponCode}` : ''
      }`,
      {
        validateStatus() {
          return true;
        },
      }
    );
  };

  const ManageBiliing = () => {
    return axios.get(MANAGE_BILLING, {
      validateStatus() {
        return true;
      },
    });
  };

  const updateCard = (
    updateType: string,
    cardToken?: string | undefined,
    name?: string | undefined,
    line1?: string | undefined,
    line2?: string | undefined,
    city?: string | undefined,
    country?: string | undefined,
    state?: string | undefined,
    postal_code?: string | undefined
  ) => {
    const payload =
      updateType === CardUpdateForm.ADDRESS
        ? {
            updateType,
            name,
            line1,
            line2,
            city,
            country,
            state,
            postal_code,
          }
        : {
            updateType,
            cardToken,
          };

    return axios.post(UPDATE_CARD_DETAILS, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const applyCouponDiscount = (coupon: string) => {
    return axios.get(`${COUPON_DISCOUNT}/${coupon}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const getBillingData = () => {
    return axios.get(`${BILLING_URL}`, {
      timeout: 10000,
      validateStatus() {
        return true;
      },
    });
  };

  const getTransactionData = () => {
    return axios.get(`${TRANSACTION_URL}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const updateBillingAccess = () => {
    return axios.get(`${BILLING_URL}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const validateCheckout = (checkoutSessionId: string) => {
    return axios.post(
      `${VALIDATE_CHECKOUT}`,
      {
        checkoutSessionId,
      },
      {
        validateStatus() {
          return true;
        },
      }
    );
  };

  const cancelSubscription = (action: string | undefined, currencyId: string) => {
    return axios.delete(`${CANCEL_SUBSCRIPTION}?cancel_type=${action}&currencyId=${currencyId}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const createSubscriptionForFree = (priceId: string) => {
    return axios.post(
      `${BILLING_URL}/subscription/free`,
      {
        priceId,
      },
      {
        validateStatus() {
          return true;
        },
      }
    );
  };
  const createCheckoutSession = (priceId: string, cancelDestination: 'onboarding' | 'settings') => {
    return axios.post(
      `${CHECKOUT_SESSION}`,
      {
        priceId,
        cancelDestination,
      },
      {
        validateStatus() {
          return true;
        },
      }
    );
  };

  return {
    getPlans,
    getIpAddress,
    postProceedToPayment,
    postProceedToPaymentCard,
    applyCouponDiscount,
    updateCard,
    proceedToProRated,
    getCardDetailsApi,
    confirmCardChange,
    changeCard,
    cancelSubscription,
    updateBillingAccess,
    getBillingData,
    getTransactionData,
    validateCheckout,
    createSubscriptionForFree,
    ManageBiliing,
    createCheckoutSession,
  };
};
