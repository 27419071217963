import { toast } from 'react-toastify';

export const handleCopyLink = (url: string) => {
  if (url) {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success('Link Copied successfully');
      })
      .catch((err) => toast.error('Failed to copy:'));
  }
};
