import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Registration } from './views/Registration';
import { ForgotPassword } from './views/ForgotPassword';
import { Login } from './views/Login';
import { AuthLayout } from './AuthLayout';
import { ResetPassword } from './views/ResetPassword';
import { EmailVerification } from './views/EmailVerification';

const AuthPage = () => {
  const { pathname } = useLocation();

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='registration' element={<Registration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password/:token' element={<ResetPassword />} />
        <Route path='email-verification' element={<EmailVerification />} />
      </Route>
      <Route path='*' element={<Navigate to={`/login?path=${pathname}`} />} />
    </Routes>
  );
};

export { AuthPage };
