import { Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes, InputTypes } from '@/enums';
import { toast } from 'react-toastify';
import { useTeamManagementRequests } from '@/requests/useTeamManagementRequests';
import { BWInput } from '../../shared/components/Input/BWInput';
import { useGlobalContext } from '../../../core/helpers';

export const CreateTeamModal = ({
  showTeamModal,
  setShowTeamModal,
}: {
  showTeamModal: boolean;
  setShowTeamModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { createTeam } = useTeamManagementRequests();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const { setUserToken, getCurrentUser } = useGlobalContext();
  const initialValues = {
    teamName: '',
  };
  const teamSchema = Yup.object().shape({
    teamName: Yup.string()
      .max(25, formatMessage({ id: 'Team name must be less than 25 characters' }))
      .required('Team name is required'),
  });

  const handleCreateTeam = async (values: { teamName: string }) => {
    setLoading(true);
    try {
      const {
        data: { data, success },
      } = await createTeam(values.teamName);
      if (success) {
        toast.success(formatMessage({ id: 'Team Created Successfully' }));
        await getCurrentUser(data);
        setUserToken(data.accessToken);
        window.location.reload();
      }
      setShowTeamModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={showTeamModal}
      onHide={() => setShowTeamModal(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      size='sm'
    >
      <Modal.Header closeButton>
        <h2>{formatMessage({ id: 'Create Team' })}</h2>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={teamSchema}
        onSubmit={handleCreateTeam}
        validateOnMount
      >
        {(formik) => (
          <Form>
            <Modal.Body>
              <BWInput
                fieldType={InputTypes.TEXT}
                fieldName={'teamName'}
                formik={formik}
                placeholder={formatMessage({ id: 'Enter the team name' })}
                label={formatMessage({ id: 'Team name' })}
                isRequired={true}
              />
              <div className='ms-1 fw-bold'>
                {formatMessage({
                  id: 'By proceeding, your new team will be subscribed to the Free plan by default. You can upgrade to a Paid plan from your account settings.',
                })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <GlobalButton
                buttonType={ButtonTypes.PRIMARY}
                buttonText='Create'
                isLoading={loading}
                isDisable={formik.isSubmitting || !formik.isValid || loading}
                onButtonClick={formik.handleSubmit}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
