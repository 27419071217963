import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Tabs, { Tab } from 'react-best-tabs';
import { useIntl } from 'react-intl';
import { CommentEntity } from '@/types/projects';
import { User } from '../../../../types/user';
import { useGlobalContext } from '../../../../core/helpers';
import { useParams } from 'react-router-dom';
import UserAvatar from '../../../shared/components/UserAvatar';
import SystemInfo from './SystemInfo';
import ConsoleInfo from './ConsoleInfo';
import { useCommentRequests } from '@/requests/useCommentRequests';
import { UserAvatarSize } from '@/enums/user';
import { TaskTextArea } from '../../../shared/components/Input/TaskTextArea';
import { Comment } from './Comment';
import { NoDataComponent } from '../../../shared/components/NoDataComponent';
import NetworkInfo from './NetworkInfo';

export const TaskTabs = ({
  formik,
  taskId,
  commentList,
  setCommentList,
  showAllComments,
  setShowAllComments,
  userList,
  systemInfo,
  consoleInfo,
  networkInfo,
  projectIdFromTaskData,
  isPublic,
}: {
  formik: any;
  taskId: number | null;
  commentList: CommentEntity[];
  setCommentList: Dispatch<SetStateAction<CommentEntity[]>>;
  showAllComments: boolean;
  setShowAllComments: Dispatch<SetStateAction<boolean>>;
  userList: User[];
  systemInfo: string | null;
  consoleInfo: string | null;
  networkInfo: string | null;
  projectIdFromTaskData?: number;
  isPublic?: boolean;
}) => {
  const { createComment, deleteComment, editComment } = useCommentRequests();
  const { projectId } = useParams();
  const [addCommentLoading, setAddCommentLoading] = useState<boolean>(false);
  const { currentUser } = useGlobalContext();
  const { formatMessage } = useIntl();
  const [activeTab, setActiveTab] = useState('comments');

  const handleAddComment = async (textareaValue: string) => {
    try {
      setAddCommentLoading(true);
      const {
        data: { data, success, errors },
      } = await createComment(
        textareaValue,
        taskId,
        projectId ? parseInt(projectId) : projectIdFromTaskData ? projectIdFromTaskData : null
      );
      if (success) {
        const assignee = userList.find((user: User) => user?.userId === data?.createdBy);
        const created = {
          userId: assignee?.userId,
          firstName: assignee?.firstName,
          lastName: assignee?.lastName,
          color: assignee?.color,
        };
        const newData = { ...data, created: created };
        setCommentList([...commentList, newData]);
        formik.setFieldValue('newcomment', '');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddCommentLoading(false);
    }
  };

  const handleEditSuccess = (editedComment: string, commentId: number | null) => {
    const updatedCommentList = commentList.map((comment: CommentEntity) => {
      if (comment.commentId === commentId) {
        return { ...comment, description: editedComment };
      }
      return comment;
    });

    setCommentList(updatedCommentList);
  };

  const handleDeleteSuccess = (commentId: number | null) => {
    setCommentList(
      (prevCommentList: CommentEntity[]) =>
        prevCommentList?.filter((comment: CommentEntity) => comment.commentId !== commentId)
    );
  };

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const tabsContainer = document.getElementsByClassName('setting-tabs-list')[0];
    if (tabsContainer) {
      const tabElements = tabsContainer.getElementsByClassName('rb-tabs-item');
      const selectedTab = Array.from(tabElements).find(
        (element) => element.textContent === activeTab
      );
      if (selectedTab) {
        const rect = selectedTab.getBoundingClientRect();
        tabsContainer.scrollTo({
          left: rect.left - tabsContainer.getBoundingClientRect().left,
          behavior: 'smooth',
        });
      }
    }
  }, [activeTab]);

  return (
    <div>
      <Tabs
        activeTab={1}
        onClick={(event, tab) => {
          handleTabClick(tab);
        }}
        ulClassName='text-muted dark-border setting-tabs-list'
        activityClassName='active-tab'
      >
        <Tab title={formatMessage({ id: 'Comments' })}>
          {
            <div className='mx-1 mt-4 mb-0 comment-container'>
              {!isPublic && (
                <div className='d-flex flex-row  justify-content-between'>
                  <div className='pt-1 mb-4'>
                    <UserAvatar user={currentUser} size={UserAvatarSize.LARGE} />
                  </div>
                  <div className='w-95 ms-1'>
                    <TaskTextArea
                      displayText={''}
                      onSave={(newComment: string) => {
                        handleAddComment(newComment);
                      }}
                      rows={1}
                      clearTextAfterAction={true}
                      placeholder={formatMessage({ id: 'Add a comment' })}
                    />
                  </div>
                </div>
              )}

              <div>
                {commentList && commentList.length > 0 ? (
                  <>
                    {commentList
                      .slice(showAllComments ? 0 : -2)
                      .reverse()
                      .map((comment: CommentEntity, index: number) => (
                        <Comment
                          comment={comment}
                          taskId={taskId}
                          onEditSuccess={handleEditSuccess}
                          onDeleteSuccess={handleDeleteSuccess}
                          isPublic={isPublic}
                        />
                      ))}
                    {commentList.length > 2 && !showAllComments && (
                      <button
                        onClick={() => setShowAllComments(true)}
                        className='w-95 mt-3 ms-10 older-comments-button mb-3'
                      >
                        <div className='fw-500'>
                          {`View ${commentList.length - 2} remaining older comments`}
                        </div>
                      </button>
                    )}
                  </>
                ) : (
                  isPublic && <div className='font-weight-500'>No comments</div>
                )}
              </div>
            </div>
          }
        </Tab>
        <Tab title={formatMessage({ id: 'System Info' })}>
          <div className='mx-1 mt-4 mb-0'>
            <SystemInfo systemInfo={systemInfo} />
          </div>
        </Tab>
        <Tab title={formatMessage({ id: 'Console' })}>
          <div className='mx-1 mt-4 mb-0'>
            <ConsoleInfo consoleInfo={consoleInfo} />
          </div>
        </Tab>
        <Tab title={formatMessage({ id: 'Network' })}>
          <div className='mx-1 mt-4 mb-0'>
            <NetworkInfo networkInfo={networkInfo || ''} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};
