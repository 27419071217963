import { UserMeta } from '@/types/user';
import { MetaKeys } from '@/enums/user';
import { useGlobalContext } from '../core/helpers';

export const useSetUserMeta = () => {
  const { userMeta, setUserMeta } = useGlobalContext();
  const setUserMetaData = (userMetaData: Partial<UserMeta> | UserMeta[]) => {
    if (!Array.isArray(userMetaData) && typeof userMetaData === 'object') {
      setUserMeta({ ...(userMeta as UserMeta), ...userMetaData });
    } else if (Array.isArray(userMetaData)) {
      const localeData: Partial<UserMeta> = {};
      (userMetaData as (Partial<UserMeta> & { key: MetaKeys; value: string | number })[]).forEach(
        (e) => {
          localeData[e.key as keyof Partial<UserMeta>] = e.value;
        }
      );

      setUserMeta(localeData as UserMeta);
    }
  };

  return {
    setUserMetaData,
  };
};
