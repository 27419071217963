/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { AuthPage, getAuth } from '../modules/auth';
import { useGlobalContext } from '../core/helpers';
import { App } from '../App';

import { VerifyInvite } from '../modules/auth/components/VerifyInvite';
import { AuthLayout } from '../modules/auth/AuthLayout';
import { ResetPassword } from '../modules/auth/views/ResetPassword';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { VerifyRegistration } from '../modules/auth/views/VerifyRegistration';
import TaskPage from '../modules/projects/components/tasks-board/TaskPage';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
  const { userToken, setUserToken } = useGlobalContext();

  useEffect(() => {
    const token = getAuth();
    if (token) {
      setUserToken(token.accessToken);
    }
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route element={<AuthLayout />}>
            <Route path='/reset-password/:token' element={<ResetPassword />} />
          </Route>
          {userToken ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route path='/invite/:inviteToken' element={<VerifyInvite />} />
              <Route path='/registration/:registerToken' element={<VerifyRegistration />} />
              <Route path='/task/public/:uuid' element={<TaskPage />} />
            </>
          ) : (
            <>
              <Route index element={<Navigate to='/login' />} />
              <Route path='/invite/:inviteToken' element={<VerifyInvite />} />
              <Route path='/registration/:registerToken' element={<VerifyRegistration />} />
              <Route path='/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/login' />} />
              <Route path='/task/public/:uuid' element={<TaskPage />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
