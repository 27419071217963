import { FC, Suspense, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { MasterLayout } from '../core/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../core/assets/ts/_utils';
import { WithChildren } from '../core/helpers';

import { useGlobalContext } from '../core/helpers';
import { OnboardingPage } from '../modules/onboarding/views/OnboardingPage';
import ProjectRoutes from '../modules/projects/ProjectRoutes';
import { ToolbarWrapper } from '../core/layout/components/toolbar/ToolbarWrapper';
import { ProjectList } from '../modules/projects/views/ProjectList';
import TaskPage from '../modules/projects/components/tasks-board/TaskPage';
import { Team } from '../modules/organization/views/Team';
import { Profile } from '../modules/profile/views/Profile';
import { IntegrationPage } from '../modules/projects/components/integrations/Integration';
import { getCookie } from '../util/cookie';
import { MetaKeys } from '@/enums/user';
import { parseCurrentSelection } from '../util/parseCurrentSelection';
import { ValidateCheckout } from '../modules/onboarding/views/ValidateCheckout';

const PrivateRoutes = () => {
  const { currentUser, userMeta } = useGlobalContext();
  const { pathname } = useLocation();
  const path = pathname.split('/').pop();

  const cookieProjectId = getCookie(process.env.REACT_APP_PROJECT_COOKIE_KEY || MetaKeys.PROJECTID);

  return (
    <>
      {currentUser ? (
        <Routes>
          {currentUser?.planId ? (
            <Route element={<MasterLayout />}>
              <Route
                index
                element={
                  <Navigate
                    to={
                      ((userMeta &&
                        userMeta[MetaKeys.CURRENT_SELECTION] &&
                        parseCurrentSelection(userMeta).projectId) ||
                        cookieProjectId) &&
                      parseCurrentSelection(userMeta!).teamId === currentUser.teamId &&
                      path === ''
                        ? `/project/${
                            cookieProjectId
                              ? cookieProjectId
                              : parseCurrentSelection(userMeta!).projectId
                          }/task-board`
                        : path !== ''
                        ? pathname
                        : '/projects'
                    }
                  />
                }
              />

              <Route path='project/*' element={<ProjectRoutes />} />
              <Route element={<Navigate to='/projects' />} />

              <Route path='projects' element={<ProjectList />} />

              <Route path='integration/oauth/' element={<IntegrationPage />} />
              <Route path='organisation' element={<Team />} />
              <Route path='profile' element={<Profile />} />
              <Route
                path='settings'
                element={
                  <SuspensedView>
                    <ToolbarWrapper />
                  </SuspensedView>
                }
              />

              <Route path='task/:taskCode' element={<TaskPage />} />
              <Route path='/redirect' element={<ValidateCheckout />} />
              <Route path='*' element={<Navigate to='/projects' />} />
            </Route>
          ) : (
            <>
              <Route index element={<Navigate to='/onboarding/' />} />
              <Route path='/redirect' element={<ValidateCheckout />} />
              <Route path='/onboarding/' element={<OnboardingPage />} />
              <Route path='*' element={<Navigate to='/onboarding/' />} />
            </>
          )}
        </Routes>
      ) : null}
    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
