import React from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';

export const ConfirmChangePasswordModal = ({
  confirmModalShow,
  setConfirmModalShow,
  getForgotedPassword,
  changePasswordLoading,
}: {
  confirmModalShow: boolean;
  setConfirmModalShow: (show: boolean) => void;
  getForgotedPassword: any;
  changePasswordLoading: boolean;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      show={confirmModalShow}
      onHide={() => setConfirmModalShow(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='border-bottom-0'>
        <h2 className='text-black'>{formatMessage({ id: 'Forgot your password?' })}</h2>
      </Modal.Header>
      <Modal.Body className='py-1 font-size-14 text-black'>
        <p>{formatMessage({ id: "We'll email you a link to reset your password." })}</p>
      </Modal.Body>
      <Modal.Footer className='pt-0 border-top-0'>
        <div className='w-100 d-flex justify-content-end gap-3'>
          <GlobalButton
            buttonText={formatMessage({ id: 'Cancel' })}
            buttonType={ButtonTypes.SECONDARY}
            onButtonClick={() => {
              setConfirmModalShow(false);
            }}
          />
          <GlobalButton
            buttonText={formatMessage({ id: 'Confirm' })}
            buttonType={ButtonTypes.PRIMARY}
            onButtonClick={getForgotedPassword}
            isLoading={changePasswordLoading}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
