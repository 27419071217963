import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../core/helpers/AssetHelpers';
import { useIntl } from 'react-intl';
import '../../styles/index.scss';
import { Footer } from './Footer';
export const Layout = () => {
  const { formatMessage } = useIntl();
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth });

  // Add a listener to update the window size when it changes
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <div>
      {windowSize.width > 450 ? (
        <>
          <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 layout'>
            <div className='w-lg-50 bw-layout-bg'>
              <div className='ms-10 welcome'>
                <div className='founder-create-logo'>
                  <img src={toAbsoluteUrl('/media/logos/bugswork-light.svg')} alt='Logo' />
                </div>
                <div className='text-white font-size-24 fw-bolder'>
                  {formatMessage({ id: 'Catch Bugs Before They Cost You,' })}
                </div>
              </div>

              <Footer color='white' font='14' />
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 align-items-center justify-content-center'>
              <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
                <div className='w-lg-500px'>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className='min-vh-100 d-flex flex-column p-5 align-items-center justify-content-center'>
              <div className='founder-create-logo'>
                <img
                  src={toAbsoluteUrl('/media/logos/DripFunnelLogo.svg')}
                  alt='Logo'
                  style={{ height: '32px' }}
                />
              </div>
              <div className='d-flex flex-center flex-column w-100'>
                <div className='w-lg-50 mb-10 w-100'>
                  <Outlet />
                </div>
                <Footer color='gray' font='10' />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
