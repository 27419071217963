import { useBillingRequests } from '@/requests/useBillingRequests';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGlobalContext } from '../../../core/helpers';
import { useIntl } from 'react-intl';

export const ValidateCheckout = () => {
  const { validateCheckout } = useBillingRequests();
  const navigate = useNavigate();
  const { setUserToken, getCurrentUser } = useGlobalContext();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const onBoardingUpdate = async (checkoutSessionId: string) => {
    try {
      const {
        data: { data, success },
      } = await validateCheckout(checkoutSessionId);
      if (success && data) {
        const tokenObj = {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        };
        getCurrentUser(tokenObj);
        setUserToken(data.accessToken);
        navigate('/projects');
      } else {
        navigate('/onboarding');
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (sessionId) {
      onBoardingUpdate(sessionId);
    }
  }, [sessionId]);

  const { formatMessage } = useIntl();
  return (
    <>
      <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <span className={`indicator-label font-size-20 p-0 opacity-50`}>
          {formatMessage({ id: 'Please wait for redirect ...' })}
          <span className='spinner-border spinner-border-sm align-middle ms-2 h-60px w-60px'></span>
        </span>
      </div>
    </>
  );
};
