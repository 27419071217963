import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../core/helpers';
import { useState } from 'react';
import { useProjectRequests } from '@/requests/useProjectRequests';

const useGetProjectById = () => {
  const { getProjectById } = useProjectRequests();
  const [projectLoading, setProjectLoading] = useState<boolean>(false);

  const { formatMessage } = useIntl();

  const getProject = async (projectId: number) => {
    try {
      setProjectLoading(true);
      const {
        data: { data, success, errors },
      } = await getProjectById(projectId);
      if (success) {
        setProjectLoading(false);
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProjectLoading(false);
    }
  };
  return { getProject, projectLoading };
};

export default useGetProjectById;
