import axios from 'axios';
import { API_URL } from '.';

const LOCATION_URL = `${API_URL}/location`;
const COUNTRY_URL = `${LOCATION_URL}/country`;

export const useLocationRequests = () => {
  const getLocation = (ip: string) => {
    return axios.get(`${LOCATION_URL}?clientIp=${ip}`, {
      validateStatus() {
        return true;
      },
    });
  };

  // getCountry
  const getCountry = () => {
    return axios.get(COUNTRY_URL);
  };

  return {
    getLocation,
    getCountry,
  };
};
