import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { Link } from 'react-router-dom';
const SidebarLogo = ({
  isSidebarCollapsed,
  setIsSidebarCollapsed,
}: {
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const { config } = useLayout();

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : '';
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default;

  useEffect(() => {
    let active = localStorage.getItem('isSidebarCollapsed');
    if (active === 'true') {
      document.body.setAttribute('data-kt-app-sidebar-minimize', 'on');
      setIsSidebarCollapsed(true);
    } else {
      document.body.removeAttribute('data-kt-app-sidebar-minimize');
      setIsSidebarCollapsed(false);
    }
  }, []);

  const handleSVGClick = () => {
    if (isSidebarCollapsed) {
      document.body.removeAttribute('data-kt-app-sidebar-minimize');
      localStorage.setItem('isSidebarCollapsed', 'false');
      setIsSidebarCollapsed(false);
    } else {
      document.body.setAttribute('data-kt-app-sidebar-minimize', 'on');
      localStorage.setItem('isSidebarCollapsed', 'true');
      setIsSidebarCollapsed(true);
    }
  };
  return (
    <div
      className={`app-sidebar-logo border-0 h-60px ${isSidebarCollapsed ? 'px-7' : 'px-8'}`}
      id='kt_app_sidebar_logo'
    >
      <div className='accordion-button collapsed w-100 app-sidebar-logo-default'>
        <Link to='/' data-bs-dismiss-='click'>
          <img src={toAbsoluteUrl('/media/logos/bugswork-light.svg')} alt='logo' height={30} />
        </Link>
      </div>

      <div className='w-100 app-sidebar-logo-minimize d-flex'>
        <img
          src={toAbsoluteUrl('/media/logos/bugswork-light-fav.svg')}
          alt='logo'
          height='30'
          className={'app-sidebar-logo-minimize'}
        />
      </div>
      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={`app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate ${
            isSidebarCollapsed ? 'active' : ''
          }`}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
          onClick={handleSVGClick}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' />
        </div>
      )}
    </div>
  );
};

export default memo(SidebarLogo);
