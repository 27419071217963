import React, { useState } from 'react';
import { CommentEntity } from '@/types/projects';
import { UserAvatarDisplayName, UserAvatarSize } from '@/enums/user';
import UserAvatar from '../../../shared/components/UserAvatar';
import { formatDate } from '../../../../util/_dateFormat';
import { Dropdown } from 'react-bootstrap';
import { TaskTextArea } from '../../../shared/components/Input/TaskTextArea';
import { useGlobalContext } from '../../../../core/helpers';
import { useCommentRequests } from '@/requests/useCommentRequests';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';

export const Comment = ({
  comment,
  taskId,
  onEditSuccess,
  onDeleteSuccess,
  isPublic,
}: {
  comment: CommentEntity;
  taskId: number | null;
  onEditSuccess: (textareaValue: string, commentId: number | null) => void;
  onDeleteSuccess: (commentId: number | null) => void;
  isPublic?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { currentUser } = useGlobalContext();
  const [editCommentIndex, setEditCommentIndex] = useState<number | null>(null);
  const [commentId, setCommentId] = useState<number | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const { deleteComment, editComment } = useCommentRequests();

  const handleEditComment = async (textareaValue: string) => {
    try {
      const {
        data: { success },
      } = await editComment(commentId, taskId, textareaValue);
      if (success) {
        onEditSuccess(textareaValue, commentId);
        toast.success(formatMessage({ id: 'Comment edited successfully' }));
        setEditCommentIndex(null);
      }
    } catch (err) {
      console.log(err);
      toast.error(formatMessage({ id: 'Failed to edit comment' }));
    }
  };

  const handleDeleteComment = async (commentId: number | null, taskId: number | null) => {
    setDeleteLoading(true);
    try {
      const {
        data: { success },
      } = await deleteComment(commentId, taskId);
      if (success) {
        setEditCommentIndex(null);
        onDeleteSuccess(commentId);
        toast.success(formatMessage({ id: 'Comment deleted successfully' }));
      }
    } catch (err) {
      console.log(err);
      toast.error(formatMessage({ id: 'Failed to delete comment' }));
    } finally {
      setDeleteLoading(false);
      setShowConfirmationModal(false);
    }
  };

  const onEditButtonClick = (commentId: number | null) => {
    setCommentId(commentId);
  };

  const openDeleteConfirmation = (commentId: number | null) => {
    setCommentId(commentId);
    setShowConfirmationModal(true);
  };

  return (
    <div className='m-4 ms-0'>
      <div className='row'>
        <div className='col-12 col-md-8 col-lg-12 mb-3 mb-md-0'>
          {comment?.createdBy && (
            <div className='d-flex flex-row'>
              <div className='comment-author fw-bold d-flex flex-row align-items-center'>
                <div className='me-2'>
                  <UserAvatar
                    user={comment.created}
                    size={UserAvatarSize.LARGE}
                    displayName={UserAvatarDisplayName.SIDE}
                  />
                </div>
              </div>
              <div className='ms-4 font-size-10 font-weight-400 text-muted mt-5'>
                {formatDate(comment.createdAt)}
              </div>
              {comment?.updatedBy && !isPublic && (
                <div className='font-size-10 font-size-400 text-muted mt-5 ms-2'>Edited</div>
              )}

              {currentUser?.userId === comment?.createdBy && !isPublic && (
                <Dropdown className='bg-body cursor-pointer rounded-1 mt-1'>
                  <Dropdown.Toggle
                    variant='bg-body'
                    className='bi bi-three-dots-vertical p-3'
                  ></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <>
                      <Dropdown.Item
                        key={1}
                        className='mb-1'
                        onClick={() => {
                          if (editCommentIndex === null) {
                            onEditButtonClick(comment.commentId);
                            setEditCommentIndex(
                              comment?.commentId === editCommentIndex ? null : comment?.commentId
                            );
                          }
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={2}
                        onClick={() => openDeleteConfirmation(comment?.commentId)}
                      >
                        Delete
                      </Dropdown.Item>
                    </>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}

          {editCommentIndex === comment?.commentId ? (
            <>
              <div className='ms-12'>
                <TaskTextArea
                  displayText={comment.description}
                  onSave={(textareaValue: string) => {
                    handleEditComment(textareaValue);
                  }}
                  rows={3}
                  clearTextAfterAction={false}
                  placeholder={'Add a comment'}
                  onCancel={() => {
                    setEditCommentIndex(null);
                  }}
                  showButtonsByDefault={true}
                />
              </div>
            </>
          ) : (
            <div className='font-size-12 font-weight-400 title-color ms-12 cursor-pointer'>
              {comment.description}
            </div>
          )}
        </div>
      </div>

      <ConfirmationModal
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        headingMessage={formatMessage({ id: 'Delete Comment' })}
        description={formatMessage({
          id: 'Are you sure you want to delete this comment permanently?',
        })}
        handleDelete={() => handleDeleteComment(commentId, taskId)}
        deleteLoading={deleteLoading}
      />
    </div>
  );
};
