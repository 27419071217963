import axios from 'axios';
const GITHUB_API_URL = 'https://api.github.com';
export const useGithubIntegrationRequests = () => {
  const getUserOrganisations = async (token: string) => {
    const response = await fetch(`${GITHUB_API_URL}/user/orgs`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return {
      status: response.status,
      data: data,
    };
  };
  const getUserAccount = async (token: string) => {
    const response = await fetch(`${GITHUB_API_URL}/user`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return {
      status: response.status,
      data: data,
    };
  };
  const getAllGithubRepos = async (orgName: string, token: string, type: string) => {
    const response = await fetch(`${GITHUB_API_URL}/${type}/${orgName}/repos`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return {
      status: response.status,
      data: data,
    };
  };
  return {
    getUserOrganisations,
    getAllGithubRepos,
    getUserAccount,
  };
};
