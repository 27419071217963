import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { loginRequest } from '@/constants/auth';
import { useUtmValidator } from './useUtmValidator';
import { useGlobalContext } from '../core/helpers';
import { useFetchCountryAndTimeZone } from './useFetchCountryAndTimeZone';

const useSocialLogin = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const path = searchParams.get('path');
  const { utmValues } = useUtmValidator();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { getCurrentUser, setUserToken } = useGlobalContext();
  const [, setErrorsArray] = useState<string[]>();
  const { fetchCountryAndTimeZone } = useFetchCountryAndTimeZone();
  const { instance } = useMsal();
  const { microsoftLogin } = useAuthRequests();
  const localStorageReferral = localStorage.getItem('referral') || '';
  const handleMicrosoftLogin = async () => {
    const { accessToken } = await instance.acquireTokenPopup(loginRequest);
    handleMsalToken(accessToken);
  };
  const handleMsalToken = async (accessToken: string) => {
    try {
      setLoading(true);
      const locationData = await fetchCountryAndTimeZone();
      const payload = {
        token: accessToken,
        referral: localStorageReferral,
        inviteToken: state?.inviteToken || undefined,
        ...utmValues,
        locale: 'en_US',
        dateFormat: 'MMM DD, YYYY',
        timeFormat: 'hh:mm:ss a',
        timeZone: locationData?.timeZone || 5.5,
        countryId: locationData?.countryId || 103,
      };
      const {
        data: { data, errors, success },
      } = await microsoftLogin(payload);
      if (success) {
        setLoading(false);
        await getCurrentUser(data);
        setUserToken(data.accessToken);
        if (path) {
          navigate(path);
        } else {
          navigate('/');
        }
      } else {
        setLoading(false);
        setErrorsArray(errors);
        await getCurrentUser(undefined);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return { handleMicrosoftLogin, handleMsalToken, loading };
};

export { useSocialLogin };
