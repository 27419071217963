export enum StripeMetaKeys {
  PLANTYPE = 'planType',
  PROJECT = 'project',
  PROJECTTYPE = 'projectType',
  TASKSPERWEEK = 'tasksPerWeek',
  USERS = 'users',
  AUTOMATION = 'automation',
}

export enum PlanValues {
  UNLIMITED = 'Unlimited',
}
