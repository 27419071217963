import { CountryModel } from '../types';
import { useLocationRequests } from '@/requests/useLocationRequests';

export const useFetchCountryAndTimeZone = () => {
  const { getLocation, getCountry } = useLocationRequests();
  const fetchCountryAndTimeZone = async () => {
    try {
      const response = await fetch(`https://api.ipify.org/`);
      const ip: any = await response.text();
      const [
        {
          data: { data: locationDetails },
        },
        {
          data: { data: countries },
        },
      ] = await Promise.all([getLocation(ip), getCountry()]);

      const country = countries.find(
        (country: CountryModel) => country.country_code === locationDetails.countryCode
      );
      if (country) {
        return { countryId: country.countryId, timeZone: locationDetails.offset };
      }
    } catch (err) {
      console.error(err);
    }
  };

  return { fetchCountryAndTimeZone };
};
