import { useState } from 'react';
import { useGlobalContext } from '../../../../core/helpers';
import { useIntl } from 'react-intl';
import { useLogout } from '../../../../hooks/useLogout';
import { ButtonTypes } from '@/enums';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';

const LogoutCard = ({ invitedEmail }: { invitedEmail: string }) => {
  const { logout } = useLogout();

  const { formatMessage } = useIntl();
  const { currentUser } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    await logout();
    setLoading(false);
  };

  return (
    <>
      <div className='font-size-16'>{formatMessage({ id: 'You are logged in to' })}</div>
      <div className='font-size-16'>
        <span className='email-container'>
          <span className='font-weight-700'>{currentUser?.email!}</span>.
        </span>
      </div>
      <div className='font-size-16'>
        {formatMessage({
          id: 'Please log out from this account to accept the invite for',
        })}
        &nbsp;
        <span className='email-container'>
          <span className='font-weight-700'>{invitedEmail}</span>.
        </span>
      </div>

      <div className='d-flex align-items-center justify-content-end gap-4 mt-4'>
        <GlobalButton
          buttonText={formatMessage({ id: 'Logout' })}
          buttonType={ButtonTypes.PRIMARY}
          isDisable={loading}
          isLoading={loading}
          onButtonClick={handleLogout}
        />
      </div>
    </>
  );
};

export { LogoutCard };
