import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { selectedOptionType, SelectFieldProps } from '../../../types';
import { useGlobalContext } from '../../../core/helpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { MenuRoutes } from '@/enums/projects';
import { useUserMetaRequests } from '@/requests/useUserMetaRequests';
import { useSetUserMeta } from '../../../hooks/useSetUserMeta';
import { MetaKeys } from '@/enums/user';
import { checkPlanAccess } from '../../../util/checkPlanAccess';
import { SelectPlanModal } from './SelectPlanModal';
import { Project } from '@/types/projects';
import useAllProjectList from '../../../hooks/useAllProjectList';
import { StripeMetaKeys } from '@/enums/stripeMetaKeys';

export const MenuProjectDropdown = ({
  options,
  isSidebarCollapsed,
  selectedValue,
}: {
  options: { id: number; label: string; value: string }[];
  isSidebarCollapsed: boolean;
  selectedValue: Partial<selectedOptionType>;
}) => {
  const { setSingleUserMeta } = useUserMetaRequests();
  const intl = useIntl();
  const { setUserMetaData } = useSetUserMeta();
  const { userMeta, setopenCreateProjectModal, currentUser } = useGlobalContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef<any>(null);
  const [selectPlanModal, setSelectPlanModal] = useState(false);

  const handleClickOutside = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const manageUrl = (projectId: number, routeName: string) => {
    navigate(`/project/${projectId}/${routeName}/`);
    window.location.reload();
  };

  const handleOptionClick = async (option: SelectFieldProps) => {
    if (option.id != userMeta?.[MetaKeys.PROJECTID]) {
      try {
        const {
          data: { data, success, errors },
        } = await setSingleUserMeta({
          key: MetaKeys.CURRENT_SELECTION,
          value: JSON.stringify({ projectId: option.id.toString(), teamId: currentUser?.teamId }),
        });
        if (success) {
          setUserMetaData({ [MetaKeys.CURRENT_SELECTION]: data.value });
          setIsOpen(false);
          if (pathname.includes(MenuRoutes.TASK_BOARD)) {
            manageUrl(option.id, MenuRoutes.TASK_BOARD);
          } else if (pathname.includes(MenuRoutes.INTEGRATION)) {
            manageUrl(option.id, MenuRoutes.INTEGRATION);
          } else if (pathname.includes(MenuRoutes.PROJECT_SETTINGS)) {
            manageUrl(option.id, MenuRoutes.PROJECT_SETTINGS);
          } else {
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div>
      <div
        ref={selectRef}
        className={`form-menu-select-size form-menu-select form-select-sm mx-8 position-relative cursor-pointer`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='custom-select-selected pt-1 project-profile-name'>
          <span
            className={`${
              selectedValue?.value || userMeta?.[MetaKeys.PROJECTID] ? 'project-profile' : 'd-none'
            } me-3`}
          >
            {selectedValue?.value ||
              options.find((option) => option.id == userMeta?.[MetaKeys.PROJECTID])?.value}
          </span>
          {selectedValue.value
            ? selectedValue.label
            : options.find((option) => option.id == userMeta?.[MetaKeys.PROJECTID])?.label}
        </div>
        {isOpen && (
          <ul className='custom-select-options'>
            {options.map((option) => (
              <Fragment>
                <li
                  key={option.id}
                  data-value={selectedValue.value}
                  onClick={() => handleOptionClick(option)}
                >
                  <span className={`project-profile px-2 me-3`}>{option.value}</span>
                  {option.label}
                </li>
                <div className='custom-select-border' />
              </Fragment>
            ))}
            <li
              onClick={() => {
                if (checkPlanAccess(options, currentUser?.metadata?.[StripeMetaKeys.PROJECT]!)) {
                  setopenCreateProjectModal(true);
                } else {
                  setSelectPlanModal(true);
                }
                navigate('/projects/');
              }}
            >
              {intl.formatMessage({ id: 'Create new project' })}
            </li>
          </ul>
        )}
      </div>
      <SelectPlanModal
        selectPlanModal={selectPlanModal}
        setSelectPlanModal={setSelectPlanModal}
        modalText={intl
          .formatMessage({
            id: `current_plan_project_message`,
          })
          .replace('{{planName}}', currentUser?.planName || '')
          .replace('{{project}}', currentUser?.metadata?.project || '')}
      />
    </div>
  );
};
