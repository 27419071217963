/* eslint-disable react/jsx-no-target-blank */
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { SidebarMenuItem } from './SidebarMenuItem';
import { PlatformMenu, ProjectMenu } from '../../../core/_constants';
import { MenuProjectDropdown } from '../../../../../modules/shared/components/MenuProjectDropdown';
import useAllProjectList from '../../../../../hooks/useAllProjectList';
import { useGlobalContext } from '../../../../helpers';
import { selectedOptionType } from '../../../../../types';
import { checkIsActive } from '../../../../helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserMetaRequests } from '@/requests/useUserMetaRequests';
import { Project } from '@/types/projects';
import { useSetUserMeta } from '../../../../../hooks/useSetUserMeta';
import { MetaKeys } from '@/enums/user';
import { setCookie } from '../../../../../util/cookie';

import { errorMessages } from '@/enums/errorMessages';
import { Roles } from '@/enums/organisation';
import { parseCurrentSelection } from '../../../../../util/parseCurrentSelection';

const SidebarMenuMain = ({
  isSidebarCollapsed,
  setIsSidebarCollapsed,
}: {
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const { getSingleUserMeta } = useUserMetaRequests();
  const { setUserMetaData } = useSetUserMeta();
  const intl = useIntl();
  const { getAllProjectList } = useAllProjectList();
  const { userMeta, currentUser } = useGlobalContext();
  const [projectList, setProjectList] = useState<Project[] | []>([]);
  const [selectedValue, setSelectedValue] = useState<Partial<selectedOptionType>>({
    label: intl.formatMessage({ id: 'Select project' }),
    id: 0,
  });
  const { pathname } = useLocation();
  const taskCode = pathname.split('/').includes('task') ?? pathname.split('/').pop();
  const navigate = useNavigate();
  const path = pathname.split('/').pop();

  const handleSideBarExpand = () => {
    document.body.removeAttribute('data-kt-app-sidebar-minimize');
    setIsSidebarCollapsed(false);
  };

  async function getUserMetaByKey() {
    try {
      const projects = await getAllProjectList();
      const {
        data: { data, success, errors },
      } = await getSingleUserMeta(MetaKeys.CURRENT_SELECTION);
      if (success) {
        if (data === errorMessages.METADATA_DOES_NOT_EXIST) {
          return;
        } else {
          setProjectList(projects);
          const project = projects.find(
            ({ projectId }: { projectId: number }) =>
              currentUser?.teamId === JSON.parse(data.value).teamId &&
              projectId === parseInt(JSON.parse(data.value).projectId)
          );

          setUserMetaData({ [MetaKeys.CURRENT_SELECTION]: data.value });
          if (project) {
            setSelectedValue({
              label: project.title,
              value: project.projectCode,
              id: project.projectId,
            });
            setCookie(
              process.env.REACT_APP_PROJECT_COOKIE_KEY || MetaKeys.PROJECTID,
              JSON.parse(data.value).projectId
            );
            if (path === '') {
              navigate(`/project/${JSON.parse(data.value).projectId}/task-board`);
            }
          } else {
            if (path === '') {
              navigate('/projects');
            } else {
              navigate(pathname);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserMetaByKey();
  }, [userMeta?.[MetaKeys.CURRENT_SELECTION] && parseCurrentSelection(userMeta).projectId]);

  return (
    <>
      {userMeta?.[MetaKeys.CURRENT_SELECTION] &&
        JSON.parse(userMeta?.[MetaKeys.CURRENT_SELECTION]?.toString() || '{}').projectId &&
        parseCurrentSelection(userMeta).teamId === currentUser?.teamId &&
        projectList?.length > 0 && (
          <>
            <div
              className={`${
                isSidebarCollapsed ? 'px-4' : 'px-8'
              }  text-uppercase text-menu-light-gray pb-2 font-10`}
            >
              {intl.formatMessage({ id: 'Project' })}
            </div>
            {isSidebarCollapsed ? (
              <div
                role='button'
                className='form-menu-select-size-collapsed form-menu-select-collapsed d-flex justify-content-center align-items-center cursor-pointer'
                onClick={handleSideBarExpand}
              >
                <span className='project-profile  mx-3' style={{ fontSize: '10px' }}>
                  {selectedValue.value ||
                    projectList.find(
                      ({ projectId }) =>
                        projectId ===
                        JSON.parse(userMeta?.[MetaKeys.CURRENT_SELECTION]?.toString() || '')
                          .projectId
                    )?.projectCode}
                </span>
              </div>
            ) : (
              <div className='pb-3'>
                <MenuProjectDropdown
                  options={
                    projectList?.map(({ title, projectId, projectCode }) => ({
                      id: projectId,
                      label: title,
                      value: projectCode,
                    })) || []
                  }
                  isSidebarCollapsed={isSidebarCollapsed}
                  selectedValue={selectedValue}
                />
              </div>
            )}

            {ProjectMenu.map(({ id, href, icon, activeIcon, hoverIcon, title, roles }) => {
              if (currentUser && !roles.includes(currentUser?.role as Roles)) {
                return null;
              }
              return (
                <SidebarMenuItem
                  key={id}
                  to={`project/${parseCurrentSelection(userMeta).projectId}/${href}`}
                  icon={icon}
                  activeIcon={activeIcon}
                  hoverIcon={hoverIcon}
                  title={intl.formatMessage({ id: title })}
                  isSidebarCollapsed={isSidebarCollapsed}
                  tabIsActive={checkIsActive(
                    pathname,
                    `project/${parseCurrentSelection(userMeta).projectId}/${href}`
                  )}
                />
              );
            })}
            <div className='custom-border mt-5 mb-5' />
          </>
        )}

      <div
        className={`${
          isSidebarCollapsed ? 'px-3' : 'px-8'
        } text-uppercase text-menu-light-gray pb-2 font-10`}
      >
        {intl.formatMessage({ id: 'Platform' })}
      </div>
      {PlatformMenu.map(({ id, href, icon, activeIcon, hoverIcon, title, roles }) => {
        if (currentUser && !roles.includes(currentUser?.role as Roles)) {
          return null;
        }

        // Render the tab
        return (
          <SidebarMenuItem
            key={id}
            to={href}
            icon={icon}
            activeIcon={activeIcon}
            hoverIcon={hoverIcon}
            title={intl.formatMessage({ id: title })}
            isSidebarCollapsed={isSidebarCollapsed}
            tabIsActive={checkIsActive(pathname, href)}
          />
        );
      })}
    </>
  );
};

export { SidebarMenuMain };
