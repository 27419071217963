/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import '../../styles/sidebar.scss';
import chromeImg from '../../../assets/images/svg/bugs.work/logos_chrome.svg';
import { SideBarDrawer } from '../../../../modules/shared/components/UI/SideBarDrawer';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useLogout } from '../../../../hooks/useLogout';
import { useQuickStartRequests } from '@/requests/useQuickStartRequest';
import { number } from 'yup/lib/locale';
import { useGlobalContext, toAbsoluteUrl } from '../../../helpers';

const SidebarFooter = ({ isSidebarCollapsed }: { isSidebarCollapsed: boolean }) => {
  const { currentUser } = useGlobalContext();
  const { getAllQuickStart } = useQuickStartRequests();
  const { formatMessage } = useIntl();

  const [showSidebar, setShowSidebar] = useState(false);
  const [quickStartData, setQuickStartData] = useState<any[]>([]);
  const handleClose = () => {
    setShowSidebar(!showSidebar);
  };
  const toggleSidebar = async () => {
    try {
      const userTeamId = currentUser?.teamId;
      const {
        data: { data, success, errors },
      } = await getAllQuickStart(userTeamId);
      if (success) {
        setQuickStartData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    toggleSidebar();
  }, []);
  const completedSteps = quickStartData.filter(
    (item: any) => item.quickStart_Teams[0]?.isCompleted
  );
  const nextSteps = quickStartData?.filter((item: any) => !item.quickStart_Teams[0]?.isCompleted);
  return (
    <div
      className='app-sidebar-footer d-flex flex-column justify-content-end px-7 mb-10 '
      id='kt_app_sidebar_footer'
    >
      {!isSidebarCollapsed ? (
        <>
          <div className='app-sidebar-logo-default text-center'>
            <div className='bg-238857 mb-2 rounded'>
              <div className='d-flex cursor-pointer'>
                <div
                  className='d-flex cursor-pointer'
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <div className='w-40px p-2 position-relative'>
                    <CircularProgressbar
                      value={completedSteps.length}
                      maxValue={quickStartData.length}
                      text={`${nextSteps.length > 0 ? nextSteps.length : ''}`}
                      background={true}
                      strokeWidth={12}
                      styles={{
                        path: {
                          stroke: `#238856`,
                        },
                        text: {
                          fill: '#ffffff',
                          fontSize: '48px',
                          fontWeight: 'bold',
                        },
                        background: {
                          fill: '#181c32',
                        },
                      }}
                    />
                    {nextSteps.length === 0 && (
                      <div className='tickContainer'>
                        <img
                          src={toAbsoluteUrl('/media/logos/tick.svg')}
                          alt='tick'
                          className='tickImage'
                        />
                      </div>
                    )}
                  </div>
                  <div className='d-flex align-items-center justify-content-between font-weight-500 font-size-14 ms-2 text-white p-2'>
                    {formatMessage({ id: 'Quick start' })}
                  </div>
                </div>
                <SideBarDrawer
                  open={showSidebar}
                  setShowSidebar={setShowSidebar}
                  onClose={handleClose}
                  toggleSidebar={toggleSidebar}
                  data={quickStartData}
                ></SideBarDrawer>
              </div>
            </div>

            <div className='text-white me-auto mt-3'>
              &copy; {new Date().getFullYear()} {formatMessage({ id: 'bugs.work' })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div
              className='mb-2 w-75 pb-1'
              onClick={() => {
                handleClose();
              }}
            >
              <CircularProgressbar
                value={nextSteps.length}
                maxValue={quickStartData.length}
                text={`${nextSteps.length}`}
                background={true}
                strokeWidth={12}
                styles={{
                  path: {
                    stroke: `rgb(19,79,49)`,
                  },
                  text: {
                    fill: '#ffffff',
                    fontSize: '48px',
                    fontWeight: 'bold',
                  },
                  background: {
                    fill: '#181c32',
                  },
                }}
              />
            </div>
            <SideBarDrawer
              open={showSidebar}
              onClose={handleClose}
              data={quickStartData}
            ></SideBarDrawer>
          </div>
        </>
      )}
    </div>
  );
};

export { SidebarFooter };
