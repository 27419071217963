import { Card } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import greenTick from '../../../../core/assets/images/svg/bugs.work/green-tick.svg';
import { toAbsoluteUrl, useGlobalContext } from '../../../../core/helpers';
import { useQuickStartRequests } from '@/requests/useQuickStartRequest';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { MetaKeys } from '@/enums/user';
import { getCookie } from '../../../../util/cookie';
import { GlobalButton } from './GlobalButton';
import { ButtonTypes } from '@/enums';
import { useState } from 'react';
import { InputToolTip } from './InputToolTip';

export const SideBarDrawer = ({ open, setShowSidebar, onClose, toggleSidebar, data }: any) => {
  const { formatMessage } = useIntl();
  const completedSteps = data.filter((item: any) => item.quickStart_Teams[0]?.isCompleted);
  const nextSteps = data.filter((item: any) => !item.quickStart_Teams[0]?.isCompleted);
  const { userMeta } = useGlobalContext();
  const { createQuickStartTeam } = useQuickStartRequests();
  const navigate = useNavigate();
  const [loadingSteps, setLoadingSteps] = useState<Record<string, boolean>>({});
  const handleComplete = async (completedStep: any) => {
    setLoadingSteps((prev) => ({ ...prev, [completedStep.quickStartId]: true }));
    try {
      const {
        data: { data, success, errors },
      } = await createQuickStartTeam(completedStep.quickStartId);
      if (success) {
        toggleSidebar();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingSteps((prev) => ({ ...prev, [completedStep.quickStartId]: false }));
    }
  };

  const redirectFunction = (step: any) => {
    const projectId = getCookie(process.env.REACT_APP_PROJECT_COOKIE_KEY || MetaKeys.PROJECTID);
    const redirectPath = step.redirectPath.replace('${projectId}', projectId);
    const finalPath = redirectPath.slice(0, redirectPath.length - 1) + '?open=true';
    if (redirectPath && finalPath) {
      navigate(finalPath);
    } else {
      window.open(process.env.REACT_APP_EXTENSION_LINK, '_blank');
    }
    setShowSidebar(!open);
  };

  return (
    <>
      <Offcanvas show={open} onHide={onClose} placement={'end'}>
        <Offcanvas.Header closeButton className='justify-content-end pb-0'></Offcanvas.Header>
        <Offcanvas.Body className='pt-0 pe-4'>
          <div className='d-flex'>
            <div className='w-25 position-relative'>
              <CircularProgressbar
                value={completedSteps.length}
                maxValue={data.length}
                text={nextSteps.length > 0 ? nextSteps.length : ''}
                strokeWidth={12}
                styles={{
                  path: {
                    stroke: `rgb(35,136,86)`,
                  },
                  text: {
                    fill: '#238856',
                    fontSize: '48px',
                    fontWeight: 'bold',
                  },
                }}
              />
              {nextSteps.length === 0 && (
                <div className='greenTickContainer'>
                  <img
                    src={toAbsoluteUrl('/media/logos/tick-green.svg')}
                    alt='tick'
                    className='tickImage'
                  />
                </div>
              )}
            </div>
            <div className='ps-3'>
              <h3>{formatMessage({ id: 'Quick start' })}</h3>
              <p>
                {formatMessage({
                  id: 'Walk through this guide to get the most out of bugs.work right way',
                })}
              </p>
            </div>
          </div>
          <Accordion>
            <Accordion.Button className='px-0'>
              <img src={greenTick} className='pe-1' />
              <h6 className='mb-0 text-238856'>COMPLETED STEP {`(${completedSteps.length})`}</h6>
            </Accordion.Button>
            <Accordion.Body className='px-0 pt-0'>
              {completedSteps.map((data: any) => {
                return (
                  <div className='py-2' key={`${data.quickStartId}`}>
                    <Card className='border-2 border'>
                      <Card.Body className='p-5 pb-0'>
                        <div className='d-flex'>
                          <img
                            src={toAbsoluteUrl(`/media/icons/quick-start/${data.img}`)}
                            alt='data.img'
                            height={30}
                            className='mt-n3'
                          />
                          <h4 className='ps-2'>{data.title}</h4>
                        </div>

                        <Card.Text>{data.description}</Card.Text>
                      </Card.Body>
                      <div className='d-flex justify-content-end p-3 text-238856'>
                        <img src={greenTick} className='pe-1' />
                        <div>Completed</div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </Accordion.Body>
          </Accordion>
          <h6>NEXT STEPS {`(${nextSteps.length})`}</h6>
          {nextSteps.map((data: any) => {
            const projectId = getCookie(process.env.REACT_APP_PROJECT_COOKIE_KEY || MetaKeys.PROJECTID);
            const isProjectIdDefined = data.quickStartId === 2 ? !!projectId : true;
            return (
              <div className='py-2' key={`${data.quickStartId}`}>
                <Card className='border-2 border'>
                  <Card.Body className='p-5 pb-0'>
                    <div className='d-flex'>
                      <img
                        src={toAbsoluteUrl(`/media/icons/quick-start/${data.img}`)}
                        alt='data.img'
                        height={30}
                        className='mt-n3'
                      />
                      <h4 className='ps-2'>{data.title}</h4>
                    </div>
                    <Card.Text>{data.description}</Card.Text>
                  </Card.Body>
                  <div className='d-flex justify-content-end align-items-center p-3'>
                    {data.button && (
                      <>
                        {!isProjectIdDefined && (
                          <InputToolTip
                            tooltipText={formatMessage({
                              id: "Created a project yet? Go to your project's settings to fetch the script code.",
                            })}
                          />
                        )}
                        <button
                          type='button'
                          className='btn btn-primary me-2 custom-height-drawer'
                          onClick={() => redirectFunction(data)}
                          disabled={!isProjectIdDefined}
                        >
                          {data.button}
                        </button>
                      </>
                    )}
                    <GlobalButton
                      buttonText={formatMessage({ id: 'Completed' })}
                      buttonType={ButtonTypes.PRIMARY}
                      onButtonClick={() => {
                        handleComplete(data);
                      }}
                      isLoading={loadingSteps[data.quickStartId] || false}
                    />
                  </div>
                </Card>
              </div>
            );
          })}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
