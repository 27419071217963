import axios from 'axios';
import { API_URL } from '.';
import { MetaKeys } from '@/enums/user';

const USER_META_URL = `${API_URL}/user-meta`;

export const useUserMetaRequests = () => {
  const setUserMetas = (userMetas: { key: MetaKeys; value: string }[]) => {
    return axios.post(
      USER_META_URL,
      {
        userMetas,
      },
      {
        validateStatus() {
          return true;
        },
      }
    );
  };

  const getUserMetas = (userMetaKeys?: string[]) => {
    return axios.get(
      `${USER_META_URL}${userMetaKeys?.length ? `?metaKeys=${userMetaKeys?.join(',')}` : ''}`
    );
  };

  const setSingleUserMeta = (userMeta: { key: MetaKeys; value: string }) => {
    return axios.post(
      `${USER_META_URL}/single`,

      userMeta,

      {
        validateStatus() {
          return true;
        },
      }
    );
  };

  const getSingleUserMeta = (userMetaKey: string) => {
    return axios.get(`${USER_META_URL}/${userMetaKey}`, {
      validateStatus() {
        return true;
      },
    });
  };

  return {
    setSingleUserMeta,
    getSingleUserMeta,
    setUserMetas,
    getUserMetas,
  };
};
