import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

export const TaskTextArea = ({
  displayText,
  onSave,
  rows,
  placeholder,
  clearTextAfterAction,
  onCancel,
  showButtonsByDefault = false,
}: {
  displayText: string;
  onSave: (textareaValue: string) => void;
  rows: number;
  placeholder: string;
  clearTextAfterAction?: boolean;
  onCancel?: () => void;
  showButtonsByDefault?: boolean;
}) => {
  const [textAreaClicked, setTextAreaClicked] = useState<boolean>(false);
  const [textareaValue, setTextareaValue] = useState<string>(displayText);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textAreaRef.current && rows === 1) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [textareaValue, rows]);
  const renderButtons = () => (
    <div className='d-flex flex-end mb-2'>
      <div className='me-2'>
        <Button
          size='sm'
          variant='secondary'
          className='me-2 '
          onClick={() => {
            setTextAreaClicked(false);
            if (clearTextAfterAction) {
              setTextareaValue('');
            }
            onCancel ? onCancel() : '';
          }}
        >
          Cancel
        </Button>
        <Button
          size='sm'
          variant='primary'
          onClick={() => {
            onSave(textareaValue);
            setTextAreaClicked(false);
            if (clearTextAfterAction) {
              setTextareaValue('');
            }
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div
        className='mb-4'
        onClick={() => {
          setTextAreaClicked(true);
        }}
      >
        <textarea
          ref={textAreaRef}
          name='description'
          id='description'
          className='p-3 w-100 text-area-edit'
          placeholder={placeholder}
          rows={rows}
          value={textareaValue}
          onChange={(e) => setTextareaValue(e.target.value)}
        ></textarea>
      </div>
      {textAreaClicked && !showButtonsByDefault && renderButtons()}
      {showButtonsByDefault && renderButtons()}
    </>
  );
};
