import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { TextArea } from '../../shared/components/Input/TextArea';
import { BWInput } from '../../shared/components/Input/BWInput';
import { useGlobalContext } from '../../../core/helpers';

export const ProjectModal = ({
  handleOnSubmit,
  loading,
  projectDetails,
}: {
  handleOnSubmit: any;
  loading?: boolean;
  projectDetails: any;
}) => {
  const { openCreateProjectModal, setopenCreateProjectModal } = useGlobalContext();
  const { formatMessage } = useIntl();
  const initialValues = {
    projectName: projectDetails?.projectId ? projectDetails.title : '',
    projectDescription: projectDetails?.projectId ? projectDetails.description : null,
    projectCode: projectDetails?.projectId ? projectDetails.projectCode : '',
  };

  const projectSchema = Yup.object().shape({
    projectName: Yup.string()
      .max(25, formatMessage({ id: 'Maximum 25 characters' }))
      .required(formatMessage({ id: 'Project title is required' })),
    projectCode: Yup.string()
      .matches(
        /^[a-zA-Z0-9]{2,4}$/,
        formatMessage({ id: 'Minimum 2 and maximum 4 alphanumeric characters allowed' })
      )
      .required(formatMessage({ id: 'Project code is required' })),
    projectDescription: Yup.string()
      .nullable()
      .max(50, formatMessage({ id: 'Maximum 50 characters allowed' })),
  });
  return (
    <>
      <Modal
        show={openCreateProjectModal}
        onHide={() => {
          setopenCreateProjectModal(false);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className=''>
          <div className='font-size-16 fw-bold title-color'>
            {projectDetails?.projectId
              ? formatMessage({ id: 'Update project' })
              : formatMessage({ id: 'Create new project' })}
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={projectSchema}
          onSubmit={(values, formikHelpers) => {
            projectDetails?.projectId
              ? handleOnSubmit(
                  projectDetails?.projectId,
                  values.projectName,
                  values.projectDescription
                )
              : handleOnSubmit(values.projectName, values.projectDescription, values.projectCode);
          }}
          validateOnMount
        >
          {(formik) => {
            useEffect(() => {
              if (formik.values.projectCode && formik.values.projectCode.length >= 5) {
                return;
              }
              if (!projectDetails?.projectId) {
                const value: string = formik.values.projectName.trim();
                let projectCode: string = '';

                const words: string[] = value.split(/\s+/);
                const filteredWords: string[] = words.filter((word) => !!word);
                const alphabeticWords: string[] = filteredWords.map((word) =>
                  word.replace(/[^a-zA-Z0-9]/g, '')
                );

                if (alphabeticWords.length >= 2) {
                  const firstLetters: string[] = alphabeticWords.map((word: string) =>
                    word.charAt(0).toUpperCase()
                  );

                  projectCode = firstLetters.slice(0, 4).join('');
                } else {
                  projectCode = alphabeticWords.join('').substring(0, 4).toUpperCase();
                }
                formik.setFieldValue('projectCode', projectCode);
              }
            }, [formik.values.projectName, projectDetails?.projectId]);
            useEffect(() => {
              const uppercaseValue = formik.values.projectCode.substring(0, 4).toUpperCase();

              formik.setFieldValue('projectCode', uppercaseValue);
            }, [formik.values.projectCode]);
            return (
              <Form>
                <Modal.Body className='pt-2 pb-0 mb-4'>
                  <BWInput
                    fieldType={'text'}
                    fieldName={'projectName'}
                    formik={formik}
                    placeholder={formatMessage({ id: 'Enter project title' })}
                    label={formatMessage({ id: 'Project title' })}
                    isRequired={true}
                  />
                  <div className='mt-5 w-50'>
                    <BWInput
                      fieldType={'text'}
                      fieldName={'projectCode'}
                      formik={formik}
                      placeholder={formatMessage({ id: 'Enter project code' })}
                      label={formatMessage({ id: 'Project code' })}
                      isRequired={true}
                      isDisabled={projectDetails?.projectId ? true : false}
                      toolTipText={formatMessage({ id: 'Project code is non-editable' })}
                    />
                  </div>

                  <TextArea
                    fieldName={'projectDescription'}
                    formik={formik}
                    placeholder={formatMessage({ id: 'Enter project description' })}
                    label={formatMessage({ id: 'Project description' })}
                    isStarRequired={false}
                    isTooltipNotRequired={true}
                    rows={3}
                  />
                </Modal.Body>
                <Modal.Footer className='pt-0 border-top-0'>
                  <GlobalButton
                    buttonText={formatMessage({ id: 'Cancel' })}
                    buttonType={ButtonTypes.SECONDARY}
                    onButtonClick={() => {
                      setopenCreateProjectModal(false);
                    }}
                  />
                  <GlobalButton
                    buttonText={
                      projectDetails?.projectId
                        ? formatMessage({ id: 'Update project' })
                        : formatMessage({ id: 'Create' })
                    }
                    buttonType={ButtonTypes.PRIMARY}
                    isLoading={loading}
                    isDisable={formik.isSubmitting || !formik.isValid || loading}
                    onButtonClick={formik.handleSubmit}
                  />
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
