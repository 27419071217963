export const generateOAuthUrl = (
  baseUrl: string,
  clientId: string,
  redirectUri: string,
  scope: string,
  state: string
) => {
  return `${baseUrl}client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&response_type=code`;
};

export const generateTrelloOAuthURL = (
  BASE_URL: string,
  EXPIRATION: string,
  SCOPE: string,
  RESPONSE_TYPE: string,
  KEY: string,
  STATE: string,
  RETURNURL: string
) => {
  return `${BASE_URL}?expiration=${EXPIRATION}&scope=${SCOPE}&response_type=${RESPONSE_TYPE}&key=${KEY}&state=${STATE}&return_url=${RETURNURL}`;
};
