import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';

import { OAuthConfig } from '@/constants/projects';

import { useIntegrationRequests } from '@/requests/useIntegrationRequests';
import { IntegrationType, JiraMetaData } from '@/types/projects';
import { useGlobalContext } from '../../../../core/helpers';
import { useGithubIntegrationRequests } from '@/requests/useGithubIntegrationRequests';
import { Spinner } from '../../../shared/components/General/Spinner';

export const GithubIntegrationModel = ({
  data,
  handleGithubToggle,
}: {
  data: any;
  handleGithubToggle: any;
}) => {
  const { formatMessage } = useIntl();
  const [orgSelection, setOrgOption] = useState('');
  const [repoSelection, setRepoOption] = useState('');
  const [userList, setUserList] = useState('');
  const [orgList, setOrgList] = useState<string[]>([]);
  const [repoList, setRepoList] = useState([]);
  const [repoDisabled, setRepoDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const urlSchema = Yup.object().shape({
    url: Yup.string().required(formatMessage({ id: 'URL is required' })),
  });
  const { openGithubOauthConfigModal, setOpenGithubOauthConfigModal } = useGlobalContext();
  const { getUserOrganisations, getAllGithubRepos, getUserAccount } =
    useGithubIntegrationRequests();
  const { createIntegrateProject } = useIntegrationRequests();

  const initialValues = {
    orgSelection: data ? JSON.parse(data?.meta_data).organisation : '',
    repoSelection: data ? JSON.parse(data?.meta_data).repository : '',
  };
  const getAllOrganisationList = async (token: string) => {
    try {
      const userDetail = await getUserAccount(token);
      if (userDetail.status === 200) {
        setUserList(userDetail.data.login);
        const { data, status } = await getUserOrganisations(token);
        if (status == 200) {
          let orgNames = data.map((org: any) => {
            return org.login + ' (Org)';
          });
          setOrgList(orgNames);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (data && data.meta_data) {
      const meta_data_object = JSON.parse(data?.meta_data);
      getAllOrganisationList(meta_data_object.token);
      if (meta_data_object.organisation) {
        setOrgOption(meta_data_object.organisation);
        handleOrgSelection(meta_data_object.organisation);
      }
      if (meta_data_object.repository) setRepoOption(meta_data_object.repository);
    }
  }, [data, openGithubOauthConfigModal]);

  const handleOrgSelection = async (targetValue: string) => {
    setOrgOption(targetValue);
    const meta_data_object = JSON.parse(data?.meta_data);
    const token = meta_data_object.token;
    const value = targetValue.split('(Org)')[0].trim();
    const type = !targetValue.endsWith('(Org)') ? 'users' : 'orgs';
    try {
      const { data, status } = await getAllGithubRepos(value, token, type);
      if (status == 200) {
        let repoNames = data.map((repo: any) => {
          return repo.name;
        });
        setRepoDisabled(false);
        setRepoList(repoNames);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleRepoSelection = async (event: any) => {
    setRepoOption(event.target.value);
  };
  const handleProceed = async () => {
    const projectId = data?.projectId;
    const platformName = data?.platformName;
    const status = data?.status;
    const meta_data = JSON.parse(data?.meta_data);
    meta_data.organisation = orgSelection;
    meta_data.repository = repoSelection;
    setLoading(true);
    try {
      const {
        data: { data: value, success, errors },
      } = await createIntegrateProject(meta_data, platformName, projectId, status);
      if (success) {
        setOpenGithubOauthConfigModal(false);
        handleGithubToggle('modal');
        setLoading(false);
        return value;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        show={openGithubOauthConfigModal}
        onHide={() => {
          setOpenGithubOauthConfigModal(false);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className=''>
          <div className='font-size-16 fw-bold title-color'>
            {formatMessage({ id: 'Select organization and repository' })}
          </div>
        </Modal.Header>
        <div className='row p-5'>
          <label
            htmlFor='headerKey'
            className='form-label font-size-14 text-dark text-capitalize mb-4'
          ></label>
          <select
            className='form-select form-select-solid form-select-lg mb-5'
            value={orgSelection}
            onChange={(e) => handleOrgSelection(e.target.value)}
          >
            <option disabled value=''>
              Select account
            </option>
            <option value={userList}>{userList}</option>
            {orgList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className='row p-5'>
          <label
            htmlFor='headerKey'
            className='form-label font-size-14 text-dark text-capitalize mb-4'
          ></label>
          <select
            className='form-select form-select-solid form-select-lg mb-5'
            value={repoSelection}
            onChange={handleRepoSelection}
            disabled={repoDisabled}
          >
            <option disabled value=''>
              Select repository
            </option>
            {repoList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <Formik
          initialValues={{ orgSelection, repoSelection }}
          // validationSchema={urlSchema}
          onSubmit={(values, formikHelpers) => {
            // handleCreateLink(values.url);
          }}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <Modal.Footer className='pt-0 border-top-0'>
                <GlobalButton
                  buttonText={formatMessage({ id: 'Cancel' })}
                  buttonType={ButtonTypes.SECONDARY}
                  onButtonClick={() => {
                    setOpenGithubOauthConfigModal(false);
                  }}
                />
                <GlobalButton
                  buttonText={formatMessage({ id: 'Proceed' })}
                  buttonType={ButtonTypes.PRIMARY}
                  isDisable={formik.isSubmitting || !formik.isValid}
                  onButtonClick={handleProceed}
                  isLoading={loading}
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
