import { useTeamManagementRequests } from '@/requests/useTeamManagementRequests';
import { User } from '@sentry/react';
import { useState } from 'react';

export const useAllTeamUserList = () => {
  const { getAllTeamUsers } = useTeamManagementRequests();
  const [allTeamUsers, setAllTeamUsers] = useState<User[]>([]);

  const handleGetAllTeamUsers = async () => {
    try {
      const {
        data: { data, success, errors },
      } = await getAllTeamUsers();
      if (success) {
        setAllTeamUsers(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return { handleGetAllTeamUsers, allTeamUsers };
};
