import { useIntl } from 'react-intl';
import '../../styles/index.scss';
export const Footer = (props: any) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className={`info d-flex font-size-${props.font} mt-auto m-24px ${
        props.font == '10' ? 'w-100' : ''
      }`}
    >
      <div className={`${props.color == 'gray' ? 'text-gray-700' : 'text-white'} me-auto`}>
        &copy; {new Date().getFullYear()} {formatMessage({ id: 'bugs.work' })}
      </div>
      <a
        href='https://bugs.work/privacy-policy/'
        target='_blank'
        className={`me-24px ${props.color == 'gray' ? 'text-gray-700' : 'text-white'}`}
      >
        {formatMessage({ id: 'Privacy Policy' })}
      </a>
      <a
        href='https://bugs.work/terms-of-use/'
        target='_blank'
        className={`me-24px ${props.color == 'gray' ? 'text-gray-700' : 'text-white'}`}
      >
        {formatMessage({ id: 'Terms of use' })}
      </a>
      <a
        href='mailto:contact@bugs.work'
        target='_blank'
        className={`${props.color == 'gray' ? 'text-gray-700' : 'text-white'}`}
      >
        {formatMessage({ id: 'Support' })}
      </a>
    </div>
  );
};
