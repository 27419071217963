import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useUtmValidator } from '../../../hooks/useUtmValidator';
import microsoftLogo from '../../../core/assets/images/svg/microsoft-logo.svg';
import { useSocialLogin } from '../../../hooks/useSocialLogin';

const MicrosoftLoginButton = () => {
  const { handleUtmEvents } = useUtmValidator();
  const { formatMessage } = useIntl();
  const { handleMicrosoftLogin, loading } = useSocialLogin();

  useEffect(() => {
    handleUtmEvents();
  }, []);

  return (
    <>
      <div className='col-12'>
        <button
          type='button'
          onClick={handleMicrosoftLogin}
          disabled={loading}
          className='btn py-3 px-5 w-100 h-48px border border-primary microsoftLoginBtn'
        >
          {!loading && (
            <>
              <img src={microsoftLogo} alt='microsoft logo' />
              <span className='indicator-label font-size-14 ps-4'>
                {formatMessage({ id: 'Login with Microsoft' })}
              </span>
            </>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {formatMessage({ id: 'Please wait...' })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default MicrosoftLoginButton;
