import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonTypes } from '@/enums';
import setting from '../../../../core/assets/images/svg/bugs.work/setting.svg';
import { toAbsoluteUrl } from '../../../../core/helpers';

export const GlobalButton = ({
  buttonText,
  buttonType,
  isLoading,
  isDisable,
  onButtonClick,
  isImage,
  src,
  alt,
}: {
  buttonText: string;
  buttonType: string;
  isLoading?: boolean;
  isDisable?: boolean;
  onButtonClick: () => void;
  isImage?: boolean;
  src?: string;
  alt?: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <button
        type='button'
        disabled={isDisable || isLoading}
        onClick={onButtonClick}
        className={`${isLoading && 'opacity-50'} ${
          buttonType === ButtonTypes.PRIMARY
            ? 'primary_button'
            : buttonType === ButtonTypes.SECONDARY
            ? 'secondary_button primary-color'
            : 'button_link'
        } d-flex align-items-center justify-content-center custom-button-property`}
      >
        {isLoading ? (
          <span className={`indicator-label font-size-13 p-0 opacity-50`}>
            {formatMessage({ id: 'Please wait...' })}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <>
            {isImage ? (
              <div className='d-flex'>
                <span className={`indicator-label font-size-14 text-nowrap`}>
                  <img className='me-2' src={src} alt={alt} />
                </span>
                <div className='text-end'>{buttonText}</div>
              </div>
            ) : (
              <span className={`indicator-label font-size-14 text-nowrap`}>{buttonText}</span>
            )}
          </>
        )}
      </button>
    </>
  );
};
