import React, { useEffect, useState } from 'react';
import '../../styles/project.scss';
import { BaseProject } from '@/types/projects';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { FaRegCopy } from 'react-icons/fa6';

export const ScriptCode = ({ projectDetails }: { projectDetails: BaseProject | null }) => {
  const [buttonText, setButtonText] = useState<string>('Copy code');
  const { formatMessage } = useIntl();
  const codeString = projectDetails
    ? `
  <script src="${process.env.REACT_APP_SCRIPT_URL}"></script>
  <script>
  bugswork.initProject("${projectDetails.projectUuid}");
  </script>
  `
    : '';
  const handleCopy = () => {
    navigator.clipboard.writeText(codeString).then(() => {
      setButtonText('Copied');
      setTimeout(() => {
        setButtonText('Copy code');
      }, 2000);
    });
  };

  return (
    <div className='script-code-box card font-size-14 fw-600'>
      <div className='d-flex flex-row justify-content-between px-5 px-lg-7 pt-4'>
        <label className='font-weight-500 font-size-16'>
          {formatMessage({ id: 'Integrate bugs.work with your web app' })}
        </label>
      </div>

      <label className='font-weight-400 font-size-14 px-5 px-lg-7 py-4'>
        {formatMessage({
          id: 'Copy and paste this code in your website or app head tag to integrate bugs.work.',
        })}
      </label>
      <div className='profile-email text-end mb-2 mx-3'>
        {formatMessage({ id: 'Having trouble?' })}
        <a target='_blank' href='https://calendly.com/bugs-work/15min'>
          {' '}
          {formatMessage({ id: 'Book a free session with our technical team' })}
        </a>
      </div>
      {!projectDetails ? (
        <Skeleton height={100} style={{ marginTop: '10px' }} />
      ) : (
        <div className='script-background'>
          <div className='cursor-pointer font-size-12 text-end me-3 pt-2' onClick={handleCopy}>
            <span className='me-1'>
              <FaRegCopy className='text-white' size={'15px'} />
            </span>
            <label className='text-white'>{buttonText}</label>
          </div>
          <pre className='text-white px-4 m-0'>{codeString}</pre>
        </div>
      )}
    </div>
  );
};
