import axios from 'axios';
import { API_URL } from '.';

const TIME_ZONE_URL = `${API_URL}/timezones`;

export const useTimeZoneRequests = () => {
  const getTimeZone = () => {
    return axios.get(TIME_ZONE_URL);
  };

  return {
    getTimeZone,
  };
};
