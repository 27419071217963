export enum UserAvatarSize {
  SMALL,
  MEDIUM,
  LARGE,
}

export enum UserAvatarDisplayName {
  HOVER,
  SIDE,
}

export enum MetaKeys {
  LOCALE = 'locale',
  DATEFORMAT = 'dateFormat',
  TIMEFORMAT = 'timeFormat',
  TIMEZONE = 'timezone',
  PROJECTID = 'projectId',
  CURRENT_SELECTION = 'currentSelection',
}
