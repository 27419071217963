export const PageHeading = ({
  title,
  description,
}: {
  title: string | undefined;
  description: string | undefined;
}) => {
  return (
    <div className='mb-4'>
      <div className='font-weight-600 font-size-20 title-color mb-1'>{title}</div>
      <div className='font-weight-400 font-size-12 title-color'>{description}</div>
    </div>
  );
};
