import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useGlobalContext } from '../core/helpers';
import { useTimeZoneRequests } from '@/requests/useTimeZoneRequests';

export const useTimeFormat = () => {
  const { userMeta } = useGlobalContext();
  const { getTimeZone } = useTimeZoneRequests();
  const [timezone, setTimezone] = useState<string>('Asia/Kolkata');
  const handleTimeZone = async (timezone: any) => {
    try {
      const {
        data: { data, success },
      } = await getTimeZone();
      if (success) {
        const matchingTimeZone = data.find((item: any) => {
          return item.timezoneId === parseInt(timezone);
        });
        if (matchingTimeZone) {
          setTimezone(matchingTimeZone.iana_title);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (userMeta?.timezone) handleTimeZone(userMeta.timezone);
  }, []);
  const getTimeValue = (time: any) => {
    const time_value = userMeta?.timeFormat;
    if (time_value) {
      if (time_value === 'H:m:s') {
        return DateTime.fromISO(time).setZone(timezone).toFormat('H:m:s');
      } else if (time_value === 'HH:mm:ss') {
        return DateTime.fromISO(time).setZone(timezone).toFormat('HH:mm:ss');
      } else if (time_value === 'h:m:s a') {
        return DateTime.fromISO(time).setZone(timezone).toFormat('h:m:s a');
      } else if (time_value === 'h:mm:ss a') {
        return DateTime.fromISO(time).setZone(timezone).toFormat('h:mm:ss a');
      } else if (time_value === 'h:mm:ss A') {
        return DateTime.fromISO(time).setZone(timezone).toFormat('h:mm:ss A');
      } else if (time_value === 'hh:mm:ss a') {
        return DateTime.fromISO(time).setZone(timezone).toFormat('hh:mm:ss a');
      }
    } else {
      return DateTime.fromISO(time).setZone(timezone).toFormat('H:m:s');
    }
  };

  return { getTimeValue };
};
