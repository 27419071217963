import { MetaKeys } from '@/enums/user';
import { useGlobalContext } from '../core/helpers';
import { removeCookie } from '../util/cookie';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { getAuth } from '../modules/auth';
export const useLogout = () => {
  const { getCurrentUser, setCurrentUser, setUserToken, setAuth, setUserMeta, setPlans } =
    useGlobalContext();
  const { logoutApi } = useAuthRequests();
  const auth = getAuth();

  const logout = async () => {
    if (auth?.refreshToken) {
      await logoutApi(auth.refreshToken);
    }
    removeCookie(process.env.REACT_APP_PROJECT_COOKIE_KEY || MetaKeys.PROJECTID);
    await getCurrentUser(undefined);
    setCurrentUser(undefined);
    setUserToken(undefined);
    setPlans([]);
    setUserMeta(undefined);
    setAuth(undefined);
  };

  return { logout };
};
