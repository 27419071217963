import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Select from 'react-select';
import { Form, Formik } from 'formik';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { selectedOptionType } from '@/types';
import { BaseUser } from '@/types/user';
import { Link } from 'react-router-dom';

export const AddUserModal = ({
  openAddUsersModal,
  setAddUsersModal,
  projectUserList,
  allTeamUsers,
  onUserSelected,
}: {
  openAddUsersModal: boolean;
  setAddUsersModal: Dispatch<SetStateAction<boolean>>;
  projectUserList: any[];
  allTeamUsers: any;
  onUserSelected: (selectedUser: selectedOptionType | null) => void;
}) => {
  const { formatMessage } = useIntl();
  const [filteredTeamUsers, setFilteredTeamUsers] = useState<BaseUser[]>([]);
  const [selectedOption, setSelectedOption] = useState<selectedOptionType | null>(null);
  const [addUserLoading, setAddUserLoading] = useState<boolean>(false);

  const urlSchema = Yup.object().shape({
    url: Yup.string().required(formatMessage({ id: 'URL is required' })),
  });

  const initialValues = {
    url: '',
  };

  const handleSelectChange = (selectedOption: selectedOptionType | null) => {
    if (selectedOption) {
      setSelectedOption(selectedOption);
    }
  };

  useEffect(() => {
    const projectUserEmails = projectUserList.map((user: any) => user.email);
    const filteredData = allTeamUsers?.filter(
      (user: any) => !projectUserEmails.includes(user.email)
    );
    setFilteredTeamUsers(filteredData);
  }, [allTeamUsers, projectUserList]);

  return (
    <>
      <Modal
        dialogClassName='user-modal'
        show={openAddUsersModal}
        onHide={() => {
          setAddUsersModal(false);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className=''>
          <div className='font-size-16 fw-bold title-color'>
            {formatMessage({ id: 'Add project member' })}
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={urlSchema}
          onSubmit={(values, formikHelpers) => {}}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <Modal.Body className='pt-2 pb-0'>
                <div>
                  <label className='title-color font-weight-400 font-size-14 mt-2'>
                    {formatMessage({
                      id: 'Allow members from your organisation to access this project. If a user is not added in your organisation',
                    })}
                    ,{' '}
                    <Link to='/organisation' className='link-primary'>
                      {formatMessage({ id: 'you can invite them' })}
                    </Link>
                    .
                  </label>
                </div>
                <div className='mt-8 mb-8'>
                  <div className='d-flex flex-column project-member'>
                    <Select
                      className='border rounded-1'
                      classNamePrefix='team-dropdown'
                      name='teamDropdown'
                      value={selectedOption}
                      onChange={handleSelectChange}
                      options={filteredTeamUsers?.map((user: any) => ({
                        value: user.userId,
                        label: `${user.firstName} ${user.lastName} (${user.email})`,
                        id: user.userId,
                      }))}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className='pt-0 border-top-0'>
                <GlobalButton
                  buttonText={formatMessage({ id: 'Cancel' })}
                  buttonType={ButtonTypes.SECONDARY}
                  onButtonClick={() => {
                    setAddUsersModal(false);
                  }}
                />
                <div>
                  <GlobalButton
                    buttonText={formatMessage({ id: 'Add' })}
                    buttonType={ButtonTypes.PRIMARY}
                    isLoading={addUserLoading}
                    isDisable={!selectedOption}
                    onButtonClick={() => {
                      onUserSelected(selectedOption);
                    }}
                  />
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
