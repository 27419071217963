import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../core/helpers';
import { useProjectRequests } from '@/requests/useProjectRequests';

const useProjectList = () => {
  const { getAllProjects } = useProjectRequests();
  const [projectListLoading, setProjectListLoading] = useState(false);
  const { formatMessage } = useIntl();

  const getAllProjectList = async () => {
    try {
      setProjectListLoading(true);
      const {
        data: { data, success, errors },
      } = await getAllProjects();
      if (success) {
        setProjectListLoading(false);
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProjectListLoading(false);
    }
  };
  return { getAllProjectList, projectListLoading };
};

export default useProjectList;
