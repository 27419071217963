import axios from 'axios';
import { API_URL } from '.';

const USERS_FOR_PROJECT = `${API_URL}/user-project`;

export const useUserProjectRequests = () => {
  const getAllUsers = (projectId: number | undefined) => {
    return axios.get(`${USERS_FOR_PROJECT}?projectId=${projectId}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const addUser = (projectId: number | undefined, userId: number | string) => {
    const payload = {
      projectId: projectId,
      userId: userId,
    };
    return axios.post(`${USERS_FOR_PROJECT}`, payload, {
      validateStatus(status: any) {
        return true;
      },
    });
  };

  const deleteUser = (projectId: number | null, userId: number | null) => {
    return axios.delete(`${USERS_FOR_PROJECT}/${userId}?projectId=${projectId}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  return {
    getAllUsers,
    addUser,
    deleteUser,
  };
};
