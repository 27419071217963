import { ButtonTypes } from '@/enums';
import { useTaskRequests } from '@/requests/useTaskRequests';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import useAllUserList from '../../../../hooks/useUserList';
import { BaseUser, User } from '../../../../types/user';
import { toAbsoluteUrl, useGlobalContext } from '../../../../core/helpers';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import cross from '../../../../core/assets/images/svg/bugs.work/cross.svg';
import '../../styles/task-page.scss';
import { TaskHeader } from './TaskHeader';
import { TaskInfo } from './TaskInfo';
import { CommentEntity, DetailedTask, Task } from '@/types/projects';
import { TaskTabs } from './TaskTabs';
import { UnauthorizedPage } from '../../../errors/UnauthorizedPage';
import { ConfirmationModal } from '../../../shared/components/ConfirmationModal';
import { FileObject, TemporaryFile, UploadFile } from '@/types/file';
import FileDisplay from '../../../shared/components/file/FileDisplay';
import FileUpload from '../../../shared/components/file/FileUpload';
import { FileDisplayType, FileType } from '@/enums/file';
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row } from 'react-bootstrap';
import FileTypes from '../../../shared/components/file/FileTypes';
import { TaskTextArea } from '../../../shared/components/Input/TaskTextArea';
import { NoDataComponent } from '../../../shared/components/NoDataComponent';
import Skeleton from 'react-loading-skeleton';
import { processUploadedFiles } from '../../../../util/onUploadFile';

// TODO: Update this component same as the comments on task modal component
const TaskPage = () => {
  const { updateTask, getSingleTaskData, deleteTask, getTaskPublicData } = useTaskRequests();
  const { taskCode, uuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchUserList } = useAllUserList();
  const { formatMessage } = useIntl();
  const [showAllComments, setShowAllComments] = useState<boolean>(false);
  const { userList } = useGlobalContext();
  const [showDeleteModal, setDeleteShowModal] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<DetailedTask>();
  const [loading, setLoading] = useState(false);
  const [commentList, setCommentList] = useState<CommentEntity[]>([]);
  const [isTaskEdit, setIsTaskEdit] = useState(true);
  const [assigneeUser, setAssigneeUser] = useState<BaseUser | undefined>();
  const [isTaskNotFound, setIsTaskNotFound] = useState(false);
  const [isTaskNotPublic, setIsTaskNotPublic] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [file, setFile] = useState<TemporaryFile[] | FileObject[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [uuidDataLoading, setUuidDataLoading] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [isFree, setIsFree] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const free = searchParams.get('f');
  useEffect(() => {
    if (taskCode) {
      setIsPublic(false);
      handleSingleTaskData(taskCode);
    } else {
      if (uuid) {
        setIsPublic(true);
        handlePublicTaskData(uuid);
      }
    }
  }, [taskCode, uuid]);

  async function handleSingleTaskData(taskCode: string) {
    setLoading(true);
    setIsTaskEdit(true);
    try {
      const {
        data: { data, success, errors },
      } = await getSingleTaskData(taskCode);
      if (success) {
        setTaskData(data);
        setFile(data?.files);
        setCommentList(data?.comment);
        if (data?.assignee) {
          setAssigneeUser(data?.assignee);
        }
      } else {
        setIsTaskNotFound(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function handlePublicTaskData(uuid: string) {
    setUuidDataLoading(true);
    try {
      const {
        data: { data, success, errors },
      } = await getTaskPublicData(uuid);
      if (success) {
        setTaskData(data);
        setFile(data?.files);
        setCommentList(data?.comment);
        if (data?.assignee) {
          setAssigneeUser(data?.assignee);
        }
      } else {
        setIsTaskNotPublic(true);
        setErrorMessage(errors?.[0] || 'The task is not public.');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUuidDataLoading(false);
    }
  }

  useEffect(() => {
    if (taskData?.projectId && !isPublic) {
      fetchUserList(taskData.projectId);
    }
  }, [taskData, uuid]);

  const taskSchema = Yup.object().shape({
    description: Yup.string()
      .max(2000, formatMessage({ id: 'Maximum 2000 characters' }))
      .required(formatMessage({ id: 'Description is required' })),
  });

  const initialValues = {
    description: taskData?.description,
    priority: taskData?.priority,
    status: taskData?.status,
    assignedUser: null,
    file: null,
  };

  const handleEditTask = async (taskData: DetailedTask, newFiles?: any[]) => {
    try {
      let payload: any = {
        description: taskData.description,
        status: taskData.status,
        priority: taskData.priority,
        projectId: taskData?.projectId,
        assigneeId: taskData.assigneeId,
      };

      if (newFiles) {
        payload['files'] = newFiles;
      }

      const {
        data: { success },
      } = await updateTask(payload, taskData.taskId);

      if (success) {
        toast.success('Task updated successfully');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onUploadSuccess = (filesArray: File[], fileIds: string[], filesKey: any[]) => {
    const filesData = filesArray.map((file, index) => ({
      fileName: file.name,
      contentType: file.type,
    }));
    setFiles(filesData);
    setFile((prevFiles: TemporaryFile[]) => {
      const newFiles = processUploadedFiles(filesArray, fileIds, filesKey);
      const fileMap = new Map<string, TemporaryFile>();

      // Add previous files to the map
      for (const file of prevFiles) {
        fileMap.set(file.fileName, file);
      }

      // Add new files to the map only if they don't already exist
      for (const file of newFiles) {
        if (!fileMap.has(file.fileName)) {
          fileMap.set(file.fileName, file);
        }
      }

      // Convert map back to array
      return Array.from(fileMap.values());
    });
    if (taskData) {
      handleEditTask(taskData, filesData);
    }
  };

  const handleDeleteTask = async (taskId?: number) => {
    try {
      setDeleteLoading(true);
      const {
        data: { success },
      } = await deleteTask(taskId!);
      if (success) {
        toast.success(formatMessage({ id: 'Task deleted successfully' }));
        navigate(`/project/${taskData?.projectId}/task-board`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      setDeleteShowModal(false);
    }
  };

  const deleteFile = (fileId: string) => {
    if (taskData?.taskId) {
      const filteredFile = file.filter((file) => file.fileName === fileId);
      handleEditTask(taskData, filteredFile);
    } else {
      setFiles(files.filter((file) => file.fileName !== fileId));
    }

    setFile((prevFiles) => {
      return prevFiles.filter((file) => file.fileName !== fileId);
    });
    setIndex(0);
  };
  const setActiveIndex = (value: number) => {
    setIndex(value);
  };
  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    if (free) {
      setIsFree(true);
    } else {
      setIsFree(false);
    }
  }, []);
  const Carouselcode = (
    <>
      <Container className='d-flex justify-content-center align-items-center carousel-container '>
        {file && file.length > 0 && (
          <Carousel
            className={`flex-grow-1 ${file.length > 1 ? '' : 'hide-arrows'}`}
            variant={'dark'}
            slide={false}
            fade={false}
            indicators={false}
            interval={null}
            activeIndex={index}
            controls={true}
            onSelect={handleSelect}
          >
            {file &&
              file.map((file, index) => (
                <Carousel.Item key={index} className='carousel-image-container'>
                  <FileTypes file={file} isFullScreen={true} isExpand={true} />
                </Carousel.Item>
              ))}
          </Carousel>
        )}
      </Container>
      <span className='d-none  d-lg-block image-line-break my-2'></span>
      <div className='d-flex  justify-content-md-center align-items-center flex-wrap mt-3 gap-4'>
        {isTaskEdit &&
          file &&
          file.map((file, index) => (
            <div onClick={() => setActiveIndex(index)}>
              <FileDisplay
                file={file}
                fileDisplayType={FileDisplayType.THUMBNAIL}
                deleteFile={deleteFile}
              />
            </div>
          ))}
      </div>
    </>
  );

  return (
    <>
      {!loading && isTaskNotFound ? (
        <UnauthorizedPage />
      ) : !loading && isTaskNotPublic ? (
        <UnauthorizedPage customMessage={errorMessage} />
      ) : (
        <div className='task-page'>
          <Formik
            initialValues={initialValues}
            validationSchema={taskSchema}
            onSubmit={(values, formikHelpers) => {}}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <>
                  {isPublic && (
                    <Link to='/'>
                      <div className=' h-60px public-page-border bg-white d-flex align-items-center py-3 px-8'>
                        <img src={toAbsoluteUrl('/media/logos/bugswork-dark.svg')} alt='logo' />
                      </div>
                    </Link>
                  )}
                  <div className='d-flex flex-row'>
                    {!(loading || uuidDataLoading) && file.length === 0 ? (
                      <div
                        className={`w-100 me-2 d-none d-lg-block ${isPublic ? 'mt-1' : '-mt-23'}`}
                      >
                        {isFree && (
                          <div className='mb-2 alert alert-warning' style={{ border: '1px solid' }}>
                            {formatMessage({
                              id: 'Upgrade to a paid plan to access tasks and attachments via public links and also in your task management tool.',
                            })}
                          </div>
                        )}
                        <NoDataComponent
                          title={formatMessage({ id: 'No attached file found' })}
                          subtitle={formatMessage({
                            id: 'No attachment available at the moment for this task.',
                          })}
                          buttonText={isPublic ? undefined : formatMessage({ id: 'Upload' })}
                          buttonClick={() => {
                            const fileInput = document.getElementById('fileInput');
                            if (fileInput) {
                              fileInput.click();
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        {isPublic && uuidDataLoading ? (
                          <div className='d-flex flex-column w-100'>
                            <div className='d-flex justify-content-center mb-15'>
                              <Skeleton height={350} width={300} />
                            </div>
                            <div className='d-flex flex-row gap-4 justify-content-center mt-15'>
                              <Skeleton height={110} width={110} />
                              <Skeleton height={110} width={110} />
                            </div>
                          </div>
                        ) : loading ? (
                          <div className='d-flex flex-column w-100'>
                            <div className='d-flex justify-content-center mb-15'>
                              <Skeleton height={350} width={300} />
                            </div>
                            <div className='d-flex flex-row gap-4 justify-content-center mt-15'>
                              <Skeleton height={110} width={110} />
                              <Skeleton height={110} width={110} />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              className={`d-none  d-lg-block carousel-code flex-grow-1 ${
                                isPublic ? 'mt-8' : 'public-margin'
                              }`}
                            >
                              {isFree && (
                                <div
                                  className='me-2 mb-2 alert alert-warning'
                                  style={{ border: '1px solid' }}
                                >
                                  {formatMessage({
                                    id: 'Upgrade to a paid plan to access tasks and attachments via public links and also in your task management tool.',
                                  })}
                                </div>
                              )}
                              {Carouselcode}
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {!taskData?.taskCode && isPublic ? (
                      <div className='px-3 d-flex flex-column task-details col-lg-6 mt-2'>
                        <div className='mt-3 w-25'>
                          <Skeleton height={25} />
                        </div>
                        <div className='mt-6 w-75'>
                          <Skeleton height={20} />
                        </div>
                        <div className='mt-5'>
                          <Skeleton height={160} />
                        </div>
                        <div className='mt-5'>
                          <Skeleton height={110} />
                        </div>
                      </div>
                    ) : loading ? (
                      <div className='px-3 d-flex flex-column task-details col-lg-6'>
                        <div className='mt-3 w-25'>
                          <Skeleton height={25} />
                        </div>
                        <div className='mt-8'>
                          <Skeleton height={120} />
                        </div>
                        <div className='mt-5'>
                          <Skeleton height={160} />
                        </div>
                        <div className='mt-5'>
                          <Skeleton height={110} />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`d-flex flex-column task-details ${
                          isPublic ? 'task-details-public-page' : ''
                        } col-lg-6`}
                      >
                        <div className='mx-4'>
                          <div className='mb-5'>
                            <div className='mt-3'>
                              <TaskHeader
                                taskCode={taskData?.taskCode as string}
                                setDeleteShowModal={setDeleteShowModal}
                                isTaskDetailPage={true}
                                publicUuid={taskData?.public_uuid!}
                                isPublic={isPublic}
                              />
                            </div>
                          </div>

                          {isPublic && taskData ? (
                            <div className='font-weight-400 font-size-12 title-color mb-5'>
                              {taskData?.description}
                            </div>
                          ) : (
                            taskData && (
                              <TaskTextArea
                                displayText={taskData?.description!}
                                onSave={(textAreaValue: string) => {
                                  if (isTaskEdit) {
                                    const updatedTaskData = {
                                      ...taskData,
                                      description: textAreaValue,
                                    };
                                    handleEditTask(updatedTaskData);
                                  }
                                }}
                                placeholder='Edit task description'
                                rows={5}
                              />
                            )
                          )}

                          <div className='d-flex flex-row'>
                            {!isPublic && (
                              <div className='mb-4 w-100'>
                                <FileUpload
                                  onUploadSuccess={onUploadSuccess}
                                  projectId={taskData?.projectId!}
                                  fullWidth={true}
                                  length={file.length}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {taskData && (
                          <div className='mb-4 mx-4'>
                            <TaskInfo
                              isTaskEdit={isTaskEdit}
                              formik={formik}
                              handleEditTask={handleEditTask}
                              taskData={taskData}
                              assigneeUser={assigneeUser}
                              setAssigneeUser={setAssigneeUser}
                              taskDetailPage={true}
                              userList={userList}
                              isPublic={isPublic}
                            />
                          </div>
                        )}

                        <span className='d-none d-lg-block line-break'></span>
                        {file && file.length > 0 ? (
                          <div className='d-lg-none'>{Carouselcode}</div>
                        ) : (
                          <div className='w-100 me-2 d-lg-none'>
                            <NoDataComponent
                              title={formatMessage({ id: 'No files attached' })}
                              subtitle={formatMessage({
                                id: 'bugs.work supports most common file formats such as images, videos, PDFs and documents.',
                              })}
                              buttonText={isPublic ? undefined : formatMessage({ id: 'Upload' })}
                              buttonClick={() => {
                                const fileInput = document.getElementById('fileInput');
                                if (fileInput) {
                                  fileInput.click();
                                }
                              }}
                            />
                          </div>
                        )}

                        <span className='d-lg-none line-break mt-4'></span>
                        <div className='pt-4 mx-4 all-tabs-content'>
                          {isTaskEdit && taskData && (
                            <TaskTabs
                              formik={formik}
                              taskId={taskData?.taskId ?? null}
                              commentList={commentList}
                              setCommentList={setCommentList}
                              showAllComments={showAllComments}
                              setShowAllComments={setShowAllComments}
                              userList={userList}
                              systemInfo={taskData?.system_info ?? null}
                              consoleInfo={taskData?.console ?? null}
                              networkInfo={taskData?.network ?? null}
                              projectIdFromTaskData={taskData?.projectId}
                              isPublic={isPublic}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </Form>
            )}
          </Formik>
        </div>
      )}

      <ConfirmationModal
        showConfirmationModal={showDeleteModal}
        setShowConfirmationModal={setDeleteShowModal}
        headingMessage={formatMessage({ id: 'Delete Task' })}
        description={formatMessage({
          id: 'Are you sure you want to delete this task permanently?',
        })}
        handleDelete={() => handleDeleteTask(taskData?.taskId)}
        deleteLoading={deleteLoading}
        id={taskData?.taskId}
      />
    </>
  );
};
export default TaskPage;
