import React, { useEffect, useState } from 'react';
import '../styles/index.scss';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { SelectInput } from '../../shared/components/Input/SelectInput';
import { userInitialValues, colorCodes } from '@/constants/profile';
import { toAbsoluteUrl, useGlobalContext } from '../../../core/helpers';
import { ChangePasswordModal } from '../../auth/components/ChangePasswordModal';
import { toast } from 'react-toastify';
import { ConfirmChangePasswordModal } from '../../auth/components/ConfirmChangePasswordModal';
import { DeleteUserConfirmationModal } from '../components/DeleteUserConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes, InputTypes } from '@/enums';
import { CountryModel } from '../../../types';
import { PageHeading } from '../../shared/components/PageHeading';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { useLocationRequests } from '@/requests/useLocationRequests';
import { useUserRequests } from '@/requests/useUserRequests';
import UserDropdown from '../../shared/components/UserDropdown';
import { BaseUser, User } from '@/types/user';
import { useLogout } from '../../../hooks/useLogout';
import { BWInput } from '../../shared/components/Input/BWInput';
import Skeleton from 'react-loading-skeleton';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';

export function Profile() {
  const { profileData, updateProfileInfo, deleteUserPermanently } = useUserRequests();
  const { forgotPassword } = useAuthRequests();
  const { getCountry } = useLocationRequests();
  const { formatMessage } = useIntl();
  const { currentUser, getCurrentUser, setUserToken } = useGlobalContext();
  const { logout } = useLogout();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const [, setHasErrors] = useState<boolean | undefined>();
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [deleteUserModalShow, setDeleteUserModalShow] = useState(false);
  const [userUpdatedAt, setuserUpdatedAt] = useState<string>('');
  const specificGMTTime = new Date(userUpdatedAt);
  specificGMTTime.setSeconds(specificGMTTime.getSeconds() + 300);
  const navigate = useNavigate();
  const [countryOptions, setcountryOptions] = useState<any[]>([]);
  const [getApiLoading, setApiLoading] = useState(false);
  const [colorDropdown, setColorDropdown] = useState<BaseUser[]>();
  const [runUserDetailsApi, setRunUserDetailsApi] = useState(false);
  const userSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, formatMessage({ id: 'Minimum 3 characters' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'First name is required' })),
    lastName: Yup.string()
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Last name is required' }))
      .nullable(),
    email: Yup.string()
      .email(formatMessage({ id: 'Invalid email format' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Email is required' })),
    contact: Yup.string().nullable(),
    country: Yup.string()
      .required(formatMessage({ id: 'Country is required' }))
      .nullable(),
    color: Yup.string().nullable(),
  });

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      let {
        data: { data: updatedUserData, success, errors },
      } = await updateProfileInfo({ ...values, countryId: parseInt(values.country) });

      if (success) {
        getCurrentUser(updatedUserData);
        setUserToken(updatedUserData.accessToken);
        const {
          data: { success, data, errors },
        } = await profileData();
        if (success) {
          userInitialValues.firstName = data.firstName;
          userInitialValues.lastName = data.lastName;
          userInitialValues.contact = data.contact!;
          userInitialValues.email = data.email;
          userInitialValues.color = data.color;
        }

        setLoading(false);
        toast.success(formatMessage({ id: 'Profile updated successfully' }));
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getForgotedPassword = async () => {
    if (currentUser?.email) {
      try {
        setChangePasswordLoading(true);
        const {
          data: { success },
        } = await forgotPassword(currentUser?.email);
        if (success) {
          setConfirmModalShow(false);
          setShowModal(true);
          setHasErrors(false);
        } else {
          setHasErrors(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setChangePasswordLoading(false);
      }
    }
  };
  const deleteUser = async () => {
    try {
      setDeleteUserLoading(true);
      const {
        data: { success },
      } = await deleteUserPermanently();
      if (success) {
        setConfirmModalShow(false);
        logout();
        navigate('/login');
        setDeleteUserLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteUserLoading(false);
    }
  };

  useEffect(() => {
    let x = colorCodes.map((hexCode) => ({
      userId: currentUser?.userId,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      color: hexCode,
    }));
    setColorDropdown(x as BaseUser[]);
    const fetchCountry = async () => {
      const {
        data: { data: countries },
      } = await getCountry();
      const countriesData = countries.map((country: CountryModel) => {
        return {
          id: country.countryId,
          name: country.country_name,
          value: country.countryId,
        };
      });
      setcountryOptions([...countriesData]);
    };

    fetchCountry();
  }, []);

  const getProfileInfo = async () => {
    try {
      setApiLoading(true);
      const {
        data: { success, data, errors },
      } = await profileData();
      if (success) {
        setApiLoading(false);
        userInitialValues.firstName = data.firstName;
        userInitialValues.lastName = data.lastName;
        userInitialValues.email = data.email;
        userInitialValues.contact = data.contact || '';
        userInitialValues.country = data.countryId!;
        userInitialValues.color = data.color;
        setRunUserDetailsApi(false);
        setuserUpdatedAt(data.updatedAt);
      } else if (errors) {
        setApiLoading(false);
        setRunUserDetailsApi(false);
      }
    } catch (err) {
      setApiLoading(false);
      console.log(err);
      setRunUserDetailsApi(false);
    }
  };

  useEffect(() => {
    getProfileInfo();
  }, [runUserDetailsApi]);

  // function setAvatarColor(user: BaseUser) {
  //   console.log(user);
  //   formik.setFieldValue('projectCode', projectCode);
  // }
  return (
    <>
      <div className='d-flex flex-row justify-content-between'>
        <PageHeading
          title={formatMessage({ id: 'Profile' })}
          description={formatMessage({
            id: 'You can manage your profile details here.',
          })}
        />
      </div>

      <div className='g-5 g-xxl-8 p-8 card w-100'>
        <div className='col-xl-12 d-md-flex mt-0'>
          <div className='w-100'>
            <Formik
              initialValues={userInitialValues}
              validationSchema={userSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form>
                    {getApiLoading ? (
                      <div className='mb-5'>
                        <div className='row d-flex mt-10'>
                          <div className='col-md-6 col-12 ps1'>
                            <Skeleton height={40} />
                          </div>
                          <div className='col-md-6 col-12'>
                            <Skeleton height={40} />
                          </div>
                        </div>
                        <div className='row d-flex mt-10'>
                          <div className='col-md-6 col-12 ps1'>
                            <Skeleton height={40} />
                          </div>
                          <div className='col-md-6 col-12'>
                            <Skeleton height={40} />
                          </div>
                        </div>
                        <div className='row d-flex mt-10'>
                          <div className='col-md-6 col-12 ps1'>
                            <Skeleton height={40} />
                          </div>
                          <div className='col-md-6 col-12'>
                            <Skeleton height={40} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className='row d-flex'>
                          <div className='col-md-6 col-12 ps1'>
                            <BWInput
                              fieldType={InputTypes.TEXT}
                              label={formatMessage({ id: 'First Name' })}
                              fieldName={'firstName'}
                              formik={formik}
                              placeholder=''
                              toolTipText={formatMessage({
                                id: 'GLOBAL.TOOLTIP.USER.FIRST_NAME',
                              })}
                              isRequired={true}
                            />
                          </div>
                          <div className='col-md-6 col-12'>
                            <BWInput
                              fieldType={InputTypes.TEXT}
                              label={formatMessage({ id: 'Last Name' })}
                              fieldName={'lastName'}
                              formik={formik}
                              placeholder=''
                              toolTipText={formatMessage({
                                id: 'GLOBAL.TOOLTIP.USER.LAST_NAME',
                              })}
                              isRequired={true}
                            />
                          </div>
                        </div>
                        <div className='row d-flex '>
                          <div className='col-md-6 col-12 ps1'>
                            <div className='profile-email text-end'>
                              <a href='mailto:contact@bugs.work?subject=Hi%2C%20I%20want%20to%20change%20my%20bugs.work%20email%20address'>
                                {formatMessage({ id: 'Want to change email?' })}
                              </a>
                            </div>

                            <BWInput
                              fieldType={InputTypes.EMAIL}
                              fieldName={'email'}
                              formik={formik}
                              placeholder={''}
                              label={formatMessage({ id: 'Email address' })}
                              toolTipText={formatMessage({
                                id: 'GLOBAL.TOOLTIP.USER.EMAIL',
                              })}
                              isRequired={true}
                              isDisabled={true}
                            />
                          </div>
                          <div className='col-md-6 col-12'>
                            <div className='number-input ms-1 me-0'>
                              <label
                                htmlFor='phone-input'
                                className='form-label font-size-14 text-dark text-capitalize fw-bold z-index-200'
                              >
                                {formatMessage({ id: 'Contact number' })}
                              </label>
                              <OverlayTrigger
                                placement='bottom'
                                overlay={
                                  <Tooltip id='tooltip'>
                                    <p className='mb-0'>
                                      {formatMessage({
                                        id: 'GLOBAL.TOOLTIP.USER.CONTACT_NUMBER',
                                      })}
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={toAbsoluteUrl('/media/icons/duotune/general/tooltip.svg')}
                                  alt='Tooltip'
                                  className='ms-1 mb-1'
                                  width='16px'
                                  height='16px'
                                />
                              </OverlayTrigger>
                              <PhoneInput
                                country={'us'}
                                value={formik.values.contact}
                                onChange={(value) => formik.setFieldValue('contact', value)}
                                containerClass='intTel'
                                inputClass='intTelInput'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='d-md-flex col-md-12 flex-wrap profile-country'>
                          <SelectInput
                            label={formatMessage({ id: 'User’s Country' })}
                            fieldName={'country'}
                            placeholder={formatMessage({
                              id: 'Select the Country',
                            })}
                            formik={formik}
                            toolTipText={formatMessage({
                              id: 'GLOBAL.TOOLTIP.USER.COUNTRY',
                            })}
                            options={countryOptions}
                            width={6}
                            margin={'me-6'}
                          />
                          {/* Color Picker */}
                          <div>
                            <div className='form-label font-size-13 fw-bolder text-dark text-capitalize ms-0 mb-2'>
                              Avatar Color{' '}
                              <OverlayTrigger
                                placement='bottom'
                                overlay={
                                  <Tooltip id='tooltip'>
                                    <p className='mb-0'>
                                      Select a color for your avatar which will be used to identify
                                      your profile.
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={toAbsoluteUrl('/media/icons/duotune/general/tooltip.svg')}
                                  alt='Tooltip'
                                  className='ms-1 mb-1'
                                  width='16px'
                                  height='16px'
                                />
                              </OverlayTrigger>
                            </div>
                            <UserDropdown
                              selectedUser={currentUser as BaseUser}
                              users={colorDropdown}
                              onChange={(user: BaseUser) => {
                                console.log(user);
                                formik.setFieldValue('color', user.color);
                              }}
                              maxAllowedTextLength={16}
                              padding={'ps-0'}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {process.env.REACT_APP_DEV_ENV === 'dev' && (
                      <div>
                        <GlobalButton
                          buttonText={formatMessage({
                            id: 'Delete User',
                          })}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={() => setDeleteUserModalShow(true)}
                          isDisable={deleteUserLoading}
                        />
                      </div>
                    )}
                    <div className='d-flex justify-content-md-end align-items-md-end button-margin flex-column-reverse flex-md-row mt-6 mt-md-0'>
                      <div className='d-grid me-md-3 mt-5 pt-2 mb-4 mb-md-0'>
                        <GlobalButton
                          buttonText={formatMessage({
                            id: 'Change password',
                          })}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={() => setConfirmModalShow(true)}
                          isDisable={changePasswordLoading}
                        />
                      </div>
                      <div className='d-grid d-md-flex'>
                        {formik.dirty && (
                          <GlobalButton
                            buttonText={formatMessage({ id: 'Save changes' })}
                            buttonType={ButtonTypes.PRIMARY}
                            isLoading={loading}
                            isDisable={formik.isSubmitting || !formik.isValid}
                            onButtonClick={formik.handleSubmit}
                          />
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>

        <ConfirmChangePasswordModal
          confirmModalShow={confirmModalShow}
          setConfirmModalShow={setConfirmModalShow}
          getForgotedPassword={getForgotedPassword}
          changePasswordLoading={changePasswordLoading}
        />

        <DeleteUserConfirmationModal
          confirmModalShow={deleteUserModalShow}
          setDeleteUserModalShow={setDeleteUserModalShow}
          deleteUser={deleteUser}
          deleteUserLoading={deleteUserLoading}
        />

        <ChangePasswordModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          setShowModal={setShowModal}
        />
      </div>
    </>
  );
}
