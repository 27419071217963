/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../helpers';
import clsx from 'clsx';
import { ThemeModeSwitcher } from '../theme-mode/ThemeModeSwitcher';
import { useIntl } from 'react-intl';
import UserAvatar from '../../../../modules/shared/components/UserAvatar';
import { UserAvatarSize } from '@/enums/user';
import { useLogout } from '../../../../hooks/useLogout';
import { Spinner } from '../../../../modules/shared/components/General/Spinner';

const HeaderUserMenu: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useGlobalContext();
  const { logout } = useLogout();
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const handleLogout = async () => {
    setLoading(true);
    await logout();
    navigate('/login');
    setLoading(false);
  };
  const itemClass = 'ms-1 ms-lg-3';
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-325px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <div className='mt-2'>
              <UserAvatar user={currentUser} size={UserAvatarSize.LARGE} />
            </div>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold text-muted text-hover-primary fs-7 wrap ms-1'>
              {currentUser?.firstName} {currentUser?.lastName}
            </div>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 mt-1'>
                {currentUser?.planName}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      {/* <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link to='/settings' className='menu-link px-5'>
          {formatMessage({ id: 'Account Settings' })}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <a onClick={handleLogout} className='menu-link px-5'>
            {formatMessage({ id: 'Sign Out' })}
          </a>
          {loading && (
            <div className='me-3'>
              <Spinner inline />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
