import React, { Dispatch, SetStateAction } from 'react';
import statusImage from '../../../../core/assets/images/svg/bugs.work/status-img.svg';
import assigneeImage from '../../../../core/assets/images/svg/bugs.work/assignee-img.svg';
import priorityImage from '../../../../core/assets/images/svg/bugs.work/priority-img.svg';
import Select, { components } from 'react-select';
import { priorityOptions, statusOptions } from '@/constants/projects';
import { Priority } from '@/enums/priority';
import { formatDate } from '../../../../util/_dateFormat';
import UserAvatar from '../../../shared/components/UserAvatar';
import { BaseUser, User } from '@/types/user';
import createdAt from '../../../../core/assets/images/svg/bugs.work/created-at.svg';
import createdBy from '../../../../core/assets/images/svg/bugs.work/created-by.svg';
import updatedAt from '../../../../core/assets/images/svg/bugs.work/updated-at.svg';
import updatedBy from '../../../../core/assets/images/svg/bugs.work/updated-by.svg';
import { TaskStatus } from '@/enums/projects';
import UserDropdown from '../../../shared/components/UserDropdown';
import { UserAvatarDisplayName, UserAvatarSize } from '@/enums/user';
import { priorityColors } from '@/constants/colors';

export const TaskInfo = ({
  isTaskEdit,
  activeColumn,
  formik,
  taskData,
  assigneeUser,
  setAssigneeUser,
  handleEditTask,
  userList,
  taskDetailPage,
  setActiveColumn,
  isPublic,
}: {
  isTaskEdit?: boolean;
  activeColumn?: TaskStatus;
  formik: any;
  taskData: any;
  assigneeUser: BaseUser | undefined;
  setAssigneeUser: Dispatch<SetStateAction<BaseUser | undefined>>;
  handleEditTask?: any;
  userList: User[];
  taskDetailPage?: boolean;
  setActiveColumn?: Dispatch<SetStateAction<TaskStatus>>;
  isPublic?: boolean;
}) => {
  const prioritycustomStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: state.data.label === Priority.MINOR ? 'black' : 'inherit',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: state.data.label === Priority.MINOR ? 'black' : 'inherit',
    }),
  };
  const reversedPriorityOptions = [...priorityOptions].reverse();
  const CustomOption = (props: any) => (
    <components.Option {...props}>
      <div className='priority-dropdown__option'>
        <span
          className='priority-badge px-2 py-1'
          style={{
            backgroundColor: props.data.color,
          }}
        >
          {props.data.label}
        </span>
      </div>
    </components.Option>
  );

  const CustomSingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <div className='priority-dropdown__single-value'>
        <span
          className={`priority-badge px-2 py-1 me-0`}
          style={{
            backgroundColor: priorityColors[props.data.label],
          }}
        >
          {props.data.label}
        </span>
      </div>
    </components.SingleValue>
  );
  return (
    <div
      className={`${
        taskDetailPage
          ? 'd-flex flex-xl-row flex-column justify-content-between task-detail-info-box'
          : isTaskEdit
          ? 'modal-information-edit-box'
          : 'modal-information-box'
      } p-4`}
    >
      <div>
        <div
          className={`d-flex flex-row 
             justify-content-between align-items-center ${isPublic ? 'mb-5' : ''}`}
        >
          <div className='d-flex flex-row'>
            <img src={statusImage} className='me-1' alt='status-image' />
            <label className='font-weight-400 font-size-12 grey-color d-flex align-items-center'>
              Status
            </label>
          </div>

          {isPublic ? (
            <div className='ms-12 title-color font-size-12 font-weight-400'>{taskData?.status}</div>
          ) : (
            <Select
              name='status'
              options={statusOptions}
              defaultValue={
                isTaskEdit
                  ? { value: taskData?.status, label: taskData?.status }
                  : statusOptions.find((option) => option.value === activeColumn)
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue('status', selectedOption.value);

                if (isTaskEdit) {
                  if (setActiveColumn) setActiveColumn(selectedOption.value);
                  const updatedTaskData = { ...taskData, status: selectedOption.value };
                  handleEditTask(updatedTaskData);
                }
              }}
            />
          )}
        </div>
        <div
          className={`d-flex flex-row justify-content-between ${
            isPublic ? 'mb-5' : 'align-items-center'
          }`}
        >
          <div className={`d-flex flex-row ${isPublic ? 'me-5' : ''}`}>
            <img src={assigneeImage} className='me-1' alt='assignee-image' />
            <label className='font-weight-400 font-size-12 grey-color d-flex align-items-center'>
              Assignee
            </label>
          </div>

          <div className='d-flex flex-row justify-content-between align-items-center'>
            <div className='d-flex flex-row align-items-center'>
              {isPublic ? (
                <>
                  <UserAvatar
                    user={assigneeUser}
                    size={UserAvatarSize.MEDIUM}
                    displayName={UserAvatarDisplayName.SIDE}
                  />
                  <div className='ms-2'>{taskData?.assignee === null && <div>Unassigned</div>}</div>
                </>
              ) : (
                <UserDropdown
                  selectedUser={assigneeUser as BaseUser}
                  users={userList}
                  onChange={(user: BaseUser): void => {
                    setAssigneeUser(user);
                    if (isTaskEdit) {
                      const updatedTaskData = { ...taskData, assigneeId: user.userId };
                      handleEditTask(updatedTaskData);
                    }
                  }}
                  maxAllowedTextLength={16}
                />
              )}
            </div>
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <div className='d-flex flex-row'>
            <img src={priorityImage} className='me-1' alt='priority-image' />
            <label className='font-weight-400 font-size-12 grey-color d-flex align-items-center'>
              Priority
            </label>
          </div>

          {isPublic ? (
            <div className='title-color font-size-12 font-weight-400 ms-5'>
              {taskData?.priority}
            </div>
          ) : (
            <Select
              name='priority'
              classNamePrefix='priority-dropdown'
              options={reversedPriorityOptions}
              defaultValue={
                isTaskEdit
                  ? { value: taskData?.priority, label: taskData?.priority }
                  : reversedPriorityOptions.find((option: any) => option.value === Priority.NORMAL)
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue('priority', selectedOption.value);
                if (isTaskEdit) {
                  const updatedTaskData = { ...taskData, priority: selectedOption.value };
                  handleEditTask(updatedTaskData);
                }
              }}
              styles={prioritycustomStyles}
              components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
            />
          )}
        </div>
      </div>
      <div>
        {isTaskEdit && (
          <>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className='d-flex flex-row mt-2'>
                <img src={createdBy} className='me-1' alt='priority-image' />
                <label className='font-weight-400 font-size-12 grey-color d-flex align-items-center'>
                  Created by
                </label>
              </div>
              <div className='d-flex flex-row align-items-center  title-color font-size-400 font-size-12'>
                {taskData && (
                  <UserAvatar
                    user={taskData?.created}
                    size={UserAvatarSize.MEDIUM}
                    displayName={UserAvatarDisplayName.SIDE}
                    maxAllowedTextLength={16}
                  />
                )}
              </div>
            </div>
            <div
              className={`d-flex flex-row justify-content-between align-items-center ${
                taskDetailPage ? 'mt-7' : 'mt-5'
              }`}
            >
              <div className='d-flex flex-row justify-content-between'>
                <img src={createdAt} className='me-2' alt='priority-image' />
                <label className='font-weight-400 font-size-12 grey-color d-flex align-items-center'>
                  Created at
                </label>
              </div>
              <div className='title-color font-size-400 font-size-12'>
                {formatDate(taskData?.createdAt)}
              </div>
            </div>
            {taskData?.updated && (
              <div className='update-creator-box'>
                <div className='d-flex flex-row justify-content-between align-items-center mt-5'>
                  <div className='d-flex flex-row'>
                    <img src={updatedBy} className='me-1' alt='priority-image' />
                    <label className='font-weight-400 font-size-12 grey-color d-flex align-items-center'>
                      Updated by
                    </label>
                  </div>
                  <div className='title-color font-size-400 font-size-12'>
                    <div className='d-flex flex-row align-items-center title-color font-size-400 font-size-12'>
                      {taskData && (
                        <UserAvatar
                          user={taskData?.updated}
                          size={UserAvatarSize.MEDIUM}
                          displayName={UserAvatarDisplayName.SIDE}
                          maxAllowedTextLength={16}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center mt-5'>
                  <div className='d-flex flex-row'>
                    <img src={updatedAt} className='me-1' alt='priority-image' />
                    <label className='font-weight-400 font-size-12 grey-color d-flex align-items-center'>
                      Updated at
                    </label>
                  </div>
                  <div className='title-color font-size-400 font-size-12'>
                    {formatDate(taskData?.updatedAt)}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
