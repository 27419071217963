import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { useParams } from 'react-router-dom';
import { DetailedTask, Task } from '../../../../types/projects';
import { BaseUser, User } from '../../../../types/user';
import { toast } from 'react-toastify';
import { TaskTabs } from './TaskTabs';
import { TaskInfo } from './TaskInfo';
import { TaskHeader } from './TaskHeader';
import { Priority } from '@/enums/priority';
import { useTaskRequests } from '@/requests/useTaskRequests';
import FileUpload from '../../../shared/components/file/FileUpload';
import FileDisplay from '../../../shared/components/file/FileDisplay';
import { FileDisplayType } from '@/enums/file';
import { CommentEntity } from '@/types/projects';
import { UnauthorizedPage } from '../../../errors/UnauthorizedPage';
import { FileObject, TemporaryFile, UploadFile } from '@/types/file';
import { CreateTaskPosition, TaskStatus } from '@/enums/projects';
import { TaskTextArea } from '../../../shared/components/Input/TaskTextArea';
import { TextArea } from '../../../shared/components/Input/TextArea';
import { getFileContentType } from '../../../../util/_file';
import { Toaster } from '../../../shared/components/General/Toaster';
import Skeleton from 'react-loading-skeleton';
import { processUploadedFiles } from '../../../../util/onUploadFile';

export const TaskModal = ({
  openTaskModal,
  setOpenTaskModal,
  isTaskEdit,
  setIsTaskEdit,
  userList,
  activeColumn,
  setDeleteShowModal,
  taskCode,
  taskData,
  setTaskData,
  assigneeUser,
  setAssigneeUser,
  createPosition,
  pushTask,
  replaceTask,
  setActiveColumn,
  file,
  setFile,
}: {
  openTaskModal: boolean;
  setOpenTaskModal: Dispatch<SetStateAction<boolean>>;
  isTaskEdit: boolean;
  setIsTaskEdit: Dispatch<SetStateAction<boolean>>;
  userList: User[];
  activeColumn: TaskStatus;
  setDeleteShowModal: Dispatch<SetStateAction<boolean>>;
  taskData: DetailedTask;
  taskCode: string | undefined;
  setTaskData: Dispatch<SetStateAction<any>>;
  assigneeUser: BaseUser | undefined;
  setAssigneeUser: Dispatch<SetStateAction<BaseUser | undefined>>;
  createPosition: CreateTaskPosition;
  pushTask: (task: Task, createPosition: CreateTaskPosition) => void;
  replaceTask: (task: Task, options: { sourceColumn: TaskStatus }) => void;
  setActiveColumn: Dispatch<SetStateAction<TaskStatus>>;
  file: TemporaryFile[] | FileObject[];
  setFile: Dispatch<SetStateAction<TemporaryFile[] | FileObject[]>>;
}) => {
  const { createTask, updateTask, getSingleTaskData } = useTaskRequests();
  const { formatMessage } = useIntl();
  const { projectId } = useParams();
  const [createTaskLoading, setCreateTaskLoading] = useState<boolean>(false);
  const [editTaskNameButton, setEditTaskNameButton] = useState<boolean>(false);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [showAllComments, setShowAllComments] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [commentList, setCommentList] = useState<CommentEntity[]>([]);
  const [isTaskNotFound, setTaskNotFound] = useState(false);
  const [files, setFiles] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (openTaskModal && isTaskEdit && taskCode) {
      handleSingleTaskData(taskCode);
    }
  }, [openTaskModal]);

  async function handleSingleTaskData(taskCode: string) {
    setLoading(true);
    setIsTaskEdit(true);
    try {
      const {
        data: { data, success, errors },
      } = await getSingleTaskData(taskCode);
      if (success) {
        setTaskData(data);
        setFile(data?.files);
        setCommentList(data?.comment);
        if (data?.assignee) {
          setAssigneeUser(data?.assignee);
        }
      } else {
        setTaskNotFound(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const taskSchema = Yup.object().shape({
    description: Yup.string()
      .max(2000, formatMessage({ id: 'Maximum 2000 characters' }))
      .required(formatMessage({ id: 'Description is required' })),
  });

  const initialValues = {
    description: taskData ? taskData?.description : '',
    priority: taskData ? taskData?.priority : Priority.NORMAL,
    status: activeColumn,
    assignedUser: null,
    file: taskData
      ? taskData.files.map((item: FileObject) => item.fileId)
      : file.map((item) => item.fileId),
  };

  const handleCreateTask = async (values: any) => {
    try {
      setCreateTaskLoading(true);
      const createTaskPayloadJson = {
        projectId: parseInt(projectId!),
        description: values.description,
        priority: values.priority,
        status: values.status,
        files: files,
        isPublic: true,
        assigneeId: assigneeUser?.userId,
        position: createPosition,
      };

      const {
        data: { data, success, errors },
      } = await createTask(createTaskPayloadJson);
      if (success) {
        toast.success('Task created successfully');
        setOpenTaskModal(false);
        const assignee = userList.find((user: User) => user?.userId === parseInt(data?.assigneeId));
        const assigneedata = {
          userId: assignee?.userId,
          firstName: assignee?.firstName,
          lastName: assignee?.lastName,
          color: assignee?.color,
        };

        const newData = { ...data, assignee: assigneedata };
        pushTask(newData, createPosition);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setOpenTaskModal(false);
      setFile([]);
      setCreateTaskLoading(false);
      setAssigneeUser(undefined);
    }
  };

  const handleEditTask = async (taskData: DetailedTask, newFiles?: any[]) => {
    try {
      let payload: any = {
        description: taskData.description,
        status: taskData.status,
        priority: taskData.priority,
        projectId: projectId ? parseInt(projectId) : undefined,
        assigneeId: taskData.assigneeId,
      };

      if (newFiles) {
        payload['files'] = newFiles;
      }

      if (taskData.status !== activeColumn) {
        payload['position'] = 0;
        taskData['position'] = 0;
      }
      const {
        data: { success },
      } = await updateTask(payload, taskData.taskId);

      if (success) {
        let updatedTask: Task = { ...taskData };

        if (!!taskData.assigneeId) {
          const assignee = userList.find((user: User) => user.userId === taskData.assigneeId);
          if (assignee) {
            updatedTask = {
              ...taskData,
              assignee: {
                userId: taskData.assigneeId,
                firstName: assignee.firstName,
                lastName: assignee.lastName,
                color: assignee.color,
              },
            };
          }
        }
        replaceTask(updatedTask, { sourceColumn: activeColumn });

        toast.success('Task updated successfully');
        setCreateTaskLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFile = (fileId: string) => {
    if (taskData?.taskId) {
      const filteredFile = file.filter((file) => file.fileName === fileId);
      handleEditTask(taskData, filteredFile);
    } else {
      setFiles(files.filter((file) => file.fileName !== fileId));
    }

    setFile((prevFiles) => {
      return prevFiles.filter((file) => file.fileName !== fileId);
    });
  };

  const onUploadSuccess = (filesArray: File[], fileIds: string[], filesKey: any[]) => {
    let filesData = taskData?.taskId
      ? [
          {
            fileName: filesKey[filesKey.length - 1].key,
            contentType: filesArray[filesArray.length - 1].type,
          },
        ]
      : filesArray.map((file, index) => ({
          fileName: filesKey[index].key,
          contentType: file.type,
        }));
    setFiles(filesData);
    setFile((prevFiles: TemporaryFile[]) => {
      const newFiles = processUploadedFiles(filesArray, fileIds, filesKey);

      const fileMap = new Map<string, TemporaryFile>();

      // Add previous files to the map
      for (const file of prevFiles) {
        fileMap.set(file.fileName, file);
      }

      // Add new files to the map only if they don't already exist
      for (const file of newFiles) {
        if (!fileMap.has(file.fileName)) {
          fileMap.set(file.fileName, file);
        }
      }

      // Convert map back to array
      return Array.from(fileMap.values());
    });
    if (taskData) {
      handleEditTask(taskData, filesData);
    }
  };

  return (
    <div id='task-modal'>
      <Toaster />
      {!loading && isTaskNotFound ? (
        <UnauthorizedPage />
      ) : (
        <Modal
          show={openTaskModal}
          onHide={() => {
            setOpenTaskModal(false);
            setIsTaskEdit(false);
            setEditTaskNameButton(false);
            setUploadedFileName('');
            setAssigneeUser(undefined);
            setFile([]);
            setTaskData('');
            setShowAllComments(false);
          }}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header
            closeButton
            className={`modal-width d-flex flex-row justify-content-between ${
              isTaskEdit && 'py-2'
            }`}
          >
            {loading ? (
              <div className='w-50 pt-3 pb-2'>
                <Skeleton height={25} />
              </div>
            ) : (
              <>
                {!isTaskEdit ? (
                  <div className='font-size-16 fw-bold title-color font-weight-600'>
                    {formatMessage({ id: 'Create new task' })}
                  </div>
                ) : (
                  <div className='w-100'>
                    <TaskHeader
                      taskCode={taskData?.taskCode}
                      setDeleteShowModal={setDeleteShowModal}
                      isTaskDetailPage={false}
                      publicUuid={taskData?.public_uuid}
                    />
                  </div>
                )}
              </>
            )}
          </Modal.Header>
          {loading ? (
            <div className='px-6 mt-3 mb-5'>
              <div className='d-flex flex-column flex-lg-row justify-content-between'>
                <div className='d-flex flex-column gap-5'>
                  <div className=''>
                    <Skeleton height={120} width={350} />
                  </div>
                  <div className='mt-15'>
                    <Skeleton height={105} width={105} />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton height={300} width={270} />
                  </div>
                </div>
              </div>
              <div className='mt-4'>
                <Skeleton height={110} />
              </div>
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={taskSchema}
              onSubmit={(values, formikHelpers) => {
                handleCreateTask(values);
              }}
              validateOnMount
            >
              {(formik) => {
                return (
                  <Form>
                    <Modal.Body className='pt-5 pb-0'>
                      <div className='d-flex flex-column flex-lg-row justify-content-between'>
                        <div className='d-flex flex-column justify-content-between'>
                          {!isTaskEdit && (
                            <div className='description-area-width mb-5'>
                              <TextArea
                                fieldName={'description'}
                                formik={formik}
                                placeholder={formatMessage({
                                  id: 'Enter task description',
                                })}
                                isStarRequired={false}
                                rows={4}
                              />
                            </div>
                          )}

                          {isTaskEdit && (
                            <TaskTextArea
                              displayText={taskData?.description ?? ''}
                              onSave={(textAreaValue: string) => {
                                if (isTaskEdit) {
                                  const updatedTaskData = {
                                    ...taskData,
                                    description: textAreaValue,
                                  };
                                  handleEditTask(updatedTaskData);
                                }
                              }}
                              placeholder={'Enter task description'}
                              rows={5}
                            />
                          )}

                          <div>
                            {isTaskEdit && (
                              <div className='d-flex flex-row flex-wrap description-area-width gap-5'>
                                {file &&
                                  file.map((file: any, index) => (
                                    <FileDisplay
                                      key={index}
                                      file={file}
                                      fileDisplayType={FileDisplayType.THUMBNAIL}
                                      deleteFile={deleteFile}
                                      onClick={true}
                                    />
                                  ))}
                                <FileUpload
                                  projectId={taskData?.projectId as number}
                                  onUploadSuccess={onUploadSuccess}
                                  length={file.length}
                                />
                              </div>
                            )}

                            {!isTaskEdit && (
                              <div className='d-flex flex-row flex-wrap description-area-width gap-5'>
                                {file &&
                                  file.map((url, index) => (
                                    <FileDisplay
                                      key={index}
                                      file={url}
                                      fileDisplayType={FileDisplayType.THUMBNAIL}
                                      deleteFile={deleteFile}
                                      onClick={true}
                                    />
                                  ))}
                                <FileUpload
                                  onUploadSuccess={onUploadSuccess}
                                  projectId={Number(projectId)!}
                                  length={file.length}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className='dotted-border mx-5 d-none d-lg-block'></div>
                        <TaskInfo
                          isTaskEdit={isTaskEdit}
                          activeColumn={activeColumn}
                          formik={formik}
                          taskData={taskData}
                          setActiveColumn={setActiveColumn}
                          assigneeUser={assigneeUser}
                          setAssigneeUser={setAssigneeUser}
                          handleEditTask={handleEditTask}
                          userList={userList}
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer className='pt-0 border-top-0 pb-0'>
                      {!isTaskEdit && (
                        <div className='mt-3 mt-lg-0'>
                          <GlobalButton
                            buttonText={formatMessage({ id: 'Create task' })}
                            isLoading={createTaskLoading}
                            buttonType={ButtonTypes.PRIMARY}
                            isDisable={formik.isSubmitting || !formik.isValid}
                            onButtonClick={formik.handleSubmit}
                          />
                        </div>
                      )}
                    </Modal.Footer>
                    <div className='p-2 p-md-4 task-modal-height'>
                      {isTaskEdit && (
                        <TaskTabs
                          formik={formik}
                          taskId={taskData?.taskId}
                          commentList={commentList}
                          setCommentList={setCommentList}
                          showAllComments={showAllComments}
                          setShowAllComments={setShowAllComments}
                          userList={userList}
                          systemInfo={taskData?.system_info}
                          consoleInfo={taskData?.console}
                          networkInfo={taskData?.network}
                        />
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Modal>
      )}
    </div>
  );
};
