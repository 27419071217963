import { useUserProjectRequests } from '@/requests/useUserProjectRequests';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { selectedOptionType } from '@/types';

export const useAddUser = () => {
  const [addUserLoading, setAddUserLoading] = useState<boolean>(false);
  const { addUser } = useUserProjectRequests();

  const handleAddUser = async (selectedOption: selectedOptionType, projectId?: string) => {
    try {
      setAddUserLoading(true);
      const {
        data: { data, success, errors },
      } = await addUser(parseInt(projectId!), selectedOption?.value);
      if (success) {
        setAddUserLoading(false);
        toast.success('User added successfully');
        return success;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddUserLoading(false);
    }
  };
  return { handleAddUser };
};
