import { Roles } from '@/enums/organisation';

export const PlatformMenu = [
  {
    id: 'projects',
    href: '/projects',
    icon: '/media/icons/sidebar/projects.svg',
    activeIcon: '/media/icons/sidebar/projectsActive.svg',
    hoverIcon: '/media/icons/sidebar/projectsHovered.svg',
    title: 'All projects',
    roles: [Roles.ADMIN, Roles.MEMBER, Roles.OWNER],
  },
  {
    id: 'organisation',
    href: '/organisation',
    icon: '/media/icons/sidebar/organisation.svg',
    activeIcon: '/media/icons/sidebar/organisationActive.svg',
    hoverIcon: '/media/icons/sidebar/organisationHovered.svg',
    title: 'Organisation',
    roles: [Roles.ADMIN, Roles.OWNER],
  },
  {
    id: 'profile',
    href: '/profile',
    icon: '/media/icons/sidebar/profile.svg',
    activeIcon: '/media/icons/sidebar/profileActive.svg',
    hoverIcon: '/media/icons/sidebar/profileHovered.svg',
    title: 'Profile',
    roles: [Roles.ADMIN, Roles.MEMBER, Roles.OWNER],
  },
  {
    id: 'settings',
    href: '/settings',
    icon: '/media/icons/sidebar/settings.svg',
    activeIcon: '/media/icons/sidebar/settingsActive.svg',
    hoverIcon: '/media/icons/sidebar/settingsHovered.svg',
    title: 'Settings',
    roles: [Roles.ADMIN, Roles.MEMBER, Roles.OWNER],
  },
];

export const ProjectMenu = [
  {
    id: 'task',
    href: 'task-board',
    icon: '/media/icons/sidebar/task.svg',
    activeIcon: '/media/icons/sidebar/taskActive.svg',
    hoverIcon: '/media/icons/sidebar/taskHovered.svg',
    title: 'Task Board',
    roles: [Roles.ADMIN, Roles.MEMBER, Roles.OWNER],
  },
  {
    id: 'integration',
    href: 'integration',
    icon: '/media/icons/sidebar/integration.svg',
    activeIcon: '/media/icons/sidebar/integrationActive.svg',
    hoverIcon: '/media/icons/sidebar/integrationHovered.svg',
    title: 'Integration',
    roles: [Roles.ADMIN, Roles.OWNER],
  },
  {
    id: 'projectSettings',
    href: 'project-settings',
    icon: '/media/icons/sidebar/projectSettings.svg',
    activeIcon: '/media/icons/sidebar/projectSettingsActive.svg',
    hoverIcon: '/media/icons/sidebar/projectSettingsHovered.svg',
    title: 'Project settings',
    roles: [Roles.ADMIN, Roles.OWNER],
  },
];

export const zapierConnect = 'https://zapier.com/app/editor/template/1812557';
