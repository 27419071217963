import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../../core/helpers';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { ButtonTypes } from '@/enums';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';

const AcceptCard = ({ inviteToken }: { inviteToken: string }) => {
  const { acceptInvite, rejectInvite } = useAuthRequests();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { setUserToken, getCurrentUser, currentUser, auth } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const handleAccept = async () => {
    setLoading(true);
    try {
      const {
        data: { data, success },
      } = await acceptInvite(inviteToken);
      if (success) {
        await getCurrentUser(data);
        setUserToken(data.accessToken);
        navigate('/projects');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    setRejectLoading(true);
    try {
      const {
        data: { success },
      } = await rejectInvite(inviteToken);
      if (success) {
        toast.success(
          formatMessage({
            id: 'Invite rejected successfully',
          })
        );
        navigate('/projects');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRejectLoading(false);
    }
  };

  return (
    <div className='d-flex align-items-center justify-content-end gap-4'>
      <GlobalButton
        buttonText={formatMessage({
          id: 'Reject',
        })}
        buttonType={ButtonTypes.SECONDARY}
        isLoading={rejectLoading}
        isDisable={rejectLoading}
        onButtonClick={handleReject}
      />
      <GlobalButton
        buttonText={formatMessage({ id: 'Accept' })}
        buttonType={ButtonTypes.PRIMARY}
        isLoading={loading}
        isDisable={loading}
        onButtonClick={handleAccept}
      />
    </div>
  );
};

export { AcceptCard };
