import Cookies from 'universal-cookie';
const oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
const cookies = new Cookies(null, {
  path: '/',
  secure: true,
  expires: oneYearFromNow,
  sameSite: 'none',
  domain: process.env.REACT_APP_ENV_KEY === 'local' ? 'localhost' : '.bugs.work',
});
export const serialize = function (obj: any) {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p) && !!obj[p]) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};
export const setCookie = function (key: string, value: string) {
  cookies.set(key, value);
};
export const getCookie = function (name: string) {
  const cookieToken = cookies.get(name);
  return cookieToken;
};
export const removeCookie = function (name: string) {
  cookies.remove(name);
};
