import axios from 'axios';
import { API_URL } from '.';

const AUTH_URL = `${API_URL}/auth`;
const LOGIN_URL = `${AUTH_URL}/signin`;
const REGISTER_URL = `${AUTH_URL}/signup`;
const VERIFY_REGISTER_URL = `${AUTH_URL}/verify-register`;
const RESET_PASSWORD_URL = `${AUTH_URL}/reset`;
const FORGOT_PASSWORD = `${AUTH_URL}/forgot`;
const SOCIAL_LOGIN_VARIFICATION = `${AUTH_URL}/google-signin`;
const GET_USER_BY_ACCESSTOKEN_URL = `${AUTH_URL}/verify-token`;
const VERIFY_INVITE = `${AUTH_URL}/verify-invite`;
const ACCEPT_INVITE = `${AUTH_URL}/accept-invite`;
const VERIFY_RESET_LINK = `${AUTH_URL}/reset/verify`;
const RESEND_VERIFICATION = `${AUTH_URL}/resend-verification`;
const RESEND_INVITE = `${AUTH_URL}/resend-invite`;
const REFRESH_TOKEN = `${AUTH_URL}/refresh-token`;
const LOGOUT = `${AUTH_URL}/logout`;
const REJECT_INVITE = `${AUTH_URL}/reject-invite`;
const MICROSOFT_LOGIN_URL = `${AUTH_URL}/microsoft-login`;
export const useAuthRequests = () => {
  function login(email: string, password: string) {
    return axios.post(
      LOGIN_URL,
      {
        email,
        password,
      },
      {
        validateStatus() {
          return true;
        },
      }
    );
  }

  //signup
  function verifyRegister(token: string) {
    return axios.post(
      VERIFY_REGISTER_URL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },

        validateStatus() {
          return true;
        },
      }
    );
  }

  // verify-sigup
  function register(
    email: string,
    firstname: string,
    lastname: string,
    password: string,
    utmValues: any,
    referral: string,
    locale: string,
    dateFormat: string,
    timeFormat: string,
    timeZone: number,
    countryId: number
  ) {
    return axios.post(
      REGISTER_URL,
      {
        email,
        firstName: firstname,
        lastName: lastname,
        password,
        ...utmValues,
        referral,
        locale,
        dateFormat,
        timeFormat,
        timeZone,
        countryId,
      },
      {
        validateStatus() {
          return true;
        },
      }
    );
  }

  //Reset password
  function resetPassword(token: string, password: string) {
    return axios.patch(
      RESET_PASSWORD_URL,
      {
        token,
        password,
      },
      {
        validateStatus() {
          return true;
        },
      }
    );
  }

  //forgot password
  function forgotPassword(email: string) {
    return axios.post(
      FORGOT_PASSWORD,
      { email },
      {
        validateStatus() {
          return true;
        },
      }
    );
  }

  function socialLoginVerification(type: string, token: string) {
    return axios.post(SOCIAL_LOGIN_VARIFICATION, { type, token });
  }

  function getUserByToken(token: string) {
    return axios.post(GET_USER_BY_ACCESSTOKEN_URL, {
      token,
    });
  }

  function verifyInvite(inviteToken: string) {
    return axios.post(
      VERIFY_INVITE,
      { inviteToken },
      {
        validateStatus() {
          return true;
        },
      }
    );
  }

  function acceptInvite(
    inviteToken: string,
    password?: string,
    firstName?: string,
    lastName?: string,
    locale?: string,
    dateFormat?: string,
    timeFormat?: string,
    timeZone?: number,
    countryId?: number
  ) {
    return axios.post(ACCEPT_INVITE, {
      inviteToken,
      password,
      firstName,
      lastName,
      locale,
      dateFormat,
      timeFormat,
      timeZone,
      countryId,
    });
  }

  const getTokenStatus = (token: string) => {
    return axios.post(VERIFY_RESET_LINK, { token });
  };

  const resendVerification = (payload: { email: string }) => {
    return axios.post(RESEND_VERIFICATION, payload, {
      validateStatus() {
        return true;
      },
    });
  };

  const resendInvite = (email: string) => {
    return axios.post(
      RESEND_INVITE,
      { email },
      {
        validateStatus() {
          return true;
        },
      }
    );
  };

  //getLocation
  function refreshTokenAPI(token: string) {
    return axios.post(
      REFRESH_TOKEN,
      {
        token,
      },
      {
        validateStatus() {
          return true;
        },
      }
    );
  }

  function logoutApi(refreshToken: string) {
    return axios.post(
      LOGOUT,
      { refreshToken },
      {
        validateStatus() {
          return true;
        },
      }
    );
  }

  function rejectInvite(token: string) {
    return axios.post(
      REJECT_INVITE,
      {
        inviteToken: token,
      },
      {
        validateStatus() {
          return true;
        },
      }
    );
  }
  function microsoftLogin(payload: any) {
    return axios.post(MICROSOFT_LOGIN_URL, payload, {
      validateStatus() {
        return true;
      },
    });
  }
  return {
    login,
    verifyRegister,
    verifyInvite,
    resendInvite,
    resendVerification,
    getTokenStatus,
    acceptInvite,
    getUserByToken,
    socialLoginVerification,
    forgotPassword,
    resetPassword,
    register,
    refreshTokenAPI,
    logoutApi,
    rejectInvite,
    microsoftLogin,
  };
};
