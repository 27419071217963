import { useState } from 'react';
import { useGlobalContext } from '../core/helpers';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useUserProjectRequests } from '@/requests/useUserProjectRequests';

const useAllUserList = () => {
  const { getAllUsers } = useUserProjectRequests();
  const [userListDataLoading, setUserListDataLoading] = useState(false);
  const { userList, setAllUserList } = useGlobalContext();
  const { formatMessage } = useIntl();
  const fetchUserList = async (projectId: number) => {
    try {
      setUserListDataLoading(true);
      const {
        data: { data, success, errors },
      } = await getAllUsers(projectId);

      if (success) {
        setAllUserList(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUserListDataLoading(false);
    }
  };

  return { fetchUserList };
};

export default useAllUserList;
