import urlImg from '../../../../core/assets/images/svg/bugs.work/url.svg';
import timeStampImg from '../../../../core/assets/images/svg/bugs.work/timestamp.svg';
import appleDevice from '../../../../core/assets/images/svg/bugs.work/apple-device.svg';
import windowsDevice from '../../../../core/assets/images/svg/bugs.work/windows.svg';
import safarios from '../../../../core/assets/images/svg/bugs.work/safarios.svg';
import chromeos from '../../../../core/assets/images/svg/bugs.work/chromeos.svg';
import size from '../../../../core/assets/images/svg/bugs.work/size.svg';
import screenShot from '../../../../core/assets/images/svg/bugs.work/screenshot-dimension.svg';
import { useIntl } from 'react-intl';

const SystemInfo = ({ systemInfo }: { systemInfo: string | null }) => {
  let systemData;
  if (systemInfo) {
    systemData = JSON.parse(systemInfo);
  }
  if (!systemInfo) {
    return <div className='p-2 font-weight-500 font-size-14'>System info unavailable</div>;
  }
  const { formatMessage } = useIntl();

  return (
    <div className='m-4'>
      <div className='d-flex flex-column justify-content-between'>
        <div className='d-flex flex-row justify-content-between mb-3'>
          <div className='comment-tabs-color font-size-12 font-weight-400'>URL</div>
          <div className='d-flex flex-row'>
            <div className='title-color font-weight-400 font-size-12 me-2'>{systemData?.URL}</div>
            <a href={systemData?.URL} target='_blank'>
              <img className='cursor-pointer' src={urlImg} alt='url-img' />
            </a>
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between mb-3'>
          <div className='comment-tabs-color font-size-12 font-weight-400'>Timestamp</div>
          <div className='d-flex flex-row'>
            <div className='title-color font-weight-400 font-size-12 me-2'>
              {systemData?.Timestamp}
            </div>
            <img src={timeStampImg} alt='time-stamp-img' />
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between mb-3'>
          <div className='comment-tabs-color font-size-12 font-weight-400'>Device</div>
          <div className='d-flex flex-row'>
            <div className='title-color font-weight-400 font-size-12 me-2'>
              {systemData?.Device}
            </div>
            <>
              {systemData?.Device?.toLowerCase().includes('windows') && (
                <img src={windowsDevice} alt='Windows Device' className='device-image' />
              )}
              {systemData?.Device?.toLowerCase().includes('apple') && (
                <img src={appleDevice} alt='Apple Device' className='device-image' />
              )}
            </>
          </div>
        </div>

        <div className='d-flex flex-row justify-content-between mb-3'>
          <div className='comment-tabs-color font-size-12 font-weight-400'>Browser</div>
          <div className='d-flex flex-row'>
            <div className='title-color font-weight-400 font-size-12 me-2'>
              {systemData?.Browser}
            </div>
            {systemData?.Browser?.toLowerCase().includes('chrome') && (
              <img src={chromeos} alt='Chrome OS' className='browser-image' />
            )}
            {systemData?.Browser?.toLowerCase().includes('safari') && (
              <img src={safarios} alt='Safari OS' className='browser-image' />
            )}
          </div>
        </div>

        <div className='d-flex flex-row justify-content-between mb-3'>
          <div className='comment-tabs-color font-size-12 font-weight-400'>Window size</div>
          <div className='d-flex flex-row'>
            <div className='title-color font-weight-400 font-size-12 me-2'>
              {systemData?.WindowSize}
            </div>
            <img src={size} alt='Windows Device' className='size' />
          </div>
        </div>
        <div className='d-flex flex-row justify-content-between mb-3 '>
          <div className='comment-tabs-color font-size-12 font-weight-400'>Country</div>
          <div className='title-colorfont-weight-400 font-size-12'>India</div>
        </div>
        <div className='d-flex flex-row justify-content-between mb-3'>
          <div className='comment-tabs-color font-size-12 font-weight-400'>
            Screenshot dimensions
          </div>
          <div className='d-flex flex-row'>
            <div className='title-color font-weight-400 font-size-12 me-2'>1360x465</div>
            <img src={screenShot} alt='screen-shot' className='size' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemInfo;
