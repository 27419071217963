import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../../core/helpers';
import { useIntl } from 'react-intl';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ButtonTypes, InputTypes } from '@/enums';
import { CustomToast } from '../../../shared/components/UI/CustomToast';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { useFetchCountryAndTimeZone } from '../../../../hooks/useFetchCountryAndTimeZone';
import { BWInput } from '../../../shared/components/Input/BWInput';

const RegistrationCard = ({
  invitedEmail,
  inviteToken,
}: {
  invitedEmail: string;
  inviteToken: string;
}) => {
  const { acceptInvite } = useAuthRequests();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { setUserToken, getCurrentUser } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { fetchCountryAndTimeZone } = useFetchCountryAndTimeZone();

  const initialValues = {
    firstname: '',
    lastname: '',
    email: invitedEmail,
    password: '',
    acceptTerms: false,
  };
  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, formatMessage({ id: 'Minimum 3 characters' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'First name is required' })),
    email: Yup.string()
      .email(formatMessage({ id: 'Invalid email format' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Email is required' })),
    lastname: Yup.string()
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Last name is required' })),
    password: Yup.string()
      .min(6, formatMessage({ id: 'Minimum 6 characters' }))
      .max(50, formatMessage({ id: 'Maximum 50 characters' }))
      .required(formatMessage({ id: 'Password is required' }))
      .matches(/\d/, formatMessage({ id: 'Password must contain at least one digit' }))
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        formatMessage({ id: 'Password must contain at least one special character' })
      )
      .matches(
        /[a-zA-Z]/,
        formatMessage({ id: 'Password must contain at least one alphabetic character' })
      ),
    acceptTerms: Yup.bool(),
  });

  const handleAcceptInvite = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setLoading(true);
    try {
      const locationData = await fetchCountryAndTimeZone();
      const {
        data: { data, success, errors },
      } = await acceptInvite(
        inviteToken,
        values.password,
        values.firstname,
        values.lastname,
        'en_US',
        'MMM DD, YYYY',
        'hh:mm:ss a',
        locationData?.timeZone || 5.5,
        locationData?.countryId! || 103
      );
      if (success) {
        await getCurrentUser(data);
        setUserToken(data.accessToken);
        navigate('/projects');
      } else {
        await getCurrentUser(undefined);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <div>
      <Formik
        validationSchema={registrationSchema}
        initialValues={initialValues}
        validateOnMount
        onSubmit={(values, { setSubmitting }) => {
          handleAcceptInvite(values, setSubmitting);
        }}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework px-3 pe-0 ps-0'>
              <div className='row d-flex justify-content-between'>
                <div className='col-6 ps1'>
                  <BWInput
                    fieldType={'text'}
                    label={formatMessage({ id: 'First Name' })}
                    fieldName={'firstname'}
                    formik={formik}
                    placeholder={formatMessage({ id: 'Enter first name' })}
                    toolTipText={formatMessage({
                      id: 'GLOBAL.TOOLTIP.REGISTRATION.FIRST_NAME',
                    })}
                    isRequired={true}
                  />
                </div>
                <div className='col-6'>
                  <BWInput
                    fieldType={'text'}
                    label={formatMessage({ id: 'Last Name' })}
                    fieldName={'lastname'}
                    formik={formik}
                    placeholder={formatMessage({ id: 'Enter last name' })}
                    toolTipText={formatMessage({
                      id: 'GLOBAL.TOOLTIP.REGISTRATION.LAST_NAME',
                    })}
                    isRequired={true}
                  />
                </div>
              </div>
              <BWInput
                fieldType={'email'}
                fieldName={'email'}
                formik={formik}
                placeholder={formatMessage({ id: 'Enter Email' })}
                label={formatMessage({ id: 'Email' })}
                toolTipText={formatMessage({
                  id: 'GLOBAL.TOOLTIP.REGISTRATION.EMAIL',
                })}
                isDisabled={true}
                isRequired={true}
              />
              <BWInput
                fieldType={'password'}
                fieldName={'password'}
                formik={formik}
                placeholder={formatMessage({ id: 'Password' })}
                label={formatMessage({ id: 'Password' })}
                toolTipText={formatMessage({
                  id: 'GLOBAL.TOOLTIP.REGISTRATION.PASSWORD',
                })}
                isRequired={true}
              />
              <div className='text-center text-md-start'>
                <Field
                  className='form-check-input terms mt-0 cursor-pointer'
                  aria-describedby='tandc'
                  type={InputTypes.CHECKBOX}
                  name={'acceptTerms'}
                  id={'tandc'}
                  autoComplete='off'
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <label htmlFor={'tandc'} className='ms-3 font-size-14 text-black'>
                  {formatMessage({ id: 'I agree to the' })}
                  <>
                    <a
                      href={`https://bugs.work/terms-of-use/`}
                      target='_blank'
                      className='ms-1 fw-bold link-primary'
                    >
                      {formatMessage({ id: 'Terms of use' })}
                    </a>{' '}
                    &{' '}
                    <a
                      href={`https://bugs.work/privacy-policy/`}
                      target='_blank'
                      className='fw-bold link-primary'
                    >
                      {formatMessage({ id: 'Privacy policy' })}
                    </a>
                    .
                  </>
                </label>
              </div>
              <div className='d-flex align-items-center justify-content-end'>
                <GlobalButton
                  buttonText={formatMessage({ id: 'Sign up and Accept' })}
                  buttonType={ButtonTypes.PRIMARY}
                  isLoading={loading}
                  isDisable={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                  onButtonClick={formik.handleSubmit}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { RegistrationCard };
