import React, { useState } from 'react';
import deleteIcon from '../../../../core/assets/images/svg/bugs.work/delete.svg';
import downloadIcon from '../../../../core/assets/images/svg/bugs.work/download.svg';
import cross from '../../../../core/assets/images/svg/bugs.work/cross.svg';
import './file-display.scss';
import { FileObject, TemporaryFile } from '@/types/file';
import { FileDisplayType, FileType } from '@/enums/file';
import { getFileContentType, getFileExtension } from '../../../../util/_file';
import FileDisplayModal from './FileDisplayModal';
import FileTypes from './FileTypes';

const FileDisplay = ({
  file,
  fileDisplayType,
  deleteFile,
  onClick,
}: {
  file: TemporaryFile | FileObject;
  fileDisplayType: FileDisplayType;
  deleteFile: (fileId: string) => void;
  onClick?: boolean;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  function downloadFile(url: string, filename: string) {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (
      !(
        (getFileContentType(file.fileName) === FileType.DOCUMENT &&
          !file.contentType.includes(FileType.PDF)) ||
        file.contentType === FileType.DOCUMENT ||
        file.contentType.includes(FileType.DOCUMENT) ||
        file.contentType === FileType.TEXT ||
        file.contentType.includes(FileType.TEXT)
      ) &&
      onClick
    )
      setIsClicked(true);
  };

  const ImageSvgContainer = (
    <div className='image-container d-flex flex-row '>
      <img
        className={` cursor-pointer me-2`}
        src={deleteIcon}
        alt='Remove'
        onClick={() => deleteFile(file.fileName)}
      />
      <img
        className={`cursor-pointer me-2`}
        src={downloadIcon}
        alt='Download'
        onClick={() => downloadFile(file.signedURL, file.fileName)}
      />
    </div>
  );

  return (
    <div>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`thumbnail-image-container ${isHovered ? 'hovered' : ''}`}
      >
        <div onClick={handleClick}>
          <div className='thumbnail-link'>
            {!isClicked && <FileTypes file={file} isFullScreen={isClicked && onClick} />}
          </div>
        </div>
        {isHovered && <div className='overlay'></div>}
        {isHovered && ImageSvgContainer}
        {isClicked && onClick && (
          <FileDisplayModal file={file} isClicked={isClicked} setIsClicked={setIsClicked} />
        )}
      </div>
    </div>
  );
};

export default FileDisplay;
