export const DEFAULT_USERAVATAR_COLOR = '#007bff';

export const userInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  contact: '',
  country: '',
  color: DEFAULT_USERAVATAR_COLOR,
};

export const colorCodes = [
  '#333333', // Black (a darker shade of gray)
  '#800000', // Red (dark red)
  '#004400', // Green (dark green)
  '#000088', // Blue (dark blue)
  '#CCCC00', // Yellow (dark yellow)
  '#800080', // Magenta (dark magenta)
  '#008888', // Cyan (a darker cyan)
  '#400000', // Maroon (a darker shade of maroon)
  '#004000', // Olive (a darker shade of olive)
  '#000040', // Navy (a darker shade of navy)
  '#404000', // Olive Green (a darker shade of olive green)
  '#400040', // Purple (a darker shade of purple)
  '#004040', // Teal (a darker shade of teal)
  '#808080', // Silver (gray)
  '#404040', // Gray (a darker shade of gray)
  '#B0B0B0', // White Smoke (a slightly darker shade of white smoke)
  '#E57768', // Light Salmon (a darker shade of light salmon)
  '#D86E5C', // Salmon (a darker shade of salmon)
  '#D1765C', // Dark Salmon (a darker shade of dark salmon)
  '#E13B00', // Orange Red (a darker shade of orange red)
  '#B8860B', // Gold (a darker shade of gold)
  '#9A6F18', // Goldenrod (a darker shade of goldenrod)
  '#E17E00', // Dark Orange (a darker shade of dark orange)
  '#E05C37', // Coral (a darker shade of coral)
  '#D23525', // Tomato (a darker shade of tomato)
];

export const maximumFullNameSize = 20;
