import axios from 'axios';
import { API_URL } from '.';

const YOUTRACK_URL = `${API_URL}/integration/youtrack`;
const GET_ALL_YOUTRACK_PROJECT = `${YOUTRACK_URL}/projects`;
const GET_ALL_ALIGLE_BOARD = `${YOUTRACK_URL}/agile-boards`;
const GET_ALL_SPRINTS = `${YOUTRACK_URL}/sprints`;

export const useYoutrackIntegrationRequests = () => {
  const getyouTrackProjects = (baseUrl: string, token: string) => {
    const payload = {
      baseUrl: baseUrl,
      token: token,
    };
    return axios.post(GET_ALL_YOUTRACK_PROJECT, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const getAllAligleBoard = (baseUrl: string, token: string, platformProjectId: string) => {
    const payload = {
      baseUrl: baseUrl,
      token: token,
      platformProjectId: platformProjectId,
    };
    return axios.post(GET_ALL_ALIGLE_BOARD, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const getAllSprints = (agileBoardId: string, token: string, baseUrl: string) => {
    const payload = {
      agileBoardId: agileBoardId,
      token: token,
      baseUrl: baseUrl,
    };
    return axios.post(GET_ALL_SPRINTS, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  return {
    getAllAligleBoard,
    getAllSprints,
    getyouTrackProjects,
  };
};
