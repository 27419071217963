export enum ButtonTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  BUTTON_LINK = 'BUTTON_LINK',
}

export enum InputTypes {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}
