import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toAbsoluteUrl, useGlobalContext } from '../../../core/helpers';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useAuthRequests } from '@/requests/useAuthRequests';
import { Card } from 'react-bootstrap';
import { Roles } from '@/enums/organisation';
import { LogoutCard } from './invite/LogoutCard';
import { AcceptCard } from './invite/AcceptCard';
import { LoginCard } from './invite/LoginCard';
import { RegistrationCard } from './invite/RegistrationCard';
import Skeleton from 'react-loading-skeleton';

const VerifyInvite = () => {
  const { verifyInvite } = useAuthRequests();
  const { formatMessage } = useIntl();

  const { pathname } = useLocation();
  const inviteToken = pathname.split('/')[2];

  const { currentUser, userToken } = useGlobalContext();
  const [invitedEmail, setInvitedEmail] = useState<string>();
  const [role, setRole] = useState<Roles>();
  const [teamName, setTeamName] = useState<string>('');
  const [userExists, setUserExists] = useState<boolean>();
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [inviteErrors, setInviteErrors] = useState<string[]>([]);

  useEffect(() => {
    const getVerifyToken = async () => {
      try {
        const {
          data: { success, data, errors },
        } = await verifyInvite(inviteToken);
        if (success) {
          setInviteErrors([]);
          setInvitedEmail(data.email);
          setRole(data.role);
          setTeamName(data.teamName);
          setUserExists(data.userExists);
        } else {
          setInviteErrors(errors);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setPageLoading(false);
      }
    };

    getVerifyToken();
  }, [inviteToken]); // eslint-disable-line react-hooks/exhaustive-deps

  function capitalizeFirstLetter(value: string) {
    if (value) return value[0].toUpperCase() + value.slice(1);
    return null;
  }

  return (
    <>
      {inviteErrors.length ? (
        <>
          <div className='bw-layout-bg added_height d-flex justify-content-center align-items-center'>
            {inviteErrors.map((error: string) => (
              <div className='card m-2'>
                <div className='card-body text-dark font-size-13'>{error}</div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className=' d-flex justify-content-center align-items-center flex-column mt-20 min-h-600px'>
          <div className='mb-12'>
            <img src={toAbsoluteUrl('/media/logos/bugswork-dark.svg')} width={200} />
          </div>
          <Card className={`${userExists ? 'w-md-25' : 'w-40'}`}>
            <Card.Body>
              {pageLoading ? (
                <div>
                  <div className='mb-5'>
                    <Skeleton height={20} count={2} />
                  </div>

                  <div className='mb-4'>
                    <Skeleton height={20} count={5} style={{ marginBottom: '3px' }} />
                  </div>
                </div>
              ) : (
                <>
                  <div className='mb-5'>
                    <span className='font-size-16 font-weight-700 d-flex justify-content-center'>
                      {formatMessage({ id: 'Invitation from' })} {capitalizeFirstLetter(teamName)}
                    </span>
                    <span className='d-flex justify-content-center font-size-14'>
                      {formatMessage({ id: 'to join as' })}{' '}
                      {capitalizeFirstLetter(role?.toString()!)}
                    </span>
                  </div>
                  {invitedEmail &&
                    (!!currentUser && invitedEmail !== currentUser.email ? (
                      <LogoutCard invitedEmail={invitedEmail} />
                    ) : userExists && !!currentUser && invitedEmail === currentUser.email ? (
                      <AcceptCard inviteToken={inviteToken} />
                    ) : userExists ? (
                      <LoginCard invitedEmail={invitedEmail} />
                    ) : (
                      <RegistrationCard inviteToken={inviteToken} invitedEmail={invitedEmail} />
                    ))}
                </>
              )}
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export { VerifyInvite };
