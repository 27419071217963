import { Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes, InputTypes } from '@/enums';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { useTeamManagementRequests } from '@/requests/useTeamManagementRequests';
import { BWInput } from '../../shared/components/Input/BWInput';

export const TeamModal = ({
  showTeamModal,
  setShowTeamModal,
  teamName,
  setRunGetUserList,
}: {
  showTeamModal: boolean;
  setShowTeamModal: Dispatch<SetStateAction<boolean>>;
  teamName: string;
  setRunGetUserList: Dispatch<SetStateAction<boolean>>;
}) => {
  const { editTeamName } = useTeamManagementRequests();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    teamName: teamName || '',
  };
  const teamSchema = Yup.object().shape({
    teamName: Yup.string()
      .max(25, formatMessage({ id: 'Team name must be less than 25 characters' }))
      .required('Team name is required'),
  });

  const handleEditTeam = async (values: { teamName: string }) => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await editTeamName(values.teamName);
      toast.success(data);
      setShowTeamModal(false);
      setRunGetUserList(true);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      show={showTeamModal}
      onHide={() => setShowTeamModal(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      size='sm'
    >
      <Modal.Header closeButton>
        <h2>{formatMessage({ id: 'Edit team name' })}</h2>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={teamSchema}
        onSubmit={handleEditTeam}
        validateOnMount
      >
        {(formik) => (
          <Form>
            <Modal.Body>
              <BWInput
                fieldType={InputTypes.TEXT}
                fieldName={'teamName'}
                formik={formik}
                placeholder={formatMessage({ id: 'Enter the team name' })}
                label={formatMessage({ id: 'Team name' })}
                isRequired={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <GlobalButton
                buttonType={ButtonTypes.PRIMARY}
                buttonText='Update'
                isLoading={loading}
                isDisable={formik.isSubmitting || !formik.isValid || loading}
                onButtonClick={formik.handleSubmit}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
