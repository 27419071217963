import axios from 'axios';
import { API_URL } from '.';

const COMMENT = `${API_URL}/comment`;

export const useCommentRequests = () => {
  const createComment = (comment: string, taskId: number | null, projectId: number | null) => {
    const payload = {
      description: comment,
      projectId: projectId,
      taskId: taskId,
    };

    return axios.post(`${COMMENT}?taskId=${taskId}`, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const editComment = (commentId: number | null, taskId: number | null, comment: string) => {
    const payload = {
      description: comment,
    };

    return axios.put(`${COMMENT}/${commentId}?taskId=${taskId}`, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const deleteComment = (commentId: number | null, taskId: number | null) => {
    return axios.delete(`${COMMENT}/${commentId}?taskId=${taskId}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  return {
    createComment,
    editComment,
    deleteComment,
  };
};
