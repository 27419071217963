import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { GlobalButton } from '../../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { useGlobalContext } from '../../../../core/helpers';
import { useTrelloIntegrationrequest } from '@/requests/useTrelloIntegrationRequest';
import { useIntegrationRequests } from '@/requests/useIntegrationRequests';

type Board = {
  id: string;
  name: string;
};

export const TrelloIntegrationModel = ({
  data,
  handleTrelloToggle,
}: {
  data: any;
  handleTrelloToggle: any;
}) => {
  const { formatMessage } = useIntl();
  const [boardList, setBoardList] = useState<Board[]>([]);
  const [idList, setIdList] = useState('');
  const [headerSelection, setHeaderOption] = useState('');
  const validationSchema = Yup.object().shape({
    headerSelection: Yup.string().required(formatMessage({ id: 'Please select a board' })),
  });

  const { getAllTrelloBoards, getAllListId } = useTrelloIntegrationrequest();
  const { createIntegrateProject } = useIntegrationRequests();
  const { openTrelloOauthConfigModal, setOpenTrelloOauthConfigModal } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const getAllTrelloBoardList = async (token: string) => {
    try {
      const { data, status } = await getAllTrelloBoards(token);
      if (status === 200) {
        const boards = data.map((board: Board) => ({
          name: board.name,
          id: board.id,
        }));
        setBoardList(boards);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllTrelloBoardListId = async (token: string, boardId: string) => {
    try {
      const { data, status } = await getAllListId(token, boardId);
      if (status === 200) {
        const listId = data[0].id;
        setIdList(listId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (data && data.meta_data) {
      const meta_data_object = JSON.parse(data?.meta_data);
      getAllTrelloBoardList(meta_data_object.token);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.meta_data) {
      setHeaderOption('');
      const meta_data_object = JSON.parse(data.meta_data);
      if (meta_data_object.boardId) {
        const defaultBoardId = meta_data_object.boardId;
        setHeaderOption(defaultBoardId);
        if (defaultBoardId) {
          getAllTrelloBoardListId(meta_data_object.token, defaultBoardId);
        }
      }
    }
  }, [openTrelloOauthConfigModal]);

  const handleSelectChange = async (event: any, setFieldValue: any) => {
    const selectedValue = event.target.value;
    setFieldValue('headerSelection', selectedValue);
    setHeaderOption(selectedValue);
    const meta_data_object = JSON.parse(data?.meta_data);
    getAllTrelloBoardListId(meta_data_object.token, selectedValue);
  };

  const handleProceed = async (values: { headerSelection: string }) => {
    const meta_data = JSON.parse(data?.meta_data);
    meta_data.boardId = values.headerSelection;
    meta_data.idList = idList;
    const projectId = data?.projectId;
    const platformName = data?.platformName;
    const status = data?.status;
    setLoading(true);
    try {
      const {
        data: { data: value, success, errors },
      } = await createIntegrateProject(meta_data, platformName, projectId, status);
      if (success) {
        setOpenTrelloOauthConfigModal(false);
        handleTrelloToggle('modal');
        return value;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={openTrelloOauthConfigModal}
        onHide={() => {
          setOpenTrelloOauthConfigModal(false);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className=''>
          <div className='font-size-16 fw-bold title-color'>
            {formatMessage({ id: 'Select Board from Trello account' })}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <label
              htmlFor='headerKey'
              className='form-label font-size-14 text-dark text-capitalize mb-4'
            ></label>
            <Formik
              initialValues={{ headerSelection }}
              validationSchema={validationSchema}
              onSubmit={handleProceed}
              validateOnMount
            >
              {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting, isValid }) => (
                <Form>
                  <select
                    className='form-select form-select-solid form-select-lg mb-5'
                    name='headerSelection'
                    value={headerSelection}
                    onChange={(e) => handleSelectChange(e, setFieldValue)}
                  >
                    <option disabled value=''>
                      {formatMessage({ id: 'Select Board' })}
                    </option>
                    {boardList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <Modal.Footer className='pt-0 border-top-0 pe-0 mt-10'>
                    <GlobalButton
                      buttonText={formatMessage({ id: 'Cancel' })}
                      buttonType={ButtonTypes.SECONDARY}
                      onButtonClick={() => {
                        setOpenTrelloOauthConfigModal(false);
                      }}
                    />
                    <GlobalButton
                      buttonText={formatMessage({ id: 'Proceed' })}
                      buttonType={ButtonTypes.PRIMARY}
                      isDisable={isSubmitting || !isValid}
                      onButtonClick={handleSubmit}
                      isLoading={loading}
                    />
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
