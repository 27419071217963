import axios from 'axios';
import { API_URL } from '.';

export const GET_ALL_JIRA_PROJECT = `${API_URL}/integration/jira/projects`;

export const useJiraIntegrationRequests = () => {
  const getJiraProjects = (projectId: string, platformName: string, cloudId: string) => {
    const payload = {
      projectId: projectId,
      platformName: platformName,
      cloudId: cloudId,
    };
    return axios.post(GET_ALL_JIRA_PROJECT, payload, {
      validateStatus(status) {
        return true;
      },
    });
  };
  return {
    getJiraProjects,
  };
};
