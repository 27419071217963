import { AuthCookie } from '@/constants/settings';
import { getCookie, removeCookie, setCookie } from '../../../util/cookie';
import { AuthModel } from '../../../types';

const AUTH_LOCAL_STORAGE_KEY = AuthCookie.TOKEN;
const getAuth = (): AuthModel | undefined => {
  const lsValue: string | undefined = getCookie(AUTH_LOCAL_STORAGE_KEY);

  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel =
      typeof lsValue === 'string' ? (JSON.parse(lsValue) as AuthModel) : lsValue;
    if (auth) {
      return auth;
    }
  } catch (error) {
    console.error('AUTH COOKIE STORAGE PARSE ERROR', error);
  }
};

const setAuth = (auth: AuthModel) => {
  try {
    const lsValue = JSON.stringify(auth);
    setCookie(AuthCookie.TOKEN, lsValue);
  } catch (error) {
    console.error('AUTH COOKIE STORAGE SAVE ERROR', error);
  }
};

const removeAuth = () => {
  try {
    removeCookie(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH COOKIE STORAGE REMOVE ERROR', error);
  }
};

export { getAuth, setAuth, removeAuth };
