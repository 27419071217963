import React from 'react';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../core/helpers';
import { NoDataComponent } from '../../shared/components/NoDataComponent';
import { Currency } from '@/enums/billing';
import Skeleton from 'react-loading-skeleton';
import { useGetLocale } from '../../../hooks/useGetLocale';

export const Transaction = ({
  transactionData,
  transactionLoading,
}: {
  transactionData: any;
  transactionLoading: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { getLocaleDate } = useGetLocale();
  return (
    <>
      <div className='card p-8'>
        {!transactionLoading && transactionData.length === 0 ? (
          <div>
            <NoDataComponent
              title={formatMessage({
                id: 'No transactions found!',
              })}
            />
          </div>
        ) : (
          Array.isArray(transactionData) && (
            <div className='border border-secondary rounded-3 table-responsive'>
              <table className='table mb-0'>
                <thead className='table-secondary font-size-14 fw-semibold text-black rounded-3 align-middle text-nowrap'>
                  <tr>
                    <th className='p-4 table-border-top-left'>
                      {formatMessage({ id: 'Transaction ID' })}
                    </th>
                    <th>{formatMessage({ id: 'Date' })}</th>
                    <th>{formatMessage({ id: 'Payment method' })}</th>
                    <th>{formatMessage({ id: 'Amount' })}</th>
                    <th className='table-border-top-right pe-3'>
                      {formatMessage({ id: 'Receipt' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {transactionLoading
                      ? [...Array(2)].map((_, index) => (
                          <tr
                            key={index}
                            className='border-top border-secondary align-middle text-nowrap'
                          >
                            <td className='p-4'>
                              <Skeleton width={100} height={20} />
                            </td>
                            <td>
                              <Skeleton width={150} height={20} />
                            </td>
                            <td>
                              <Skeleton width={150} height={20} />
                            </td>
                            <td>
                              <Skeleton width={75} height={20} />
                            </td>
                            <td>
                              <Skeleton width={25} height={20} />
                            </td>
                          </tr>
                        ))
                      : transactionData.map(
                          ({
                            transactionId,
                            transactionDate,
                            cardType,
                            cardNumber,
                            currencyCode,
                            amount,
                            receiptPdfLink,
                          }: {
                            transactionId: string;
                            transactionDate: number;
                            cardType: string | null;
                            cardNumber: string | null;
                            currencyCode: string;
                            amount: number;
                            receiptPdfLink: string;
                          }) => (
                            <tr
                              className='border-top border-secondary align-middle text-nowrap'
                              key={transactionId}
                            >
                              <td className='p-4'>{transactionId.slice(-8).toUpperCase()}</td>
                              <td>{getLocaleDate(transactionDate)}</td>
                              <td>
                                {cardType || cardNumber ? (
                                  <>
                                    <img
                                      src={toAbsoluteUrl('/media/icons/duotune/general/visa.svg')}
                                      alt='card'
                                      width='20'
                                      height='20'
                                      className='me-1'
                                    />
                                    {cardType
                                      ? cardType?.charAt(0).toUpperCase() + cardType?.slice(1)
                                      : ''}{' '}
                                    {formatMessage({ id: 'ending in' })} {cardNumber}
                                  </>
                                ) : (
                                  '---'
                                )}
                              </td>
                              <td>
                                {'$'} {amount}
                              </td>
                              <td>
                                <a href={receiptPdfLink} target='blank'>
                                  <img
                                    src={toAbsoluteUrl('/media/icons/duotune/general/download.svg')}
                                    alt='receipt'
                                    width='20'
                                    height='20'
                                  />
                                </a>
                              </td>
                            </tr>
                          )
                        )}
                  </>
                </tbody>
              </table>
            </div>
          )
        )}
      </div>
    </>
  );
};
