import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useRefreshToken from '../../../../hooks/useRefreshToken';

const useAxiosResponse = (axios: any) => {
  const { refreshToken } = useRefreshToken();

  useEffect(() => {
    axios.interceptors.response.use(
      async (response: any) => {
        if (response.status >= 500) {
          toast.error('Something went wrong.');
        } else if (response.status === 401) {
          return await refreshToken(response.config);
        } else if (response.status >= 400) {
          if (!!response.data.errors?.length) {
            response.data.errors?.forEach((error: string) => {
              if (error === 'VERIFY_SIGNUP_AGAIN') {
                toast.error('Your invite has expired. Please resend the verification email.');
              } else {
                toast.error(error);
              }
            });
          }
        }
        return response;
      },
      (err: any) => {
        toast.error('Something went wrong.');
        return Promise.reject(err.message);
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default useAxiosResponse;
