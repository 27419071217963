import React, { useState } from 'react';
import '../styles/user-dropdown.scss';
import UserAvatar from './UserAvatar';
import { BaseUser } from '@/types/user';
import { UserAvatarDisplayName, UserAvatarSize } from '@/enums/user';
import dropDownImg from '../../../../src/core/assets/images/svg/bugs.work/downArrow.svg';
import OutsideClickHandler from 'react-outside-click-handler';
const UserDropdown = ({
  selectedUser,
  users,
  onChange,
  maxAllowedTextLength,
  padding,
}: {
  selectedUser: BaseUser;
  users?: BaseUser[];
  onChange?: any;
  maxAllowedTextLength?: number;
  padding?: string;
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState<BaseUser>(selectedUser as BaseUser);

  const handleOutSideClick = () => {
    setIsActive(false);
  };
  return (
    <OutsideClickHandler onOutsideClick={handleOutSideClick}>
      <div className='user-dropdown col-1'>
        <div className='dropdown'>
          <div
            onClick={(e) => {
              setIsActive(!isActive);
            }}
            className='item'
          >
            <div
              key={selected?.userId}
              className={`d-flex align-items-center justify-content-around ${
                padding || 'ps-2'
              } css-13cymwt-control`}
            >
              {selected ? (
                <>
                  <UserAvatar
                    user={selected}
                    size={UserAvatarSize.MEDIUM}
                    displayName={UserAvatarDisplayName.SIDE}
                    maxAllowedTextLength={maxAllowedTextLength}
                  />
                  <img src={dropDownImg} className='me-1' />
                </>
              ) : (
                <>
                  <UserAvatar size={UserAvatarSize.MEDIUM} />
                  <div>Unassigned</div>
                  <img src={dropDownImg} className='me-1' />
                </>
              )}
            </div>
          </div>

          <div
            className='dropdown-content scroll-y'
            style={{
              display: isActive ? 'block' : 'none',
              maxHeight: users && users?.length > 3 ? '200px' : 'auto',
            }}
          >
            {users &&
              users.map((option, index) => (
                <div
                  key={index}
                  onClick={(e) => {
                    onChange(option);
                    setSelected(option);
                    setIsActive(!isActive);
                  }}
                  className='item'
                >
                  <div key={option.userId} className='d-flex align-items-center mt-1 pb-2'>
                    <UserAvatar
                      user={option}
                      size={UserAvatarSize.MEDIUM}
                      displayName={UserAvatarDisplayName.SIDE}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default UserDropdown;
