import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import '../../styles/network.scss';
import { NoDataComponent } from '../../../shared/components/NoDataComponent';
import Tabs, { Tab } from 'react-best-tabs';
import { BaseNetworkData, NetworkDataItem } from '@/types/projects';
import lens from '../../../../core/assets/images/svg/bugs.work/lens.svg';
import ResizePanel from 'react-resize-panel';

const NetworkInfo = ({ networkInfo }: { networkInfo: string }) => {
  const [selectedEntry, setSelectedEntry] = useState<BaseNetworkData | null>(null);

  const { formatMessage } = useIntl();
  const [networkData, setNetworkData] = useState<NetworkDataItem[]>([]);
  const [filterType, setFilterType] = useState<string>('All'); // Default filter
  const [searchTerm, setSearchTerm] = useState<string>(''); // Search term
  const [showErrorsOnly, setShowErrorsOnly] = useState<boolean>(false);

  useEffect(() => {
    if (networkInfo) {
      try {
        const parsedData = JSON.parse(networkInfo);
        setNetworkData(parsedData);
      } catch (error) {
        console.error('Error parsing networkInfo JSON:', error);
        setNetworkData([]);
      }
    } else {
      setNetworkData([]);
    }
  }, [networkInfo]);

  const getStatusColor = (status: string) => {
    const statusCode = parseInt(status, 10);
    if (statusCode >= 200 && statusCode <= 208) {
      return 'green';
    } else if (statusCode >= 400 && statusCode <= 511) {
      return 'red';
    } else {
      return 'gray';
    }
  };

  const handleItemClick = (item: NetworkDataItem) => {
    let formattedResponse;
    try {
      formattedResponse = JSON.stringify(JSON.parse(item.response), null, 2);
    } catch (error) {
      console.error('Error formatting response JSON:', error);
      formattedResponse = item.response;
    }

    let requestBody;
    try {
      requestBody = JSON.stringify(JSON.parse(item.requestBody), null, 2);
    } catch (error) {
      console.error('Error formatting request JSON:', error);
      requestBody = 'Request body unavailable since this is a GET request.';
    }
    setSelectedEntry({
      header: item.url,
      method: item.method,
      status: item.status,
      response: formattedResponse,
      requestBody: requestBody,
    });
  };

  const handleCloseClick = () => {
    setSelectedEntry(null);
  };

  const handleFilterClick = (type: string) => {
    setFilterType(type);
  };

  // Filtered network data based on selected type
  const filteredNetworkData = networkData.filter((item) => {
    const matchesType = filterType === 'All' || item.name === filterType;
    const matchesSearch =
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.url.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesErrorFilter =
      !showErrorsOnly || (showErrorsOnly && parseInt(item.status, 10) >= 400);

    return matchesType && matchesSearch && matchesErrorFilter;
  });

  return (
    <div>
      <div className=''>
        {networkData.length > 0 && (
          <>
            <div className='d-md-flex flex-column flex-sm-row my-4 align-items-center justify-content-between filter-row p-1'>
              <div>
                <img src={lens} alt='lens' className='search-img-icon mb-3 mb-sm-0 me-2 me-sm-4' />

                <input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className='search-bar py-2 py-sm-3  ms-2 ms-sm-0 border-0'
                  placeholder='Search'
                />
              </div>

              <div className='d-flex align-items-center mt-3 mt-sm-0 '>
                <input
                  type='checkbox'
                  checked={showErrorsOnly}
                  onChange={(e) => setShowErrorsOnly(e.target.checked)}
                  className='me-1'
                />
                <span className='me-2'>{formatMessage({ id: 'Errors Only' })}</span>
              </div>
            </div>
            <div className='d-flex flex-wrap mb-4'>
              {['All', 'XMLHttpRequest', 'WebSocket', 'JSRequest', 'CSSRequest', 'Media'].map(
                (type) => (
                  <label
                    key={type}
                    className={`px-3 py-2 m-2 pointer label-filter ${
                      filterType === type ? 'active' : ''
                    }`}
                    onClick={() => handleFilterClick(type)}
                  >
                    {formatMessage({
                      id:
                        type === 'XMLHttpRequest'
                          ? 'Fetch/XHR'
                          : type === 'WebSocket'
                          ? 'WS'
                          : type,
                    })}
                  </label>
                )
              )}
            </div>
          </>
        )}
        <div className=''>
          <div className='content panel'>
            <div className={`table-container ${selectedEntry ? 'narrow' : ''}`}>
              {filteredNetworkData?.length > 0 ? (
                <div className='table-responsive'>
                  <table className='table p-2'>
                    <thead>
                      <tr>
                        <th className='font-10'>{formatMessage({ id: 'Name' })}</th>
                        <th className='font-10'>{formatMessage({ id: 'Method' })}</th>
                        <th className='font-10'>{formatMessage({ id: 'Status' })}</th>
                        <th className='font-10'>{formatMessage({ id: 'Request URL' })}</th>
                        <th className='font-10'>{formatMessage({ id: 'Type' })}</th>
                        <th className='font-10'>{formatMessage({ id: 'Size' })}</th>
                        <th className='font-10'>{formatMessage({ id: 'Time' })}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredNetworkData.map((item, index) => (
                        <tr key={index}>
                          <td onClick={() => handleItemClick(item)} className='pointer font-10'>
                            {item.name}
                          </td>
                          <td onClick={() => handleItemClick(item)} className='pointer font-10'>
                            {item.method}
                          </td>
                          <td
                            style={{ color: getStatusColor(item.status) }}
                            className='pointer font-10'
                          >
                            {item.status}
                          </td>
                          <td onClick={() => handleItemClick(item)} className='pointer font-10'>
                            {item.url}
                          </td>
                          <td onClick={() => handleItemClick(item)} className='pointer font-10'>
                            {item.type}
                          </td>
                          <td onClick={() => handleItemClick(item)} className='pointer font-10'>
                            {item.size}
                          </td>
                          <td onClick={() => handleItemClick(item)} className='pointer font-10'>
                            {item.time.toFixed(2)} ms
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <>
                  <div className='font-weight-500 font-size-14  text-center mb-2'>
                    {formatMessage({
                      id: 'No Network Data.',
                    })}
                  </div>
                  <div className='font-weight-500 font-size-14 text-center'>
                    {formatMessage({
                      id: 'Network info is only captured for current tab recording via bugs.work widget.',
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          {selectedEntry && (
            <ResizePanel
              direction='w'
              style={{ width: '500px' }}
              handleClass='customHandle'
              borderClass='customResizeBorder'
            >
              <div className='sidebar panel'>
                <div className='details-header d-flex justify-content-between align-items-center p-3'>
                  <div>
                    <span>{formatMessage({ id: 'Request Details' })}</span>
                  </div>
                  <div className='mb-5 me-5'>
                    <button onClick={handleCloseClick} className='close-button border-0'>
                      x
                    </button>
                  </div>
                </div>
                <div>
                  <div></div>
                  <Tabs activeTab={0}>
                    <Tab title={formatMessage({ id: 'Headers' })}>
                      <div className='p-2'>
                        <div className='row'>
                          <div className='col-12 col-md-6 col-lg-6'>
                            <label>{formatMessage({ id: 'Request URL' })}:</label>
                          </div>
                          <div className='col-12 col-md-6 col-lg-6'>
                            <label className='text-wrap w-100'>{selectedEntry.header}</label>
                          </div>
                        </div>
                        <div className='row my-2'>
                          <div className='col-md-6 col-lg-5 col-12'>
                            <label className='me-md-5'>
                              {formatMessage({ id: 'Request Method' })}:
                            </label>
                          </div>
                          <div className='col-md-6 col-lg-7 col-12'>
                            <label className='request-method '>{selectedEntry.method}</label>
                          </div>
                        </div>

                        <div className='row  my-2'>
                          <div className='col-md-4 col-lg-4 col-12'>
                            <label>{formatMessage({ id: 'Status Code' })}:</label>
                          </div>
                          <div className='col-md-8 col-lg-8 col-12'>
                            <label className='mt-1'>
                              <span
                                style={{ color: getStatusColor(selectedEntry.status) }}
                                className='h-5px w-5px'
                              >
                                {' '}
                                ●
                              </span>
                              <span>{selectedEntry.status}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Tab>

                    <Tab title={formatMessage({ id: 'Request' })}>
                      <div className='col-12 h-100 overflow-auto'>
                        <div>
                          {(() => {
                            try {
                              const parsedRequestBody = JSON.parse(selectedEntry.requestBody);
                              return <label>{JSON.stringify(parsedRequestBody, null, 2)}</label>;
                            } catch (error) {
                              console.error('Error parsing request JSON:', error);
                              return <label>{selectedEntry.requestBody}</label>;
                            }
                          })()}
                        </div>
                      </div>
                    </Tab>

                    <Tab title={formatMessage({ id: 'Response' })}>
                      <div className='col-12 h-auto overflow-hidden text-start'>
                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                          <code style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            {selectedEntry?.response}
                          </code>
                        </pre>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </ResizePanel>
          )}
        </div>
      </div>
    </div>
  );
};

export default NetworkInfo;
