import clsx from 'clsx';
import { BWInputProps } from '../../../../types';
import '../../styles/index.scss';
import { ErrorMessage, Field } from 'formik';
import { InputToolTip } from '../UI/InputToolTip';
import { toAbsoluteUrl } from '../../../../core/helpers';
import { useState } from 'react';
import { InputTypes } from '@/enums';

export const BWInput = ({
  fieldName,
  label,
  toolTipText,
  placeholder,
  formik,
  fieldType,
  isRequired,
  isDisabled,
}: BWInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`fv-row mb-6`}>
      <div className={`d-flex justify-content-between `}>
        {label && (
          <label className={`form-label font-size-14 text-dark fw-bold z-index-200`}>
            {label}
            {isRequired && <span className='red-color'>*</span>}
            {toolTipText && <InputToolTip tooltipText={toolTipText as string} />}
          </label>
        )}
      </div>
      <div className={`d-md-flex flex-md-column`}>
        <div className='position-relative'>
          <Field
            className={`${clsx(
              'form-control font-size-14 login-input',
              {
                'is-invalid-local':
                  formik?.touched && formik.touched[fieldName] && formik.errors[fieldName],
              },
              {
                'is-valid-local':
                  formik?.touched && formik.touched[fieldName] && !formik.errors[fieldName],
              }
            )}`}
            type={showPassword && fieldType === InputTypes.PASSWORD ? InputTypes.TEXT : fieldType}
            disabled={isDisabled}
            name={fieldName}
            placeholder={placeholder}
            autoComplete='off'
            onChange={(e: any) => formik.handleChange(e)}
          />
          {fieldType === InputTypes.PASSWORD && (
            <div className='password' role='button' onClick={() => setShowPassword(!showPassword)}>
              <img
                src={toAbsoluteUrl(
                  showPassword
                    ? '/media/icons/duotune/general/openEye.svg'
                    : '/media/icons/duotune/general/eyeHide.svg'
                )}
                width='24px'
                height='24px'
                alt='eye'
              />
            </div>
          )}
        </div>
        <div className='text-danger mt-1'>
          <ErrorMessage name={fieldName} />
        </div>
      </div>
    </div>
  );
};
