import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGlobalContext } from '../../../core/helpers';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/onboarding.scss';
import { GlobalButton } from '../../shared/components/UI/GlobalButton';
import { ButtonTypes } from '@/enums';
import { toAbsoluteUrl } from '../../../core/helpers';
import { useBillingRequests } from '@/requests/useBillingRequests';
import { BillingModal } from '../../shared/components/BillingModal';
import { CurrentPlan } from '@/types/billing';
import { isFreePlan } from '../../../util/isFreePlan';
import { PlanCycle } from '@/enums/billing';
import Skeleton from 'react-loading-skeleton';
import { PlanName } from '@/enums/settings';

export const OnboardingPage = () => {
  const { createCheckoutSession, createSubscriptionForFree } = useBillingRequests();
  const { formatMessage } = useIntl();
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [active, setActive] = useState<PlanCycle>(PlanCycle.YEARLY);
  const { plans, setUserToken, getCurrentUser, plansApiLoading } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<CurrentPlan | undefined>();
  const [clickedPlan, setClickedPlan] = useState<CurrentPlan | undefined>();

  useEffect(() => {
    if (plans) {
      const defaultPlan = plans.find((plan: any) => plan?.title == 'Free');
      setCurrentPlan(defaultPlan);
      setClickedPlan(defaultPlan);
    }
  }, [plans]);
  const navigate = useNavigate();

  const handleSubscription = async () => {
    try {
      setLoading(true);
      if (currentPlan?.title === PlanName.FREE) {
        const {
          data: { data, success },
        } = await createSubscriptionForFree(currentPlan?.price_id as string);
        if (success) {
          const tokenObj = {
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          };
          getCurrentUser(tokenObj);
          setLoading(false);
          setUserToken(data.accessToken);
          navigate('/projects');
        }
      } else {
        const {
          data: { data, success },
        } = await createCheckoutSession(currentPlan?.price_id as string, 'onboarding');
        if (success && data) {
          setLoading(false);
          window.location.href = data;
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div
        className='d-flex flex-column justify-content-center vh-100'
        style={
          {
            '--green-tick-svg': `url(${toAbsoluteUrl('/media/onboarding/green-tick.svg')})`,
          } as React.CSSProperties
        }
      >
        <div className='text-center mb-4'>
          <img
            src={toAbsoluteUrl('/media/logos/bugswork-dark.svg')}
            alt='logo'
            width='180'
            height='50'
          />
        </div>
        <>
          {windowSize.width > 450 ? (
            <div className='d-flex justify-content-center align-items-center  text-black my-5'>
              <div className='company-container container-border d-flex flex-column justify-content-center w-lg-35 px-17'>
                <div className='font-size-24'>
                  <span className='fw-bold'>{formatMessage({ id: 'Choose plan' })}</span>
                </div>

                <div className='mt-8'>
                  <div className='d-flex flex-row justify-content-start'>
                    {plansApiLoading ? (
                      <div>
                        <div>
                          <Skeleton height={25} width={180} />
                        </div>
                        <div className='mt-10'>
                          <Skeleton
                            height={20}
                            width={150}
                            count={4}
                            style={{ marginBottom: '7px' }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className='col-12'>
                        <div className='d-flex justify-content-start'>
                          <img
                            src={toAbsoluteUrl('/media/onboarding/plant.svg')}
                            className='plant mb-1'
                            alt='plant'
                          />
                          <div className='d-flex flex-column mb-2'>
                            <span className='font-size-20 fw-bold m-0 mb-2'>
                              {currentPlan?.title}
                              {currentPlan?.title && !isFreePlan(currentPlan?.title) && (
                                <span className=' ms-2 font-size-16'>
                                  ({active?.charAt(0)?.toUpperCase() + active?.slice(1) + 'ly'})
                                </span>
                              )}
                            </span>
                          </div>
                          <div className='fw-bold font-size-14 text-black mt-2 ms-2'>
                            <Link
                              to=''
                              onClick={() => setShowBillingModal(true)}
                              className='link-primary'
                            >
                              {formatMessage({ id: 'Change plan' })}
                            </Link>
                          </div>
                        </div>
                        <ul className='mt-6 pe-8'>
                          {currentPlan?.features?.map((feature: any, index: number) => (
                            <li className='plan-list' key={index}>
                              {feature.title}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-row justify-content-end mt-7'>
                    <div>
                      <GlobalButton
                        buttonText={formatMessage({ id: 'Continue' })}
                        buttonType={ButtonTypes.PRIMARY}
                        onButtonClick={() => {
                          handleSubscription();
                        }}
                        isDisable={false}
                        isLoading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className='d-flex justify-content-center align-items-center text-black'>
                <div className='company-container d-flex flex-column justify-content-center py-10 w-90 px-10'>
                  <div className='font-size-20'>
                    <span className='fw-bold'>{formatMessage({ id: 'Choose plan' })}</span>
                  </div>
                  <div className=' mt-8'>
                    <div className='d-flex flex-row justify-content-start'>
                      {plansApiLoading ? (
                        <div>
                          <div>
                            <Skeleton height={25} width={180} />
                          </div>
                          <div className='mt-10'>
                            <Skeleton
                              height={20}
                              width={150}
                              count={4}
                              style={{ marginBottom: '7px' }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className='col-12'>
                          <div className='d-flex justify-content-start align-items-center'>
                            <img
                              src={toAbsoluteUrl('/media/onboarding/plant.svg')}
                              className='plant mt-4'
                              alt='plant'
                            />
                            <div className='d-flex flex-row'>
                              <h2 className='font-size-16 fw-bold m-0'>{currentPlan?.title}</h2>
                              {currentPlan?.title && !isFreePlan(currentPlan?.title) && (
                                <span className=' font-size-16'>
                                  ({active.charAt(0)?.toUpperCase() + active.slice(1) + 'ly'})
                                </span>
                              )}
                            </div>
                            <div className=' fw-bold font-size-14 text-black mt-2 ms-2'>
                              <Link
                                to=''
                                onClick={() => setShowBillingModal(true)}
                                className='link-primary'
                              >
                                {formatMessage({
                                  id: 'Change plan',
                                })}
                              </Link>
                            </div>
                          </div>
                          <div className='mt-6 pe-8'>
                            {currentPlan?.features?.map((feature: any, index: number) => (
                              <li className='plan-list' key={index}>
                                {feature.title}
                              </li>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='d-flex flex-row justify-content-end'>
                      <div className='mt-7'>
                        <GlobalButton
                          buttonText={formatMessage({
                            id: 'Continue',
                          })}
                          buttonType={ButtonTypes.PRIMARY}
                          onButtonClick={() => {
                            handleSubscription();
                          }}
                          isDisable={false}
                          isLoading={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>
      <BillingModal
        setShowBillingModal={setShowBillingModal}
        showBillingModal={showBillingModal}
        active={active}
        setActive={setActive}
        setCurrentPlan={setCurrentPlan as Dispatch<SetStateAction<CurrentPlan>>}
        currentPlan={currentPlan as CurrentPlan}
        clickedPlan={clickedPlan}
        setClickedPlan={setClickedPlan}
      />
    </>
  );
};
