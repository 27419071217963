import { FileType } from '@/enums/file';

export function getFileContentType(url?: any) {
  const extension = url?.split('.').pop().toLowerCase();
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'webm'];
  const documentExtensions = ['pdf', 'doc', 'docx', 'txt', 'csv', 'xlsx', 'xls'];

  if (imageExtensions.includes(extension)) {
    return FileType.IMAGE;
  } else if (videoExtensions.includes(extension)) {
    return FileType.VIDEO;
  } else if (documentExtensions.includes(extension)) {
    return FileType.DOCUMENT;
  } else {
    return 'unknown';
  }
}

export const getFileExtension = (fileName: string) => {
  const extension = fileName.split('.').pop();
  return extension;
};
