import axios from 'axios';
import { API_URL } from '.';

const REFERRAL_URL = `${API_URL}/referrals`;
const GET_REFERRAL_DATA = `${REFERRAL_URL}/code`;
const UPDATE_PAYPAL_EMAIL = `${REFERRAL_URL}/paypal`;
const TOTAL_REFERRAL_EARNING = `${REFERRAL_URL}/earnings`;

export const useReferralRequests = () => {
  const getReferralData = () => {
    return axios.get(GET_REFERRAL_DATA, {
      validateStatus() {
        return true;
      },
    });
  };

  const putPaypalReferralEmail = (data: { paypalEmail: string | undefined }) => {
    return axios.put(
      UPDATE_PAYPAL_EMAIL,
      { paypalEmail: data.paypalEmail },
      {
        validateStatus() {
          return true;
        },
      }
    );
  };

  const getTotalReferralEarning = () => {
    return axios.get(TOTAL_REFERRAL_EARNING, {
      validateStatus() {
        return true;
      },
    });
  };

  return {
    getTotalReferralEarning,
    putPaypalReferralEmail,
    getReferralData,
  };
};
