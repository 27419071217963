import { useBillingRequests } from '@/requests/useBillingRequests';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../core/helpers';
import { useState } from 'react';

const useGetBillingData = () => {
  const { getBillingData } = useBillingRequests();
  const [loading, setLoading] = useState(false);
  const getBillingDetailsAPI = async () => {
    setLoading(true);
    try {
      const {
        data: { data: values, success, errors },
      } = await getBillingData();
      if (success) {
        return { billingDetails: values.billingDetails, schedule: values.schedule };
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return { getBillingDetailsAPI, loading };
};
export default useGetBillingData;
