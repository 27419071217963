export enum FileDisplayType {
  THUMBNAIL = 'THUMBNAIL',
  FULL = 'FULL',
}

export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  DOCUMENT = 'document',
  PDF = 'application/pdf',
  TEXT = 'text/plain',
}
