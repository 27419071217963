export const useSplashScreen = () => {
  function setSplashScreen(display: boolean) {
    const splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
      if (display) {
        splashScreen.style.setProperty('display', 'flex');
      } else {
        splashScreen.style.setProperty('display', 'none');
      }
    }
  }
  return { setSplashScreen };
};
