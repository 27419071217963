import React from 'react';
import { InputToolTip } from '../UI/InputToolTip';
import clsx from 'clsx';
import { ErrorMessage, Field } from 'formik';

export const TextArea = ({
  fieldName,
  label,
  margin,
  width,
  toolTipText,
  formik,
  placeholder,
  isTooltipNotRequired,
  isStarRequired,
  isMargin,
  rows,
}: {
  fieldName: string;
  label?: string;
  margin?: string;
  width?: number;
  toolTipText?: string;
  formik?: any;
  placeholder: string;
  isTooltipNotRequired?: boolean;
  isStarRequired?: boolean;
  isMargin?: string;
  rows: number;
}) => {
  return (
    <div className={`fv-row ${margin} ${width ? `col-md-${width}` : ''}`}>
      {label && (
        <div className='d-flex justify-content-between'>
          <label className={` form-label font-size-14 text-dark fw-bold`} htmlFor={fieldName}>
            {isStarRequired ? (
              !isTooltipNotRequired ? (
                <>
                  {label}* <InputToolTip tooltipText={toolTipText as string} />
                </>
              ) : (
                <>{label}*</>
              )
            ) : !isTooltipNotRequired ? (
              <>
                {label} <InputToolTip tooltipText={toolTipText as string} />
              </>
            ) : (
              <>{label}</>
            )}
          </label>
        </div>
      )}
      <div className='position-relative'>
        <Field
          className={clsx(
            'form-control font-size-14 header_bg_color',
            {
              'is-invalid-local': formik.touched[fieldName] && formik.errors[fieldName],
            },
            { 'is-valid-local': formik.touched[fieldName] && !formik.errors[fieldName] }
          )}
          aria-describedby='basic-addon2'
          as={'textarea'}
          rows={rows}
          name={fieldName}
          placeholder={placeholder}
          autoComplete='off'
          onChange={(e: any) => formik.handleChange(e)}
        />
      </div>
      <div className='text-danger '>
        <ErrorMessage name={fieldName} />
      </div>
    </div>
  );
};
