import axios from 'axios';
import { API_URL } from '.';
import { serialize } from '../util/cookie';
import { TaskRequest } from '@/types/projects';
import { TaskStatus } from '@/enums/projects';

const TASK = `${API_URL}/task`;

export const useTaskRequests = () => {
  const getAllTaskLists = (
    projectId: number | undefined,
    status: TaskStatus,
    PER_PAGE_DATA: number,
    pagenumber: number,
    searchValue?: string,
    selectedUserIds?: number[],
    priority?: string
  ) => {
    const params: any = {
      projectId,
      perPage: PER_PAGE_DATA,
      page: pagenumber,
      status,
      priority,
    };

    if (searchValue) {
      params.search = searchValue;
    }

    if (selectedUserIds && selectedUserIds.length > 0) {
      params.assignee = selectedUserIds.join(',');
    }

    // Serialize parameters
    const serializedParams = serialize(params);

    return axios.get(`${TASK}?${serializedParams}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const createTask = (createTaskPayloadJsonData: TaskRequest) => {
    return axios.post(TASK, createTaskPayloadJsonData, {
      validateStatus() {
        return true;
      },
    });
  };

  const updateTask = (payload: any, taskId: number | null) => {
    return axios.put(`${TASK}/${taskId}`, payload, {
      validateStatus() {
        return true;
      },
    });
  };

  const deleteTask = (taskId: number) => {
    return axios.delete(`${TASK}/${taskId}`, {
      validateStatus() {
        return true;
      },
    });
  };

  const getImageUrl = (taskCode: string | undefined, projectId: number | undefined) => {
    return axios.get(`${TASK}/${taskCode}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const getTaskDetails = (taskId: number | undefined, projectId: number | undefined) => {
    return axios.get(`${TASK}/${taskId}?projectId=${projectId}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const getSingleTaskData = (taskCode: string) => {
    return axios.get(`${TASK}/${taskCode}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  const getTaskPublicData = (uuid: string) => {
    return axios.get(`${TASK}/public/${uuid}`, {
      validateStatus(status) {
        return true;
      },
    });
  };

  return {
    getSingleTaskData,
    getTaskDetails,
    getImageUrl,
    deleteTask,
    createTask,
    updateTask,
    getAllTaskLists,
    getTaskPublicData,
  };
};
